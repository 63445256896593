import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'reactstrap';
import { CreateCommentForAsset } from 'src/store/stockpile/stockpileSlice';
import InputFloatingLabel from '../UI/forms/InputFloatingLabel';
import TextArea from '../UI/forms/TextArea';
import { useRequest } from '../../hooks/useRequest'
import { ICategory } from "src/models/Category";
import { useHistory } from 'react-router-dom';

interface Props {
    title: string;
    view: boolean;
    setView: any
    toggle: any
    data?: any
    setData?: any
    sendData?: any;
    placeHolder?: string;
    id?: any;
}

const SelectTypeContact: React.FC<Props> = (props) => {

    const dispatch = useDispatch()

    const { data: categories } = useRequest<ICategory[]>({
        url: "categories/contacts/all",
    });



    const setComment = () => {
        props.sendData()
        props.setData('')
        props.setView(false)
    }

    const history = useHistory();



    return (
        <Modal isOpen={props.view} toggle={() => props.toggle} centered >
            <div className="container text-center">
                <div className="row p-4">
                    <div className="col-12">
                        <h2 className="font-weight-bold text-secondary typo-display">
                            {props.title}
                        </h2>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="container-Selector">

                            {
                                categories &&
                                categories.map((cat: any) => (
                                    <div
                                        className="container-selector-map"
                                        onClick={() => {
                                            history.push(`/blackbook/432332347111638077?create=` + cat?._id)
                                            props.setView(false)
                                        }}
                                        style={{ cursor: 'pointer', alignContent: 'start', }}
                                    >
                                        <h6 className="factory-link" style={{cursor: 'pointer'}}>
                                            {cat?.name}
                                        </h6>

                                    </div>
                                ))
                            }

                        </div>

                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SelectTypeContact;
