import FlatList from 'flatlist-react';
import { Input, Modal } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { getSections } from '../../util/ItemsFunctions';
import RateSection from '../Rates/RateList/RateSection';
import RateListEmpty from '../Rates/RateList/RateListEmpty';
import RateItem from '../Rates/RateList/RateItem';
import { useToast } from "src/hooks/useToast";
import { useEffect, useState, Fragment, useMemo } from 'react';
import {
    GetAllRateForItem,
    getAllItems,
} from "../../store/stockpile/stockpileSlice";
import ItemModel, { RatesModel } from '../../models/ItemModel';
import { RootState } from '../../store/store';
import AddItemsSection from '../Factory/FactoryView/createQuoteForm/modal/AddItemsSection';
import React from 'react';
import SelectInput from '../UI/forms/Select';
import ButtonIconText from "../UI/buttons/ButtonIconText";
import InputSelect from 'src/components/UI/forms/InputSelect';
import InputDate from '../UI/forms/InputDate';
import BarcodeIcon from "../../assets/icons/barcode.svg";
import InputFloatingLabel from 'src/components/UI/forms/InputFloatingLabel';
import Scanner from "../Scanner/scanner";
import SimpleButton from '../UI/buttons/SimpleButton';
import Checkbox from '../UI/forms/Checkbox';
import { GetAllContacts } from '../../store/blackbook/getContactSlice';
import { RateCardItemModel } from '../../models/RateModel';


interface Props {
    isOpen: boolean;
    onClose: () => void;
    onSelectItems: (items: ItemModel[]) => void;
    handleChange?: (item: ItemModel, checked: boolean) => void;
    selectedItemsGeneral?: any
}

const QuoteBuilder: React.FC<Props> = (props) => {
    const selectedItemsGeneral: Array<ItemModel> = props.selectedItemsGeneral
    const dispatch = useDispatch();
    const toast: any = useToast()
    const { ItemData, LoadingItems } = useSelector((state: RootState) => state.stockpileSlice);
    const GetContact: Array<any> = useSelector((state: RootState) => state.getContactAll.getContact);
    const RatesItemSelected: any = useSelector((state: RootState) => state.stockpileSlice.RatesForItem);

    const [cargado, setCargado] = useState(false);
    const [selectedItems, setSelectedtItems] = useState<ItemModel[]>([]);
    const [search, setSearch] = useState<string>("");
    const [scanModal, setScanModal] = useState<boolean>(false);
    const [code, setCoder] = useState<any>("");
    const [supFilter, setSupFilter] = useState<any>("")
    const [itemSelected, setItemSelected] = useState('')
    const [dataSelected, setDataSelected] = useState<any>({})
    const [rangeSupplier, setRangeSupplier] = useState('')
    const [rangeClient, setRangeClient] = useState<any>('')
    const [totalQuote, setTotal] = useState<number>(0)
    const [qty, setQty] = useState(0)
    const [costPerUnit, setCostPerUniy] = useState(0)
    const [chargePerUnit, setChargePerUnit] = useState<string>(0)
    const [marginPerUnit, setMarginPerUnit] = useState(0)

    const [totalMargin, setTotalMargin] = useState<any>('')
    const [totalCost, setTotalCost] = useState(0)
    //const [itemSelected, setCharge] = useState(0)

    const [exempt, setexempt] = useState(false)
    const [discount, setDiscount] = useState(false)


    useEffect(() => {

        setCargado(true)

        if (RatesItemSelected.length < 1 && cargado == true) {
            toast.error('the item selected has not rates')
        }
    }, [RatesItemSelected])



    useEffect(() => {
        dispatch(getAllItems());
    }, [dispatch])


    const handleChange: any = props.handleChange




    const byName = (item: any, name: any) => {
        if (name) {
            console.log(name)
            return item.name.toLowerCase().includes(name.toLowerCase());
        } else
            return true;
    };

    const bySupplier = (item: ItemModel, idSupllier: any) => {
        if (idSupllier) {
            return item?.supplier == idSupllier
        } else {
            return true
        }
    }

    const filteredItems = useMemo(() => {
        return ItemData.filter((item: ItemModel) => byName(item, search))
            .filter((item: any) => item?.supplier)
            .filter((item: ItemModel) => bySupplier(item, supFilter))

    }, [ItemData, search, supFilter])

    let sections = getSections(filteredItems, 'name');

    const suppliersOptions = GetContact?.filter(
        model => model.entityModel === "Client" && model.entity.is_supplier === true
    ).map((contact: any) => ({ label: contact?.entity?.first_name, value: contact?.entity?._id }))

    useEffect(() => {
        dispatch(GetAllContacts())

    }, [])


    const handleSelectQuoteBuildr = (item: ItemModel, checked: boolean) => {
        if (checked) {
            setItemSelected(item?._id)
            setDataSelected({ ...item, tax: exempt, discount_: discount })
        } else {
            setItemSelected('')
            setDataSelected({})
        }
        //  handleChange( item , checked)
    }



    const qtyhandler = (diferencia: any) => {

        let itemsPusheables: Array<ItemModel> = []



        for (let i = 0; i < diferencia; i++) {
            itemsPusheables.push(dataSelected)
            //  handleChange(dataSelected, true)            
        }

        handleChange({}, true, [...selectedItemsGeneral].concat(itemsPusheables))

    }


    useEffect(() => {
        console.log(selectedItemsGeneral);
        if (itemSelected != '') {
            dispatch(GetAllRateForItem(itemSelected))
        }
    }, [itemSelected])

    const calDays = (date: Array<string>) => {

        let inicion = new Date(date[0]).getTime()
        let fin = new Date(date[1]).getTime()
        const diferenciaEnMilisegundos = fin - inicion;
        const dias = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));

        return dias

    }


    const handleQuoteBuilder = () => {

        const ratesItem: RatesModel = RatesItemSelected[0]?.rate_card_item?.rates
        const date_client: any = rangeClient
        const date_supplier: any = rangeSupplier

        const diasSupplier = calDays(date_supplier)

        let contratado = diasSupplier


        //  let calc_holidays = ratesItem?.holiday_day * holidays

        let calc_facturado = ratesItem?.daily * contratado


        setCostPerUniy(calc_facturado)
        setTotal(calc_facturado)


        // let totalCal = calc_facturado + totalQuote

        //    setTotal(totalCal)




    }

    useEffect(() => {
        handleQuoteBuilder()

    }, [rangeClient, rangeSupplier, qty])

    const Calc__margin = () => {
        const date_client: any = rangeClient

        const diasClient = calDays(date_client)

        let taxes = (parseInt(chargePerUnit) * (12 / 100))

        let margin = (parseInt(chargePerUnit) - costPerUnit)

        setMarginPerUnit(margin)

    }

    useEffect(() => {

        setCostPerUniy(totalQuote)
    }, [totalQuote])

    useEffect(() => {
        Calc__margin()
    }, [chargePerUnit, rangeClient, qty])

    const Calc__total_margin = () => {

        const date_client: any = rangeClient

        const diasClient = calDays(date_client)

        let total


        if (marginPerUnit.toString() != 'NaN') {

            total = marginPerUnit * qty

            console.log(qty);
            console.log(marginPerUnit);



        } else {
            total = 0.00
        }

        console.log(total);


        setTotalMargin(total.toString())



    }

    useEffect(() => {
        Calc__total_margin()
    }, [chargePerUnit, qty])

    useEffect(() => {
        let totalrate = (parseInt(chargePerUnit) * (calDays(rangeClient) + 1)) * qty

        setDataSelected({
            ...dataSelected,
            total_for_rate: {
                total: totalrate,
                days: (calDays(rangeClient) + 1),
                qty: qty
            }
        })
    }, [totalQuote, rangeClient, qty, marginPerUnit, chargePerUnit])

    useEffect(() => {
      
        setDataSelected({...dataSelected, tax: exempt, discount_: discount  })
      
    }, [exempt, discount])
    




    return (
        <Modal isOpen={props.isOpen} toggle={props.onClose} style={{ maxWidth: '60vh' }} centered >
            <div className="container " style={{ height: '85vh' }}>
                <div className="cursor-pointer m-1" onClick={() => {
                    props.onClose()
                    setItemSelected('')
                }}>
                    <i className="bi bi-x-lg fs-5"></i>
                </div>
                <div className="row pt-5 pb-2">
                    <div className="col-12" style={{ marginTop: '-5%' }}>
                        <h2 className="text-secondary typo-display fw-bold text-center">
                            Quote builder external
                        </h2>
                    </div>

                </div>
                <div className="row position-relative mx-0">
                    <div className="col p-0 h-100 contact-list custom-scrollbar row " style={{ maxHeight: '75vh' }}>

                        <div className='col-12 mb-3'>
                            <SelectInput
                                value={supFilter}
                                onChange={(value: any) => setSupFilter(value)}
                                options={suppliersOptions}
                                placeholder={'Supplier'}
                            //  isDisabled={true}
                            />
                        </div>
                        <div style={itemSelected == '' ? { pointerEvents: 'none', opacity: 0.6 } : {}}>




                            <div className='col-12 mb-2 mt-1 ps-3 ' style={{ height: '20px' }}>
                                <p>Days hired for supplier</p>
                            </div>
                            <div className='col-12'>
                                <InputDate
                                    select={"range"}
                                    touchUi={true}
                                    onChange={(e: any) => {
                                        setRangeSupplier(e.value)

                                    }}
                                    value={rangeSupplier}
                                    // error={ErrorDate}
                                    placeholder="Select days"
                                    id={'job-date'}
                                />
                            </div>

                            <div className='col-12 mb-2 mt-3 ps-3 ' style={{ height: '20px' }}>
                                <p>Days billed to client</p>
                            </div>
                            <div className='col-12 '>
                                <InputDate
                                    select={"range"}
                                    touchUi={true}
                                    onChange={(e: any) => {
                                        setRangeClient(e.value)

                                    }}
                                    value={rangeClient}
                                    // error={ErrorDate}
                                    // error={ErrorDate}
                                    placeholder="Select days"
                                    id={'job-date'}
                                />
                            </div>



                            <div className='d-flex flex-row gx-5 my-3 ms-4 '>
                                <div className='col'>
                                    <Checkbox
                                        title="Non discountable"
                                        value={discount}
                                        onChange={(value) => { setDiscount(value) }}
                                    />
                                </div>

                                <div className='col'>
                                    <Checkbox
                                        title="TAXT exempt"
                                        value={exempt}
                                        onChange={(value) => { setexempt(value) 
                                            console.log(value);
                                         }}

                                    />
                                </div>
                                <div className='col'>
                                    <Checkbox
                                        title="Confimed"
                                        value={false}
                                        onChange={(value) => { }}

                                    />
                                </div>

                            </div>
                            <div className='row col-12 pt-1  m-0' style={{ minWidth: '48vh' }} >

                                <div className='col-4' style={{ pointerEvents: 'none', opacity: 0.5 }}>
                                    <InputFloatingLabel
                                        placeHolder="COST PER UNIT"
                                        value={costPerUnit || 0.00}
                                        errorMessage={false}
                                        onChange={(val) => { }}
                                    />
                                </div>

                                <div className='col-4'>
                                    <InputFloatingLabel
                                        placeHolder="CHARGE PER UNIT"
                                        value={chargePerUnit}
                                        errorMessage={false}
                                        onChange={(val) => { setChargePerUnit(val) }}
                                    />
                                </div>
                                <div className='col-4' style={{ pointerEvents: 'none', opacity: 0.5 }}>
                                    <InputFloatingLabel
                                        placeHolder="MARGIN PER UNIT"
                                        value={marginPerUnit || 0.00}
                                        errorMessage={false}
                                        onChange={(val) => { }}

                                    />
                                </div>
                                <div className='col-12'>
                                    <InputSelect
                                        select={{
                                            placeholder: 'type',
                                            value: {},
                                            onChange: (value: string) => {
                                            },
                                            options: [
                                                { value: 'mobile', label: 'kg' },
                                                { value: 'home', label: 'Home' },
                                                { value: 'office', label: 'Office' },
                                            ],
                                        }}
                                        input={{
                                            isRequired: false,
                                            placeHolder: 'QTY',

                                            value: qty,
                                            /* @ts-ignore */
                                            errorMessage: false,
                                            onChange: (val: any) => {
                                                setQty(val)
                                            },
                                            width: '100px'
                                        }}
                                    />
                                </div>
                                <table className='table ms-2' >
                                    <thead className="">
                                        <tr className="text-primary typo-body">
                                            <th scope="col"  >
                                                TOTAL COST
                                            </th>
                                            <th scope="col" >
                                                TOTAL CHARGE
                                            </th>
                                            <th scope="col" >
                                                TOTAL MARGIN
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p className='text-primary'>
                                                    ${(totalQuote * (calDays(rangeClient) + 1)) * qty || 0.00}
                                                </p>
                                            </td>
                                            <td>
                                                <p className='text-primary'>
                                                    ${(parseInt(chargePerUnit) * (calDays(rangeClient) + 1)) * qty || 0.00}
                                                </p>
                                            </td>
                                            <td>
                                                <p className='text-primary'>
                                                    ${(marginPerUnit * (calDays(rangeClient) + 1)) * qty || 0.00}
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>



                        <div className="col p-0 " style={{ maxHeight: '400px' }}>

                            {LoadingItems ?
                                (
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                    // style={{ width: "88vw", height: "40vh" }}
                                    >
                                        <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block" />
                                        <span className="text-black ms-3">Loading...</span>
                                    </div>
                                ) :
                                (
                                    <Fragment>
                                        {filteredItems.length > 0 ? (
                                            <FlatList
                                                list={Object.keys(sections)}
                                                renderItem={(item: string, index: number) => {

                                                    return (
                                                        <AddItemsSection
                                                            ref={sections[item].ref}
                                                            key={index}
                                                            letter={item}
                                                            items={sections[item].items}
                                                            handleSelect={handleSelectQuoteBuildr}
                                                            active={itemSelected}
                                                        // indexItem={index}
                                                        // indexItemSelected={indexItemSelected}
                                                        />
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <div className="d-flex flex-column justify-content-center align-items-center h-100 text-muted mx-5 text-center">
                                                <i className="fs-3 bi bi-back d-block"></i>
                                                <p className="h6">No items were found for the current filters.</p>
                                            </div>
                                        )}

                                    </Fragment>
                                )}
                        </div>


                    </div>


                </div>

            </div>
            <div className='row pt-5 mb-2'>
                <div className="col-12 justify-content-center ">
                    <button
                        className="btn btn-success text-white "
                        style={{
                            marginLeft: '14%'
                            , width: '70%'

                        }}
                        onClick={() => {
                            if (qty > 0) {


                                qtyhandler(qty)
                                //handleSelectItems()
                                // close modal
                                props.onClose()
                                setItemSelected('')
                                setRangeClient('')
                                setRangeSupplier('')
                            } else {
                                toast.error('should take qty more than 0')
                            }
                        }}
                    >
                        Add Items
                    </button>

                </div>
            </div>
            <Scanner
                isOpen={scanModal}
                setCode={setSearch}
                setStateModal={setScanModal}
            />
        </Modal>
    );
};

export default QuoteBuilder;
