import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  CaseReducer,
  createReducer,
} from "@reduxjs/toolkit";
import { QuoteStatusData } from "src/models/QuotesModel";
import factoryService from "src/services/factory";
import {
  JobsModel,
  JobsModelId,
  ProjectModel,
} from "../../models/factoryModel";

interface data {
  id: any;
}

export const getAllJobs = createAsyncThunk("factory/getAllJobs", async () => {
  try {
    const data = await factoryService.GetAllJobs();
    return { data: data };
  } catch (error) {
    console.error(error);
  }
});

export const getAllJobsTypes = createAsyncThunk(
  "factory/GetAllJobsTypes",
  async () => {
    try {
      const data = await factoryService.GetAllJobsTypes();
      return { data: data };
    } catch (error) {
      console.error(error);
    }
  }
);

export const getJobsId = createAsyncThunk(
  "factory/getJobsId:id",
  async (id: JobsModelId) => {
    try {
      const data: any = await factoryService.GetJobsId(id);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const getJobById = createAsyncThunk(
  "factory/getJobById:id",
  async (id: JobsModelId) => {
    try {
      const data: any = await factoryService.GetJobById(id);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const CreateJob = createAsyncThunk(
  "factory/createjobs",
  async (
    jobs: any
    //  JobsModel
  ) => {
    try {
      const data: any = await factoryService.CreateJobs(jobs);
      return data;
    } catch (error: any) {
      console.error(error);
      console.log("eerrooorrrrrrJOB", error.data.statusCode.http);
      return error.data.statusCode.http;
    }
  }
);

export const Deletejobs = createAsyncThunk(
  "factory/deleteJobs",
  async (id: any) => {
    try {
      const response = await factoryService.DeleteJobs(id);
      return { data: response };
    } catch (error) {
      console.error(error);
    }
  }
);

export const getAllQuotes = createAsyncThunk(
  "factory/getAllQuotes",
  async () => {
    try {
      const data = await factoryService.GetAllQuotes();
      return { data: data };
    } catch (error) {
      console.error(error);
    }
  }
);

export const getQuotesId = createAsyncThunk(
  "factory/getQuotesId:id",
  async (id: any) => {
    try {
      const data: any = await factoryService.GetQuotesId(id);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const getRatequoteVersion = createAsyncThunk(
  "factory/rate-card-versions/:id/rate-card-items",
  async (id: any) => {
    try {
      const data: any = await factoryService.GetRateCardVerSion(id);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const GetSpecificRatesbyItem_Id = createAsyncThunk(
  "factory/rate-card-versions/:id/accumulated-rate-card-items",
  async (id: any) => {
    try {
      const data: any = await factoryService.GetSpecificRatesbyItemId(
        id.rate_id,
        id.getIdItem
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const CreateQuote = createAsyncThunk(
  "factory/createQuote",
  async (quote: any) => {
    try {
      const data: any = await factoryService.CreateQuotes(quote);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const DeleteQuotes = createAsyncThunk(
  "factory/deleteQuotes",
  async (id: any) => {
    try {
      const response = await factoryService.DeleteQuotes(id);
      return { data: response };
    } catch (error) {
      console.error(error);
    }
  }
);

export const UpdateStatusQuote = createAsyncThunk(
  "factory/quote/id/status",
  async (data: QuoteStatusData) => {
    try {
      const { id, ...fields } = data;
      const response: any = await factoryService.UpdateQuoteStatus(id, fields);
      return response;
    } catch (error) {
      console.error(error);
    }
  }
);

export const GetQuoteVersion = createAsyncThunk(
  "factory/quotes/quote-versions/:id/quote-fields",
  async (id: any) => {
    try {
      const data: any = await factoryService.GetQuoteVersion(id);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const GetTermsAndConditions = createAsyncThunk(
  "factory/quotes/term-conditions",
  async (id: any) => {
    try {
      const data: any = await factoryService.GetTermsAndConditions(id);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
);

const initialState = {
  loading: false,
  jobsData: [] as any,
  jobsDataId: [] as any,
  jobDataById: null,
  jobsTypeData: [] as any,
  QuoteData: [] as any,
  rateQuoteVersion: [] as any,
  specificRatesbyItemId: [] as any,
  QuotesId: null,
  message: "" as any,
  updateQuoteStatus: "" as any,
  jobResponse: null as any,
  QuoteVersion: [] as any,
  Term_conditions: [] as any,
};

const getJobsTypeSlice = createSlice({
  name: "getjob",
  initialState,
  reducers: {
    cleanJobsTypeData: (state) => {
      state.jobsTypeData = [] as any;
      state.jobsData = [] as any;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetTermsAndConditions.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetTermsAndConditions.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(GetTermsAndConditions.fulfilled, (state, action) => {
        state.loading = false;
        state.Term_conditions = action.payload;
      })
      .addCase(GetQuoteVersion.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetQuoteVersion.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(GetQuoteVersion.fulfilled, (state, action) => {
        state.loading = false;
        state.QuoteVersion = action.payload;
      })
      .addCase(UpdateStatusQuote.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(UpdateStatusQuote.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(UpdateStatusQuote.fulfilled, (state, action) => {
        state.loading = false;
        state.updateQuoteStatus = action.payload;
      })
      .addCase(CreateQuote.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(CreateQuote.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(CreateQuote.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
      })
      .addCase(getQuotesId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getQuotesId.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getQuotesId.fulfilled, (state, action) => {
        state.loading = false;
        state.QuotesId = action.payload;
      })

      .addCase(GetSpecificRatesbyItem_Id.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(GetSpecificRatesbyItem_Id.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(GetSpecificRatesbyItem_Id.fulfilled, (state, action) => {
        state.loading = false;
        state.specificRatesbyItemId = action.payload;
      })
      .addCase(getRatequoteVersion.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getRatequoteVersion.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getRatequoteVersion.fulfilled, (state, action) => {
        state.loading = false;
        state.rateQuoteVersion = action.payload;
      })
      .addCase(getAllQuotes.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllQuotes.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllQuotes.fulfilled, (state, action) => {
        state.loading = false;
        state.QuoteData = action.payload;
      })
      .addCase(getJobsId.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getJobsId.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getJobsId.fulfilled, (state, action) => {
        state.loading = false;
        state.jobsDataId = action.payload;
      })

      .addCase(getJobById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getJobById.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getJobById.fulfilled, (state, action) => {
        state.loading = false;
        state.jobDataById = action.payload;
      })

      .addCase(getAllJobs.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllJobs.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllJobs.fulfilled, (state, action) => {
        state.loading = false;
        state.jobsData = [...action.payload?.data];
      })

      .addCase(CreateJob.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(CreateJob.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(CreateJob.fulfilled, (state, action) => {
        console.log("state.fulfilled desde Actionssssssss", action);
        state.jobResponse = action.payload;
        state.loading = false;
      })

      .addCase(getAllJobsTypes.pending, (state, action) => {
        state.loading = true;
      })

      .addCase(getAllJobsTypes.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllJobsTypes.fulfilled, (state, action) => {
        state.jobsTypeData = [...action.payload?.data];
      });
  },
});

export const { cleanJobsTypeData } = getJobsTypeSlice.actions;
export default getJobsTypeSlice.reducer;
