import React, { useState } from "react"
import { Modal } from 'reactstrap';
import InputFloatingLabel from '../../UI/forms/InputFloatingLabel';
import { useFormik } from 'formik'
import QuickAddScheme from './validations'
import ItemModel from 'src/models/ItemModel';
import SelectInput from "../../UI/forms/Select";
import { useDispatch } from "react-redux";
import { CreateItem, CreateRates, getAllItems } from "../../../store/stockpile/stockpileSlice";
import { DataTableItemModel } from "../../../models/RateModel";
import { useToast } from "src/hooks/useToast";

interface Props {
    view: boolean,
    setView: any,
    suppliers: any,
    selecterItem:any
}

const QuickAddExternalItem: React.FC<Props> = (props) => {
    const dispatch = useDispatch()
    const [name, setName] = useState<any>('')
    const [supplier, setSupplier] = useState<any>('')
    const [rates, setRates] = useState<any>({})
    const [modalRates, setModalRates] = useState<any>(false)
    const [idItem, setidItem] = useState<any>()
    const [dataItem, setDateItem] = useState<any>()

    const toast = useToast()

    const formik = useFormik({
        validationSchema: QuickAddScheme,
        initialValues: {
            name: '',
            supplier: ''
        } as ItemModel,
        onSubmit: (values) => {
        }
    })

    const { setFieldValue, errors } = formik

    const handleCreate = async () => {
        const res: any = await dispatch(CreateItem({
            name: name,
            supplier: supplier,
            brand: '',
            category: '67807598690173953',
            description: '',
            picture_url: '',
            photos: []
        } as ItemModel))
        if (res.payload?._id) {
            toast.success('Save item succesfull')
            dispatch(getAllItems())
          //  props.setView(false)
            setName('')
            setSupplier('')
            setidItem(res.payload?._id)
            setDateItem(res?.payload)
           // props.selecterItem(res.payload, true)
            setModalRates(true)
        }
    }

    const handleRates = async () => {
        const AllDataRates = {
            data: rates,
            item: idItem
        }
        const resRates:any = await dispatch(CreateRates(AllDataRates))

            
            toast.success('Save rates succesfull')
            setModalRates(false)
            props.setView(false)
            props.selecterItem(dataItem, true)
        
    }


    return (
        <Modal isOpen={props.view} toggle={() => { }} centered
        >

            <div className="container text-center">
                <div className="row p-4">
                    <div className="col-12">
                        <h2 className="font-weight-bold text-secondary typo-display">
                            Quick Add external item
                        </h2>
                    </div>
                    <div className="col-12 mt-3">
                        <InputFloatingLabel
                            value={name}
                            onChange={(value: any) => {
                                setName(value)
                                setFieldValue("name", value)
                            }}
                            placeHolder='Name'
                            errorMessage={errors.name}
                        />
                    </div>
                    <div className="col-12 mt-2">
                        <SelectInput
                            placeholder={'item supplier'}
                            onChange={(data: any) => {
                                setSupplier(data)
                                setFieldValue('supplier', data)
                            }}
                            value={formik.values.supplier}
                            options={props.suppliers}
                            searchable={true}
                        />
                    </div>
                    <button className="btn btn-success text-white mt-3"
                        style={{ width: '170px', marginLeft: '30%' }}
                        // disabled={formik.errors.length > 0 ? true : false }
                        onClick={() => handleCreate()}
                    >
                        Create external item
                    </button>
                </div>
            </div>
            <Modal isOpen={modalRates} 
                    style={{width: '120%'}}

            >
                <div className="container text-center p-2">
                    <div className="row p-0 mt-4">
                        <div className="col-12">
                            <h2 className="font-weight-bold text-secondary typo-display">
                                Rates
                            </h2>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <InputFloatingLabel
                                placeHolder="Hour rate"
                                value={rates?.hour}
                                onChange={(value) => {
                                    setRates({...rates, hour: value})
                                }}
                                errorMessage={errors?.external?.prices?.hour}
                            />
                        </div>

                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <InputFloatingLabel
                                placeHolder="Day rate"
                                value={rates?.daily}
                                onChange={(value) => {
                                    setRates({...rates, daily: Number(value) })
                                }}
                                errorMessage={errors?.external?.prices?.daily}
                            />
                        </div>

                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <InputFloatingLabel
                                placeHolder="Week rate"
                                value={rates?.week}
                                onChange={(value) => {
                                    setRates({...rates, week: value})
                                }}
                                errorMessage={errors?.external?.prices?.week}
                            />
                        </div>

                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <InputFloatingLabel
                                placeHolder="Sell rate"
                                value={rates?.sale}
                                onChange={(value) => {
                                    setRates({...rates, sale: Number(value) })
                                }}
                                errorMessage={errors?.external?.prices?.sale}
                            />
                        </div>
                    
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <InputFloatingLabel
                                placeHolder="Overtime hour rate"
                                value={rates?.overtime_hour}
                                onChange={(value) => {
                                    setRates({...rates, overtime_hour: value })
                                }}
                                errorMessage={errors?.external?.prices?.overtime_hour}
                            />
                        </div>

                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <InputFloatingLabel
                                placeHolder="Holiday hour rate"
                                value={rates?.holiday_hour}
                                onChange={(value) => {
                                    setRates({...rates, holiday_hour: value })
                                }}
                                errorMessage={errors?.external?.prices?.holiday_hour}
                            />
                        </div>

                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <InputFloatingLabel
                                placeHolder="Holiday day rate"
                                value={rates?.holiday_day}
                                onChange={(value) => {
                                    setRates({...rates, holiday_day: value })
                                }}
                                errorMessage={errors?.external?.prices?.holiday_day}
                            />
                        </div>

                        <div className="col-lg-5 col-md-12 col-sm-12 col-12">
                            <InputFloatingLabel
                                placeHolder="Holiday overtime rate"
                                value={rates?.holiday_overtime_hour}
                                onChange={(value) => {
                                    setRates({...rates, holiday_overtime_hour: value })
                                }}
                                errorMessage={errors?.external?.prices?.holiday_overtime_hour}
                            />
                        </div>
                    </div>
                    <button onClick={() =>  handleRates()} className="btn btn-success text-white" style={{ marginLeft: '0%', width: '200px' }}>
                        Save rates
                    </button>
                </div>
            </Modal>
        </Modal>
    )
}

export default QuickAddExternalItem