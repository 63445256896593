import React from 'react';
import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export interface LayoutStyles {
    FixedPostion: boolean;
}


const initialState: LayoutStyles = {
    FixedPostion: false,
};

const LayoutStyles = createSlice({
    name: 'LayoutStyles',
    initialState,
    reducers: {
        setFixedPosition: (state, action) => {
            state.FixedPostion = action.payload
        }
    },
});

export const { setFixedPosition } = LayoutStyles.actions;

export default LayoutStyles.reducer;
