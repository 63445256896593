import { FreelanceData as TFreelanceData } from 'src/models/ContactModel';
import { AnySchema, boolean, number } from 'yup';
import { array, object, string } from 'yup';

export type FreelanceData = TFreelanceData & {
  contact_since: string;
}

const phoneNumbersSchema = array().of(
  object<Record<keyof Required<FreelanceData>['phone_numbers'][number], AnySchema>>({
    number: string().min(1).max(50).required(),
    type: string().oneOf(['home', 'office', 'mobile']).min(1).max(50).required(),
    primary: boolean().optional().default(false),
  })
).min(1).max(10);

const FreelancePersonalValidation = object<Record<keyof Omit<FreelanceData, 'bookable_skills' | 'credits_name' | 'health_note' | 'languages' | 'payment_info'>, AnySchema>>({
  logo: string().optional(),
  first_name: string().min(1).max(20).required(),
  last_name: string().min(1).max(20).optional(),
  middle_name: string().min(1).max(20).optional(),
  nickname: string().min(1).max(20).optional(),
  email: string().max(100).email().required(),

  nationality: string().min(1).max(50).optional(),
  gender: string().min(1).max(15).optional(),
  title: string().min(1).max(8).optional(),
  address: string().min(1).max(50).optional(),
  union_affiliation: string().min(1).max(150).optional(),

  phone_numbers: phoneNumbersSchema,

  emergency_contacts: array().of(
    object<Record<keyof Required<FreelanceData>['emergency_contacts'][number], AnySchema>>({
      name: string().min(1).max(50),
      relationship: string().min(1).max(50),
      phone_numbers: phoneNumbersSchema,
      emails: array().of(
        object<Record<keyof Required<FreelanceData>['emergency_contacts'][number]['emails'][number], AnySchema>>({
          address: string().email().min(1).max(50),
          type: string().oneOf(['work', 'personal', 'other']).min(1).max(50),
        })
      ).optional(),
    })
  ).optional(),

  social: object({
    facebook: string().min(1).max(100),
    twitter: string().min(1).max(100),
    instagram: string().min(1).max(100),
    linkedin: string().min(1).max(100),
  }).optional(),
  
  communication: object({
    skype: string().min(1).max(100),
    discord: string().min(1).max(100),
    website: string().min(1).max(100),
  }).optional(),

  date_of_birth: string().optional(),
  contact_since: string().optional(),
})

export const FreelanceDetailsValidation = object({
  credits_name: string().min(1).max(30).optional(),

  bookable_skills: array().of(
    object({
      rates: object({
        hour: number().min(1).optional().typeError('Hour price must be a number'),
        daily: number().min(1).optional().typeError('Day price must be a number'),
        week: number().min(1).optional().typeError('Week price must be a number'),
        holiday_day: number().min(1).optional().typeError('Holiday rate price must be a number'),
        holiday_hour: number().min(1).optional().typeError('Holiday hour price must be a number'),
        overtime_hour: number().min(1).optional().typeError('Overtime hour price must be a number'),
        holiday_overtime_hour: number().min(1).optional().typeError('Holiday overtime hour price must be a number'),
      }).optional(),
      skillset: string().min(1).max(50).required(),
    }).optional(),
  ).optional(),

  payment_info: array().of(
    object({
      account: string().min(1).required(),
      account_owner: string().min(1).required(),
      type: string().min(1).required(),
      bank: string().min(1).required(),
      method: string().min(1).required(),
    }).optional()
  ),
})

export default FreelancePersonalValidation;