import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams, useRouteMatch  , useHistory } from "react-router-dom";
import Clock from "../../components/clock";
import authService from "../../services/auth";
import { useRequest } from "../../hooks/useRequest";
import logoIcon from "../../assets/images/GAMEAE_logo_white.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import useQuery from "../../hooks/useQuery";
import { getProjectId } from "../../store/factory/factorySlice";
import { getJobById } from "../../store/factory/factoryJobSlice";
import { layoutConfig } from '../../store/layout';

interface Props {
  className?: String;
  toggleSideBar: () => void;
}

const routes: { [key: string]: string } = {
  "/blackbook": "BLACKBOOK",
  "/stockpile": "STOCKPILE",
  "/rates": "RATES",
  "/factory": "FACTORY",
};

const Topbar: React.FC<Props> = (props) => {
  const location = useLocation();
  const { mutate } = useRequest({ url: "users/me" });
  const dispatch = useDispatch();

  let history = useHistory();


  let match = useRouteMatch("/factory/:idproject/:idjob");

  const config = useSelector((state: RootState) => state.layoutSlice);

  const handleLogout = useCallback(() => {
    authService.Logout();
    /* Revalidate user login status */
    mutate(null, true);
  }, [mutate]);

  const ProjectData = useSelector((state: RootState) => state.factorySlice);

  const { projectDataId } = ProjectData;

  const { jobDataById } = useSelector(
    (state: RootState) => state.getJobsTypeSlice
  );

  const getDataId = () => {
    dispatch(getProjectId(match?.params.idproject));
    dispatch(getJobById(match?.params.idjob));
  };

  useEffect(() => {
    if(!config.moduleTitle){
      dispatch(layoutConfig.setModuleTitle(routes[location.pathname] ?? ''));
    }
  }, [location.pathname, dispatch]);

  return (
    <div
      // color="primary"
      className="topbar container-fluid bg-primary pb-5"
    >
      <div className="row align-items-center h-100" style={{paddingBottom: '25%'}}>
        <div className="col col-sm-5 d-flex align-items-center">
          <i className="bi bi-list text-white ms-2 h3 m-0" onClick={props.toggleSideBar}></i>
          <Link to={'#'} onClick={ () => history.goBack() }>
            <i className="bi bi-chevron-left text-white h6 m-0 ms-4 "></i>
          </Link>
          <p className="text-white m-0 ms-3 h6">
            {config.moduleTitle}
          </p>
          {
            config.path.map( (e) => 
              <Link
                to={e.redirect}
                className="text-white text-decoration-none text-nowrap"
              >
                {`\\ ${e.label.toUpperCase()}`}
              </Link>
            )
          }
        </div>

        <div className="col-2 d-flex justify-content-center d-sm-block">
          <Link to="/">
            <img
              src={logoIcon}
              alt=""
              height={'40px'}
              width={'150px'}
              style={{margin: 0 , padding: 0, marginTop: '5%'}}
              className="pb-2 ms-4"
            />
          </Link>
        </div>

        <div className="col  d-flex align-items-center justify-content-end">
          <p className="text-white m-0 ms-3 d-none d-lg-block">
            Translate Script
          </p>

          <div className="d-none d-md-block">
            <Clock className="mx-3" />
          </div>

          <i className="bi bi-search text-white h6 m-0 mx-3"></i>
          <i className="bi bi-bell-fill text-white h6 m-0 mx-3"></i>

          <div className="dropdown z-3">
            <button
              className="btn btn-primary"
              type="button"
              id="avatar_dropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              
            >
              <img
                className="avatar mx-2 dropdown-toggle"
                src="https://www.edarabia.com/wp-content/uploads/2015/11/7-ways-to-become-the-person-everyone-respects.jpg"
                alt=""
              />
            </button>

            <ul className="dropdown-menu position-absolute z-3" aria-labelledby="avatar_dropdown" >
              <li>
                <a className="dropdown-item" href="#">
                  Action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
              </li>
              <li>
                <span className="dropdown-item" onClick={handleLogout}>
                  Logout
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
