import FlatList from "flatlist-react";
import LetterSection from "src/components/DashboardLayout/MidCard/LetterSection";
import ListItem from "src/components/DashboardLayout/MidCard/ListItem";
import ItemModel from "src/models/ItemModel";
import ContactItem from '../Stock/StockList/ContactItem';
import { ABC, getSections } from '../../util/index';
import { useDispatch, useSelector } from "react-redux";
import { getAllItems, setPagePag } from "src/store/stockpile/stockpileSlice";
import { RootState } from "src/store/store";
import InfiniteScroll from 'react-infinite-scroll-component';
import { lazy, useEffect, useState } from "react";


interface Props {
    ItemData: any,
    LoadingItems: any,
    sections: any,
    results: any,
    setID: any,
    showAssets: any
}




const ItemListComponent: React.FC<Props> = (props) => {

    const dispatch = useDispatch()



    const ItemData = props.ItemData
    const LoadingItems = props.LoadingItems
    const sections = props.sections
    const results = props.results


    const fetchData = () => {
       // dispatch(getAllItems());

    }

    useEffect(() => {
        fetchData()
    }, []);




    return (

        <div id="scrollableDiv" className="col  h-100 custom-scrollbar contact-list p-0 ps-2" >
       
                <div className="p-0 m-0 h-100" >
                    {LoadingItems && (
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block"></i>
                        </div>
                    )}

                    {ItemData && ItemData.length > 0 && (
                        <FlatList
                            list={Object.keys(sections)}
                            renderItem={(item: any, index: any) => {
                                if (!sections[item].items.length) return null;
                                if (!item) return 'without brand'
                                return (
                                    <LetterSection
                                        key={index}
                                        letter={item}
                                        ref={sections[item].ref}
                                        renderItems={() => (
                                            <>
                                                {sections[item].items.map((Item: ItemModel, index: any) => {
                                                    return (<ContactItem
                                                        key={index}
                                                        item={Item}
                                                        onSelect={() => { }}
                                                        setID={props.setID}
                                                        showAssets={props.showAssets}
                                                        index={index}

                                                    />)
                                                })}
                                            </>
                                        )}
                                    />
                                );

                            }}
                            renderWhenEmpty={() =>
                                <p></p>
                            }

                        />)}
                    {results.length < 1 && ItemData.length > 0 && (
                        <div className="d-flex flex-column justify-content-center align-items-center h-100 text-muted mx-5 text-center">
                            <i className="fs-3 bi bi-back d-block"></i>
                            <p className="h6">No items were found for the current filters.</p>
                        </div>
                    )}

                    {ItemData.length < 1 && (
                        <div className="d-flex flex-column justify-content-center align-items-center h-100 text-muted mx-5 text-center">
                            <i className="fs-3 bi bi-back d-block"></i>
                            <p className="h6">No items were found , must be create an new item.</p>
                        </div>
                    )}
                </div>
        </div >
    )

}

export default ItemListComponent