import { FormikProvider, Form, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import ComboBox from "../../../../UI/forms/ComboBox";
import InputFloatingLabel from "../../../../UI/forms/InputFloatingLabel";
import InputSelect from "../../../../UI/forms/InputSelect";
import ItemModel, { AssetModel, warehouse, purchase } from '../../../../../models/ItemModel'
import {
  setData, setAsset, updateValuesAssets, setDatesData, AddFormDate
  , changeAssetsErrors, setNameFiles, ChangeFormNameFile, DeleteAsset, DeleteDate
} from "../../../../../store/stockpile/createFormSlice";
import { Button } from "reactstrap";
import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import AssetScheme from "./validation";
import { RootState } from '../../../../../store/store';
import ButtonFiles from "../../../../UI/buttons/ButtonFile";
import SimpleButton from "src/components/UI/buttons/SimpleButton";
import { Datepicker } from "@mobiscroll/react";
import moment from "moment";
import InputDate from "src/components/UI/forms/InputDate";
import SelectInput from "src/components/UI/forms/Select";

import { ConvertSize } from '../../../../../util/sizesConvert'
import { AllWareHouses } from "src/store/stockpile/stockpileSlice";
import { useRequest } from "src/hooks/useRequest";
import DropZone from "src/components/UI/Dropzone";
import IconButton from "src/components/UI/buttons/IconButton";
import CurrencyInput from 'react-currency-input-field';


interface Props {
  setImages: (element: any) => void;
  index: number,
  dateCount?: any,
  setDateCount?: any,
  ref?: any
}


const FormAsset = forwardRef((props: Props) => {

  const input: any = useRef()
  const prefix = "$ ";
  const [value, setValue] = useState<any>();

  const data = useSelector((state: RootState) => state.createFormSlice.AssetsData);
  const ItemID: any = useSelector((state: RootState) => state.stockpileSlice.ItemResponse);
  const ArrayArraysDates = useSelector((state: RootState) => state.createFormSlice.DatesData);
  const WareHouses: Array<any> = useSelector((state: RootState) => state.stockpileSlice.AllWareHouses);
  const { data: ALL_ASSETS } = useRequest<any>({ url: 'stockpile/assets/all' }) || [];

  const [errorDigitalReceipt, setErrorDigitalReceipt] = useState<any>()
  const [fileReceipt, setFileReceipt] = useState<any>([])
  const dateCount = ArrayArraysDates[props.index]
  const nameManual = useSelector((state: RootState) => state.createFormSlice.files);
  const ValuesData = data[props.index]

  const dispatch = useDispatch()

  useEffect(() => {

  }, [dateCount])

  const setDateCount = (value: any, indexInput: any) => {

    const cp = dateCount.map((object, index) => {
      let typ
      typ = index == 0 ? 'date' : index == 1 ? 'endoflife' : index == 2 ? 'warranty' : 'undefined'
      if (index == indexInput) {
        object = { ...object, ...{ date: value.date, type: typ } }
      }
      return object
    })

    dispatch(setDatesData({ index: props.index, data: cp }))

    if (indexInput == 0) {
      updateCamp({ ...ValuesData, purchase: { ...ValuesData.purchase, date: moment(value.date).format('YYYY-MM-DD') } } as AssetModel)
    }

    if (indexInput == 1) {
      updateCamp({ ...ValuesData, end_of_life: moment(value.date).format('YYYY-MM-DD') } as AssetModel)

    }
    if (indexInput == 2) {
      updateCamp({ ...ValuesData, warranty_expiration: moment(value.date).format('YYYY-MM-DD') } as AssetModel)
    }


  }

  const AddDate = () => {
    dispatch(AddFormDate(props.index))
  }

  useEffect(() => {
    ['', '', ''].map(() => {
      AddDate()
    })
  }, [])

  const base = {
    item: ItemID?._id,
    serial: ValuesData?.serial,
    purchase: {
      date: '',
      origin: '',
      price: 0
    },
    warehouse: {
      warehouse: 'warehouse',
      location: '',
      shelf_id: ''
    },
    warranty_expiration: '2020-08-05',
    end_of_life: '2020-08-05',
    is_external: false,
    has_digital_receipt: false,
    sizes: {
      name: 'dw',
      width: '',
      height: '',
      weight: '',
      length: '',
      weight_unit: 'centimeters',
      sizes_unit: 'centimeters'
    }
  } as AssetModel

  const formik = useFormik({
    validationSchema: AssetScheme,
    initialValues: {
      ...base
    } as AssetModel,
    onSubmit: (values) => {
    }
  })

  const { errors, handleSubmit, values, setFieldValue, setErrors } = formik

  useEffect(() => {
    dispatch(AllWareHouses())
  }, [])

  const warer = WareHouses.length > 0 ? WareHouses.map((war) => {
    return { label: war.name, value: war._id }
  }) : [{ label: 'No options', value: '----' }]

  useEffect(() => {
    if (warer.length > 0) {
      updateCamp({ ...ValuesData, warehouse: { ...ValuesData?.warehouse, warehouse: warer[0].value } } as AssetModel)
    }
  }, [WareHouses])

  const updateCamp = (value: AssetModel) => {
    const ret: any = data.slice(0);
    const newData: any = {
      ...{ sizes: { weight_unit: 'grams', sizes_unit: 'centimeters' } },
      ...ret[props.index], ...value, item: ItemID,
      is_external: false
    } as AssetModel;


    ret[props.index] = Object.keys(newData)
      .filter((k) => newData[k] != null)
      .reduce((a, k) => ({ ...a, [k]: newData[k] }), {});

    dispatch(updateValuesAssets(ret))
  }

  useEffect(() => {

    const ret: any = data.slice(0);
    const newData: any = { ...ValuesData, has_digital_receipt: false } as AssetModel;

    ret[props.index] = Object.keys(newData)
      .filter((k) => newData[k] != null)
      .reduce((a, k) => ({ ...a, [k]: newData[k] }), {});

    dispatch(updateValuesAssets(ret))

  }, [])

  const checkAlreadyExistSerial = (serial: any) => {
    const com: Array<any> = ALL_ASSETS?.filter((asset: any) => asset?.serial == serial)
    return com?.length > 0 ? false : true
  }

  useEffect(() => {
    !checkAlreadyExistSerial(formik.values.serial) && setErrors({ serial: 'the serial already exist' })
  }, [formik.values.serial, errors.serial])

  const updateCampDates = () => {
    /*
    const ret = data.slice(0);
    ret[props.index] = {
      ...ret[props.index],
      purchase: {
        ...ret[props.index]?.purchase,
        date: '2000-08-03'
      }
    } as AssetModel;
    dispatch(updateValuesAssets(ret))
    */
  }

  const handleDigitalReceipt = (e: any) => {
    setFileReceipt(e)
    const file = e[0]
    //  if (!file.name.match(/\.(pdf|odt|doc|docx)$/)) {
    //    setErrorDigitalReceipt('Please select valid File. (pdf , odt, doc, docx)');
    //   return false;
    // } else {
    props.setImages(file)
    dispatch(ChangeFormNameFile({ index: props.index, name: file.name }))
    updateCamp({ ...ValuesData, has_digital_receipt: true } as AssetModel)
    //}
  }

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      dispatch(changeAssetsErrors(true))
    } else {
      dispatch(changeAssetsErrors(false))
    }
  }, [errors])

  const deleteForm = () => { dispatch(DeleteAsset(props.index)) }

  const handleChange = (e) => {

    setValue(parsedValue);
  };

  const handleOnBlur = () => setValue(Number(value).toFixed(2));


  return (
    <>
      <div className="row" ref={props.ref}>
        <div className="d-flex gap-3 text-primary align-items-center m-2">
          <p style={{ whiteSpace: 'nowrap' }} className="mb-0">Asset #{props.index + 1}</p>
          <hr className="w-100" />
          {
            props.index > 0 && <IconButton
              outline
              color="danger"
              icon="bi bi-x"
              tooltip="Remove skillset"
              onClick={deleteForm}
            />
          }
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <InputFloatingLabel
            placeHolder="Serial number"
            value={formik.values.serial}
            onChange={(value) => {
              updateCamp({ ...ValuesData, serial: value } as AssetModel)
              setFieldValue('serial', value)
            }}
            isRequired
            errorMessage={errors.serial}
          />
        </div>

        <div className="col-sm-12 col-md-12 col-lg-4">
          <CurrencyInput
            prefix={prefix}
            name="currencyInput"
            id="currencyInput"
            data-number-to-fixed="2"
            data-number-stepfactor="100"
            value={ValuesData?.purchase?.price}
            placeholder="Price paid"
            onChange={
              (e: any) => {
                e.preventDefault();
                const { value = "" } = e.target;
                const parsedValue = value.replace(/[^\d.]/gi, "");
                updateCamp({ ...ValuesData, purchase: { ...ValuesData?.purchase, price: parsedValue } } as AssetModel)
                setFieldValue('purchase.price', parsedValue)
              }
            }
            onBlur={handleOnBlur}
            allowDecimals
            decimalsLimit="2"
            disableAbbreviations
            onError={
              errors.purchase?.price

            }
            className="form-control text-secondary typo-body-important colorCurrency"
            style={{ height: 42, color: 'red' }}

          />
        </div>

        <div className="col-sm-12 col-md-12 col-lg-4">
          <InputFloatingLabel
            placeHolder="Origin of purchase"
            value={ValuesData?.purchase?.origin}
            onChange={(value) => {
              updateCamp({ ...ValuesData, purchase: { ...ValuesData?.purchase, origin: value } } as AssetModel)
            }}
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-sm-12 col-md-12 col-lg-6">
          <p className="text-primary typo-body">SELECT DIGITAL RECEIPT</p>

          <div className="p-0 w-100">

            <DropZone
              setFile={handleDigitalReceipt}
              files={fileReceipt}
              maxFiles={1}
              errors={errorDigitalReceipt}
              file
            />
          </div>
        </div>

        <div className="col-lg-6  p-0">
          {
            dateCount?.slice(0, 3).map((item: any, index: any) => {
              return (<>
                {//index > 0 && <div className="col-sm-12 col-md-12 col-lg-6"></div>
                }
                <div className={`col-sm-12 col-md-12 col-lg-12 p-0 offset-lg-6 row mt-1
               ms-0`}
                >
                  <div className="col-sm-12 col-md-12 col-lg-4 ">
                    <ComboBox
                      onChange={(value) => {
                        setDateCount({ ...item.type, type: value }, index)
                      }}
                      value={
                        index == 0 ? 'date' : index == 1 ? 'endoflife' : index == 2 ? 'warranty' : ""
                      }
                      options={
                        index == 0 ? [
                          { title: 'Date of purchase', value: 'date' }] :
                          index == 1 ? [{ title: 'End of life', value: 'endoflife' }] :
                            index == 2 ? [{ title: 'Warranty Expiration', value: 'warranty' }] :
                              []
                      }
                    />
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6" >
                    <InputDate onChange={(event: any) => {
                      setDateCount({ date: event.value, ...item.date }, index)
                    }}
                      value={item.date} ></InputDate>
                  </div>

                </div>
              </>
              )
            })
          }
        </div>

      </div>



















      <div className="row mt-3">






        <div className="col-sm-12 col-md-12 col-lg-6">
          <SelectInput
            placeholder={'Condition'}
            onChange={(value: any) => {
              updateCamp({ ...ValuesData, condition: value } as AssetModel)
            }}
            value={ValuesData?.condition}
            options={[
              { label: 'Excellent', value: 'excellent' },
              { label: 'Good', value: 'good' },
              { label: 'Fair', value: 'fair' },
              { label: 'Poor', value: 'poor' },
            ]}
          />
        </div>

        <div className="col-sm-12 col-md-12 col-lg-6">
          <div >

          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-sm-12 col-md-12 col-lg-6">
          <p className="m-0 text-primary typo-body">Item sizes</p>
          <div className="mt-1">
            <InputSelect
              select={{
                onChange: (value: any) => {
                  updateCamp({ ...ValuesData, sizes: { ...ValuesData?.sizes, weight_unit: value } } as AssetModel)
                  //setFieldValue('sizes.weight_unit', value)
                },
                options: [
                  {
                    label: 'Kg',
                    value: 'kilograms',
                  },
                  {
                    label: 'Grams',
                    value: 'grams',
                  },
                  {
                    label: 'Pounds',
                    value: 'pounds',
                  },
                  {
                    label: 'Ounces',
                    value: 'ounces',
                  }
                ],
                value: ValuesData?.sizes?.weight_unit ? ValuesData?.sizes?.weight_unit : 'centimeters',
              }}
              input={{
                onChange: (value) => {
                  updateCamp({ ...ValuesData, sizes: { ...ValuesData?.sizes, weight: value } } as AssetModel)
                  setFieldValue('sizes.weight', value)
                },
                placeHolder: 'Weight',
                value: ValuesData?.sizes?.weight,
                errorMessage: errors?.sizes?.weight
              }}
            />
          </div>

          <div className="mt-2">
            <InputSelect
              select={{
                onChange: (value: any) => {
                  updateCamp({ ...ValuesData, sizes: { ...ValuesData?.sizes, sizes_unit: value } } as AssetModel)
                  // setFieldValue('sizes.sizes_unit', value)
                },
                options: [
                  {
                    label: ConvertSize('centimeters'),
                    value: 'centimeters',
                  },
                  {
                    label: 'Feet',
                    value: 'feet',
                  },
                  {
                    label: 'Inches',
                    value: 'inches',
                  },
                  {
                    label: 'Meters',
                    value: 'meters',
                  },
                ],
                value: ValuesData?.sizes?.sizes_unit ? ValuesData?.sizes?.sizes_unit : 'centimeters',
              }}
              input={{
                onChange: (value) => {
                  updateCamp({ ...ValuesData, sizes: { ...ValuesData?.sizes, length: value } } as AssetModel)
                  setFieldValue('sizes.length', value)
                },
                placeHolder: 'Length',
                value: ValuesData?.sizes?.length,
                errorMessage: errors?.sizes?.length
              }}
            />
          </div>

          <div className="mt-2 col-md-10 float-end" style={{ paddingLeft: '7.5%', paddingRight: '.5%' }}>
            <InputFloatingLabel
              placeHolder="Width"
              value={ValuesData?.sizes?.width}
              onChange={(value) => {
                updateCamp({ ...ValuesData, sizes: { ...ValuesData?.sizes, width: value } } as AssetModel)
                setFieldValue('sizes.width', value)
              }}
              errorMessage={errors?.sizes?.width}
            />
          </div>

          <div className="mt-2 col-md-10 float-end" style={{ paddingLeft: '7.5%', paddingRight: '.5%' }}>
            <InputFloatingLabel
              placeHolder="Height"
              value={ValuesData?.sizes?.height}
              onChange={(value) => {
                updateCamp({ ...ValuesData, sizes: { ...ValuesData?.sizes, height: value } } as AssetModel)
                setFieldValue('sizes.height', value)
              }}
              errorMessage={errors?.sizes?.height}
            />
          </div>
        </div>

        <div className="col-lg-6 col-sm-12 col-md-12">
          <p className="m-0 text-primary typo-body">Warehouse Location</p>

          <div className="mt-2">
           
            <SelectInput
              placeholder={'WareHouse'}
              onChange={(value: any) => {
                updateCamp({ ...ValuesData, warehouse: { ...ValuesData?.warehouse, warehouse: value } } as AssetModel)
              }}
              value={ValuesData?.warehouse?.warehouse}
              options={warer}
            />
          </div>

          <div className="mt-2">
            <InputFloatingLabel
              placeHolder="Location within warehouse"
              value={ValuesData?.warehouse?.location}
              onChange={(value) => {
                updateCamp({ ...ValuesData, warehouse: { ...ValuesData?.warehouse, location: value } } as AssetModel)
                setFieldValue('warehouse.location', value)
              }}
              errorMessage={errors?.warehouse?.location}
            />
          </div>

          <div className="mt-2">
            <InputFloatingLabel
              placeHolder="Shelf ID"
              value={ValuesData?.warehouse?.shelf_id}
              onChange={(value) => {
                updateCamp({ ...ValuesData, warehouse: { ...ValuesData?.warehouse, shelf_id: value } } as AssetModel)
                setFieldValue('warehouse.shelf_id', value)
              }}
              errorMessage={errors?.warehouse?.shelf_id}
            />
          </div>
        </div>
      </div>
    </>)
})

export default FormAsset