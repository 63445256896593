import Select, { components, StylesConfig, GroupBase } from "react-select";

interface Props {
  onChange: any;
  value: any;
  options: any;
  placeholder?: any;
  searchable?: boolean;
  className?: any;
}

const DivColor = (props: any) => {
  return (
    <>
      <div
        style={{
          width: "32px",
          height: "32px",
          background: props.props.selectProps.value.value
            ? props.props.selectProps.value.value
            : "#e600a1",
        }}
      />
    </>
  );
};

const ValueContainer = ({ children, ...props }: any) => (
  <components.ValueContainer {...props}>
    <DivColor props={props} />
  </components.ValueContainer>
);

const CaretDownIcon = () => {
  return (
    <>
      <svg
        enable-background="new 0 0 29 14"
        height="10px"
        id="Layer_1"
        version="1.1"
        viewBox="0 0 29 14"
        width="7px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon fill="#8898AA" points="0.15,0 14.5,14.35 28.85,0 " />
      </svg>
    </>
  );
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};

const colourStyles: StylesConfig<any, false, GroupBase<any>> = {
  option: (styles: any, sourc) => {
    const { isFocused } = sourc;

    return {
      ...styles,
      background: "#ffff",

      color: "#6B8AB8",
      "&:hover": {
        color: "white",
        backgroundColor: isFocused ? "#6B8AB8" : null,
      },
    };
  },

  placeholder: (base) => ({
    ...base,
    fontSize: "13px",
    color: "#6B8AB8",
    fontWeight: 400,
  }),
  menu: (provided) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: "#6B8AB8",
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #6B8AB8" : "1px solid #DEE2E6",
    boxShadow: state.isFocused ? "0px 0px 6px #6B8AB8" : "none",
    color: "white",
    height: 45,
    minHeight: 37,
  }),

  singleValue: (base) => ({ ...base, color: "white" }),
  valueContainer: (base) => ({
    ...base,
    background: "#fff",
    color: "white",
    width: "50px",
    height: "42px",
  }),
};

const SelectInput: React.FC<Props> = (props) => {
  return (
    <Select
      defaultValue={props.value}
      styles={colourStyles}
      components={{ ValueContainer, DropdownIndicator }}
      isSearchable
      name="color"
      options={props.options}
      onChange={(newValue) => props.onChange(newValue.value)}
    />
  );
};

export default SelectInput;
