import React, { useEffect, useState } from "react";
import Milestone from "./Milestone";
import Requiriment from "./Requirement";
import Document from "./Document";
import Deliverable from "./Deliverable";

interface Props {
  GetJobType?: any;
  milestoneStateArray: any;
  setMilestoneStateArray: any;
  onSubmitControlForm: any;
  setOnSubmitControlForm: any;
  generarId: any;
}

const MilestonesTab: React.FC<Props> = (props) => {
  const [milestones, setMilestones] = useState<[]>([]);
  const { jobsTypeData } = props.GetJobType;

  const selectData = () => {
    if (jobsTypeData) {
      let type: any = [];

      jobsTypeData.forEach((e: any) => {
        if (e.milestones) {
          type.push(e.milestones);
        }
      });

      setMilestones(type);
    }
  };

  const initData = {
    milestones_name: "",
    location: "",
    start_date_milestone: "",
    end_date_milestone: "",
    color: "",
    id: props.generarId(),
  };

  useEffect(() => {
    selectData();
  }, [jobsTypeData]);

  let mil = milestones.flat(3);

  const SelectOptions = mil.map((e: any) => {
    let obj = { label: e.color, value: e.color };

    return obj;
  });

  const [checkBoxDeliverable, setCheckBoxDeliverable] = useState<any>([
    [
      { name: "Deliverable 1", checked: false },
      { name: "Deliverable 2", checked: false },
      { name: "Deliverable 3", checked: false },
      { name: "Deliverable 4", checked: false },
      { name: "Deliverable 5", checked: false },
      { name: "Deliverable 6", checked: false },
      { name: "Deliverable 7", checked: false },
      { name: "Deliverable 8", checked: false },
    ],
  ]);

  const [checkBoxDocument, setCheckBoxDocument] = useState<any>([
    [
      { name: "Document 1", checked: false },
      { name: "Document 2", checked: false },
      { name: "Document 3", checked: false },
      { name: "Document 4", checked: false },
      { name: "Document 5", checked: false },
      { name: "Document 6", checked: false },
      { name: "Document 7", checked: false },
      { name: "Document 8", checked: false },
    ],
  ]);
  const [checkBoxRequirement, setCheckBoxRequirement] = useState<any>([
    [
      { name: "Requirement 1", checked: false },
      { name: "Requirement 2", checked: false },
      { name: "Requirement 3", checked: false },
      { name: "Requirement 4", checked: false },
      { name: "Requirement 5", checked: false },
      { name: "Requirement 6", checked: false },
      { name: "Requirement 7", checked: false },
      { name: "Requirement 8", checked: false },
    ],
  ]);

  const newDeliverable = [
    { name: "Deliverable 1", checked: false },
    { name: "Deliverable 2", checked: false },
    { name: "Deliverable 3", checked: false },
    { name: "Deliverable 4", checked: false },
    { name: "Deliverable 5", checked: false },
    { name: "Deliverable 6", checked: false },
    { name: "Deliverable 7", checked: false },
    { name: "Deliverable 8", checked: false },
  ];

  const newDocument = [
    { name: "Document 1", checked: false },
    { name: "Document 2", checked: false },
    { name: "Document 3", checked: false },
    { name: "Document 4", checked: false },
    { name: "Document 5", checked: false },
    { name: "Document 6", checked: false },
    { name: "Document 7", checked: false },
    { name: "Document 8", checked: false },
  ];

  const newRequirement = [
    { name: "Requirement 1", checked: false },
    { name: "Requirement 2", checked: false },
    { name: "Requirement 3", checked: false },
    { name: "Requirement 4", checked: false },
    { name: "Requirement 5", checked: false },
    { name: "Requirement 6", checked: false },
    { name: "Requirement 7", checked: false },
    { name: "Requirement 8", checked: false },
  ];

  return (
    <div>
      <small className="text-gray">MILESTONES</small>

      {props.milestoneStateArray.map((mileStone: any, index: number) => (
        <Milestone
          key={mileStone.id}
          index={index}
          mileStone={mileStone}
          SelectOptions={SelectOptions}
          milestoneStateArray={props.milestoneStateArray}
          setMilestoneStateArray={props.setMilestoneStateArray}
          onSubmitControlForm={props.onSubmitControlForm}
          setOnSubmitControlForm={props.setOnSubmitControlForm}
          generarId={props.generarId()}
        />
      ))}

      <div
        className="cursor-pointer "
        onClick={() =>
          // props.milestoneStateArray.length > 1
          //   ? null
          //   :
          props.setMilestoneStateArray([
            ...props.milestoneStateArray,
            initData,
          ])
        }
        style={{ marginTop: "-10px" }}
      >
        <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6" />
        <small className=" text-primary "> ADD MILESTONE</small>
      </div>

      <div className="mt-4">
        {checkBoxDeliverable.map((deliv: any, index: number) => (
          <Deliverable
            key={index}
            deliv={deliv}
            index={index}
            checkBoxDeliverable={checkBoxDeliverable}
            setCheckBoxDeliverable={setCheckBoxDeliverable}
            onSubmitControlForm={props.onSubmitControlForm}
            setOnSubmitControlForm={props.setOnSubmitControlForm}
          />
        ))}
        <div
          className="cursor-pointer mb-3"
          onClick={() =>
            // checkBoxDeliverable.length > 1
            //   ? null
            //   :
            setCheckBoxDeliverable([
              ...checkBoxDeliverable,
              newDeliverable,
            ])
          }
        >
          <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6"></i>
          <small className=" text-primary "> ADD DELIVERABLE</small>
        </div>
        <div>
          <div className="container ">
            <div className="row">
              {checkBoxDocument.map((docu: any, index: number) => (
                <Document
                  key={index}
                  docu={docu}
                  index={index}
                  checkBoxDocument={checkBoxDocument}
                  setCheckBoxDocument={setCheckBoxDocument}
                  onSubmitControlForm={props.onSubmitControlForm}
                  setOnSubmitControlForm={props.setOnSubmitControlForm}
                />
              ))}
            </div>
          </div>
          <div
            className="cursor-pointer mb-3"
            onClick={() =>
              // checkBoxDeliverable.length > 1
              //   ? null
              //   :
              setCheckBoxDocument([...checkBoxDocument, newDocument])
            }
          >
            <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6"></i>
            <small className=" text-primary "> ADD DOCUMENTS</small>
          </div>
        </div>
        <div className="container ">
          <div className="row">
            {checkBoxRequirement.map((requi: any, index: number) => (
              <Requiriment
                key={index}
                requirement={requi}
                index={index}
                checkBoxRequirement={checkBoxRequirement}
                setCheckBoxRequirement={setCheckBoxRequirement}
                onSubmitControlForm={props.onSubmitControlForm}
                setOnSubmitControlForm={props.setOnSubmitControlForm}
              />
            ))}
          </div>
        </div>
        <div
          className="cursor-pointer mb-3"
          onClick={() =>
            // checkBoxDeliverable.length > 1
            //   ? null
            //   :
            setCheckBoxRequirement([
              ...checkBoxRequirement,
              newRequirement,
            ])
          }
        >
          <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6"></i>
          <small className=" text-primary ">ADD REQURIMENT</small>
        </div>
      </div>
    </div>
  );
};

export default MilestonesTab;
