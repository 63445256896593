
interface Props {
    onClick?: any
    next?: boolean
    previous?: boolean
    save?: boolean
    custom?: boolean
    customText?: any,
    style?: any
    className?: any,
    id?:any
}

const StepButton: React.FC<Props> = (props) => {
    return (
        <button id={props.id} className={`btn btn-step col-auto order-1 ` + props.className}
            onClick={props.onClick} style={props.style} >
            {props.previous &&
                <>
                    <i className="bi bi-arrow-left"></i>
                    {props.customText ? props.customText : 'Previous step'}
                </>
            }
            {props.next &&
                <>
                    {props.customText ? props.customText : 'Next step'}
                    <i className="bi bi-arrow-right"></i>
                </>
            }
            {props.save &&
                <>
                    {props.customText ? props.customText : 'Save item'}
                    <i className="bi bi-check-lg"></i>
                </>
            }
            {props.custom &&
                <>
                    {props.customText ? props.customText : 'Save item'}
                </>
            }
        </button>
    );
};

export default StepButton;
