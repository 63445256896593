import React, { useState } from "react";
import { Container } from "reactstrap";

import Topbar from "../Topbar/Topbar";
import Sidebar from "../Sidebar/Sidebar";

const Layout: React.FC = (props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebarState = () => setIsSidebarOpen((prev) => !prev);

  return (
    <div className="vh-100 vw-100">
      <Topbar toggleSideBar={toggleSidebarState} />
      <div className="d-flex full-container">
        <Sidebar isOpen={isSidebarOpen} />
        <Container fluid className="main-content custom-scrollbar overflow-y-none vw-100" >
          {props.children}          
        </Container>
      </div>
    </div>
  );
};

export default Layout;
