import React from 'react';
import InputFloatingLabel from '../../../../../UI/forms/InputFloatingLabel';
import TextArea from '../../../../../UI/forms/TextArea';
import InputDateFatoryForm from "../../../../../UI/forms/InputDateFatoryForm";
import moment from 'moment';

interface Props {
    onSubmitControlForm: any;
    setOnSubmitControlForm: any;
}

const TasksTab: React.FC<Props> = (props: Props) => {
    return (
        <div>
            <div className=" row g-3 ">
                <div>
                    <small className="text-gray">PRE-JOB -TASK</small>
                    <div className="row">
                        <div className="col-md-4 mt-1">
                        <InputFloatingLabel
                            placeHolder="Task"
                            value={props.onSubmitControlForm.task}
                            onChange={(val) =>
                            props.setOnSubmitControlForm({
                            ...props.onSubmitControlForm,
                            task: val,
                            })
                            }
                        />
                </div>
                <div className="col-md-4 mt-1">
                    <InputFloatingLabel
                    placeHolder="Assign to"
                    value={props.onSubmitControlForm.asignTo}
                    onChange={(val) =>
                        props.setOnSubmitControlForm({
                        ...props.onSubmitControlForm,
                        asignTo: val,
                        })
                    }
                    />
                </div>
                <div className="col-md-4 ">
                    <InputDateFatoryForm
                    // placeHolder="End date & time"
                    onChange={(e: any) =>
                        props.setOnSubmitControlForm({
                        ...props.onSubmitControlForm,
                        dueDate: moment(e.value).format("DD/MM/YYYY"),
                        })
                    }
                    value={props.onSubmitControlForm.dueDate}
                    />
                </div>
            </div>
            <div className="col-md-12">
                <TextArea
                    placeHolder="Task description"
                    title=""
                    value={props.onSubmitControlForm.textAreaJob}
                    onChange={(val) =>
                    props.setOnSubmitControlForm({
                        ...props.onSubmitControlForm,
                        textAreaJob: val,
                    })
                    }
                />
            </div>
            <div className="cursor-pointer ">
                <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6"></i>
                <small className=" text-primary "> ADD TASK</small>
            </div>
        </div>
        </div>
        </div>
    );
};

export default TasksTab;
