import FlatList from 'flatlist-react';
import { Modal } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { getSections } from '../../util/ItemsFunctions';
import RateSection from '../Rates/RateList/RateSection';
import RateListEmpty from '../Rates/RateList/RateListEmpty';
import RateItem from '../Rates/RateList/RateItem';
import { useEffect, useState, Fragment, useMemo, useRef, useLayoutEffect } from 'react';
import {
  getAllItems,
} from "../../store/stockpile/stockpileSlice";
import ItemModel from '../../models/ItemModel';
import { RootState } from '../../store/store';
import AddItemsSection from '../Factory/FactoryView/createQuoteForm/modal/AddItemsSection';
import React from 'react';
import SelectInput from '../UI/forms/Select';
import ButtonIconText from "../UI/buttons/ButtonIconText";
import InputDate from '../UI/forms/InputDate';
import BarcodeIcon from "../../assets/icons/barcode.svg";
import Scanner from "../Scanner/scanner";
import SimpleButton from '../UI/buttons/SimpleButton';
import QuoteBuilder from './QuoteBuilder/index';
import { Button } from '@mobiscroll/react';
import EmptyCircle from '../UI/EmptyCircle';


interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSelectItems: (items: ItemModel[]) => void;
}

const ItemQuotePicker: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { ItemData, LoadingItems } = useSelector((state: RootState) => state.stockpileSlice);
  const [selectedItems, setSelectedtItems] = useState<ItemModel[]>([]);
  const [search, setSearch] = useState<string>("");
  const [scanModal, setScanModal] = useState<boolean>(false);
  const [code, setCoder] = useState<any>("");
  const [isOpenQuoteBuilderModal, setIsOpenQuoteBuilderModal] = useState<boolean>(false);

  const [test, settest] = useState<any>([]);
  const [addModal, setAddModal] = useState<boolean>(false);

  const refList: any = useRef(null)

  useEffect(() => {
    dispatch(getAllItems());
  }, [dispatch])

  useEffect(() => {
    console.log(selectedItems.toString());
    
  }, [selectedItems])
  



  const handleChange = (item: ItemModel, checked: boolean, multiple?: Array<ItemModel>) => {
    if (multiple && multiple.length > 0) {
      setSelectedtItems(multiple)
      return ''
    }
    if (checked) {
      setSelectedtItems([...selectedItems, item]);
    } else {
      setSelectedtItems(selectedItems.filter(e => e._id !== item._id));
    }
  }
  const checker = new Map()

  const handleSelectItems = () => {


    let selectedFormated: any = selectedItems?.map((item, index) => {
      const CantidadDeEsteItem = selectedItems?.filter(itemD => itemD === item).length
      if (!checker.has(item)) {
        checker.set(item, true);

        return { ...item, data: 'none', quantity: CantidadDeEsteItem
         }
      }
      return null
    })

    props.onSelectItems([...selectedFormated].filter(item => item != null));
    //setSelectedtItems([]) 
  }

  const byName = (item: any, name: any) => {
    if (name) {
      console.log(name)
      return item.name.toLowerCase().includes(name.toLowerCase());
    } else
      return true;
  };

  const filteredItems = useMemo(() => {
    return ItemData.filter((item: any) => byName(item, search))
  }, [ItemData, search])

  let sections = getSections(filteredItems, 'name');


  const scrollAction = () => {
    refList?.current?.scrollTo({
      behavior: "smooth",
      top: 15000
    });

  }
  useLayoutEffect(() => {
    scrollAction()
  }, [test]);

  const mapa = new Map()


  return (
    <Modal isOpen={props.isOpen} toggle={props.onClose} centered>
      <div className="container" style={{ height: '84vh' }}>
        <div className="cursor-pointer m-1" onClick={props.onClose}>
          <i className="bi bi-x-lg fs-5"></i>
        </div>
        <div className="row py-5 ">
          <div className="col-12" style={{ marginTop: '-5%' }}>
            <h1 style={{fontSize: '30px', fontWeight:'bold'}} className="text-secondary typo-display fw-bold text-center">
              PFP Item picker requirements
            </h1>
          </div>

          <div className='col-12 ps-5 mb-0 custom-scrollbar mt-5' style={{
            maxHeight: '400px', overflow: 'scroll', overflowX: 'hidden'
          }} ref={refList}>
            {
              selectedItems.length < 1 && (
                <div className="d-flex my-1 mt-5 me-4 flex-column justify-content-center align-items-center h-100 text-muted  text-center">
                  <i className="fs-3 bi bi-back d-block"></i>
                  <p className="h6">There are no items selected to quote.</p>
                </div>
              )
            }

            {
              selectedItems?.map((item: ItemModel) => {
                console.log(selectedItems);

                const CantidadDeEsteItem = selectedItems.filter(itemD => itemD === item).length

                if (!mapa.has(item)) {
                  mapa.set(item, true);

                  return (<div className='justify-content-center my-5 '>
                    <li className="col-12 row p-0" >
                      <div className='col  p-0'>
                        <EmptyCircle title={item?.name} small={true} />
                      </div>
                      <div className=' text-start p-0 m-0 ' style={{ width: '326px' }}>
                        <p className='text-bold m-0'>{item?.name}</p>
                        <p className="text-body-secondary m-0">
                          descripcion item
                        </p>
                      </div>
                      <div className='col p-0'>
                        <p>{CantidadDeEsteItem}</p>
                      </div>
                    </li>
                  </div>)
                }
              }
              )

            }

          </div>
          <div className='col-12 align-content-center me-0 pe-0 ms-3' style={{ paddingLeft: '15%' }}>
            <button
              onClick={() => {
                //   settest([...test, ''])
                setAddModal(true)
                scrollAction()
              }}
              className='btn btn-primary w-75  mt-2 '>Add item</button>

            <button
              onClick={() => setIsOpenQuoteBuilderModal(true)}
              className='btn btn-outline-primary w-75  mt-2 '>Add external item</button>

            { /*   */}
          </div>

        </div>


        <Modal
          isOpen={addModal}
          centered
          style={{ padding:0   }}
        >
            <div className="cursor-pointer m-2" onClick={() => {
                    setAddModal(false)
                   
                }}>
                    <i className="bi bi-x-lg fs-5"></i>
                </div>
          <div className="col-11 input-icon my-0 mb-1 ms-3 d-flex align-items-center">
            <i className="bi bi-search text-primary mx-2"></i>
            <input
              className="form-control typo-body text-primary"
              type="search"
              id="example-search-input"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="row position-relative mx-0 ps-3 pe-0 mb-2">
            <div className="col p-0 h-100 contact-list custom-scrollbar row" style={{ maxHeight: '60vh' }}>
              <div className='col-6 mb-1'>
                <SelectInput
                  value={''}
                  onChange={''}
                  options={[]}
                  placeholder={'Filter Subcategory'}
                />
              </div>

              <div className='col-6 mb-1'>
                <SelectInput
                  value={''}
                  onChange={''}
                  options={[]}
                  placeholder={'Filter Category'}
                />
              </div>

              <div className='col-6'>
                <SelectInput
                  value={''}
                  onChange={''}
                  options={[]}
                  placeholder={'Brand'}
                />
              </div>

              <div className='col-6'>
                <SelectInput
                  value={''}
                  onChange={''}
                  options={[]}
                  placeholder={'Package'}
                />
              </div>

              <div className='col-6 mt-1'>
                <SelectInput
                  value={''}
                  onChange={''}
                  options={[]}
                  placeholder={'Add internal / External'}
                />
              </div>

              <div className='col-6 mt-1'>
                <SelectInput
                  value={''}
                  onChange={''}
                  options={[]}
                  placeholder={'Filter Items'}
                />
              </div>

              <div className="col-4 mt-2 mb-2">
                <ButtonIconText
                  title={"Scan code"}
                  custom={BarcodeIcon}
                  customStyle={{ maxWidth: "15px", height: "12px", marginTop: "5%" }}
                  onClick={() => setScanModal(true)}
                />
              </div>
              <div className='col-2'>

              </div>
              <div className='col-6 ps-2'>
                <InputDate
                  max={new Date()}
                  value={''}
                  error={false}
                  onChange={(e: any) => { }}
                  placeholder='Date'
                />
              </div>
            </div>
          </div>
          <div className="col p-0 h-25 contact-list custom-scrollbar" style={{ maxHeight: '400px' }}>

            {LoadingItems ?
              (
                <div
                  className="d-flex justify-content-center align-items-center"
                // style={{ width: "88vw", height: "40vh" }}
                >
                  <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block" />
                  <span className="text-black ms-3">Loading...</span>
                </div>
              ) :
              (
                <Fragment>
                  {filteredItems.length > 0 ? (
                    <FlatList
                      list={Object.keys(sections)}
                      renderItem={(item: string, index: number) => {
                        return (
                          <AddItemsSection
                            ref={sections[item].ref}
                            key={index}
                            letter={item}
                            items={sections[item].items}
                            handleSelect={handleChange}
                          />
                        );
                      }}
                    />
                  ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center h-100 text-muted mx-5 text-center">
                      <i className="fs-3 bi bi-back d-block"></i>
                      <p className="h6">No items were found for the current filters.</p>
                    </div>
                  )}

                </Fragment>
              )}
          </div>
          <button
            className="btn btn-success text-white col-10 mb-4 mt-1 "
            style={{ marginLeft: '8%' }}
            onClick={() => {
              //handleSelectItems()
              setAddModal(false)
              scrollAction()
            }}
          >
            Add Items
          </button>
        </Modal >

      </div >
      <Scanner
        isOpen={scanModal}
        setCode={setSearch}
        setStateModal={setScanModal}
      />
      <QuoteBuilder
        isOpen={isOpenQuoteBuilderModal}
        onClose={() => setIsOpenQuoteBuilderModal((prev) => !prev)}
        onSelectItems={() => { }}
        handleChange={handleChange}
        selectedItemsGeneral={selectedItems}
      />
      <div className="m-0 mb-4 align-self-center " >
        <button
          className="btn btn-success text-white col ms-3"
          style={{ fontWeight: 'bold', fontSize: '14px' }}
          onClick={() => {
            handleSelectItems()
            props.onClose()
            setSelectedtItems([])
          }}
        >
          Confirm items
        </button>
        <button
          className="btn btn-success text-white col ms-3 d-none"
          onClick={() => handleSelectItems()}
        >
          Confirm items without dates
        </button>
      </div>
    </Modal >
  );
};

export default ItemQuotePicker;
