import React, { useEffect, useState } from "react";
import { formatNumber } from "../../../../../util";
import Select from "../../../../UI/forms/Select";

interface Props {
    data: {
        sub_total: number,
        discount: number,
        agency_fee: number,
        tax: number,
        headers: Array<any>
    }
    onChange: (name, value) => void;
    excempt_taxes: number,
    injectSub: any
}

const QuoteSummary: React.FC<Props> = (props) => {
    const { data, onChange, excempt_taxes } = props;
    const [first, setfirst] = useState(0)
    const [second, setSecond] = useState(0)
    const [nondisc, setnondisc] = useState(0)

    const options = [
        {
            label: "0%",
            value: 0,
        },
        {
            label: "IVA(12%)",
            value: 12,
        },
        {
            label: "ISR(25%)",
            value: 25,
        },
        {
            label: "L.A(9%)",
            value: 9,
        },
    ];

    useEffect(() => {
        // Suma la longitud de los arrays en la propiedad 'products'
        const totalProductos = data.headers.reduce((contador, objeto) => contador + objeto.elements.length, 0);

        console.log(data.headers);
        
        

        let total = data.sub_total * (1 - data.discount / 100)

        console.log(total);
        

        const allElements = data.headers.map((obj) => obj.elements).concat();

        const combinedElements = [].concat(...allElements).map((item: any) => ({ rate: item?.rate, tax: item?.tax, discount_: item?.discount_, sub_t: item?.sub_total }));

        ///Genera un array con los items que estan excemptos de taxt para restarlos
        const filteredRates = combinedElements.filter((item: any) => item.tax == true).map((item: any) => item.rate * (data.tax / 100));

        let totaExcemptTax = filteredRates.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        let numdesc =  combinedElements
        .filter((item) => item?.discount_ === true) // Filtra los elementos con descuento
        .map((item) => ({ total_non_disc: (item?.sub_t * data.discount) / 100 })); // C
        let sumaTotal = numdesc.reduce((acumulador, objeto) => acumulador + objeto?.total_non_disc, 0);

        setnondisc(sumaTotal)
        
        
        let secondVar = (data.tax / 100) * (data.sub_total * (1 - data.discount / 100)+ nondisc ) || 0
        let total_left_tax = total 

        
        
        setSecond(secondVar - totaExcemptTax)
        setfirst(total_left_tax + sumaTotal)
        

      //  props.injectSub(first)
    }, [data.sub_total, data.tax, data.discount])

    useEffect(() => {
        console.log(nondisc);
    }, [nondisc])


    useEffect(() => {
     setfirst(first + second)
     console.log(first);
     console.log(second);
     
     
    }, [second])
    

   



    return (
        <div className="w-100 d-flex justify-content-end">
            <table className="table table-striped table-borderless" style={{ width: '32%' }}>
                <thead>
                    <tr className="text-primary typo-body align-middle">
                        <th className="py-3" style={{ width: '40%' }}>QUOTE SUMMARY</th>
                        <th className="py-3" style={{ width: '20%' }}>COST</th>
                        <th className="py-3" style={{ width: '20%' }}>MARGIN</th>
                        <th className="py-3" style={{ width: '20%' }}>TOTAL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="text-primary inter typo-body align-middle">
                        <td>SUBTOTAL</td>
                        <td>{formatNumber(data.sub_total)}</td>
                        <td>{formatNumber(data.sub_total)}</td>
                        <td>{formatNumber(data.sub_total)}</td>
                    </tr>
                    <tr className="text-primary inter typo-body align-middle">
                        <td colSpan={2}>DISCOUNT</td>
                        <td>{formatNumber((data.sub_total * (data.discount / 100) - nondisc ) )}</td>
                        <td>{`${data.discount}%`}</td>
                    </tr>
                    <tr className="text-primary inter typo-body align-middle">
                        <td colSpan={2}>AGENCY FEE</td>
                        <td>{formatNumber((data.agency_fee / 100) * (data.sub_total * (1 - data.discount / 100)))}</td>
                        <td>
                            <input type="number" className="w-100 form-control text-primary h-25 p-1" style={{ fontSize: '12px' }} name='agency_fee' value={data.agency_fee} min={0} onChange={(e) => onChange(e.target.name, parseFloat(e.target.value))} />
                        </td>
                    </tr>
                    <tr className="text-primary inter typo-body align-middle">
                        <td colSpan={2}>TAX</td>
                        <td>{formatNumber(
                            second
                            //(data.tax / 100) * (data.sub_total * (1 - data.discount / 100)))
                        )}</td>
                        <td>
                            <Select
                                placeholder={"0$"}
                                onChange={(value) => onChange('tax', parseFloat(value))}
                                value={data.tax}
                                options={options}
                                styles={{
                                    valueContainer: {
                                        padding: "2px",
                                    },
                                    singleOption: () => ({
                                        fontSize: "12px"
                                    }),
                                    placeholder: {
                                        fontSize: "12px"
                                    },
                                }}
                                height={28}
                            />
                        </td>
                    </tr>
                    <tr className="text-primary inter typo-body align-middle">
                        <td colSpan={3}>TOTAL</td>
                        <td>{formatNumber(first)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default QuoteSummary;