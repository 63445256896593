import FlatList from 'flatlist-react';
import { Modal } from 'reactstrap';
import { getSections } from '../../util/ContactsFunctions';
import ContactSection from '../Contacts/ContactList/ContactSection';
import DUMMY_CONTACTS from '../../dummy_data/contacts.json';
import InputSearch from '../UI/forms/InputSearch';

interface Props {
  onClose: (value?: boolean) => void;
  title: string;
  description: string[];
}

const ContactsModal: React.FC<Props> = (props) => {
  const sections = getSections(DUMMY_CONTACTS);

  return (
    <Modal isOpen={true} toggle={props.onClose.bind(null, undefined)} centered>
      <div className="container">
        <div className="row p-3">
          <div className="col-12">
            <h2 className="font-weight-bold text-secondary typo-display">
              {props.title}
            </h2>
          </div>

          <div className="col-12 mt-1">
            {props.description.map((item, index) => {
              return (
                <p key={index} className="text-secondary typo-body m-0">
                  {item}
                </p>
              );
            })}
          </div>
        </div>

        <div className="input-icon mt-3 mx-2">
        <InputSearch
            value={''}
            placeholder="Search"
            onChange={(e) => {}}
          />
        </div>

        <div
          className="overflow-y overflow-x-none px-2 custom-scrollbar mt-3"
          style={{ height: 400 }}
        >
          <FlatList
            list={Object.keys(sections)}
            renderItem={(item: any, index: any) => {
              return (
                <ContactSection
                  ref={sections[item].ref}
                  key={index}
                  letter={item}
                  items={sections[item].items}
                />
              );
            }}
            renderWhenEmpty={() => <div>List is empty!</div>}
          />
        </div>

        <div className="row justify-content-center my-4">
          <button
            className="btn btn-success  text-white col-auto"
            onClick={props.onClose.bind(null, true)}
          >
            LINK CONTACT
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ContactsModal;
