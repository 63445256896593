interface Props {
    AllAssetForItem:any
}

const StockQty: React.FC<Props> = (props) => {

    const { AllAssetForItem } = props


    return(      
  <div className="d-flex flex-column align-items-end">
    <div className="d-flex align-item-en">
      <p className="m-0 text-black typo-body text-end">Stock:</p>
      <p className="m-0 contact-trailing typo-body bg-primary ms-3">{AllAssetForItem.length}</p>
    </div>

    <div className="d-flex align-item-en me-3 my-1">
      <p className="m-0 text-black typo-body">Quarantine:</p>
      <p className="m-0  typo-body ms-3 text-black">0</p>
    </div>

    <div className="d-flex align-item-en me-3">
      <p className="m-0 text-black typo-body">Currently available:</p>
      <p className="m-0  typo-body ms-3 text-black">0</p>
    </div>
  </div>)
}

export default StockQty