import { Modal } from 'reactstrap';

type Props = {
  onClose: (value?: boolean) => void;
  onConfirm: (value?: boolean) => void;
  title: string;
  description: string;
} & ({
  confirmText: string;
  cancelText: string;
  buttons?: undefined;
} | {
  buttons: React.ReactNode;
  confirmText?: undefined;
  cancelText?: undefined;
})

const ConfirmDuplicate: React.FC<Props> = ({
  onClose,
  onConfirm,
  title,
  buttons,
  cancelText,
  confirmText,
  description,
}) => {
  return (
    <Modal isOpen={true} toggle={onClose.bind(null, undefined)} centered>
      <div className="container text-center">
        <div className="row p-4">
          <div className="col-12">
            <h2 className="font-weight-bold text-secondary typo-display">
              {title}
            </h2>
          </div>
          <div className="col-12 mt-3">
            <p className="text-muted typo-body">{description}</p>
          </div>

          <div className="col-12 mt-3">
            <div className="row justify-content-evenly">
              {buttons !== undefined ? buttons : (
                <>
                  <button
                    className="btn btn-danger text-white col-auto"
                    onClick={onClose.bind(null, false)}
                  >
                    {cancelText}
                  </button>
                  <button
                    className="btn btn-success text-white col-auto"
                    onClick={() => {
                        onClose.bind(null, true)
                        onConfirm()
                    }}
                  >
                    {confirmText}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDuplicate;
