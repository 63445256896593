import { returnDate } from "@mobiscroll/react/dist/src/core/util/datetime";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import update from "immutability-helper";

const initialState = {
  quoteValue: [] as any,
};

const factorySetTableQuote = createSlice({
  name: "setQuote",
  initialState,
  reducers: {
    setEditQuote: (state, action) => {
      const index = state.quoteValue.findIndex(
        (e: any) => e.id === action.payload.id
      );

      state.quoteValue =
        index === -1
          ? [...state.quoteValue, action.payload]
          : update(state.quoteValue, { [index]: { $merge: action.payload } });
    },
  },
});

export const { setEditQuote } = factorySetTableQuote.actions;
export default factorySetTableQuote.reducer;
