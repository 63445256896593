import get from 'lodash.get';
import { createRef, RefObject } from 'react';

export type PartiallyRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

const abc = ['A', 'B', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'
  , 'M', 'N', 'Ñ', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'] as const;
export type ABC = typeof abc[number];

type SectionsResult<T, RefT> = Record<ABC, { items: T[]; ref: RefObject<RefT> }>;

export const getSections = <T, RefT>(
  items: T[],
  uKey: string | ((item: T) => string),
): SectionsResult<T, RefT> => {
  const ordered = items.sort((a, b) => {
    const aKey = (typeof uKey === 'string' ? a[uKey as keyof typeof a] : uKey(a)) as string;
    const bKey = (typeof uKey === 'string' ? b[uKey as keyof typeof b] : uKey(b)) as string;

    return aKey.localeCompare(bKey);
  });

  /* @ts-ignore it'll be filled below */
  const data: SectionsResult<T, RefT> = {};
  abc.forEach((letter) => {
    data[letter] = { items: [], ref: createRef() };
  })

  for (let item of ordered) {
    const key = (typeof uKey === 'string' ? item[uKey as keyof typeof item] : uKey(item)) as string;
    const field = String(get(item, key, '') || '');
    let letter = field?.charAt(0).toUpperCase() as ABC;

    if (data[letter] === undefined) {
      data[letter] = {
        items: [],
        ref: createRef<RefT>(),
      };
    }

    data[letter].items.push(item);
  }

  return data;
};

export const getFileUrl = (key?: string): string => {
  if (!key) return '';

  return `${process.env.REACT_APP_IMAGES_AWS}${key}`;
}

export const parseFileKey = (key?: string) => {
  if (!key) return {
    original: '',
    folder: '',
    id: '',
  };

  const [folder, generated_key] = key.split('/')
  const [generated_id, ...original_name] = generated_key.split('-');

  return {
    original: original_name.join('-'),
    id: generated_id,
    folder,
  }
}

export const mapObjToFiles = (obj: Record<string, any>) => {
  const files: Record<string, any> = {};

  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'string') {
      files[key] = parseFileKey(value).original;
    }

    if (typeof value === 'object' && value.constructor.name === Object.name) {
      files[key] = mapObjToFiles(value);
    }

    if (Array.isArray(value)) {
      files[key] = value.map(mapObjToFiles);
    }
  }

  return files;
}

export const deepEntries = (obj: Record<string, any>, keys: [string, string][] = [], path?: string) => {
  for (const [key, value] of Object.entries(obj)) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      deepEntries(obj[key], keys, `${path || ''}${key}.`)
    } else {
      keys.push([`${path || ''}${key}`, value])
    }
  }
  return keys
}

export const ROImageMimeTypesArray = ['image/jpeg', 'image/png'] as const;
export const RODocumentMimeTypesArray = [
  'application/pdf',
  'text/csv',
  'text/html',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
] as const;

export function parseSnakeCase(text: string) {
  return text.split('_').map(capitalizeText).join(' ');
}

export function capitalizeText(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export function parseInputError(error: string) {
  return error.replace(/([0-9])/g, '-').split('_').map(capitalizeText).join(' ').split(/\[(.*)\]/g).join(' ').split('.').map(capitalizeText).join(' ');
}

export function isURL(text: string) {
  // eslint-disable-next-line no-useless-escape
  const regex = /^(http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
  return regex.test(text);
}

export function addUrlProtocol(text: string, protocol: 'http://' | 'https://' = 'https://') {
  if (text.startsWith('http://') || text.startsWith('https://')) {
    return text;
  }

  return `${protocol}${text}`;
}

export function calcAGE(dob: string) {
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return `${age}`;
}

export interface UploadError {
  in: number;
  field: string;
  error: string;
}

export function groupUploadErrors(errors: UploadError[]) {
  return errors.reduce((acc, error) => {
    if (acc.some((err) => err.in === error.in)) {
      acc.find((err) => err.in === error.in)?.errors.push(error);
    } else {
      acc.push({
        in: error.in,
        field: error.field,
        errors: [error]
      });
    }

    return acc;
  }, [] as { in: number; field: string; errors: UploadError[] }[]);
}

export function formatNumber (number: number) {
  let n: string = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(number);

  return n;
};