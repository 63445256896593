import EquipmentData from '../../Stock/StockView/tabs/EquipmentData';
import History from '../../Stock/StockView/tabs/History';
import Kits from '../../Stock/StockView/tabs/Kits';
import Accessories from '../../Stock/StockView/tabs/Accessories';
import Maintenance from '../../Stock/StockView/tabs/Maintenance';
import Rates from '../../Stock/StockView/tabs/Rates';
import Projects from '../../Stock/StockView/tabs/Projects';
import { useState } from 'react';

const TabComponent: React.FC<any> = (props) => {

    const [currentTab, setCurrentTab] = useState(0);

    const { innerHeight } = window


    const tabs = [
        'Equipment data',
        'Rates',
        'Maintenance',
        'Projects',
        'History',
        'Accessories',
        'Kits',
      ];

    const tabsContent = [
        <EquipmentData />,
        <Rates />,
        <Maintenance />,
        <Projects />,
        <History />,
        <Accessories />,
        <Kits />,
      ];

    return (
        <>
            <ul className="nav nav-tabs mt-3">
                {tabs.map((item, index) => {
                    return (
                        <li
                            key={index}
                            className="nav-item"
                            onClick={setCurrentTab.bind(null, index)}
                        >
                            <p
                                className={`nav-link typo-body user-select-none ${currentTab === index ? 'active' : ''
                                    }`}
                                aria-current="page"
                            >
                                {item}
                            </p>
                        </li>
                    );
                })}
            </ul>

            {/* Tabs */}
            <div style={{ maxHeight: innerHeight - 320 + 'px' }}>
                {tabsContent[currentTab]}
            </div>
        </>
    )
}

export default TabComponent