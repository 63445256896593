import { useCallback, useEffect, useMemo, useState } from 'react';
import ItemModel, { RatesModel } from '../../../models/ItemModel';
import { useToast } from "src/hooks/useToast";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { getAllItems, GetAllRateForItem } from '../../../store/stockpile/stockpileSlice';
import { getSections } from '../../../util/ItemsFunctions';
import { GetAllContacts } from '../../../store/blackbook/getContactSlice';
import { calDays } from '../../../util/DateFunctions'


// Custom Hook
export const useSupplierContacts = () => {
  const dispatch = useDispatch();

  // Obtén los contactos del estado de Redux
  const GetContact = useSelector((state: RootState) => state.getContactAll.getContact);

  // Filtra los contactos que sean proveedores
  const suppliersOptions = useMemo(() => {
    return GetContact?.filter(
      (model) => model.entityModel === "Client" && model.entity.is_supplier === true
    ).map((contact) => ({
      label: contact?.entity?.first_name,
      value: contact?.entity?._id
    }));
  }, [GetContact]);

  // Dispatch para obtener los contactos al montar el componente
  useEffect(() => {
    dispatch(GetAllContacts());
  }, [dispatch]);

  // Retornar el listado filtrado
  return suppliersOptions;
};



interface UseSelectQuoteBuildrProps {
  exempt: any;   // O el tipo de dato adecuado
  discount: any; // O el tipo de dato adecuado
  dataSelected: any
  setDataSelected: any;
  itemSelected: any;
  setItemSelected: any;
}

export const useSelectQuoteBuildr = ({
  exempt, discount, dataSelected, setDataSelected, itemSelected, setItemSelected
}: UseSelectQuoteBuildrProps) => {

  const dispatch = useDispatch();

  const handleSelect = (item: ItemModel, checked: boolean) => {
    if (checked) {
      setItemSelected(item._id);
      setDataSelected({ ...item, tax: exempt, discount_: discount });
    } else {
      setItemSelected('');
      setDataSelected({});
    }
    // Si `handleChange` es necesario, puedes exponerlo desde aquí también o manejarlo internamente.
  };

  useEffect(() => {
    if (itemSelected !== '') {
      dispatch(GetAllRateForItem(itemSelected));
    }
  }, [itemSelected, dispatch]);

  useEffect(() => {

    setDataSelected({ ...dataSelected, tax: exempt, discount_: discount })

    console.log({...dataSelected});
    
  }, [exempt, discount])

  return handleSelect

};

interface StatesMargin {
  totalQuote: any;
  chargePerUnit: any;
  rangeClient: any;
  qty: any;
  costPerUnit: any;
  setCostPerUnit: (value: any) => void;
  marginPerUnit: any;
  setMarginPerUnit: (value: any) => void;
  setTotalMargin: (value: any) => void;
  dataSelected: any;
  setDataSelected: (value: any) => void;
}


interface StatesMargin {
  totalQuote: any;
  chargePerUnit: any;
  rangeClient: any;
  qty: any;
  costPerUnit: any;
  setCostPerUnit: (value: any) => void;
  marginPerUnit: any;
  setMarginPerUnit: (value: any) => void;
  setTotalMargin: (value: any) => void;
  dataSelected: any;
  setDataSelected: (value: any) => void;
}

export const useMarginCalculations = (states: StatesMargin) => {
  const { totalQuote, setCostPerUnit, rangeClient, chargePerUnit, costPerUnit, setMarginPerUnit
    , marginPerUnit, setTotalMargin, qty, setDataSelected, dataSelected
  } = states



  useEffect(() => {
    setCostPerUnit(totalQuote);
  }, [totalQuote]);

  useEffect(() => {
    const diasClient = calDays(rangeClient);
    const taxes = (parseInt(chargePerUnit) * (12 / 100));
    const margin = (parseInt(chargePerUnit) - costPerUnit);

    setMarginPerUnit(margin);
  }, [chargePerUnit, rangeClient, costPerUnit]);

  useEffect(() => {
    const diasClient = calDays(rangeClient);
    let total;

    if (marginPerUnit.toString() !== 'NaN') {
      total = marginPerUnit * qty;
    } else {
      total = 0.00;
    }

    setTotalMargin(total);
  }, [chargePerUnit, qty, marginPerUnit]);

  useEffect(() => {
    const totalRate = (parseInt(chargePerUnit) * (calDays(rangeClient) + 1)) * qty;

    setDataSelected({
      ...dataSelected,
      total_for_rate: {
        total: totalRate,
        days: (calDays(rangeClient) + 1),
        qty: qty
      }
    });
  }, [totalQuote, rangeClient, qty, marginPerUnit, chargePerUnit]);

  return {
  };
};


export const useQuoteBuilder = (RatesItemSelected, rangeClient, rangeSupplier, setCostPerUnit, setTotal) => {
  const ratesItem: RatesModel = RatesItemSelected[0]?.rate_card_item?.rates

    useEffect(() => {
        const handleQuoteBuilder = () => {
            const date_client = rangeClient;
            const date_supplier = rangeSupplier;

            const diasSupplier = calDays(date_supplier);

            let contratado = diasSupplier;

            let calc_facturado = ratesItem?.daily * contratado;

            setCostPerUnit(calc_facturado);
            setTotal(calc_facturado);
        };

        handleQuoteBuilder();
    }, [ratesItem, rangeClient, rangeSupplier, setCostPerUnit, setTotal]);
};


interface qtyState {
  dataSelected:any, selectedItemsGeneral:any, handleChange:any
}


export const useQtyHandler = (states:qtyState) => {
  const { dataSelected , selectedItemsGeneral, handleChange } = states
    const qtyHandler = useCallback((diferencia) => {
        let itemsPusheables = [];

        for (let i = 0; i < diferencia; i++) {
            itemsPusheables.push(dataSelected);
        }

        handleChange({}, true, [...selectedItemsGeneral].concat(itemsPusheables));
    }, [dataSelected, selectedItemsGeneral, handleChange]);

    return qtyHandler;
};











