import React from "react";
import FlatList from "flatlist-react";
import { useHistory, useParams } from "react-router-dom";
import { useState, Fragment, useEffect } from "react";
import { getSections } from "../../../util/RateFunctions";
import { Link } from "react-router-dom";
import DUMMY_RATES from "../../../dummy_data/ratecards.json";
import RateModel from "../../../models/RateModel";
import IconButton from "../../UI/buttons/IconButton";
import RateSection from "./RateSection";
import RateListEmpty from "./RateListEmpty";
import { useRequest } from "../../../hooks/useRequest";
import AddRateCardModal, {
  RateCardModalType,
} from "../../../components/Modals/AddRateCardModal";
import RateItem from "./RateItem";
import useQuery from "../../../hooks/useQuery";
import useRates from "../../../hooks/useRates";
import InputSearch from "../../UI/forms/InputSearch";

interface Props {
  onAddRateCard: (type?: string) => void;
  typeRate: string;
  onChangeTypeRate?: (type: string) => void;
}

const RateList: React.FC<Props> = (props) => {
  const query = useQuery();
  const history = useHistory();
  const params = useParams<{ rateCardId: string }>();

  const create = query.get("create");

  const [filter, setFilter] = useState<string>("");
  const [search, setSearch] = useState<string>("");

  const [loading, setloading] = useState(true);

  const [data, setdata] = useState<RateModel[]>([]);
  const [list, setlist] = useState<RateModel[]>([]);
  const [standartRateCardList, setStandartRateCardList] = useState<RateModel[]>(
    []
  );

  const [typeRateCards, setTypeRateCards] = useState<string>(props.typeRate);

  const [addRateCardModalOpen, setaddRateCardModalOpen] =
    useState<boolean>(false);
  const [addRateCardModalType, setaddRateCardModalType] =
    useState<RateCardModalType>("system");

  const { data: rates, error } = useRates();

  const changeRateCardModalOpen = (
    modalType: RateCardModalType,
    open: boolean
  ) => {
    setaddRateCardModalType(modalType);
    setaddRateCardModalOpen(open);
  };

  const changeRate = ({ _id }: RateModel) => {
    // console.log("Change rate: " + id);
    history.push(`/rates/${typeRateCards}/${_id}`);
  };

  const getRateCardData = (id: string) => {
    let searchRateCardData = list.find((item) => item._id === id);
    return searchRateCardData;
  };

  useEffect(() => {
    if (props.typeRate !== typeRateCards) {
      setTypeRateCards(props.typeRate);
    }

    if (loading) {
      if (Array.isArray(rates)) {
        if (typeRateCards === "system") {
          let systemRateCardList = rates.filter(
            (item) => !item.assigned_to && !item.blocked
          );
          let standartRateCardList = rates.filter(
            (item) => !item.assigned_to && item.blocked
          );

          setStandartRateCardList(standartRateCardList);
          setdata(systemRateCardList);
          setlist(systemRateCardList);

          setloading(false);
        } else if (typeRateCards === "clients") {
          let clientsRateCardList = rates.filter((item) => item.assigned_to);
          setdata(clientsRateCardList);
          setlist(clientsRateCardList);

          setloading(false);
        }
      }
    }

    if (
      (typeRateCards === "master" &&
        props.typeRate !== typeRateCards &&
        props.typeRate !== "system") ||
      (typeRateCards === "system" &&
        props.typeRate !== typeRateCards &&
        props.typeRate !== "master") ||
      (typeRateCards === "clients" && props.typeRate !== typeRateCards)
    ) {
      console.log(props.typeRate);
      // setrateSelected("");
      setSearch("");
      setStandartRateCardList([]);
      setlist([]);
      setloading(true);
    }
  });

  //set sections
  let sections = getSections(list);
  const goTo = (letter: string) => {
    if (sections[letter]) {
      sections[letter].ref.current?.scrollIntoView();
    }
  };

  //search
  const searchData = (text: string) => {
    let newData = [...data];
    let searchItems = newData.filter((item) =>
      item.name.toLocaleLowerCase().includes(text.trim().toLocaleLowerCase())
    );

    console.log(searchItems);

    setlist(searchItems);
    setSearch(text);
  };

  return (
    <div className="d-flex flex-column h-100">
      {/* Modal add rate card */}
      {addRateCardModalOpen && (
        <AddRateCardModal
          onClose={() => setaddRateCardModalOpen(false)}
          type={addRateCardModalType}
          onSave={props.onAddRateCard}
          systemRateCardId={
            rates
              ? rates
                .filter((item) => !item.assigned_to && item.blocked)[0]
                ._id.toString()
              : ""
          }
        />
      )}
      {/* Modal add rate card */}
      <div className="contact-header d-flex flex-column">
        {/* rate list - top buttons */}
        <div className="px-3">
          <div className="mt-3">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-auto col-lg-10">
                <Link
                  to="/rates/system"
                  // onClick={() => (typeRateCards !== "system" && typeRateCards !== "master") ? props.onChangeTypeRate("master") : ""}
                  className={
                    (typeRateCards === "system" || typeRateCards === "master"
                      ? "btn-primary text-white"
                      : "btn-default text-primary") + " btn px-2 px-xl-3"
                  }
                >
                  SYSTEM RATE CARDS2
                </Link>
                <Link
                  to="/rates/clients"
                  // onClick={() => props.onChangeTypeRate("clients")}
                  className={
                    (typeRateCards === "clients"
                      ? "btn-primary text-white"
                      : "btn-default text-primary") + " btn px-2 px-xl-3 mx-1"
                  }
                >
                  CLIENT RATE CARDS
                </Link>
              </div>
              <div className="col-md-auto col-lg-2">
                <div className="d-inline-flex p-0">
                  <div className="col-auto">
                    <div className="dropdown p-0">
                      <IconButton
                        color="primary"
                        icon="bi bi-plus-lg"
                        tooltip="Add new contact"
                        // onClick={() => setaddRateCardModalOpen(true)}
                        buttonProps={{
                          "data-bs-toggle": "dropdown",
                          "data-toggle": "tooltip",
                        }}
                      />
                      <ul className="dropdown-menu">
                        <li
                          className="dropdown-item typo-body user-select-none"
                          onClick={() =>
                            changeRateCardModalOpen("system", true)
                          }
                        >
                          System
                        </li>
                        <li
                          className="dropdown-item typo-body user-select-none"
                          onClick={() =>
                            changeRateCardModalOpen("clients", true)
                          }
                        >
                          Clients
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* rate list - top buttons */}

        {/* rate list - top search */}
        <div className="px-3">
          <div className="mt-4">
            <InputSearch
              placeholder="search"
              value={search}
              onChange={(value)  => searchData(value)}
            />
          </div>
        </div>
        {/* rate list - top search */}
      </div>

      <div className="row contact-container w-100 mx-auto">
        <div className="col p-0 h-100 custom-scrollbar contact-list">
          {/* render standart rate card */}
          {standartRateCardList.map((item: RateModel, index: number) => {
            return (
              <RateItem
                key={index}
                contact={item}
                onSelect={() => changeRate(item)}
                active={params.rateCardId === item._id.toString()}
                block
              />
            );
          })}
          {/* render standart rate card */}

          {loading && (
            <div className="d-flex justify-content-center align-items-center py-5">
              <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block"></i>
            </div>
          )}

          {!loading && (
            <Fragment>
              {list.length > 0 ? (
                <FlatList
                  list={Object.keys(sections)}
                  renderItem={(item: any, index: any) => {
                    return (
                      <RateSection
                        ref={sections[item].ref}
                        key={index}
                        letter={item}
                        items={sections[item].items}
                        onRateSelected={changeRate}
                        active={!!params.rateCardId ? params.rateCardId : ""}
                      />
                    );
                  }}
                  renderWhenEmpty={() => <RateListEmpty />}
                />
              ) : (
                <RateListEmpty />
              )}
            </Fragment>
          )}
        </div>

        <div className="list-linked p-2 overflow-y hide-scrollbar h-100">
          {Object.keys(sections).map((item, index) => {
            return (
              <p
                // onClick={goTo.bind(null, item)}
                key={index}
                className="m-0 p-0 typo-body text-primary"
              >
                {item}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RateList;
