import SelectInput from "./Select";
import InputFloatingLabel from "./InputFloatingLabel";

interface Props {
  select: {
    options: ({
      label: string;
      value: string;
    } & React.OptionHTMLAttributes<HTMLOptionElement>)[];
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    searchable?: boolean;
    isOpen?:any
  };
  input: {
    value: string;
    onChange: (value: string) => void;
    placeHolder: string;
    className?: string;
    isRequired?: boolean;
    errorMessage?: any;
    NameField?: string;
    width?:string
  };
  className?: string;
}

const InputSelect: React.FC<Props> = (props) => {
  return (
    <div className={`container-fluid p-0 ${props.className}`}>
      <div className="row p-0 justify-content-between">
        <div className="col-3">
          <SelectInput
            options={props.select.options}
            onChange={props.select.onChange}
            value={props.select.value}
            placeholder={props.select.placeholder}
            searchable={props.select.searchable}
            isOpen={props.select.isOpen}
          />
        </div>
        <div className="col ps-0 pe-3">
          <InputFloatingLabel {...props.input} />
        </div>
      </div>
    </div>
  );
};

export default InputSelect;
