import React from "react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import InputFloatingLabel from "../../../../../components/UI/forms/InputFloatingLabel";
import SelectFloatingLabel from "../../../../../components/UI/forms/SelectFloatingLabel";
import { ProjectModel } from "../../../../../models/factoryModel";
import InputDate from "../../../../../components/UI/forms/InputDate";
import { RootState } from "../../../../../store/store";
import { useSelector } from "react-redux";

interface Props {
  errorFormulary?: boolean;
  setErrorFormulary?: any;
  setCurrentTab?: any;
  onSubmitControlProject?: any;
  setOnSubmitControlProject?: any;
  setActiveControlView?: any;
  getContact?: [];
  activeControlView?: string | null;
  setIdProject?: any;
  idParamsProject?: any;
  loading?: boolean;
  active?: boolean;
  errorFormularyName: boolean;
  errorFormularyFile: boolean;
  errorFormularyStartDate: boolean;
  errorFormularyOrganization: boolean;
  errorFormularyEndDate: boolean;
  errorFormularyClient: boolean;
  errorFormularyEmail: boolean;
  datesProject: any;
  setDatesProject: any;
}

const CreateProjectForm: React.FC<Props> = (props) => {
  const [freelance, setFreelance] = useState([]);
  const [organization, seOrganization] = useState([]);
  const [client, setClient] = useState([]);

  const { clientTempForm } = useSelector((state: RootState) => state.ViewResources );


  const SelectOrganization = organization.map((e: any) => {
    let obj = { label: e.entity.name, value: e._id };

    return obj;
  });

  const SelectClient = client.map((e: any) => {
    const tab = "\u00A0";
    let obj = {
      label: e.entity.first_name + tab + e.entity.last_name,
      value: e._id,
    };

    return obj;
  });

  const SelectEmail = client.map((e: any) => {
    let obj = {
      label: e.entity.email,
      value: e.entity.email,
    };

    return obj;
  });

  const formik = useFormik<ProjectModel>({
    initialValues: {
      name: "",
      client: "",
      organization: "",
      start_date: "",
      end_date: "",
      file_cover: "",
    },

    // validationSchema: ClientPersonalValidation,

    onSubmit: (val) => {},
  });

  const selectData = () => {
    if (props.getContact) {
      let type: any = [];
      let type1: any = [];
      let type2: any = [];

      props.getContact?.forEach((e: any) => {
        if (e.type === "FREELANCE") {
          type.push(e);
        } else if (e.type === "ORGANIZATION") {
          type1.push(e);
        } else if (e.type === "CLIENT") {
          type2.push(e);
        }
      });

      setFreelance(type);
      seOrganization(type1);
      setClient(type2);
    }
  };

  useEffect(() => {
    selectData();
  }, [props.getContact]);

  const { handleSubmit } = formik;

  const ErrorDate =
    props.errorFormularyStartDate && "You must enter a job start date";

  return (
    <div className="custom-scrollbar">
    <form className="row g-4" onSubmit={handleSubmit}>
      <div className="col-md-6 mt-5">
        <div
          style={{
            marginBottom: "-18px",
          }}
        >
          <InputFloatingLabel
            placeHolder="Project Name"
            value={props.onSubmitControlProject?.name}
            onChange={(val) =>
              props.setOnSubmitControlProject({
                ...props.onSubmitControlProject,
                name: val,
              })
            }
            isRequired
            // errorMessage={props.errorFormularyName && true}
            id={'project-name-form'}
          />
        </div>
        <div>
          {props.errorFormularyName ? (
            <span className=" validation">You must enter a project name</span>
          ) : null}
        </div>
      </div>
      <div className="d-flex flex-column col-md-6 container-input-file-create mt-5">
        <div
          className="custom-input-file-create col-md-6 col-sm-6 col-xs-6 "
          style={{ width: "450px" }}
        >
          <input
            type="file"
            name="files"
            multiple
            accept="image/*"
            className={
              "input-file form-control text-primary typo-body-impotant" +
              (formik.errors.file_cover ? " is-invalid" : "")
            }
            onChange={(val) =>
              props.setOnSubmitControlProject({
                ...props.onSubmitControlProject,
                file_cover: val.currentTarget.files?.item(0),
              })
            }
            id="file-cover-project"
          />

          <span
            className={
              props.onSubmitControlProject?.file_cover !== ""
                ? "text-success"
                : ""
            }
          >
            {props.onSubmitControlProject?.file_cover !== ""
              ? props.onSubmitControlProject?.file_cover.name
              : "Select cover picture"}
            {props.onSubmitControlProject?.file_cover !== ""
              ? ""
              : " or drag and drop here."}
          </span>
        </div>
        <div className="mt-1 ">
          {props.errorFormularyFile ? (
            <span className="validation text-decoration-none p-0 m-0">
              You must a select file
            </span>
          ) : null}
        </div>
      </div>
      <div className="col-6  " style={{ marginTop: "18px" }}>
        {/* <label
          className="text-primary"
          htmlFor="start_date"
          style={{ fontSize: "12px" }}
        >
          Date
        </label> */}

        <InputDate
          select={"range"}
          touchUi={true}
          placeholder="Select date"
          onChange={(e: any) => {
            props.setDatesProject(e.value);
            console.log(e.value);
            
            props.setOnSubmitControlProject({
              ...props.onSubmitControlProject,
              start_date: e.value[0],
              end_date: e.value[1],
            });
          }}
          value={props.datesProject}
          id={'date_range_project'}
          // error={ErrorDate}
        />
        <div>
          {props.errorFormularyStartDate ? (
            <span className="validation">You must enter a project date</span>
          ) : null}
        </div>
      </div>
      <div className="col-md-6 mt-4">
        <SelectFloatingLabel
          className="text-black"
          placeholder="Organization name"
          value={props.onSubmitControlProject.organization}
          options={SelectOrganization}
          onChange={(val: React.ChangeEvent<HTMLSelectElement>) =>
            props.setOnSubmitControlProject({
              ...props.onSubmitControlProject,
              organization: val,
            })
          }
          id={'org-name-project'}
        />
        <div>
          {props.errorFormularyOrganization ? (
            <span className=" validation">You must enter a organization</span>
          ) : null}
        </div>
      </div>
      <div className="col-md-6 mt-4">
        <SelectFloatingLabel
          className="text-black"
          placeholder="Client name"
          onChange={(val: React.ChangeEvent<HTMLSelectElement>) =>{
            props.setOnSubmitControlProject({
              ...props.onSubmitControlProject,
              client: val,
            })
            console.log(val);
            
          }
          }
          value={clientTempForm ? clientTempForm: props.onSubmitControlProject.client}
          options={SelectClient}
        />
        <div>
          {props.errorFormularyClient ? (
            <span className=" validation">You must enter a client</span>
          ) : null}
        </div>
      </div>
      <div className="col-md-6 mt-4">
        <SelectFloatingLabel
          className="text-black"
          placeholder="Client Email"
          value={props.onSubmitControlProject.email}
          options={SelectEmail}
          onChange={(val: React.ChangeEvent<HTMLSelectElement>) =>
            props.setOnSubmitControlProject({
              ...props.onSubmitControlProject,
              email: val,
            })
          }
        />
        <div>
          {props.errorFormularyEmail ? (
            <span className="validation">You must enter a client email</span>
          ) : null}
        </div>
      </div>
      <div className="col-md-6 ">
        <SelectFloatingLabel
          placeholder="Primary account manager"
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
            props.setCurrentTab(event.target.value as string)
          }
          value=""
          options={[]}
        />
      </div>
      <div className="col-md-6 ">
        <SelectFloatingLabel
          placeholder="Secondary account manager"
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
            props.setCurrentTab(event.target.value as string)
          }
          value=""
          options={[]}
        />
      </div>
      activeControlView
      {props.activeControlView === "project" ? (
        <div >
          <button
            type="button"
            className="btn btn-primary btn-lg ms-auto d-flex "
            onClick={() => props.setCurrentTab(1)}
            disabled={props.active}
          >
            Next step
            <i
              className="bi bi-arrow-right ms-2 "
              style={{ marginTop: "2px" }}
            />
          </button>
        </div>
      ) : (
        []
      )}
    </form>
  </div>
  );
};

// props.idParamsProject === undefined ?

export default CreateProjectForm;
