import React, { useEffect, useState } from "react";
import logoIconblack from "../../assets/images/gameae.png"
import alps from "../../assets/images/alps.png";
import TablePreview from "./TablePreview";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getAllItems } from "../../store/stockpile/stockpileSlice";
import { RootState } from "../../store/store";
import UserInfoModal from "../../components/Modals/UserInfoModal/UserInfoModal";

interface Props {
  QuoteData?: any;
  QuotesId: any;
  QuoteVersion: any;
}

const Preview: React.FC<Props> = (props) => {
  const [client, setClient] = useState<any>([]);
  const [organization, setOrganization] = useState<any>([]);
  const [modalApproved, setmodalApproved] = useState(false)

  const [modalRequestChange, setmodalRequestChange] = useState(false)

  const [modalReject, setmodalReject] = useState(false)


  const dispatch = useDispatch();

  const getallItems = useSelector((state: RootState) => state.stockpileSlice);

  const  ItemData  =  useSelector((state: RootState) => state.factoryPublicSlice.Items);


  const getData = () => {
    dispatch(getAllItems());
  };

  useEffect(() => {
    getData();
  }, []);

  const tab = "\u00A0";
  const selectData = () => {
    [props.QuotesId && props.QuotesId].map((e: any) => {
      if (e?.client) {
        setClient(e.client.entity);
      }
      if (e?.organization) {
        setOrganization(e.organization.entity);
      }
    });
  };

  useEffect(() => {
    selectData();
  }, [props.QuotesId]);

  const filterHeader:any = props.QuoteVersion?.reduce((acc: any, curr: any) => {
    if (!acc[curr.name] && !curr.assigned_to) {
      acc = {
        ...acc,
        [curr.name]: curr.elements,
        ...curr.sub_headers?.reduce((acc, curr) => {
          acc = { ...acc, [curr.name]: curr.elements };
          return acc;
        }, []),
      };
    }

    return acc;
  }, {});

  const list = Object.keys(filterHeader)?.reduce((acc: any, curr) => {
    return [
      ...acc,
      {
        name: curr,
        total: filterHeader[curr]?.reduce(
          (acc2: any, current2: any) =>
            acc2 +
            Number(current2.quantity || 0) *
              Number(current2.rate || 0) *
              Number(current2.units_number || 0),
          0
        ),
      },
      ...filterHeader[curr],
    ];
  }, []);

  const totalItems = () => {
    const total2 = list?.reduce((acc: any, el: any) => {
      return acc + (el.total || 0);
    }, 0);

    return total2;
  };

  const formatNumber = (number: number) => {
    let n: string = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(number);

    return n;
  };

  console.log("QuotesId", props.QuotesId);

  return (
    <div
      className="m-0 p-0"
      style={{ width: "100%", height: "690px" }}
    >
      <div className="d-flex flex-column mt-2 m-0 p-0">
        <div className="d-flex pe-4 ps-4">
          <div>
            <img
              src={logoIconblack}
              alt=""
              style={{ width: "200px", height: "65px" }}
              className=" "
            />
          </div>
          <div className="ms-auto">
            <img
              src={alps}
              alt=""
              style={{ width: "200px", height: "65px" }}
              className=""
            />
          </div>
        </div>
      </div>
      <div
        className="d-flex col-3 mt-2 p-2 ms-3 justify-content-around"
        style={{ width: "1060px" }}
      >
        <div className="flex-column me-auto">
          <p className="text-secondary  d-flex justify-content-start">
            <span className="fw-bolder me-1">Organization: </span>
            {!props.QuotesId ? (
              <div className="d-flex fle-row">
                <i className="text-primary fs-5 bi bi-arrow-repeat animate-spin d-block" />
              </div>
            ) : (
              organization?.name
            )}
          </p>
          <p
            className="text-secondary  ms-auto d-flex ms-auto"
            style={{ marginTop: "-17px" }}
          >
            <span className="fw-bolder  d-flex justify-content-start me-1">
              Client:
            </span>
            {!props.QuotesId ? (
              <div className="d-flex fle-row">
                <i className="text-primary fs-5 bi bi-arrow-repeat animate-spin d-block" />
              </div>
            ) : (
              client?.first_name + tab + client?.last_name
            )}
          </p>
        </div>

        <div className="flex-column me-4">
          <p className="text-secondary  d-flex justify-content-start">
            <span className="fw-bolder me-1">Project:</span>
            {!props.QuotesId ? (
              <div className="d-flex fle-row">
                <i className="text-primary fs-5 bi bi-arrow-repeat animate-spin d-block" />
              </div>
            ) : (
              props.QuotesId?.project?.name
            )}
          </p>
          <p
            className="text-secondary   d-flex justify-content-start"
            style={{ marginTop: "-17px" }}
          >
            <span className="fw-bolder me-1">Job:</span>
            element
          </p>
        </div>

        <div className="flex-column me-4 ">
          <p className="text-secondary d-flex justify-content-start">
            <span className="fw-bolder me-1">Quote Number:</span>
            {!props.QuotesId ? (
              <div className="d-flex fle-row">
                <i className="text-primary fs-5 bi bi-arrow-repeat animate-spin d-block" />
              </div>
            ) : (
              props.QuotesId?._id
            )}
          </p>
          <p
            className="text-secondary  d-flex justify-content-start"
            style={{ marginTop: "-17px" }}
          >
            <span className="fw-bolder me-1">Date:</span>
            {!props.QuotesId ? (
              <div className="d-flex fle-row">
                <i className="text-primary fs-5 bi bi-arrow-repeat animate-spin d-block" />
              </div>
            ) : (
              moment(props.QuotesId?.created_at?.slice(0, -1)).format(
                "MM/DD/YYYY"
              )
            )}
          </p>
        </div>
        <div className="flex-column me-auto">
          <p className="text-secondary d-flex justify-content-start">
            <span className="fw-bolder me-1 ">Quote expiration date:</span>
            {!props.QuotesId ? (
              <div className="d-flex fle-row">
                <i className="text-primary fs-5 bi bi-arrow-repeat animate-spin d-block" />
              </div>
            ) : (
              moment(props.QuotesId?.due_date?.slice(0, -1)).format("MM/DD/YYYY")
            )}
          </p>
          <p
            className="text-secondary  ms-auto d-flex ms-auto"
            style={{ marginTop: "-17px" }}
          >
            <span className="fw-bolder d-flex justify-content-start me-1">
              Payment termns:
            </span>
            Payment term 1
          </p>
        </div>
      </div>
      <div className="me-4 ms-4" style={{ textAlign: "justify" }}>
        {!props.QuotesId?.description ? (
          <div className="d-flex fle-row">
            <i className="text-primary fs-5 bi bi-arrow-repeat animate-spin d-block" />
          </div>
        ) : (
          <p>{props.QuotesId?.description}</p>
        )}
      </div>
      {!props.QuoteVersion ? (
        <div className="d-flex m-auto justify-content-center">
          <div className="me-2">
            <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block" />
          </div>
          <div className="mt-3">
            <p>Loading...</p>
          </div>
        </div>
      ) : (
        <TablePreview list={list} ItemData={ItemData} />
      )}

      <div
        className="container-column ms-auto mb-3"
        style={{
          width: "13.5vw",
        }}
      >
        {!props.QuoteVersion ? (
          []
        ) : (
          <div className="row mt-3">
            <p className="text-gray " style={{ fontSize: "15px" }}>
              QUOTE SUMMARY<span className="ms-4"> { }</span>
            </p>
            <div
              className="col-md-12  bg-light-blue    d-flex  justify-content-around align-items-center "
              style={{ height: "32px", marginTop: "-10px", width: "269px" }}
            >
              <span className="">Total:</span>
              <span className="ps-2" style={{ fontSize: "15px" }}>
                {formatNumber(totalItems())}
              </span>
            </div>
          </div>
        )}
      </div>

      <div>
        <h2 className="text-secondary fw-bold mt-2">Term & Conditions</h2>

        <div className="ms-4 me-4" style={{ textAlign: "justify" }}>
          <p>{props.QuotesId?.term?.content}</p>
        </div>
        <div className="d-flex  justify-content-around p-5">
          <button
            className="btn btn-danger text-white fw-bold"
            style={{ height: "40px" }}
            onClick={() => setmodalReject(true)}
          >
            Reject quote 
          </button>
          <button className="btn btn-info text-white fw-bold"
            onClick={() => setmodalRequestChange(true)}
          >
            Request changes
          </button>
          <button
            className="btn btn-success text-white fw-bold"
            onClick={() => setmodalApproved(true)}
          >
            Approve quote
          </button>
        </div>
      </div>
      <UserInfoModal
        view={modalApproved}
        setView={setmodalApproved}
        toggle={() => setmodalApproved(!modalApproved)}
        title={'Signature & Confirmation Of identity'}
        response="Approved"
      />
      <UserInfoModal
        view={modalRequestChange}
        setView={setmodalRequestChange}
        toggle={() => setmodalRequestChange(!modalRequestChange)}
        title={'Signature & Confirmation Of identity'}
        response="RequestChange"
      />
      <UserInfoModal
        view={modalReject}
        setView={setmodalReject}
        toggle={() => setmodalReject(!modalReject)}
        title={'Signature & Confirmation Of identity'}
        response="Reject"
      />
    </div>
  );
};

export default Preview;
