import { useDispatch, useSelector } from 'react-redux';
import CategoryItem from '../../components/Contacts/ContactCategories/CategoryItem';
import { GetCategories, setCategoryFilter, GetTotalInvetory, setSubcategoryFilter, setCategorieActive } from '../../store/stockpile/categorieSlice';
import SimpleButton from '../../components/UI/buttons/SimpleButton'
import { RootState } from 'src/store/store';

interface Props {
    setViewPackageModal:any
    messageComming:any
}   

const CategorieList: React.FC<Props> = (props) => {
    const categories: Array<any> = useSelector((state: RootState) => state.categorieSlice.categories);
    const selected: any = useSelector((state: RootState) => state.categorieSlice.categorieActive);
    const {  setViewPackageModal , messageComming } = props

    const dispatch = useDispatch()



  const setSelected = (cate: any) => {
    dispatch(setCategorieActive(cate))
  }


    return (
        <ul className="list-unstyled pt-3 px-2 p-3 ps-4">
            <CategoryItem
                title="All items"
                category={{ _id: 'any' }}
                selected={selected}
                setSelect={setSelected}
                index={888876373900000}
            />

            {categories.map((cat: any, index) => {



                const subs = cat?.sub_categories?.map((sub: any) => {


                    return { name: sub?.name, action: () => dispatch(setSubcategoryFilter(sub?._id)) }
                })



                return (
                    <CategoryItem
                        key={index}
                        title={cat?.name}
                        options={subs}
                        count={cat?.sub_categories.length > 0 ? cat?.sub_categories.length : false}
                        category={cat}
                        selected={selected}
                        setSelect={setSelected}
                        index={index}
                    />
                )
            })}
            <div style={{ opacity: 0.7 }}>
                <SimpleButton onClick={() => { messageComming('ADD NEW CATEGORY') }}>
                    ADD NEW CATEGORY
                </SimpleButton>
            </div>

            <SimpleButton onClick={() => { setViewPackageModal(true) }} >
                CREATE PACKAGE
            </SimpleButton>
        </ul>
    )

}

export default CategorieList;

