import { forwardRef, Ref , useRef } from 'react';
import ItemModel from '../../../models/ItemModel';
import ContactItem from './ContactItem';

interface Props {
  letter: string;
  items: ItemModel[];
  setID: any;
  showAssets: any;
  itemsPre:any;
  setPre:any;
  setMaxValue?:any
  maxValue?:any
}

const ContactSection = forwardRef<HTMLDivElement, Props>(

  (props, ref: Ref<HTMLDivElement>) => {

    return (
      <div ref={ref} className="container-fluid p-0" style={{marginLeft: '.5em'}}>
        <div className="row">
          <div className="col-12">
            <p
              className="m-0 typo-body ps-3 py-1 text-primary"
              style={{ background: '#f3f3f3' }}
            >
              {props.letter}
            </p>
            <div>
              {props.items.map((item: any, index: number) => {
                return (
                    <ContactItem
                      key={index}
                      index={index}
                      item={item}
                      onSelect={() => { }}
                      setID={props.setID}
                      showAssets={props.showAssets}
                      itemsPre={props.itemsPre}
                      setPre={props.setPre}
                      maxValue={props.maxValue}
                      setMaxValue={props.setMaxValue}
                    />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default ContactSection;
