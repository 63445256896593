import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { RootState } from "src/store/store";
import { Address, AddressProps } from "./Address";
import { TabsContent } from "../../../AddContact";
import { toBase64 } from "src/util/converToBase64";
import { useFieldArray } from "src/hooks/useFieldArray";
import InputSelect from "src/components/UI/forms/InputSelect";
import OrganizationValidation, { OrganizationData } from "../validation";
import { createContactForm } from "src/store/blackbook/createContactSlice";
import InputFloatingLabel from "src/components/UI/forms/InputFloatingLabel";
import SimpleButton from "src/components/UI/buttons/SimpleButton";
import InputDate from "src/components/UI/forms/InputDate";
import ButtonFiles from "src/components/UI/buttons/ButtonFile";
import { ROImageMimeTypesArray } from "src/util";
import { useToast } from "src/hooks/useToast";
import SocialAndCommForm from "../../SocialAndComm";
import DropZone from "../../../../../UI/Dropzone";
import Checkbox from "../../../../../UI/forms/Checkbox";
import { setArray, setClientType, setSupplierType, setVenueType } from "../../../../../../store/blackbook/ViewBlackbookResources";

type SocialKeys = "facebook" | "twitter" | "instagram" | "linkedin";
type PhonesKeys = 'office' | 'mobile';

const OrganizationGeneralInformation = forwardRef((_props: any, ref: TabsContent['ref'],
) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.createContactFormSlice.data);
  const { clientType, supplierType, venueType, ArrayTypes } = useSelector((state: RootState) => state.ViewBlackbookResources);

  const [socials, setSocials] = useState<{ type: SocialKeys }[]>([]);
  const [phones, setPhones] = useState<{ type: PhonesKeys }[]>([{ type: 'office' }, { type: 'mobile' }]);
  const [cropped, setcropped] = useState(false)
  const [deleted, setdeleted] = useState(false)

  const [type, setType] = useState(false)

  const [files, setFiles] = useState<any>([])






  const formik = useFormik<OrganizationData>({
    initialValues: {
      name: '',
      contact_since: data.contact_since || '',
      ...(data.data || {}),
      type: []
    },
    validationSchema: OrganizationValidation.omit(['type']),
    onSubmit: () => { },
  });

  useImperativeHandle(ref, () => ({
    async handleValidation() {
      try {
        const errors = await formik.validateForm();
        if (errors && Object.keys(errors).length > 0) {
          return false;
        }

        return true;
      } catch {
        return false;
      }
    }
  }));

  useEffect(() => {
    if (data.initialValues !== undefined && data.appliedInitialValues === false) {
      formik.setValues(data.initialValues || {});
      /* @ts-ignore */
      dispatch(createContactForm.setField('appliedInitialValues', true, ''));
    }

    for (const key in (data.data?.social || data?.initialValues?.social || {})) {
      if ((data?.data?.social?.[key] || data?.initialValues?.social?.[key]) && socials.length === 0) {
        setSocials(socials => [...socials, { type: key as SocialKeys }]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, socials.length])

  const handleChange = (fieldName: string, value: any, path = 'data') => {
    formik.setFieldValue(fieldName, value);

    /* Files will be saved as strings instead of File, and then they will be re-coded to Files in the request. */
    if (value instanceof File) {
      toBase64(value as File).then(base64 => {
        /* @ts-ignore */
        dispatch(createContactForm.setField(fieldName, base64 as string, 'data'));
        /* @ts-ignore */
        dispatch(createContactForm.setField(fieldName, value.name, 'files'));
      }).catch(err => {
        console.log(err);
      })

      return;
    }

    /* @ts-ignore */
    dispatch(createContactForm.setField(fieldName, value, path));
  }

  const addresses = useFieldArray<OrganizationData, Required<OrganizationData>['addresses'][number]>(formik, 'addresses', handleChange);

  const handleSetFile = async (dataFile: any) => {
    console.log(dataFile[0]);

    handleChange('logo', dataFile[0])

    setFiles(dataFile)

  }

  const handleChangeType = (fieldName: string, value: any) => {

    let arrayTy = ArrayTypes
    let resultado: Array<any> = []


    if (arrayTy?.includes(value)) {
      resultado = arrayTy.filter(type => type !== value);
    } else {
      resultado = [value, ...arrayTy]
    }

    formik.setFieldValue(fieldName, resultado);
    dispatch(setArray(resultado))
    /* @ts-ignore */
    dispatch(createContactForm.setField(fieldName, resultado));

  }


  const deletePicture = () => {
    setdeleted(false)
    setFiles([])
  }

  useEffect(() => console.log(clientType), [clientType])


  return (
    <div className="container-fluid px-3 mt-3">
      <div className="row">

        <div className="col-6">
          <InputFloatingLabel
            id="organization-name-form-create-org"
            isRequired
            value={formik.values.name}
            placeHolder="Organization name"
            errorMessage={formik.errors.name}
            onChange={(val) => handleChange('name', val)}
          />
        </div>

        <div className="col-6">

          <DropZone
            setFile={async (data: any) => {
              handleSetFile(data)
              setcropped(true)
              setdeleted(true)
            }}
            files={files}
            maxFiles={1}
            errors={formik.errors.logo}
            setvieCropper={setcropped}
            vieCropper={cropped}
            setImage={(b: any) => {
              dispatch(createContactForm.setField('logo', b as string, 'data'));
            }}
            nameFile={files[0]?.name}
            Delete={deleted}
            setDelete={deletePicture}
          />
        </div>
        <div className="col-6 ps-5 mb-3 mt-n5">
          <p className="ms-n4" style={{ color: '#344469' }}>Organization Type</p>
          <Checkbox
            id={'type-org-create-check-1'}
            title="Client"
            value={clientType}
            onChange={(value) => {
              handleChangeType('type', 'client')
              console.log(clientType);
              dispatch(setClientType(!clientType))
            }
            }
          />
          <Checkbox
            id={'type-org-create-check-2'}
            title="Supplier"
            value={supplierType}
            onChange={(value) => {
              handleChangeType('type', 'supplier')
              dispatch(setSupplierType(!supplierType))
            }}
          />
          <Checkbox
            id={'type-org-create-check-3'}
            title="Venue"
            value={venueType}
            onChange={(value) => {
              handleChangeType('type', 'venue')
              dispatch(setVenueType(!venueType))
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          {phones.map((phone, index) => {
            const key = phone.type === 'mobile' ? 'mobile_number' : 'office_number';
            return (
              <InputSelect
                key={phone.type}
                select={{
                  value: `${phone.type}`,
                  onChange: (value: string) => {
                    if (phones.some(ph => ph.type === value)) {
                      toast.warn('Phone type already added.');
                      return;
                    }

                    phones.splice(index, 1, { type: value as PhonesKeys });
                  },
                  options: [
                    { value: 'office', label: 'Office' },
                    { value: 'mobile', label: 'Mobile' },
                  ],
                }}
                input={{
                  placeHolder: 'Phone',
                  errorMessage: formik.errors?.[key],
                  value: `${formik.values?.[key] || ''}`,
                  onChange: (val) => { handleChange(key, val) },
                }}
              />
            )
          })}

          {phones.length < 2 && (
            <SimpleButton
              onClick={() => {
                let toAdd: PhonesKeys = 'office';
                ['office', 'mobile'].forEach((item) => {
                  if (!phones.find((ph) => ph.type === item)) {
                    toAdd = item as PhonesKeys;
                  }
                })

                if (phones.length === 2) {
                  toast.warn('You can only add 2 phone types.');
                  return;
                }

                setPhones((prev) => [...prev, { type: toAdd }])
              }}
            >
              ADD PHONE
            </SimpleButton>
          )}
        </div>

        <div className="col-6">
          <InputFloatingLabel
            placeHolder="Home URL"
            errorMessage={formik.errors.home_url}
            value={`${formik.values.home_url || ''}`}
            onChange={(val) => handleChange('home_url', val)}
          />
        </div>
      </div>

      <SocialAndCommForm
        formik={formik}
        socials={socials}

        setSocials={setSocials}
        handleChange={handleChange}

        fillWith={(
          <>
            {(formik.values?.addresses || []).map((address, index) => (
              <Address errors={formik.errors.addresses as unknown as AddressProps['errors']} edit={addresses.set} index={index} remove={addresses.remove} value={address} />
            ))}

            <div className="col-6">
              <SimpleButton
                onClick={() => addresses.add({ map_location: '', type: 'billing' })}
              >
                ADD ADDRESS
              </SimpleButton>
            </div>
          </>
        )}
      />

      <div className="row">
        <div className="col-3">
          <p className="typo-body-important m-1 opacity-75 p-0 text-muted text-opacity-50">CONTACT SINCE:</p>
          <InputDate
            max={new Date()}
            value={formik.values.contact_since}
            error={formik.errors.contact_since}
            onChange={(e: any) => handleChange('contact_since', e.valueText, '')}
          />
        </div>
      </div>
    </div>
  )
})

export default OrganizationGeneralInformation;