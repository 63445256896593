import React, { useEffect, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal } from "reactstrap";
import InputDate from "../UI/forms/InputDate";
import InputFloatingLabel from "../UI/forms/InputFloatingLabel";
import Select from "../UI/forms/Select";

interface Props {
  // view: boolean;
  isOpen: boolean;
  toggle: () => void;
  filters: any;
  handleSearch: (filters) => void
  // activeControlView: string;
  // setActiveControlView: any;
}

const initialAdvancedFilters = {
  fromDate: 0,
  toDate: 0,
  organizationName: '',
  clientName: ''
}

const FactoryModalFilter: React.FC<Props> = (props) => {
  const [advancedFilters, setAdvancedFilters] = useState<any>(initialAdvancedFilters);

  const handleChangeFilters = (name, value) => {
    setAdvancedFilters((prev) => ({...prev, [name]: value}))
  };
  return (
    <div className={`container-factory-filters ${props.isOpen ? '' : 'd-none'}`} onClick={() => props.toggle()}>
      <div className="factory-filters" onClick={(e) => e.stopPropagation()} >
        <div className="row p-2">
          <div className="col-md-12" style={{ height: "45px" }}>
            <InputDate
              placeholder="Project start date"
              onChange={(e) => handleChangeFilters('fromDate', e.value)}
              value={advancedFilters.fromDate}
            />
          </div>
          <div className="col-md-12 mt-2">
            <InputDate
              placeholder="Project end date"
              onChange={(e) => handleChangeFilters('toDate', e.value)}
              value={advancedFilters.toDate}
              error={advancedFilters.toDate && advancedFilters.fromDate >= advancedFilters.toDate ? 'End Date must be greater than Start Date' : false}
            />
          </div>
          <div className="col-md-12 mt-2">
            <Select
              placeholder="Primary account manager"
              onChange={() => {}}
              value=""
              options={[]}
            />
          </div>
          <div className="col-md-12 mt-2" style={{ height: "43px" }}>
            <InputFloatingLabel
              placeHolder="Organitacion name"
              onChange={(v) => handleChangeFilters('organizationName', v)}
              value={advancedFilters.organizationName}
            />
          </div>
          <div className="col-md-12 mt-2" style={{ height: "43px" }}>
            <InputFloatingLabel
              placeHolder="Client name"
              onChange={(v) => handleChangeFilters('clientName', v)}
              value={advancedFilters.clientName}
            />
          </div>
          <div className="col-md-12 mt-2" style={{ height: "43px" }}>
            <InputFloatingLabel
              placeHolder="Account manager"
              onChange={() => {}}
              value=""
            />
          </div>
          <small className=" text-black m-1 ml-2 mt-3 ">
            Project total between
          </small>
          <div className="container">
            <div className="row g-2 ">
              <div className="col-6">
                <InputFloatingLabel
                  placeHolder="Min"
                  onChange={() => {}}
                  value=""
                />
              </div>
              <div className="col-6">
                <InputFloatingLabel
                  placeHolder="Max"
                  onChange={() => {}}
                  value=""
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-4 m-1  ">
              <button
                className="btn btn-primary btn-lg"
                style={{ width: "100px" }}
                onClick= {() => {props.handleSearch(advancedFilters); props.toggle()}}
              >
                Search
              </button>
            </div>
            <div className="col-4 m-2  fs-6">
              <a className="" onClick={() => {setAdvancedFilters(initialAdvancedFilters); props.handleSearch(initialAdvancedFilters);}}>Clear filter</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FactoryModalFilter;
