import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

interface ILayoutPath{
  label: string,
  redirect: string,
};
interface LayoutConfigState {
  moduleTitle: string;
  path: ILayoutPath[];
}

const initialState: LayoutConfigState = {
  moduleTitle: '',
  path: [],
};

const LayoutConfigSlice = createSlice<LayoutConfigState, SliceCaseReducers<LayoutConfigState>>({
  name: 'layout-configuration',
  initialState,
  reducers: {
    setModuleTitle: {
      reducer: (state, action: PayloadAction<string>) => {
        state.moduleTitle = action.payload;
        state.path = initialState.path;
      },
      prepare: (moduleTitle: string) => {
        return { payload: moduleTitle };
      }
    },
    setPath: {
      reducer: (state, action: PayloadAction<ILayoutPath[]>) => {
        state.path = action.payload;
      },
      prepare: (path: ILayoutPath[]) => {
        return { payload: path };
      }
    }
  },
});

export const layoutConfig  = LayoutConfigSlice.actions;

export default LayoutConfigSlice.reducer;
