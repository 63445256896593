import { Modal } from 'reactstrap';
import InputFloatingLabel from '../../UI/forms/InputFloatingLabel';
import Checkbox from '../../UI/forms/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import ClientPersonalValidation, { ClientData } from '../../Contacts/AddContact/tabs/Client/PersonalInformation/validation';
import { RootState } from "src/store/store";
import { toBase64 } from "src/util/converToBase64";
import { setArray, setClientType, setSupplierType, setVenueType } from "src/store/blackbook/ViewBlackbookResources";
import { BlackbookService } from '../../../services/blackbook'
import { createContactForm } from "src/store/blackbook/createContactSlice";
import { useToast } from '../../../hooks/useToast';
import { AxiosResponse } from 'axios';
import { ApiError } from '../../../models';

type Props = {
    onClose: (value?: boolean) => void;
    onConfirm: (value?: boolean) => void;
    title: string;
    description: string;
    organizations?:any;
    setorganizations?:any;
} & ({
    confirmText: string;
    cancelText: string;
    buttons?: undefined;
} | {
    buttons: React.ReactNode;
    confirmText?: undefined;
    cancelText?: undefined;
})

const CreateQuickOrganization: React.FC<Props> = ({
    onClose,
    onConfirm,
    title,
    buttons,
    cancelText,
    confirmText,
    organizations,
    setorganizations
}) => {

    const dispatch = useDispatch();
    const toast = useToast();

    const data = useSelector((state: RootState) => state.createContactFormSlice.data);
    const { clientType, supplierType, venueType, ArrayTypes } = useSelector((state: RootState) => state.ViewBlackbookResources);


    const formik = useFormik<ClientData>({
        initialValues: {
            email: '',
            first_name: '',
            last_name: '',
            contact_since: data.contact_since || '',
            ...(data.data || {}),
        },
        validationSchema: ClientPersonalValidation,
        onSubmit: () => { },
    });


    const handleChange = (fieldName: string, value: any, path = 'data') => {
        formik.setFieldValue(fieldName, value);

        /* Files will be saved as strings instead of File, and then they will be re-coded to Files in the request. */
        if (value instanceof File) {
            toBase64(value as File).then(base64 => {
                /* @ts-ignore */
                dispatch(createContactForm.setField(fieldName, base64 as string, 'data'));
                /* @ts-ignore */
                dispatch(createContactForm.setField(fieldName, value.name, 'files'));
            }).catch(err => {
                console.log(err);
            })

            return;
        }

        /* @ts-ignore */
        dispatch(createContactForm.setField(fieldName, value, path));
    }

    const handleChangeType = (fieldName: string, value: any) => {

        let arrayTy = ArrayTypes
        let resultado: Array<any> = []


        if (arrayTy?.includes(value)) {
            resultado = arrayTy.filter(type => type !== value);
        } else {
            resultado = [value, ...arrayTy]
        }

        formik.setFieldValue(fieldName, resultado);
        dispatch(setArray(resultado))
        /* @ts-ignore */
        dispatch(createContactForm.setField(fieldName, resultado));

    }

    const CreateData = () => {

        const dataCopy = JSON.parse(JSON.stringify(data.data));


        BlackbookService.CreateContact(
            {
                data: dataCopy,
                category: '104861306456114188',
                contact_since: data.contact_since,
                ...(data.note ? { note: data.note } : {}),
            },
            data.files
        )
            .then((contact) => {
                setorganizations([...organizations, contact ])
              //  dispatch(createContactForm.clearData({}));
              handleChange('name', '')
                dispatch(setSupplierType(false))
                dispatch(setVenueType(false))
                dispatch(setClientType(false))



                toast.success("Organization created successfully.");

                //Trigger Modal
                return onClose()

                // return history.push(`/blackbook/${contact._id}`);
            })
            .catch((err: AxiosResponse<ApiError>) => {
                return toast.error(err.data.message);
            });
    }

    return (
        <Modal isOpen={true} toggle={onClose.bind(null, undefined)} centered>
            <div className="container text-center">
                <div className="row p-4">
                    <div className="col-12">
                        <h2 className="font-weight-bold text-secondary typo-display">
                            {title}
                        </h2>
                    </div>
                    <div className="col-12 mt-3">
                        <InputFloatingLabel
                            value={formik.values.name}
                            placeHolder="Organization name"
                            errorMessage={formik.errors.name}
                            onChange={(val) => handleChange('name', val)}
                        />
                    </div>

                    <div className="col-6 ps-5" style={{ marginLeft: '-12%' }}>
                        <p className="ms-n0" style={{ color: '#344469' }}>Organization Type</p>
                        <div style={{ marginLeft: '-2%' }}>
                            <Checkbox
                                title="Client"
                                value={clientType}
                                onChange={(value) => {
                                    handleChangeType('type', 'client')
                                    console.log(clientType);
                                    dispatch(setClientType(!clientType))
                                }
                                }
                            />
                        </div>
                        <div style={{ marginLeft: '7%' }}>
                            <Checkbox
                                title="Supplier"
                                value={supplierType}
                                onChange={(value) => {
                                    handleChangeType('type', 'supplier')
                                    dispatch(setSupplierType(!supplierType))
                                }}

                            />
                        </div>
                        <div style={{ marginLeft: '1%' }}>
                            <Checkbox
                                title="Venue"
                                value={venueType}
                                onChange={(value) => {
                                    handleChangeType('type', 'venue')
                                    dispatch(setVenueType(!venueType))
                                }}
                            />
                        </div>

                    </div>
                </div>


                <div className="col-12 mt-3 mb-2">
                    <div className="row justify-content-evenly">
                        {buttons !== undefined ? buttons : (
                            <>
                                <button
                                    className="btn btn-success text-white col-auto"
                                    onClick={() => {
                                        CreateData()
                                    }}
                                >
                                    {confirmText}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CreateQuickOrganization;
