import React, { useEffect, useState } from "react";
import InputDateFatoryForm from "../../../../../components/UI/forms/InputDateFatoryForm";
import InputFloatingLabel from "../../../../../components/UI/forms/InputFloatingLabel";
import SelectFloatingLabel from "../../../../../components/UI/forms/SelectFloatingLabel";
import SelectFloatingLabelJobType from "../../../../../components/UI/forms/SelectFloatingLabelJobType";
import TextArea from "../../../../../components/UI/forms/TextArea";
import TextEdit from "./TextEdit/TextEdit";
import moment from "moment";
import Milestones from "./milestones";
import Deliverable from "./milestones/Deliverable";
import Document from "./milestones/Document";
import Requiriment from "./milestones/Requirement";
import InputDate from "../../../../UI/forms/InputDate";

interface Props {
  onSubmitControlForm?: any;
  setOnSubmitControlForm?: any;
  termsAndConditions: any
  errorFormulary?: boolean;
  setErrorFormulary?: any;
  jobDataById: any;
  idJob: any;
  loading: any;
  activeControlView: any;
  errorFormularyNameJob: boolean;
  errorFormularyStartDateJob: boolean;
  errorFormularyEndDateJob: boolean;
}

const JobForm: React.FC<Props> = (props) => {
  const [textView, setTextView] = useState<boolean>(false);

  const [dates, setDates] = useState<any>([
    props.onSubmitControlForm.start_date,
    props.onSubmitControlForm.end_date,
  ]);

  useEffect(() => {
    if (props.idJob) {
      props.setOnSubmitControlForm({
        ...props.onSubmitControlForm,
        name: props.jobDataById?.name,
        start_date: props.jobDataById?.start_date,
        end_date: props.jobDataById?.end_date,
        project: props.jobDataById?.project?._id,
        file_client_brief: props.jobDataById?.file_client_brief,
        client_brief: props.jobDataById?.client_brief,
      });
      setDates([props.jobDataById?.start_date, props.jobDataById?.end_date]);
    }
  }, [props.idJob, props.jobDataById]);

  const ErrorDate =
    props.errorFormularyStartDateJob && "You must enter a job start date";

  return (
    <div className="custom-scrollbar">
      <div className="">
        <form className="row g-3 ">
          <div className="col-md-6 mt-5">
            <div
              style={{
                marginBottom: "-18px",
              }}
            >
              <InputFloatingLabel
                placeHolder="Job Name"
                value={props.onSubmitControlForm.name}
                isRequired
                onChange={(val) =>
                  props.setOnSubmitControlForm({
                    ...props.onSubmitControlForm,
                    name: val,
                  })
                }
                id={'job-name-form'}
                // errorMessage={props.errorFormularyNameJob && true}
              />
            </div>

            <div>
              {props.errorFormularyNameJob ? (
                <span className=" validation">You must enter a job name</span>
              ) : null}
            </div>
          </div>

          <div className="col-md-6 mt-5">
            <SelectFloatingLabel
              placeholder="Project manager"
              onChange={() => {}}
              value=""
              options={[]}
            />
          </div>
          <div className="col-md-6 " style={{ marginTop: "25px" }}>
            <SelectFloatingLabel
              placeholder="Client manager of job"
              onChange={() => {}}
              value=""
              options={[]}
            />
          </div>
          <div className="col-6" style={{ marginTop: "20px" }}>
            {/* <label
              className="text-primary"
              htmlFor="start_date"
              style={{ fontSize: "12px" }}
            >
              Date
            </label> */}
            <InputDate
              select={"range"}
              touchUi={true}
              onChange={(e: any) => {
                setDates(e.value);

                props.setOnSubmitControlForm({
                  ...props.onSubmitControlForm,
                  start_date: e.value[0],
                  end_date: e.value[1],
                });
              }}
              value={dates}
              // error={ErrorDate}
              placeholder="Select date"
              id={'job-date'}
            />
            <div>
              {props.errorFormularyStartDateJob ? (
                <span className=" validation">You must enter a job date</span>
              ) : null}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6 ">
              <SelectFloatingLabelJobType
                className="text-black"
                placeholder="Job type"
                value={props.onSubmitControlForm.job_type}
                options={[]}
                onChange={(val: React.ChangeEvent<HTMLSelectElement>) =>
                  props.setOnSubmitControlForm({
                    ...props.onSubmitControlForm,
                    job_type: val,
                  })
                }
              />
            </div>
            <div className="col-md-6 ">
              <SelectFloatingLabel
                className="text-black"
                placeholder="Terms & conditions"
                value={props.onSubmitControlForm.term_conditions}
                options={props.termsAndConditions?.map(e => ({ label: e.title, value: e._id }))}
                onChange={(val: React.ChangeEvent<HTMLSelectElement>) =>
                  props.setOnSubmitControlForm({
                    ...props.onSubmitControlForm,
                    term_conditions: val,
                  })
                }
              />
            </div>
          </div>
          <div>
            <button
              type="button"
              className={
                textView ? "btn btn-default btn-lg" : "btn btn-primary btn-lg"
              }
              onClick={() => setTextView(false)}
            >
              UPLOAD CLIENT BRIEF
            </button>
            <button
              type="button"
              className={
                textView
                  ? "btn btn-primary btn-lg margin-left"
                  : "btn btn-default btn-lg margin-left"
              }
              onClick={() => setTextView(true)}
            >
              TYPE CLIENT BRIEF
            </button>
          </div>
          {textView ? (
            <TextEdit
              onSubmitControlForm={props.onSubmitControlForm}
              setOnSubmitControlForm={props.setOnSubmitControlForm}
              jobDataById={props.jobDataById}
              idJob={props.idJob}
            />
          ) : (
            <div className="col-md-12 container-input-file">
              <div className="custom-input-file col-md-6 col-sm-6 col-xs-6">
                <input
                  type="file"
                  name="files"
                  multiple
                  // accept="image/*"
                  id=""
                  className="input-file"
                  onChange={(val) =>
                    props.setOnSubmitControlForm({
                      ...props.onSubmitControlForm,
                      file_client_brief: val.currentTarget.files?.item(0),
                    })
                  }
                />
                <span
                  className={
                    props.onSubmitControlForm?.file_client_brief !== "" &&
                    props.onSubmitControlForm?.file_client_brief?.name
                      ? "text-success"
                      : ""
                  }
                >
                  {props.onSubmitControlForm?.file_client_brief !== "" &&
                  props.onSubmitControlForm?.file_client_brief?.name
                    ? props.onSubmitControlForm?.file_client_brief?.name
                    : "Select cover picture"}
                </span>
                {props.onSubmitControlForm?.file_client_brief !== "" &&
                props.onSubmitControlForm?.file_client_brief?.name
                  ? ""
                  : " or drag and drop here."}
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default JobForm;
