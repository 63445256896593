import { useState, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';
import Checkbox from '../../../../UI/forms/Checkbox';
import InputFloatingLabel from '../../../../UI/forms/InputFloatingLabel';
import InputSelect from '../../../../UI/forms/InputSelect';
import TextArea from '../../../../UI/forms/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { CreateItem } from '../../../../../store/stockpile/stockpileSlice';
import { setData, setSizeG, changeGeneralErrors, setNameFiles, EmptyForm } from '../../../../../store/stockpile/createFormSlice';
import { sizesModel } from '../../../../../models/ItemModel';
import ItemModel from '../../../../../models/ItemModel';
import { RootState } from '../../../../../store/store';
import { useFormik, Form, FormikProvider } from 'formik';
import ItemSchemaValidation from './validation';
import { toBase64 } from '../../../../../util/converToBase64';
import ButtonFiles from '../../../../UI/buttons/ButtonFile';
import SimpleButton from 'src/components/UI/buttons/SimpleButton';
import { Select } from '@mobiscroll/react';
import SelectInput from 'src/components/UI/forms/Select';
import { ConvertSize } from '../../../../../util/sizesConvert'
import { setCheckItemFormAlert } from 'src/store/stockpile/RecursosVisualeSlice';
import { useToast } from 'src/hooks/useToast';
import { Input } from 'reactstrap';
import { useDropzone } from 'react-dropzone'
import DropZone from 'src/components/UI/Dropzone';
import { GetAllContacts } from 'src/store/blackbook/getContactSlice';


interface Props {
  alertComplete: any,
  resetAll: boolean
}

const GeneralData: React.FC<Props> = (props) => {
  const toast = useToast()

  const data = useSelector((state: RootState) => state.createFormSlice.data);
  const sizesG = useSelector((state: RootState) => state.createFormSlice.sizesG);
  const categories: any = useSelector((state: RootState) => state.categorieSlice.categories);
  const nameFiles: any = useSelector((state: RootState) => state.createFormSlice.files);
  const GetContact: Array<any> = useSelector((state: RootState) => state.getContactAll.getContact);

  const [webLinks, setWebLinks] = useState<any>([])

  const [setO, oS] = useState(true)

  const [errorpictureSelected, setErrorPictureSelected] = useState<any>('')
  const [errormanualFile, setErrorManualFile] = useState<any>('')
  const [errorphotos, setErrorFotos] = useState<any>('')
  const [manuavl, setmanuavl] = useState<any>('')
  const [testing, setTesting] = useState<any>('')

  const [eventCropper, seteventCropper] = useState<any>(null)

  const [vieCropper, setvieCropper] = useState<boolean>(false)



  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      ...data,
      dryhire: true,
      discountable: true,
      //  sizes: { ...(data?.sizes && data?.sizes[0] || {} as sizesModel) }
      consumption_unit: 'watts',
      consumption_unit2: 'watts',
      sizes: sizesG
    },
    onSubmit: (values, { setSubmitting }) => {

    },
    validationSchema: ItemSchemaValidation
  })

  const [files, setFiles] = useState([]);

  const [photos, setPhotos] = useState([]);

  const [manual, setManual] = useState([]);

  const { errors, values, handleSubmit, setFieldValue, touched, resetForm, setErrors } = formik

  const [urlCount, setUrlCount] = useState<{ type: string; value: string }[]>([]);

  const [deleted, setdeleted] = useState(false)


  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      dispatch(changeGeneralErrors(errors))
    } else {
      dispatch(changeGeneralErrors(false))
    }
  }, [errors])

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      dispatch(setCheckItemFormAlert(false))
    }
    dispatch(setSizeG({ weight_unit: 'kilograms' }))
    setFieldValue('sizes.weight_unit', 'kilograms')
  }, [errors])

  useEffect(() => {
    if (props.resetAll) {
      resetForm()
    }
  }, [])

  useEffect(() => {
    if (props.alertComplete) {
      setFieldValue('name', '')
      toast.error('Complete the required fields')
    }

  }, [props.alertComplete])

  const handlePicture = async (dataFile: any) => {

    console.log(dataFile);


    setFiles(dataFile)

    setErrorPictureSelected(false);

    setdeleted(true)


    dispatch(setNameFiles({ picture_name: dataFile[0]?.name }))

    dataFile[0] && setvieCropper(true)

  }

  const handleUserManual = async (event: any) => {
    console.log(event);

    setManual(event)
    const file = event[0]
    const value = await toBase64(file)
    dispatch(setData({ manual_url: value } as ItemModel))
    //   if (!file.name.match(/\.(pdf|odt|doc|docx)$/)) {
    //     setErrorManualFile('Please select valid File. (pdf , odt, doc, docx)');
    //     return false;s
    //   } else {
    setErrorManualFile(false)
    setmanuavl(value)
    dispatch(setNameFiles({ user_manual_uno: event[0].name }))
    //  }

  }

  const handlePhoto = async (dataFiles: any) => {
    setPhotos(dataFiles)
    const files: Array<any> = dataFiles
    const files64 = []

    /*  for (let i = 0; i < files.length; i++) {
        if (!files[i].name.match(/\.(jpg|jpeg|png)$/)) {
          setErrorFotos('Please select valid image. (jpg , jpeg, png)');
          return false;
        } else {
          setErrorFotos('');
        }
      }
  */
    for (let i = 0; i < files.length; i++) {
      files64[i] = await toBase64(files[i])
    }

    dispatch(setData({ photos: files64 } as ItemModel))

    const names = []

    for (let i = 0; i < files.length; i++) {
      names[i] = files[i].name
    }

    // setNameFotos(names)
    dispatch(setNameFiles({ photos_name: names }))

  }

  const sub_categories: any = categories.filter((cat: any) => cat?._id == '67807598690173953' ? true : false)[0]?.sub_categories

  useEffect(() => {
    console.log(sub_categories);

  }, [categories])

  const handleWebLinks = () => {
    const links = urlCount.map((link) => {
      return link.value
    })
    dispatch(setData({ web_links: links } as ItemModel))
    setWebLinks(links)
  }

  useEffect(() => {
    setFieldValue('category', '67807598690173953')
    dispatch(setData({ category: '67807598690173953' }))


  }, [formik.values.name])

  useEffect(() => {
    dispatch(setData({ discountable: true } as ItemModel))
    dispatch(setData({ dryhire: true } as ItemModel))

  }, [])



  const optionCategories = categories.map((item: any) => {
    return {
      label: item?.name,
      value: item?._id
    }
  })

  const optionSubCategories = sub_categories?.map((item: any) => {
    return {
      label: item?.name,
      value: item?._id?.toString()
    }
  })


  const suppliersOptions = GetContact?.filter(
    model => model.entityModel === "Client" && model.entity.is_supplier === true
  ).map((contact:any) => ({ label: contact?.entity?.first_name, value: contact?.entity?._id }) )

    useEffect(() => {
    console.log(GetContact);
    console.log(suppliersOptions);
  }, [GetContact])

  const deletePicture = () => {
    setdeleted(false)
    setFiles([])
    dispatch(setNameFiles({ picture_name: '' }))
  }


  return (
    <div className="container-fluid px-3 mt-3 pb-5 mb-2" style={{
      maxHeight: '62vh'
    }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate >
          <div className="row">
            <div className="mt-0 ms-3 mb-3">
              <Checkbox
                onChange={(value: any) => {
                  setFieldValue('dryhire', value)
                  dispatch(setData({ dryhire: value } as ItemModel))
                }}
                value={formik.values.dryhire}
                title="Available for dryhire"
                className=" mt-1"
              />

              <Checkbox
                onChange={(value: any) => {
                  setFieldValue('discountable', value)
                  dispatch(setData({ discountable: value } as ItemModel))
                }}
                value={formik.values.discountable}
                title="Discountable item"
                className=" mt-1"
              />
            </div>
            {/** item name */}
            <div className="col-sm-12 col-md-12 col-lg-4">
              <InputFloatingLabel
                id='nameItem'
                placeHolder="Item name"
                value={formik.values.name}
                onChange={(value: string) => {
                  setFieldValue('name', value)
                  dispatch(setData({ name: value }))
                }}
                isRequired
                errorMessage={errors.name}
              />
            </div>

            {/** item brand */}
            <div className="col-sm-12 col-md-12 col-lg-4">
              <InputFloatingLabel
                placeHolder="Brand"
                value={formik.values.brand}
                onChange={(value: any) => {
                  setFieldValue('brand', value)
                  dispatch(setData({ brand: value }))
                }}
                errorMessage={errors.brand}
              />
              <p className='validation' >{
                touched.brand && errors.brand
              }</p>
            </div>

            {/** item model */}
            <div className="col-sm-12 col-md-12 col-lg-4">
              <InputFloatingLabel
                placeHolder="Model"
                value={formik.values.model}
                onChange={(value: any) => {
                  setFieldValue('model', value)
                  dispatch(setData({ model: value }))
                }}
              />
              <p className='validation' >{
                Boolean(touched.model && errors.model)
              }</p>
            </div>
          </div>

          {/** item category */}
          <div className="row mt-3">
            <div className="col-sm-12 col-md-12 col-lg-4 mb-4" style={{ pointerEvents: 'none', opacity: 0.6 }}>

              <SelectInput
                placeholder={'Select Category'}
                onChange={(data: any) => {
                  // setFieldValue('category', data)
                  dispatch(setData({ category: data }))
                }}
                value={formik.values.category}
                options={optionCategories}
                searchable={false}
              />
              <p className='validation' >{
                errors.category
              }</p>
            </div>

            {/** item sub_category */}
            {

              <div className="col-sm-12 col-md-12 col-lg-4 mb-4">
                <SelectInput
                  placeholder={'Select Subcategory'}
                  onChange={(data: any) => {
                    setFieldValue('sub_category', data)
                    console.log(data);

                    dispatch(setData({ category: data }))
                  }}
                  value={formik.values.sub_category}
                  options={optionSubCategories}
                  searchable={false}

                />
              </div>

            }
            <div className="col-sm-12 col-md-12 col-lg-4 mb-4">
              <SelectInput
                placeholder={'item supplier'}
                onChange={(data: any) => {
                  setFieldValue('supplier', data)
                  dispatch(setData({ supplier: data } as ItemModel))
                }}
                value={formik.values.supplier}
                options={suppliersOptions}
                searchable={true}

              />
            </div>

            {/** item picture */}
            <div className="col-sm-12 col-md-12 col-lg-6 mb-4">
              <p className=' text-primary typo-body p-0'>SELECT PICTURE</p>
              <DropZone
                setFile={handlePicture}
                files={files}
                maxFiles={1}
                errors={errorpictureSelected}
                setvieCropper={setvieCropper}
                vieCropper={vieCropper}
                setImage={
                  (base64: any) => {
                    dispatch(setData({ picture_url: base64 } as ItemModel))
                  }
                }
                nameFile={nameFiles?.picture_name}
                Delete={deleted}
                setDelete={deletePicture}
              />
            </div>
          </div>

          {/** item descripcion */}
          <div className="row mt-3">
            <div className="col">
              <TextArea
                // title="General note"
                placeHolder="Description..."
                value={formik.values.description}
                onChange={(value: any) => {
                  setFieldValue('description', value)
                  dispatch(setData({ description: value }))
                }}
                errorMessage={errors.description}
              />
            </div>
          </div>

          {/** item web_links */}
          <div className="row mt-3">
            <div className='col-sm-12 col-md-12 col-lg-4 mb-4' >
              {urlCount.map((item, index: number) => {

                const ErrorWebLinks = errors.web_links ? errors.web_links[index] : errors.web_links

                return (
                  <div style={{ marginTop: 5, display: 'flex', flexDirection: 'row', background: '' }} >
                    <div style={{ width: '70%' }}>
                      <InputFloatingLabel
                        className="form-control text-primary typo-body-impotant"
                        placeHolder="web link"
                        value={item.value}
                        onChange={
                          (value) => {
                            const urlCountCopy: any = urlCount.map((item, i) => {
                              if (index == i) item.value = value
                              return item
                            })
                            setUrlCount(urlCountCopy)
                            handleWebLinks()
                            setFieldValue('web_links', data.web_links)
                          }
                        }
                        errorMessage={ErrorWebLinks}
                      />
                    </div>
                    <p className='DeleteInputBtn' onClick={() => {
                      const cpy = [...urlCount]
                      cpy.splice(index, 1);
                      setUrlCount(cpy)
                    }} >
                      x
                    </p>
                  </div>
                );
              })}
              <div style={{ maxWidth: '200px' }} className='d-none'>
                <SimpleButton onClick={setUrlCount.bind(null, (prev) => [
                  ...prev,
                  { type: '', value: '' },
                ])}>
                  ADD WEB LINK
                </SimpleButton>
              </div>
            </div>
          </div>

          {/** item photo */}
          <div className="row mt-3">
            <div className="col-sm-12 col-md-12 col-lg-6 mb-4" >
              <p className=' text-primary typo-body p-0'>SELECT PHOTOS</p>
              <DropZone
                setFile={handlePhoto}
                files={photos}
                errors={errorphotos}
              />
            </div>

            {/** item consumption */}
            <div className="col-sm-12 col-md-12 col-lg-6 mt-4">
              <InputSelect
                className='mt-2'
                select={{
                  value: formik.values.consumption_unit
                  ,
                  onChange: (value) => {
                    setFieldValue('consumption_unit', value)
                    dispatch(setData({ consumption_unit: value } as ItemModel))
                  },
                  options: [
                    {
                      label: 'Watts',
                      value: 'watts',
                    }
                  ],
                  placeholder: "Watts"
                }}
                input={{
                  placeHolder: 'Power consumption',
                  value: formik.values.consumption,
                  isRequired: false,
                  onChange: (value: any) => {
                    setFieldValue('consumption', value)
                    dispatch(setData({ consumption: value } as ItemModel))
                  },
                  errorMessage: errors?.consumption
                }}
              />
              <InputSelect
                className='mt-2'
                select={{
                  value: formik.values.consumption_unit2
                  ,
                  onChange: (value) => {
                    setFieldValue('consumption_unit2', value)
                    dispatch(setData({ consumption_unit2: value } as ItemModel))
                  },
                  options: [
                    {
                      label: 'Amps',
                      value: 'amps',
                    }
                  ],
                  placeholder: "Amps"
                }}
                input={{
                  placeHolder: 'Power consumption',
                  value: formik.values.consumption2,
                  isRequired: false,
                  onChange: (value: any) => {
                    setFieldValue('consumption2', value)
                    dispatch(setData({ consumption2: value } as ItemModel))
                  },
                  errorMessage: errors?.consumption2
                }}
              />
            </div>
          </div>

          {/** item manual */}
          <div className="row mt-3" >
            <div className="col-sm-12 col-md-12 col-lg-6 mb-4">
              <p className=' text-primary typo-body p-0'>SELECT USER MANUAL FILE</p>
              <DropZone
                setFile={handleUserManual}
                files={manual}
                maxFiles={1}
                errors={errormanualFile}
                file={true}
              />
            </div>

            <div className="col-sm-12 col-md-12 col-lg-6 mt-4">
              <InputFloatingLabel
                className='mt-1'
                placeHolder="User manual link"
                value={formik.values.manual_url}
                onChange={(value: any) => {
                  setFieldValue('manual_url', value)
                  dispatch(setData({ manual_url: value } as ItemModel))
                }}
                errorMessage={errors.manual_url}
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <p className="m-0 text-primary typo-body p-0">ITEM INFORMATION</p>

              {/** item size weight */}
              <div className="mt-2 col-12">
                <InputSelect
                  select={{
                    onChange: (value) => {
                      setFieldValue('sizes.weight_unit', value)
                      dispatch(setSizeG({ weight_unit: value } as sizesModel))
                    },
                    options: [
                      {
                        label: 'Kg',
                        value: 'kilograms',
                      },
                      {
                        label: 'Grams',
                        value: 'grams',
                      },
                      {
                        label: 'Pounds',
                        value: 'pounds',
                      },
                      {
                        label: 'Ounces',
                        value: 'ounces',
                      }
                    ],
                    value: formik.values.sizes?.weight_unit,
                    placeholder: "Unit",
                    searchable: false
                  }}
                  input={{
                    onChange: (value: any) => {
                      setFieldValue('sizes.weight', value)
                      dispatch(setSizeG({ weight: value } as sizesModel))
                    },
                    placeHolder: 'Weight',
                    value: values.sizes.weight,
                    errorMessage: errors?.sizes?.weight?.toString()
                  }}
                />
              </div>

              {/** item length */}
              <div className="mt-2 col-12">
                <InputSelect
                  select={{
                    onChange: (value: any) => {
                      setFieldValue('sizes.sizes_unit', value)
                      dispatch(setSizeG({ sizes_unit: value } as sizesModel))
                    },
                    options: [
                      {
                        label: 'Cm',
                        value: 'centimeters',
                      }
                      ,
                      {
                        label: 'Meter',
                        value: 'meters',
                      },
                      {
                        label: 'Inches',
                        value: 'inches',
                      },
                      {
                        label: 'Feet',
                        value: 'feet',
                      },
                    ],
                    value: formik.values.sizes.sizes_unit,
                    placeholder: "Unit",
                    searchable: false
                  }}
                  input={{
                    onChange: (value: any) => {
                      setFieldValue('sizes.length', value)
                      dispatch(setSizeG({ length: value } as sizesModel))
                    },
                    placeHolder: 'Length',
                    value: formik.values.sizes.length,
                    errorMessage: errors.sizes?.length
                  }}
                />
              </div>
              {/** item width */}
              <div className="mt-2 col-md-10 float-end" style={{ paddingLeft: '7.5%', paddingRight: '.5%' }}>
                <InputFloatingLabel
                  placeHolder="Width"
                  value={formik.values.sizes.width}
                  onChange={(value: any) => {
                    setFieldValue('sizes.width', value)
                    dispatch(setSizeG({ width: value } as sizesModel))
                  }}
                  errorMessage={errors.sizes?.width}
                />
              </div>

              <div className="mt-2 col-md-10 float-end" style={{ paddingLeft: '7.5%', paddingRight: '.5%' }}>
                <InputFloatingLabel
                  placeHolder="Height"
                  value={formik.values.sizes.height}
                  onChange={(value) => {
                    setFieldValue('sizes.height', value)
                    dispatch(setSizeG({ height: value } as sizesModel))
                  }}
                  errorMessage={errors.sizes?.height}
                />
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-6">
              <p className="m-0 text-primary typo-body">USAGE MEASUREMENT</p>

              <div className="mt-2">
                <InputSelect
                  select={{
                    onChange: () => { },
                    options: [
                      {
                        label: 'Units',
                        value: 'units',
                      },
                      {
                        label: 'Days',
                        value: 'days',
                      },
                      {
                        label: 'Hours',
                        value: 'hours',
                      }
                    ],
                    value: '',
                    placeholder: "Unit",
                    searchable: false

                  }}
                  input={{
                    onChange: (value: any) => {
                      setFieldValue('usage_statement', value)
                      dispatch(setData({ usage_statement: value } as ItemModel))
                    },
                    placeHolder: 'Start at...',
                    value: formik.values.usage_statement,
                  }}
                />
              </div>


            </div>

          </div>

        </Form>
      </FormikProvider>

    </div>
  );
}

export default GeneralData;
