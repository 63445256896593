import InfoDisplay from "src/components/UI/InfoDisplay";
import { ContactType, IContact } from "src/models/ContactModel";
import { getFileUrl } from "src/util";
import { IContactContactTypeEx } from "src/util/ContactsFunctions";
import { BlackbookService } from '../../../../../services/blackbook'
import ConfirmDuplicate from "../../../../Modals/Contact/ConfirmDuplicate";
import { useState } from "react";
import ViewerFileModal from "../../../../Modals/ViewerFileModal";



const OrganizationDocuments: React.FC<{ contact: IContact, extractor: IContactContactTypeEx }> = ({ contact, extractor }) => {

  const [files, setFiles] = useState<any>([])
  const [viewer, setviewer] = useState<boolean>(false)

  const [confirmA, setConfirmA] = useState<boolean>(false)
  const [confirmB, setConfirmB] = useState<boolean>(false)
  const [confirmC, setConfirmC] = useState<boolean>(false)
  const [confirmD, setConfirmD] = useState<boolean>(false)
  const [confirmE, setConfirmE] = useState<boolean>(false)



  if (contact.type !== ContactType.ORGANIZATION) return null;

  const handleDeleteFile = (type: any) => {
    switch (type) {
      case 'docs_logo':
        setConfirmA(true)

        break;

      case 'contract':
        setConfirmB(true)

        break;
      case 'insurance':
        setConfirmC(true)


        break;
      case 'trade_license':
        setConfirmD(true)

        break;

      case 'style_guide':
        setConfirmE(true)
        break;

      default:
        break;
    }
  }

  const DeletedConfirmed = (typeFile: any) => {
    BlackbookService.DeleteFileContact({
      contact: contact._id,
      file: typeFile
      // File: 'docs_logo'
    })

    switch (typeFile) {
      case 'docs_logo':
        setConfirmA(false)

        break;

      case 'contract':
        setConfirmB(false)

        break;
      case 'insurance':
        setConfirmC(false)


        break;
      case 'trade_license':
        setConfirmD(false)

        break;

      case 'style_guide':
        setConfirmE(false)
        break;

      default:
        break;
    }
  }

  const onCancelModalClose = (type:any) => {
    switch (type) {
      case 'docs_logo':
        setConfirmA(false)

        break;

      case 'contract':
        setConfirmB(false)

        break;
      case 'insurance':
        setConfirmC(false)


        break;
      case 'trade_license':
        setConfirmD(false)

        break;

      case 'style_guide':
        setConfirmE(false)
        break;

      default:
        break;
    }
  }

  return (
    <div className="container-fluid px-3">
      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <InfoDisplay
            title="logo for document branding"
            texts={[
              {
                isFile: true,
                fileHref: getFileUrl(contact.entity.documents?.docs_logo),
                text: contact.entity.documents?.docs_logo?.split('/')[1] || 'Not uploaded yet',
              },
            ]}
            removableItem={{
              removableArg: contact.entity.documents?.docs_logo?.split('/')[1],
              confirmD: confirmA,
              onCancelModalClose:  () => onCancelModalClose('docs_logo'),
              handleDelete:  () => handleDeleteFile('docs_logo'),
              DeletedConfirmed: () => DeletedConfirmed('docs_logo'),
              textEmpty: 'Not uploaded yet'
            }}

          />

        </div>

        <div className="col-6">
          <InfoDisplay
            title="contract"
            texts={[
              {
                isFile: false,
                fileHref: false,
                //  fileHref: '/blackbook/view/files/'+  getFileUrl(contact.entity.documents?.contract),
                text: contact.entity.documents?.contract?.split('/')[1] || 'Not uploaded yet',
                action: () => {
                  setFiles([getFileUrl(contact.entity.documents?.contract)])
                  setviewer(true)
                }
              },
            ]}
            removableItem={{
              removableArg: contact.entity.documents?.contract?.split('/')[1],
              confirmD: confirmB,
              onCancelModalClose:  () => onCancelModalClose('contract'),
              handleDelete:  () => handleDeleteFile('contract'),
              DeletedConfirmed: () => DeletedConfirmed('contract'),
              textEmpty: 'Not uploaded yet'
            }}
          />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="trade license"
            texts={[
              {
                isFile: true,
                fileHref: getFileUrl(contact.entity.documents?.trade_license),
                text: contact.entity.documents?.trade_license?.split('/')[1] || 'Not uploaded yet',
              },
            ]}
            removableItem={{
              removableArg: contact.entity.documents?.trade_license?.split('/')[1],
              confirmD: confirmD,
              onCancelModalClose:  () => onCancelModalClose('trade_license'),
              handleDelete:  () => handleDeleteFile('trade_license'),
              DeletedConfirmed: () => DeletedConfirmed('trade_license'),
              textEmpty: 'Not uploaded yet'
            }}
          />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="style guide"
            texts={[
              {
                isFile: true,
                fileHref: getFileUrl(contact.entity.documents?.style_guide),
                text: contact.entity.documents?.style_guide?.split('/')[1] || 'Not uploaded yet',
              },
            ]}
            removableItem={{
              removableArg: contact.entity.documents?.style_guide?.split('/')[1],
              confirmD: confirmE,
              onCancelModalClose: () => onCancelModalClose('style_guide'),
              handleDelete:  () => handleDeleteFile('style_guide'),
              DeletedConfirmed: () => DeletedConfirmed('style_guide'),
              textEmpty: 'Not uploaded yet'
            }}

          />
        </div>

        <div className="col-6">
          <InfoDisplay
            title="insurance"
            texts={[
              {
                isFile: true,
                fileHref: getFileUrl(contact.entity.documents?.insurance),
                text: contact.entity.documents?.insurance?.split('/')[1] || 'Not uploaded yet',
              },
            ]}
            removableItem={{
              removableArg: contact.entity.documents?.insurance?.split('/')[1],
              confirmD: confirmC,
              onCancelModalClose:  () => onCancelModalClose('insurance'),
              handleDelete:  () => handleDeleteFile('insurance'),
              DeletedConfirmed: () => DeletedConfirmed('insurance'),
              textEmpty: 'Not uploaded yet'
            }}
          />
        </div>
      </div>

      {
        viewer && (
          <ViewerFileModal
            title="ViewerDoc"
            files={files}
            onClose={() => { setviewer(false) }}
          cancelText="Cerrar"
          />
        )
      }
    </div>
  );
};

export default OrganizationDocuments;
