import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'reactstrap';
import SelectInput from 'src/components/UI/forms/Select';
import { useToast } from 'src/hooks/useToast';
import ItemModel from 'src/models/ItemModel';
import { updateAccesoriesMandatory } from 'src/store/stockpile/createFormSlice';
import { CreateCommentForAsset, CreateItem, CreateRates, getAllItems } from 'src/store/stockpile/stockpileSlice';
import { RootState } from 'src/store/store';
import InputFloatingLabel from '../../UI/forms/InputFloatingLabel';
import TextArea from '../../UI/forms/TextArea';
import ModalItemCreate from './ModalItemCreated';
import QuickAddScheme from './validations';

interface Props {
    title: string;
    view: boolean;
    setView: any;
    indexInput?: any;
    setModalSelect?: any
    setViewInfo?: any
    viewInfo?: any
}

const QuickAddItem: React.FC<Props> = (props) => {

    const dispatch = useDispatch()

    const toast = useToast()

    const [name, setName] = useState('')
    const [brand, setBrand] = useState('')
    const [category, setCategory] = useState('')
    const [description, setDescription] = useState('')


    const formik = useFormik({
        validationSchema: QuickAddScheme,
        initialValues: {
            name: '',
            brand: '',
            category: '',
            description: ''
        } as ItemModel,
        onSubmit: (values) => {
        }
    })

    const { setFieldValue, errors } = formik

    const categories: any = useSelector((state: RootState) => state.categorieSlice.categories);
    const sub_categories: any = categories.filter((cat: any) => cat?._id == '67807598690173953' ? true : false)[0]?.sub_categories


    const optionCategories = categories.map((item: any) => {
        return {
            label: item?.name,
            value: item?._id
        }
    })


    const optionSubCategories = sub_categories?.map((item: any) => {
        return {
            label: item?.name,
            value: item?._id?.toString()
        }
    })

    useEffect(() => {
        setCategory('67807598690173953')
    }, [])



    const setItem = async () => {
        const res: any = await dispatch(CreateItem({
            name: name,
            brand: brand,
            category: category,
            description: description,
            picture_url: '',
            photos: []
        } as ItemModel))
        if (res?.payload?._id) {
            dispatch(updateAccesoriesMandatory({ index: props.indexInput, data: res.payload?._id }))
            toast.success('Save item succesfull')
            dispatch(getAllItems())
           props.setView(false)
           props.setViewInfo(true)
    //   props.setModalSelect({ index: '', modal: false })

           const rates = {
                daily: 0 ,
                holiday_day: 0,
                holiday_hour : 0,
                holiday_overtime_hour :0,
                hour:0 ,
                overtime_hour :0,
                sale: 0,
                week: 0
            }

            ////Create Rates
            const AllDataRates = {
                data: rates,
                item: res.payload._id
            }
            const resRates = await dispatch(CreateRates(AllDataRates))



        } else {
            toast.error('error could not create the item')
        }

    }


    return (
        <Modal isOpen={props.view} toggle={() => { }} centered>
            <div className="container text-center">
                <div className="row p-4">
                    <div className="col-12">
                        <h2 className="font-weight-bold text-secondary typo-display">
                            {props.title}
                        </h2>
                    </div>
                    <div className="col-12 mt-3">
                        <InputFloatingLabel
                            value={name}
                            onChange={(value: any) => {
                                setName(value)
                                setFieldValue('name', value)
                            }}
                            placeHolder='Name'
                            errorMessage={errors.name}
                        />
                    </div>
                    <div className="col-12 mt-3">
                        <InputFloatingLabel
                            value={brand}
                            onChange={(value: any) => {
                                setBrand(value)
                                setFieldValue('brand', value)
                            }}
                            placeHolder='Brand'
                        />
                    </div>
                    <div className="col-12 mt-3" >
                        <SelectInput
                            placeholder={'Select Category'}
                            onChange={(data: any) => {
                                setCategory(data)
                            }}
                            value={'67807598690173953'}
                            options={optionCategories}
                            searchable={false}
                        />
                    </div>
                    <div className="col-12 mt-3" >

                        <SelectInput
                            placeholder={'Select sub category'}
                            onChange={(data: any) => {
                                setCategory(data)
                            }}
                            value={"151915432205354011"}
                            options={optionSubCategories}
                            searchable={false}
                        />
                    </div>

                    <div className="col-12 mt-3">
                        <TextArea
                            value={description}
                            onChange={(value: any) => {
                                setDescription(value)
                                setFieldValue('description', value)
                            }}
                            placeHolder='Description'
                        />
                    </div>

                    <div className="col-12 mt-3">
                        <div className="row justify-content-evenly">
                            <button
                                className="btn btn-danger text-white col-auto"
                                onClick={() => props.setView(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-success  text-white col-auto"
                                onClick={() => setItem()}
                                disabled={errors.name ? true : false}
                            >
                                Create
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default QuickAddItem;
