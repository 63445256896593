import { useDispatch } from "react-redux"
import { setShowAssetLis } from "../../store/stockpile/RecursosVisualeSlice"
import { useHistory } from "react-router-dom"
import { useState } from "react"
import { cleanAsset, setDataAsset } from "../../store/stockpile/selectAssetsSlice"
import Arrowback from "../../assets/icons/Arrowback.svg";
import AssetList from "../Stock/StockList/AssetList"

interface Props {
    setItemID?: any
}

const BackHeaderList: React.FC<Props> = (props) => {
    const { setItemID } = props



    const dispatch = useDispatch()
    const history = useHistory();


    const setAssetList = (val: boolean) => {
        dispatch(setShowAssetLis(val));
      };

    return (
            <div
                className="d-flex p-0 ms-3 mt-2 mb-0 ContainerBackHeader"
                onClick={() => {
                    setAssetList(false);
                    dispatch(cleanAsset());
                    dispatch(setDataAsset({}));
                    setItemID("");
                    history.push("/stockpile");
                }}
            >
                <div className="">
                    <img src={Arrowback} alt="" />
                </div>
                <div
                    className="ms-2 containerText"

                >
                    <p>SELECT THE ITEM YOU WANT TO REVIEW</p>
                </div>
            </div>
    )

}

export default BackHeaderList;

