import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/store";
import SelectFloatingLabel from "src/components/UI/forms/SelectFloatingLabel";
import { useEffect, useState } from "react";
import { GetAllContacts } from "src/store/blackbook/getContactSlice";
import { DropdownMenu } from "reactstrap";
import { blackbookFilters } from "src/store/blackbook/filtersSlice";

interface Props {
  setOrganization?: any
  setSearch?: any
  searchValue?: any
}

const AdvanceSearch: React.FC<Props> = ({ setOrganization, searchValue, setSearch }) => {
  const dispatch = useDispatch()
  const [organization, seOrganization] = useState([]);
  const [searchData, setsearchData] = useState('')

  const [idOR, setIdor] = useState('');

  const GetContact: any = useSelector((state: RootState) => state.getContactAll);

  const { getContact } = GetContact;

  const SelectOrganization = organization.map((e: any) => {
    let obj = { label: e.entity.name, value: e?.entity?._id };

    return obj;
  });


  const selectData = () => {
    if (getContact) {
      let type: any = [];
      let type1: any = [];
      let type2: any = [];

      getContact?.forEach((e: any) => {
        if (e.type === "FREELANCE") {
          type.push(e);
        } else if (e.type === "ORGANIZATION") {
          type1.push(e);
        } else if (e.type === "CLIENT") {
          type2.push(e);
        }
      });

      seOrganization(type1);
    }
  };

  const getData = () => {
    dispatch(GetAllContacts());
  };

  useEffect(() => {
    getData()
  }, [])
  

  useEffect(() => {
    selectData();
  }, [getContact]);

  const AplyFilters = async (idOrganization: any) => {

    setOrganization(idOrganization)

    dispatch(blackbookFilters.setSearch(searchData));
  }

  const ClearFilters = async () => {

    setOrganization('')
    seOrganization([])
    setsearchData('')
    dispatch(blackbookFilters.setSearch(''));

  }



  return (
    <DropdownMenu className={` p-2 `} style={{ width: 300, zIndex: 6, position: 'absolute' }} >
      <p className="text-primary typo-body">Advanced search</p>


      <select className="form-select text-primary typo-body-important  mt-2"
        onChange={(e: any) => setIdor(e.target.value)}
      >
        <option value="" selected className=" ">
          Organization name
        </option>
        {
          SelectOrganization?.map((org: any) => (
            <option value={org.value}>{org.label}</option>
          ))
        }
      </select>


      <select className="form-select text-primary typo-body-important  mt-2">
        <option value="" selected className=" ">
          Bookable skillsets
        </option>
        <option>Actor</option>
        <option>Hairdresser</option>
        <option>Makeup</option>
        <option>Videographer</option>
      </select>

      <input
        className="form-control  text-primary mt-2 typo-body-import"
        placeholder="Includes the words"
        onChange={(e) => setsearchData(e.target.value)}
        value={searchData}
      />

      <button onClick={() => { AplyFilters(idOR) }} className="btn btn-primary mt-3 text-white typo-body"
      >
        Search
      </button>
      <button onClick={() => { ClearFilters() }} className="btn btn-outline-primary mt-3 ms-1  typo-body"
      >
        Clear filters
      </button>
    </DropdownMenu>
  );
};

export default AdvanceSearch;
