import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/store/store';
import { ICategory } from 'src/models/Category';
import { useRequest } from 'src/hooks/useRequest';
import { blackbookFilters } from 'src/store/blackbook/filtersSlice';
import CategoryItem from 'src/components/DashboardLayout/LeftCard/CategoryItem';
import { useToast } from '../../../hooks/useToast'
import SimpleButton from '../../UI/buttons/SimpleButton'
import { setCategorieActive } from '../../../store/stockpile/categorieSlice';
import AddCategory from '../../Modals/Categories/AddCategory';
import { useState } from 'react';

const ContactCategories: React.FC = () => {
  const toast = useToast()
  const dispatch = useDispatch()
  const [modalCeate, setmodalCeate] = useState(false)

  const { data: categories, error } = useRequest<ICategory[]>({ url: 'categories/contacts/all' });
  const selectedCategory = useSelector((state: RootState) => state.blackbookFiltersSlice.selectedCategory);

  const handleSelect = (category: ICategory, index:any ) => {
    dispatch(blackbookFilters.selectCategory(category));
      dispatch(setCategorieActive(index)) 
  }

  const messageComming = (message: any) => {
    toast.info(message + ' is coming soon!')
  }

  return (
    <div className="d-flex flex-column h-100">
      <div className="custom-scrollbar overflow-x-none overflow-y">
        <ul className="list-unstyled pt-3 px-2">
          <CategoryItem category={{ _id: 'all', name: 'All contacts' } as ICategory} selected={!selectedCategory} onSelect={handleSelect} />
          
          {!categories && !error && (
            <div className="d-flex justify-content-center align-items-center h-100">
              <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block"></i>
            </div>
          )}
          
          {!categories && error && (
            <div className="d-flex flex-column justify-content-center align-items-center h-100 text-muted">
              <i className="fs-3 bi bi-exclamation-triangle d-block"></i>
              <p className="h6">Error while loading the blackbook categories.</p>
            </div>
          )}

          {categories && !error && (
            categories.map((category: ICategory,  index:any) => (
              <CategoryItem
                key={category._id}
                category={category}
                onSelect={handleSelect}
                selected={selectedCategory}
                index={index}
                count={category?.sub_categories.length > 0 ? category?.sub_categories.length : false}
              />
            ))
          )}

            <div style={{ opacity: 0.7 }}>
                <SimpleButton onClick={() => { setmodalCeate(true) }}>
                    ADD NEW CATEGORY
                </SimpleButton>
            </div>
        </ul>
      </div>
      <AddCategory 
        setView={setmodalCeate}
        view={modalCeate}
      />
    </div>
  );
};

export default ContactCategories;
