import React from "react";
import { useBarcode } from "react-barcodes";

interface Props {
    serial?: any
    valueCondition?: any
    changeCondition?: any
}

const BarcodePreview: React.FC<Props> = (props) => {

    const { inputRef } = useBarcode({
        value: props?.serial,
        options: {
            width: 0.5,
            height: 34,
            displayValue: false
        }
    });

    return (
        <div className='BarcodePreview row'>
            <div className="col-sm-12 col-md-6 ">
                <p className="Title"> Owner <br /> <span className="text">Direct</span> </p>
                <p className="Title" >
                    Condition <br />
                    <span className={`text ${!props.valueCondition && 'd-none'}`} >{props.valueCondition}
                        &nbsp;&nbsp; &nbsp;
                    </span>
                    <span className="badge badgeCard" onClick={props.changeCondition}>
                        CHANGE
                    </span>
                </p>
            </div>
            <div className="BarcodeContainer col-sm-12 col-md-6">
                <p className="Title">Asset Code</p>
                <div className="Title ">
                    <div className="Barcode">
                        <svg ref={inputRef} />
                    </div>
                </div>
            </div>
        </div>
    )

}

export default BarcodePreview