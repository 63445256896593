import axios from 'axios';
// import get from 'lodash.get';

import { R } from 'src/models';
// import { deepEntries } from 'src/util';
import { BCreateRateCard, IRateCard, BAddRateCardItem, BCreateRateCardVersion, RateCardItemModel } from 'src/models/RateModel';
// import { IContact } from 'src/models/ContactModel';
// import { decodeBase64 } from 'src/util/converToBase64';

const GetRateCards = async (): Promise<IRateCard> => {

    const { data } = await axios.get<R<IRateCard>>('rate-cards/all');

    return data.data;
}

const CreateRateCard = async (params: BCreateRateCard): Promise<IRateCard> => {

    const { data } = await axios.post<R<IRateCard>>('rate-cards/', params);

    return data.data;
}

const CreateRateCardVersion = async (id: string, params: BCreateRateCardVersion): Promise<IRateCard> => {

    const { data } = await axios.post<R<IRateCard>>(`rate-cards/${id}/rate-card-versions`, params);

    return data.data;
}


const DeleteRateCard = async (id: string): Promise<IRateCard> => {
    // const params = { permanently: permanentlyDelete };
    const { data } = await axios.delete<R<IRateCard>>(`rate-cards/${id}`);
  
    return data.data;
}

const GetRateCardItemsByVersion = async (id: string): Promise<RateCardItemModel[]> => {
    const { data } = await axios.get<R<RateCardItemModel[]>>(`rate-card-versions/${id}/rate-card-items`);
  
    return data.data;
}


export const RateCardService = {
    GetRateCards,
    CreateRateCard,
    DeleteRateCard,
    CreateRateCardVersion,
    GetRateCardItemsByVersion
}