import EmptyCircle from 'src/components/UI/EmptyCircle';
import { useRequest } from 'src/hooks/useRequest';
import { RateCardItemModel } from 'src/models/RateModel';
import AdvanceSearch from '../../Contacts/ContactList/AdvanceSearch';
import IconButton from '../../UI/buttons/IconButton';
import Checkbox from '../../UI/forms/Checkbox';
import InputFloatingLabel from '../../UI/forms/InputFloatingLabel';

interface Props {
  onGlobalChange: () => void;
  firstRateId: string,
  secRateId: string
}

const RateComparison: React.FC<Props> = (props) => {
  const {
    data: items,
    error: errorItems,
    mutate: mutateItems,
  } = useRequest<RateCardItemModel[]>(
      {
        url: `rate-card-versions/${props.secRateId}/rate-card-items`
      }
  );

  const {
    data: sItems,
    error: sErrorItems,
    mutate: smutateItems,
  } = useRequest<RateCardItemModel[]>(
      {
        url: `rate-card-versions/${props.firstRateId}/rate-card-items`
      }
  );

  return (
    <div className="container-fluid h-100">
      <div className="row" style={{ height: '98%' }}>
        <div className="col card h-100 custom-scrollbar overflow-y p-3">
          <div className="row justify-content-between">
            <div className="col-auto">
              <button
                className="btn btn-outline-black btn-icon-text p-0 shadow-none"
                onClick={props.onGlobalChange}
              >
                <i className="bi bi-chevron-left h6"></i>
                <p className="h6">System Rate Card B - Standard</p>
              </button>
            </div>

            <div className="col-3">
              <div className="input-icon mt-3">
                <i className="bi bi-search text-primary"></i>
                <input
                  className="form-control py-2 typo-body text-primary"
                  type="search"
                  id="example-search-input"
                  placeholder="Search"
                />
              </div>

              <div className="row justify-content-end mt-1 user-select-none">
                <div className="col-auto">
                  <div className="dropdown col-auto p-0 me-1">
                    <button
                      className="btn btn-link typo-body p-0"
                      data-bs-toggle="dropdown"
                      style={{ boxShadow: 'none' }}
                    >
                      ADVANCED SEARCH
                    </button>
                    <AdvanceSearch />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-100 custom-scrollbar overflow-x mt-3">
            <table className="table table-borderless">
              <thead>
                <tr className="text-primary typo-body">
                  <th scope="col">ITEM</th>
                  <th scope="col">COMPARISON</th>
                  <th scope="col">HOUR</th>
                  <th scope="col">DAY</th>
                  <th scope="col">WEEK</th>
                  <th scope="col">SALE</th>
                  <th scope="col">OVERTIME HOUR</th>
                  <th scope="col">HOLIDAY HOUR</th>
                  <th scope="col">HOLIDAY DAY</th>
                  <th scope="col">HOLIDAY OVERTIME HOUR</th>
                </tr>
              </thead>

              <tbody>
                {items?.map((item: any, index) => {
                  return (
                    <tr
                      className={`${(index / 2) % 1 > 0 ? "" : "table-primary"
                        } text-primary typo-body`}
                      key={index}
                    >
                      <td className="">
                        <div className='d-flex align-items-center '>
                          {
                            item?.item?.picture_url && <img loading="lazy" src={process.env.REACT_APP_IMAGES_AWS + item?.item?.picture_url} alt="" className="avatar2" />
                          }
                          {
                            !item?.item?.picture_url && <EmptyCircle title={item?.item?.name} small />
                          }

                          <p className="m-0 ms-2">
                            {item.item && item.item.name
                              ? `${item.item.name}`
                              : ""}
                            {item.item && item.item.brand
                              ? `- ${item.item.brand}`
                              : ""}
                          </p>
                        </div>
                        <p> </p>
                        <p> </p>
                        <p> </p>
                      </td>
                      <td className="align-middle">
                        {item.item ? item.item.category?.name : "-"}
                      </td>
                      <td className="align-middle">
                        <p className="m-0">System Rate Card B</p>
                        <p className="m-0">Standard</p>
                        <p className="m-0">Difference</p>
                      </td>
                      <td className="align-middle">
                          <p className="m-0">${item.rates.hour}</p>
                          <p className="m-0">${sItems?.[index].rates.hour}</p>
                          <p className="m-0">{(((Number(sItems?.[index].rates.hour) - item.rates.hour)/item.rates.hour)*100).toFixed(1)}%</p>
                      </td>
                      <td className="align-middle">
                          <p className="m-0">${item.rates.daily}</p>
                          <p className="m-0">${sItems?.[index].rates.daily}</p>
                          <p className="m-0">{(((Number(sItems?.[index].rates.daily) - item.rates.daily)/item.rates.daily)*100).toFixed(1)}%</p>
                      </td>
                      <td className="align-middle">
                          <p className="m-0">${item.rates.week}</p>
                          <p className="m-0">${sItems?.[index].rates.week}</p>
                          <p className="m-0">{(((Number(sItems?.[index].rates.week) - item.rates.week)/item.rates.week)*100).toFixed(1)}%</p>
                      </td>
                      <td className="align-middle">
                          <p className="m-0">${item.rates.sale}</p>
                          <p className="m-0">${sItems?.[index].rates.sale}</p>
                          <p className="m-0">{(((Number(sItems?.[index].rates.sale) - item.rates.sale)/item.rates.sale)*100).toFixed(1)}%</p>
                      </td>
                      <td className="align-middle">
                          <p className="m-0">${item.rates.overtime_hour}</p>
                          <p className="m-0">${sItems?.[index].rates.overtime_hour}</p>
                          <p className="m-0">{(((Number(sItems?.[index].rates.overtime_hour) - item.rates.overtime_hour)/item.rates.overtime_hour)*100).toFixed(1)}%</p>
                      </td>
                      <td className="align-middle">
                          <p className="m-0">${item.rates.holiday_hour}</p>
                          <p className="m-0">${sItems?.[index].rates.holiday_hour}</p>
                          <p className="m-0">{(((Number(sItems?.[index].rates.holiday_hour) - item.rates.holiday_hour)/item.rates.holiday_hour)*100).toFixed(1)}%</p>
                      </td>
                      <td className="align-middle">
                          <p className="m-0">${item.rates.holiday_day}</p>
                          <p className="m-0">${sItems?.[index].rates.holiday_day}</p>
                          <p className="m-0">{(((Number(sItems?.[index].rates.holiday_day) - item.rates.holiday_day)/item.rates.holiday_day)*100).toFixed(1)}%</p>
                      </td>
                      <td className="align-middle">
                          <p className="m-0">${item.rates.holiday_overtime_hour}</p>
                          <p className="m-0">${sItems?.[index].rates.holiday_overtime_hour}</p>
                          <p className="m-0">{(((Number(sItems?.[index].rates.holiday_overtime_hour) - item.rates.sale)/item.rates.holiday_overtime_hour)*100).toFixed(1)}%</p>
                      </td>
                      <td className="align-middle">
                        <IconButton
                          color="primary bg-white"
                          outline
                          icon="bi bi-pencil-fill"
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RateComparison;
