export const byCategoryForID = (item: any, category: any) => {
    if (category) {
        if (category == "any") {
            return true;
        } else {
            const result = item.category?._id == category ? true : false;

            return result;
        }
    } else return true;
};

export const bySubCategoryForID = (item: any, sub_category: any) => {
    if (sub_category) {
        if (sub_category == "any") {
            return true;
        } else {
            const result =
                item?.category?.sub_category?._id == sub_category ? true : false;

            return result;
        }
    } else return true;
};

export const byType = (item: any, type: any) => {
    if (type) {
        if (type == 0) {
            return item.internal_assets > 0;
        }
        if (type == 1) {
            return item.external_assets > 0;
        }
    } else return item;
};

export const byWordsName = (item: any, word: any) => {
    if (word) {
        return item.name.toLowerCase().includes(word.toLowerCase());
    } else return item;
};

export const byWordsDesc = (item: any, word: any) => {
    if (word) {
        return item.description.toLowerCase().includes(word?.toLowerCase());
    }
};

export const bySearch = (item: any, search: any) => {
    if (search) {
        return item.name?.toLowerCase().includes(search?.toLowerCase());
    } else return item;
};

export const FWords = (item: any, words: any) => {
    if (words != "") {
        return item?.name.toLowerCase().includes(words?.toLowerCase());
    } else return item;

    /* if (words.length > 0) {
             const mp = words?.reduce((acc: Array<object>, word: any) => {
                 return acc.concat(items
                     .filter((item: any) => byWordsName(item, word))
                     .filter((item: any) => byWordsDesc(item, word))
                 )
             }, [])
             return mp
         } else return items*/
};

export const filteredList = (
    items: any,
    search?: any,
    cod?: any,
    category?: any,
    subCategory?: any,
    AdvanceCategory?: any,
    type?: any,
    words?: any
) => {
    const t = items
        .filter((item: any) => byCategoryForID(item, category))
        .filter((asset: any) => bySearch(asset, cod))
        .filter((item: any) => bySearch(item, search))
        .filter((item: any) => byCategoryForID(item, AdvanceCategory))
        .filter((item: any) => byType(item, type))
        .filter((item: any) => bySubCategoryForID(item, subCategory))
        .filter((item: any) => FWords(item, words));

    return t;
};