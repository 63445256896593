import { useDispatch } from 'react-redux';
import InfoDisplay from '../../../UI/InfoDisplay';
import SimpleButton from '../../../UI/buttons/SimpleButton';
import { setActiveControlViewAll, setClient } from '../../../../store/factory/ViewResources';
import { useHistory, useParams } from 'react-router-dom';
import { useRequest } from 'src/hooks/useRequest';
import { useEffect } from 'react';

interface Props {
  contact: any
}

const Projects: React.FC<Props> = (props) => {

  const dispatch = useDispatch()
  const history = useHistory()

  const params = useParams<{ contactId: string; }>();

  const { data , error, mutate: mutateContact } = useRequest(params?.contactId ? { url: `factory/projects/all?client=${params.contactId}` } : null);


  useEffect(() => {
    
    console.log(data);
  
  }, [])
  

  return (
    <div className="container-fluid px-3">
      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <InfoDisplay
            title="primary account manager"
            texts={[
              {
                text: 'Amy Hail',
              },
            ]}
          />
        </div>
        <div className="col-6">
          <InfoDisplay
            title="secondary account manager"
            texts={[
              {
                text: 'James Girvan',
              },
            ]}
          />
        </div>
        <div className="col-6">
          <InfoDisplay
            title="current projects"
            texts={
             /* {
                text: 'Alps Electric Corporate Video 2021',
                isUnderline: true,
                fileHref: '/factory/295242003367592977',
                isFile: false
              },*/
              data ?
              data?.map((project:any) => ({
                text: project?.name,
                fileHref: '/factory/' + project?._id,
                isUnderline: true,
                isFile: false
              })) : []
            }
          />
        </div>
        <div className="col-6">
          <InfoDisplay
            title="current proposals"
            texts={[
              {
                text: 'Alps Electric Facebook & Instagram Ad',
                isUnderline: true,
              },
              {
                text: 'Alps Electric Corporate Video 2022',
                isUnderline: true,
              },
            ]}
          />
        </div>

      </div>
      <div className='mt-1'>
        <SimpleButton onClick={() => {

          let obj = {
            label: props.contact.entity.first_name + ' ' + props.contact.entity.last_name,
            value: props.contact._id,
          };

          dispatch(setClient(obj.value))
          history.push('/factory')
          dispatch(setActiveControlViewAll('project'))
        }} >
          CREATE NEW PROJECT
        </SimpleButton>
      </div>
    </div>
  );
};

export default Projects;
