import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import EmptyCircle from "../ProjectList/EmptyCircle";
import { setEquipmentItem } from "src/store/stockpile/RecursosVisualeSlice";
import { RootState } from "src/store/store";
import {
  cleanAsset,
  getCommentsForAsset,
  setIdItem,
} from "src/store/stockpile/selectAssetsSlice";
import moment from "moment";
import { setDetailView } from "../../../../../store/factory/factorySlice";

interface Props {
  index?: any;
  onSelect?: (id: string) => void;
  item: any;
  setID: any;
  showAssets: any;
}

const ContactItem: React.FC<Props> = (props) => {
  let count1 = 10;
  let count2 =
    props.item.category?._id == "67807598690173953" ||
      props.item.category?._id == "67807598690173950"
      ? true
      : false;

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ itemId: string }>();

  const ASSET_SELECTED = useSelector(
    (state: RootState) => state.selectAssetsSlice.data
  );

  const [click, setClick] = useState<number>(0);

  const handleAsset = () => {
    dispatch(cleanAsset());
    props.showAssets(true);
    dispatch(setEquipmentItem(true));
    history.push(`/stockpile/${props.item._id}`);
    dispatch(setIdItem(props.item._id));
  };

  const getTypeClass = (type: any) => {
    switch (type) {
      case 'Approved':
        return 'approved';

        break;

      case 'On Hold':
        return 'onHold'
        break;

      case 'Proposal':
        return 'proposal'
        break;

      case 'Complete':
        return 'complete'
        break;
      default:
        break;
    }
  }

  return (
    // <DoubleClick onDoubleClick={} >
    <div
      className={`border-top p-3 py-2 contact-item w-100  ${props.item._id == params.itemId && "contact-item-selected"
        }`}
    >
      <div className="row ps-3">
        
        <div className="align-item-center" style={{ padding: '0.5%', width: '10%' }}>
          <div className="px-1 pe-5">
            {props.item.picture_url && (
              <img
                loading="lazy"
                src={process.env.REACT_APP_IMAGES_AWS + props.item.picture_url}
                alt=""
                className="avatar2"
              />
            )}
            {!props.item.picture_url && (
              <EmptyCircle title={props.item.name} small />
            )}
          </div>

        </div>

        <div
          onClick={() => {
            if (props.item.category == "67807598690173953") {
              handleAsset();
            } else {
             // dispatch(setEquipmentItem(false));
             // dispatch(setIdItem(props.item._id));
             // dispatch(cleanAsset());
           //  dispatch(setDetailView())
             //history.push(`/factory/job/detail/${props.item._id}`);
            }
          }}
          className={`col d-flex p-0  mt-1 text-truncate  ${props.onSelect ? "ms-3" : "ms-2"
            }`}
        >

          <div className="ms-2">

            <div className="row">
              <p className="m-0 text-black typo-body contact-title ">
                {props.item.name}
              </p>
              <div className="row col-6 p-0 m-0">
                <div className="col-6">
                  <div className="col-12 my-0 py-0 ">
                    <p style={{ color: '#8898AA', fontSize: '13px' }}>JOB TYPE</p>
                  </div>
                  <div className="col-12 mt-n3">
                    <p style={{ color: '#8898AA', fontSize: '13px' }}>JOB START DATE</p>
                  </div>
                  <div className="col-12 mt-n3">
                    <p style={{ color: '#8898AA', fontSize: '13px' }}>JOB DUE DATE</p>
                  </div>
                  <div className="col-12 mt-n3">
                    <span className={getTypeClass(props.item.status) + ` baseBadge `}
                    style={{ color: '#8898AA', fontSize: '13px' }}
                    >
                      {props.item.status}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row col-6 ps-1 p-0 m-0 ">
                <div className="col-12">
                  <p className="items-Lists-subtitle-black">{props.item._id}</p>
                </div>
                <div className="col-12 mt-n4">
                  <p className="items-Lists-subtitle-black">{moment(props.item.start_date).format("MM/DD/YYYY")}</p>
                </div>
                <div className="col-12 mt-n4">
                  <p className="items-Lists-subtitle-black">{moment(props.item.end_date).format("MM/DD/YYYY")}</p>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
    //  </DoubleClick>
  );
};

export default ContactItem;
