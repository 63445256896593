import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFullWidth } from "../../../../../store/stockpile/RecursosVisualeSlice";
import EmptyCircle from "../../../../UI/EmptyCircle";
import TrashIcon from '../../../../../assets/icons/trash.svg'
import EditIcon from '../../../../../assets/icons/edit.svg'
import IconButton from "../../../../UI/buttons/IconButton";
import CopyIcon from "../../../../../assets/icons/copy.svg";
import ProjectDetail from "./tabs/ProjectDetail";
import Quotes from "./tabs/Quotes";
import { RootState } from "../../../../../store/store";
import { useToast } from "../../../../../hooks/useToast";
import { Link, useHistory, useParams } from "react-router-dom";
import FactoryModal from "../../../../Modals/FactoryModal";
import ModalFactoryPreviewQuote from "../../tabs/modalQuotePreview/ModalFactoryPreviewQuote ";
import { AxiosResponse } from "axios";
import { ApiError } from "../../../../../models";
import { UploadError } from "../../../../../util";
import { factoryService } from "../../../../../services/factory";
import ConfirmModal from "../../../../Modals/ConfirmModal";
import { getAllQuotes, getJobsId } from "../../../../../store/factory/factoryJobSlice";
import { getAllProject } from "../../../../../store/factory/factorySlice";


interface Props {
  setActiveControlView?: any;
  setActiveTask?: any;
  openModal?: boolean;
  setOpenModal?: any;
  setSelectJob?: any;
  selectJob?: any;
  activeTask?: any;
  activeControlView?: any;
  getDataQuotes?: any;
  selectProject?: any;
  idProject?: string;
  setSelectProject?: any;
  closeModalJob: boolean;
  setCloseModalJob: any;
  setAllscreen?:any;
  allscreen?:any
}
const ProjectView: React.FC<Props> = (props) => {

  const FullWidth = ''
  const dispatch = useDispatch()
  const toast = useToast()

  const projectDataId: any = useSelector((state: RootState) => state.factorySlice.projectDataId);
  const { jobsData, loading, jobsDataId, QuotesId, QuoteVersion } = useSelector(
    (state: RootState) => state.getJobsTypeSlice
  );

  const { QuoteData } = useSelector(
    (state: RootState) => state.getJobsTypeSlice
  );

  const { id: idParamsProject }: any = useParams();


  const [currentTab, setCurrentTab] = useState(0);
  const [view, setView] = useState(false);
  const [preview, setPreView] = useState<boolean>(false);
  const [deleteQuoteModal, setDeleteQuoteModal] = useState<boolean>(false);
  const [idQuoteDelete, setIdQuoteDelete] = useState<string>("");
  const [getQuoteById, setGetQuoteById] = useState<string>("");
  const [quoteAllState, setQuoteAllState] = useState(QuoteData.data);
  const [jodIdDelete, setjobIdDelete] = useState<string | null>(null);


  const { innerHeight } = window

  const [deleteProjectModal, setdeleteProjectModal] = useState<boolean>(false);
  const [deleteJobModal, setDeleteJobModal] = useState<boolean>(false);

  const messageComming = (name: string) => {
    toast.info(name + ' is comming soon!')
  }
  const tabs = [
    'Project Details',
    'Quotes',
    'Roles',
    'Financials',
    'Suppliers',
    'Scheduling',
    'Calendar',
    'Tasks',
    'Comms',
    'Documents',
    'Completion reports'
  ];

  const tabsDisbled = [
    "Roles",
    "Suppliers",
    'Financials',
    "Calendar",
    "Tasks",
    'Scheduling',
    "Comms",
    "Documents",
    "Completion reports",
  ];

  const tabsContent = [
    <ProjectDetail setjobIdDelete={setjobIdDelete} setDeleteJobModal={setDeleteJobModal} />,
    <Quotes
      QuoteData={QuoteData}
      view={view}
      setView={setView}
      preview={preview}
      setPreView={setPreView}
      deleteQuoteModal={deleteQuoteModal}
      setDeleteQuoteModal={setDeleteQuoteModal}
      setIdQuoteDelete={setIdQuoteDelete}
      getDataQuotes={props.getDataQuotes}
      setGetQuoteById={setGetQuoteById}
      jobsDataId={jobsDataId}
      loading={loading}
      quoteAllState={quoteAllState}
      setQuoteAllState={setQuoteAllState}
    />
  ];

  const [viewOpenModalFactory, setViewOpenModalFactory] = useState(false);
  const [newActiveControlView, setNewActiveControlView] = useState("");
  const [newActiveTask, setNewActiveTask] = useState("");
  const history = useHistory()
  const params = useParams<{ id: any; }>();

  const [viewOptions, setViewOptions] = useState(false);


  const newJob = () => {
    history.replace(`/factory/create/job/${params.id}`);

    props.setActiveControlView("job");
    props.setSelectJob(props.selectProject._id);
  };



  const handleConfirmDelete = () => {
    factoryService
      .DeleteProject(params.id)
      .then((res) => {
        toast.success(
          // res ||
          "The project has been successfully deleted."
        );
        setdeleteProjectModal(false);
        history.push("/factory");
        dispatch(getAllProject());
        // props.setActiveControlView(null);

        return;
      })
      .catch((err: AxiosResponse<ApiError<UploadError[]>>) => {
        if (err?.data?.message) {
          return toast.error(err?.data?.message);
        }
      });
  };
  const getJobsDataId = () => {
    dispatch(getJobsId(idParamsProject));
  };
  // DELETE JOB

  const handleJobConfirmDelete = () => {
    factoryService
      .DeleteJobs(jodIdDelete)
      .then((res) => {
        toast.success(
          // res ||
          "The job has been successfully deleted."
        );
        setDeleteJobModal(false);
        getJobsDataId();

        return;
      })
      .catch((err: AxiosResponse<ApiError<UploadError[]>>) => {
        if (err?.data?.message) {
          return toast.error(err?.data?.message);
        }
      });
  };

  const getQuotes = () => {
    dispatch(getAllQuotes());
    // dispatch(getQuotesId(idQuotes));
  };

  useEffect(() => {
    if (loading || params.id) {
      getQuotes();
    }
  }, []);

  // DELETE QUOTE

  const handleQuoteConfirmDelete = () => {
    factoryService
      .DeleteQuotes(idQuoteDelete)
      .then((res) => {
        toast.success(
          // res ||
          "The quote has been successfully deleted."
        );
        setDeleteQuoteModal(false);
        getQuotes();

        return;
      })
      .catch((err: AxiosResponse<ApiError<UploadError[]>>) => {
        if (err?.data?.message) {
          return toast.error(err?.data?.message);
        }
      });
  };

  const onCancelModalClose = (value?: boolean) => {
    if (deleteProjectModal) {
      setdeleteProjectModal(false);
    } else if (deleteJobModal) {
      setDeleteJobModal(false);
    } else if (deleteQuoteModal) {
      setDeleteQuoteModal(false);
    }
  };

  const activeModalJob = () => {
    // props.setSelectProject(props.selectProject);
    // history.replace(`/factory/create/job/${id}`);
    setViewOpenModalFactory(true);
  };

  const activeModaltask = () => {
    setViewOpenModalFactory(true);
    setNewActiveControlView("task");
    setNewActiveTask("task");
  };


  const openModalCreate = () => {
    props.setOpenModal(true);
    props.setCloseModalJob(true);
    setViewOptions(true);
  };

  return (
    <>
      <ModalFactoryPreviewQuote
        view={view}
        setView={setView}
        toggle={view}
        preview={preview}
        setPreView={setPreView}
        QuoteData={QuoteData}
        QuotesId={QuotesId}
        QuoteVersion={QuoteVersion}
      />

      <FactoryModal
        toggle={setViewOpenModalFactory}
        view={viewOpenModalFactory}
        setView={setViewOpenModalFactory}
        activeControlView={newActiveControlView}
        setActiveControlView={setNewActiveControlView}
        selectJob={props.selectJob}
        setSelectJob={props.setSelectJob}
        activeTask={newActiveTask}
        setActiveTask={setNewActiveTask}
        selectProject={projectDataId}
        setSelectProject={props.setSelectProject}
        closeModalJob={props.closeModalJob}
        setCloseModalJob={props.setCloseModalJob}
        setViewOptions={setViewOptions}
      />

      <header className="w-100 mt-3 d-flex justify-content-between align-items-center ps-1">
        <i
          onClick={() => props.allscreen ? props.setAllscreen(false): props.setAllscreen(true) }
          className={`${FullWidth
            ? 'bi bi-arrows-angle-contract'
            : 'bi bi-arrows-angle-expand'
            } col-auto  h5 m-0`} style={{ fontWeight: 'bold', color: '#344469' }}
        ></i>
        <div className='d-flex' style={{ flexDirection: 'row' }} >


          <div className='d-flex ' style={{ flexDirection: 'column' }}>

            <div className="d-flex align-items-center">
              <Link to={`/factory/edit/project/${idParamsProject}`}>

                <IconButton
                  color="primary"
                  custom={EditIcon}
                  customStyles={{ width: '12px', height: '12px' }}
                  onClick={() => { }}
                  outline
                />
              </Link>
              <IconButton
                color="primary"
                custom={CopyIcon}
                customStyles={{ width: '12px', height: '12px' }}
                // tooltip="Add"
                onClick={() => { }}
                outline
              />




              <IconButton
                color="danger"
                custom={TrashIcon}
                customStyles={{ width: '12px', height: '12px' }}
                onClick={() => setdeleteProjectModal(true)}
                outline
              />
            </div>


          </div>

        </div>

      </header>

      <div className="w-100 mt-3 d-flex justify-content-between ps-1 ms-2 mt-n1">
        <div className="d-flex align-items-center">
          {projectDataId?.file_cover && (
            <img
              loading="lazy"
              src={process.env.REACT_APP_IMAGES_AWS + projectDataId?.file_cover}
              alt=""
              className="avatar-profile"
            />
          )}
          {!projectDataId?.file_cover && (
          <EmptyCircle title={projectDataId?.name} />
          )}



          <div className="ms-3">
            <p className="m-0 text-black typo-body contact-title">
              {projectDataId?.name && projectDataId?.name}
            </p>
            <p className="m-0 text-black typo-body contact-subtitle">
              {
                //ITEM_DATA?.description
              }

            </p>
          </div>
        </div>


      </div>

      <div className="row ms-4">
        <div className="col offset-1 d-flex align-items-cente">
          <button className="btn btn-primary mx-1 typo-body "
            onClick={newJob}
          >
            Create new job
          </button>

          <button className="btn btn-primary mx-1 typo-body "
            onClick={activeModalJob}
          >
            Create job quote
          </button>

          <button className="btn btn-primary mx-1 typo-body " onClick={activeModaltask} >
            Create task
          </button>
        </div>
      </div>

      <ul className="nav nav-tabs mt-3 custom-scrollbar" style={{
        overflowX: 'scroll', maxWidth: '100%', flexDirection: 'column',
        maxHeight: '50px', overflowY: 'hidden'
      }} >
        {tabs.map((item, index) => {

          let check = tabsDisbled.find((tab) => {
            if (item == tab) {
              return true;
            }
            return false;
          });
          return (
            <li
              key={index}
              className="nav-item"
              onClick={
                check
                  ? () => messageComming(item)
                  : setCurrentTab.bind(null, index)
              }
              style={check ? { opacity: 0.5 } : {}}
            >
              <p
                className={`nav-link typo-body user-select-none ${currentTab === index ? 'active' : ''
                  }`}
                aria-current="page"
                style={{ fontSize: '10px' }}
              >
                {item}
              </p>
            </li>
          );
        })}
      </ul>

      {deleteProjectModal || deleteJobModal || deleteQuoteModal ? (
        <ConfirmModal
          title="Are you sure?"
          description={
            deleteProjectModal
              ? "This Project will be discarted"
              : deleteJobModal
                ? "This job will be discarted"
                : deleteQuoteModal
                  ? "This Quote will be discarted"
                  : ""
          }
          onClose={onCancelModalClose}
          buttons={
            <>
              <button
                className="btn btn-danger text-white col-auto"
                onClick={
                  deleteProjectModal
                    ? handleConfirmDelete
                    : deleteJobModal
                      ? handleJobConfirmDelete
                      : deleteQuoteModal
                        ? handleQuoteConfirmDelete
                        : undefined
                }
              >
                DELETE
              </button>

              <button
                className="btn btn-success text-white col-auto"
                onClick={() => {
                  onCancelModalClose();
                }}
              >
                CANCEL
              </button>
            </>
          }
        />
      ) : (
        []
      )}

      <div style={{ maxHeight: innerHeight - 320 + 'px' }}>
        {tabsContent[currentTab]}
      </div>
    </>
  )
}

export default ProjectView