
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    nameFileCSV: '',
    FileValueCSV: '',
    files: [],
    status: false
};

const CreateItemCsvForm = createSlice({
    name: 'CreateItemCsvForm',
    initialState,
    reducers: {
        setCSV: (state, action) => {
            state.FileValueCSV = action.payload
        },
        setNameFileCSV:(state, action) => {
            state.nameFileCSV = action.payload
        }
        ,
        setFiles:(state, action) => {
            state.files = action.payload
        },
        setStatus: (state,action) => {
            state.status = action.payload
        }
    }
});

export const { setNameFileCSV , setCSV , setFiles, setStatus } = CreateItemCsvForm.actions;
export default CreateItemCsvForm.reducer;
