import FlatList from 'flatlist-react';
import { Input, Modal } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { getSections } from '../../../util/ItemsFunctions';
import RateSection from '../../Rates/RateList/RateSection';
import RateListEmpty from '../../Rates/RateList/RateListEmpty';
import RateItem from '../../Rates/RateList/RateItem';
import { useToast } from "src/hooks/useToast";
import { useEffect, useState, Fragment, useMemo } from 'react';
import SimpleButton from "src/components/UI/buttons/SimpleButton";
import {
    GetAllRateForItem,
    getAllItems,
} from "../../../store/stockpile/stockpileSlice";
import ItemModel, { RatesModel } from '../../../models/ItemModel';
import { RootState } from '../../../store/store';
import AddItemsSection from '../../Factory/FactoryView/createQuoteForm/modal/AddItemsSection';
import React from 'react';
import SelectInput from '../../UI/forms/Select';
import InputSelect from 'src/components/UI/forms/InputSelect';
import InputDate from '../../UI/forms/InputDate';
import InputFloatingLabel from 'src/components/UI/forms/InputFloatingLabel';
import Scanner from "../../Scanner/scanner";
import Checkbox from '../../UI/forms/Checkbox';
import { useMarginCalculations, useQtyHandler, useQuoteBuilder, useSelectQuoteBuildr, useSupplierContacts } from './hooksDD';
import { calDays } from '../../../util/DateFunctions';
import useCustomState from './hooks/useCustomState'
import useValidateRates from './hooks/useValidateRates'
import useFilteredItems from './hooks/useFilteredItems'
import QuickAddExternalItem from '../QuickAddExternalItem';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onSelectItems: (items: ItemModel[]) => void;
    handleChange?: (item: ItemModel, checked: boolean) => void;
    selectedItemsGeneral?: any
}

const QuoteBuilder: React.FC<Props> = (props) => {
    const { selectedItemsGeneral, handleChange } = props
    const toast = useToast();
    const {LoadingItems } = useSelector((state: RootState) => state.stockpileSlice);
    const RatesItemSelected = useSelector((state: RootState) => state.stockpileSlice.RatesForItem);

    const States = useCustomState()

    useValidateRates(RatesItemSelected, { cargado: States.cargado, setCargado: States.setCargado })

    const { filteredItems, sections } = useFilteredItems({ search: States.search, supFilter: States.supFilter });

    const suppliersOptions = useSupplierContacts();

    const handleSelectQuoteBuildr = useSelectQuoteBuildr({
        exempt: States.exempt,
        discount: States.discount,
        itemSelected: States.itemSelected,
        setItemSelected: States.setItemSelected,
        dataSelected: States.dataSelected,
        setDataSelected: States.setDataSelected
    })

    useMarginCalculations({
        totalQuote: States.totalQuote, rangeClient: States.rangeClient,
        qty: States.qty, setCostPerUnit: States.setCostPerUnit,
        costPerUnit: States.costPerUnit, setDataSelected: States.setDataSelected,
        dataSelected: States.dataSelected, setTotalMargin: States.setTotalMargin,
        setMarginPerUnit: States.setMarginPerUnit, marginPerUnit: States.marginPerUnit,
        chargePerUnit: States.chargePerUnit
    })

    useQuoteBuilder(
        RatesItemSelected, States.rangeClient,
        States.rangeSupplier, States.setCostPerUnit,
        States.setTotalQuote
    )

    const qtyhandler = useQtyHandler({ selectedItemsGeneral: selectedItemsGeneral, handleChange: handleChange, dataSelected: States.dataSelected })


    return (
        <Modal isOpen={props.isOpen} toggle={props.onClose} style={{ maxWidth: '60vh' }} centered >
            <div className="container " style={{ height: '85vh' }}>
                <div className="cursor-pointer m-1" onClick={() => {
                    props.onClose()
                    States.setItemSelected('')
                }}>
                    <i className="bi bi-x-lg fs-5"></i>
                </div>
                <div className="row pt-5 pb-2">

                    <div className="col-12" style={{ marginTop: '-5%' }}>
                        <h2 style={{fontSize: '30px', fontWeight:'bold'}} className="text-secondary typo-display fw-bold text-center">
                            Quote builder external
                        </h2>
                    </div>

                </div>
                <SimpleButton
                    onClick={() => { States.setQuickAdd(!States.quickadd) }}
                >
                    <p className='p-0 m-0' style={{fontSize:'12px'}}>ADD QUICK EXTERNAL ITEM</p>
                    </SimpleButton>

                <div className="row position-relative mx-0">

                    <div className="col p-0 h-100 contact-list custom-scrollbar row " style={{ maxHeight: '75vh' }}>

                        <div className='col-12 mb-3'>
                            <SelectInput
                                value={States.supFilter}
                                onChange={(value: any) => States.setSupFilter(value)}
                                options={suppliersOptions}
                                placeholder={'Supplier'}
                            //  isDisabled={true}
                            />
                        </div>
                        <div style={States.itemSelected == '' ? { pointerEvents: 'none', opacity: 0.6 } : {}}>




                            <div className='col-12 mb-2 mt-1 ps-3 ' style={{ height: '20px' }}>
                                <p style={{fontSize: '17px', fontWeight: 'bold' }}>Days hired for supplier</p>
                            </div>
                            <div className='col-12'>
                                <InputDate
                                    select={"range"}
                                    touchUi={true}
                                    onChange={(e: any) => {
                                        States.setRangeSupplier(e.value)

                                    }}
                                    value={States.rangeSupplier}
                                    // error={ErrorDate}
                                    placeholder="Select days"
                                    id={'job-date'}
                                />
                            </div>

                            <div className='col-12 mb-2 mt-3 ps-3 ' style={{ height: '20px' }}>
                                <p style={{fontSize: '17px', fontWeight: 'bold'}}>Days billed to client</p>
                            </div>
                            <div className='col-12 '>
                                <InputDate
                                    select={"range"}
                                    touchUi={true}
                                    onChange={(e: any) => {
                                        States.setRangeClient(e.value)

                                    }}
                                    value={States.rangeClient}
                                    // error={ErrorDate}
                                    // error={ErrorDate}
                                    placeholder="Select days"
                                    id={'job-date'}
                                />
                            </div>



                            <div className='d-flex flex-row gx-5 my-3 ms-4 '>
                                <div className='col'>
                                    <Checkbox
                                        title="Non discountable"
                                        value={States.discount}
                                        onChange={(value) => { States.setDiscount(value) }}
                                    />
                                </div>

                                <div className='col'>
                                    <Checkbox
                                        title="TAX exempt"
                                        value={States.exempt}
                                        onChange={(value) => {
                                            States.setexempt(value)
                                            console.log(value);
                                        }}

                                    />
                                </div>
                                <div className='col'>
                                    <Checkbox
                                        title="Confimed"
                                        value={false}
                                        onChange={(value) => { }}

                                    />
                                </div>

                            </div>
                            <div className='row col-12 pt-1  m-0' style={{ minWidth: '48vh' }} >

                                <div className='col-4' style={{ pointerEvents: 'none', opacity: 0.5 }}>
                                    <InputFloatingLabel
                                        placeHolder="COST PER UNIT"
                                        value={States.costPerUnit || 0.00}
                                        errorMessage={false}
                                        onChange={(val) => { }}
                                    />
                                </div>

                                <div className='col-4'>
                                    <InputFloatingLabel
                                        placeHolder="CHARGE PER UNIT"
                                        value={States.chargePerUnit}
                                        errorMessage={false}
                                        onChange={(val) => { States.setChargePerUnit(val) }}
                                    />
                                </div>
                                <div className='col-4' style={{ pointerEvents: 'none', opacity: 0.5 }}>
                                    <InputFloatingLabel
                                        placeHolder="MARGIN PER UNIT"
                                        value={States.marginPerUnit || 0.00}
                                        errorMessage={false}
                                        onChange={(val) => { }}
                                    />
                                </div>
                                <div className='col-12'>
                                    <InputSelect
                                        select={{
                                            placeholder: 'type',
                                            value: {},
                                            onChange: (value: string) => {
                                            },
                                            options: [
                                                { value: 'mobile', label: 'kg' },
                                                { value: 'home', label: 'Home' },
                                                { value: 'office', label: 'Office' },
                                            ],
                                        }}
                                        input={{
                                            isRequired: false,
                                            placeHolder: 'QTY',

                                            value: States.qty,
                                            /* @ts-ignore */
                                            errorMessage: false,
                                            onChange: (val: any) => {
                                                States.setQty(val)
                                            },
                                            width: '100px'
                                        }}
                                    />
                                </div>
                                <table className='table ms-2' >
                                    <thead className="">
                                        <tr className="text-primary typo-body">
                                            <th scope="col"  >
                                                TOTAL COST
                                            </th>
                                            <th scope="col" >
                                                TOTAL CHARGE
                                            </th>
                                            <th scope="col" >
                                                TOTAL MARGIN
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p className='text-primary'>
                                                    ${(States.totalQuote * (calDays(States.rangeClient) + 1)) * States.qty || 0.00}
                                                </p>
                                            </td>
                                            <td>
                                                <p className='text-primary'>
                                                    ${(parseInt(States.chargePerUnit) * (calDays(States.rangeClient) + 1)) * States.qty || 0.00}
                                                </p>
                                            </td>
                                            <td>
                                                <p className='text-primary'>
                                                    ${(States.marginPerUnit * (calDays(States.rangeClient) + 1)) * States.qty || 0.00}
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>



                        <div className="col p-0 " style={{ maxHeight: '400px' }}>

                            {LoadingItems ?
                                (
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                    // style={{ width: "88vw", height: "40vh" }}
                                    >
                                        <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block" />
                                        <span className="text-black ms-3">Loading...</span>
                                    </div>
                                ) :
                                (
                                    <Fragment>
                                        {filteredItems.length > 0 ? (
                                            <FlatList
                                                list={Object.keys(sections)}
                                                renderItem={(item: string, index: number) => {

                                                    return (
                                                        <AddItemsSection
                                                            ref={sections[item].ref}
                                                            key={index}
                                                            letter={item}
                                                            items={sections[item].items}
                                                            handleSelect={handleSelectQuoteBuildr}
                                                            active={States.itemSelected}
                                                        // indexItem={index}
                                                        // indexItemSelected={indexItemSelected}
                                                        />
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <div className="d-flex flex-column justify-content-center align-items-center h-100 text-muted mx-5 text-center">
                                                <i className="fs-3 bi bi-back d-block"></i>
                                                <p className="h6">No items were found for the current filters.</p>
                                            </div>
                                        )}

                                    </Fragment>
                                )}
                        </div>


                    </div>


                </div>

            </div>
            <div className='row pt-5 mb-2'>
                <div className="col-12 justify-content-center ">
                    <button
                        className="btn btn-success text-white "
                        style={{
                            marginLeft: '14%'
                            , width: '70%'

                        }}
                        onClick={() => {
                            if (States.qty > 0) {


                                qtyhandler(States.qty)
                                //handleSelectItems()
                                // close modal
                                props.onClose()
                                States.setItemSelected('')
                                States.setRangeClient('')
                                States.setRangeSupplier('')
                            } else {
                                toast.error('should take qty more than 0')
                            }
                        }}
                    >
                        Add Items
                    </button>

                </div>
            </div>
            <Scanner
                isOpen={States.scanModal}
                setCode={States.setSearch}
                setStateModal={States.setScanModal}
            />
            <QuickAddExternalItem
                view={States.quickadd}
                setView={States.setQuickAdd}
                suppliers={suppliersOptions}
                selecterItem={handleSelectQuoteBuildr}
            />
        </Modal>
    );
};

export default QuoteBuilder;
