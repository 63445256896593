import React, { useEffect, useMemo, useState } from "react";
import ItemRow from "./Rows/ItemRow";
import MainRow from "./Rows/MainRow";

interface Props {
    elements: any[];
    isVisible: boolean;
    name: string;
    subTotal: number;
    onChange: (name, value) => void;
    onChangeItem: (index, name, value) => void;
    onDelete: () => void;
    onDeleteItem: (index) => void;
    onAddItems: () => void;
    onDragItem: (e, path: string) => void;
    onDropItem: (e, path: string) => void;
    onDrag: (e) => void;
    onDrop: (e) => void;
    onSelectAssetItem: (index, item) => void;
}

const SubHeaderQuote: React.FC<Props> = (props) => {
    const [isExpand, setIsExpand] = useState(true);
    const { elements, isVisible, name, subTotal, onChange, onChangeItem, onDelete, onDeleteItem, onAddItems, onDropItem, onDragItem, onDrop, onDrag, onSelectAssetItem} = props;
    const handleAddItems = () => {
        setIsExpand(true)
        onAddItems();
    }

    return (
        <>
            <MainRow name={name} subTotal={subTotal} isExpand={isExpand} isVisible={isVisible} isSubRow onExpand={() => setIsExpand((prev) => !prev)} onChange={onChange} onDelete={onDelete} onAddItems={handleAddItems} onDrop={onDrop} onDrag={onDrag}/>
            {elements?.map((e, index) =>
                <ItemRow
                    isVisible={isVisible && isExpand} 
                    isSubRow 
                    item={e} 
                    onChange={(name, value) => onChangeItem(index, name, value)} 
                    onDelete={() => onDeleteItem(index)} 
                    onDrag = {(e) => onDragItem(e,`elements.${index}`)}
                    onDrop = {(e) => onDropItem(e,`elements.${index}`)} 
                    onSelectAssets = {(item) => onSelectAssetItem(index, item)}
                    />
            )}
        </>
    );
};

export default SubHeaderQuote;