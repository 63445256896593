import { useDispatch, useSelector } from 'react-redux';
import { Input, Modal, ModalHeader } from 'reactstrap';
import { getSections } from '../../../util/ItemsFunctions';
import { RootState } from '../../../store/store'
import { useEffect, useState } from 'react';
import FlatList from 'flatlist-react';
import ContactSection from './ContactSection';
import AdvanceSearch from '../../Contacts/ContactList/AdvanceSearch';
import { setItemPackage, SelectItems, setNewInfoRates } from '../../../store/stockpile/packageItems';
import { useRequest } from 'src/hooks/useRequest';
import { GetAllRateForItem } from 'src/store/stockpile/stockpileSlice';
import InputSearch from 'src/components/UI/forms/InputSearch';


interface Props {
  setView: any,
  View: any,
  toggleAddingPackage: any
  itemsPreselect: any;
  setPreselectItems: any;
  Qtys: Array<any>;
  setMaxValue?:any
}

const PackageModal: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const ItemData = useSelector((state: RootState) => state.stockpileSlice.ItemData);
  const [shotby, setShortBy] = useState<string>('name')
  const Items = useSelector((state: RootState) => state.packageItems.Items);
  const RatesSingle = useSelector((state: RootState) => state.stockpileSlice.RatesForItem);

  const [assetList, setAssetList] = useState<boolean>(false)
  const [itemID, setItemID] = useState<string>('')
  const [itemSearch, setItemSearch] = useState<string>('')

  const itemsPreselect = props.itemsPreselect

  const setPreselectItems = props.setPreselectItems


  const getItemsRates = async (DateItems: Array<any>) => {
    const res: any = await dispatch(setItemPackage(itemsPreselect))

    if (res?.payload) {
        const rates = await Promise?.all(Items?.map(async (item: any, index: any) => {
          const res: any = await dispatch(GetAllRateForItem(item?._id))
          if (res?.payload?.data) {
            dispatch(setNewInfoRates({
              ...res?.payload?.data[0]?.rate_card_item?.rates,
              qty: props.Qtys[index],
              _id: res?.payload?.data[0]?.rate_card_item?._id
            }))
          }
          return res?.payload?.data && res?.payload?.data[0]
        }))
      
    }

  }

  useEffect(() => {
    if (Items.length > 0) {
      getItemsRates([])
    }
  }, [Items])


  const handleSearch = (e: any) => {
    setItemSearch(e.target.value)
  }

  const bySearch = (item: any, search: any) => {
    if (search) {
      return item.name.toLowerCase().includes(search.toLowerCase());
    } else return item;
  };

  const filteredList = (items: any, search: any) => {
    return items
      // .filter((asset: any) => bySearch(asset, cod))
      .filter((item: any) => bySearch(item, search));

    /**
     *     return items
    .filter((item: any) => bySearch(item, search))
    .filter((item: any) => item?.category?._id=='67807598690173953'?true:false );
     */
  }

  const sections = getSections(filteredList(ItemData, itemSearch), shotby)
  return (
    <Modal isOpen={props.View} toggle={() => props.setView(false)} centered
      style={{ width: '367px', height: '553px', overflowX: 'hidden' }}
    >
      <p className='poppinsText' style={{
        fontSize: '22px',
        position: 'absolute',
        marginBottom: '-25%',
        marginTop: '-2%',
        marginLeft: '2%',
        fontWeight: 'lighter',
        cursor: 'pointer',
        fontFamily: 'revert'
      }}
        onClick={() => props.setView(false)}
      >
        x
      </p>
      <div className="row container" style={{ padding: '.8em', marginTop: '-35%' }} >

        <div className="col-12" style={{
          display: 'flex', alignSelf: 'center',
          justifyContent: 'center',
          marginTop: '40%', marginBottom: '-14%'
        }}>
          <p className="ModalTitle"
            style={{
              fontSize: '24px', fontWeight: 'bold',
              justifyContent: 'center', alignSelf: 'center',
              display: 'flex', marginLeft: '4%', color: '#344469'
            }}
          >
            Select Items
          </p>
        </div>
        <div className="col-12 mt-3">
          <div className="input-icon mt-3" style={{
            margin: '.8em'
            , width: '108%', marginLeft: '-1%',
            marginTop: '1.5em'
          }} >
            <Input
            className="form-control py-2 typo-body text-primary"
            type="search"
            id="example-search-input"
            placeholder="Search"
            value={itemSearch}
            onChange={handleSearch}
            style={{ fontSize: "12px", height: "37px" }}
          />
          </div>
          <div className="col-12" style={{
            marginLeft: '74%',
            marginTop: '-5%',
            marginBottom: '-5px'
          }} >
            <button
              className="btn btn-link typo-body p-0"
              data-bs-toggle="dropdown"
              style={{ boxShadow: 'none', fontSize: '10px' }}
            >
              ADVANCED SEARCH
            </button>
            <AdvanceSearch />
          </div>
          <div className="row mt-2 contact-container" style={
            {
              paddingRight: '.8em',
              // paddingBottom: '.3em',
              width: '123%',
              marginLeft: '-6%',
              height: '400px',
              maxWidth: '400px'
            }
          }  >

            <div className="col-12 custom-scrollbar contact-list"
              style={{
                height: '500px', maxWidth: '600px', paddingLeft: '.1em'
              }}>

              <FlatList
                list={Object.keys(sections)}
                renderItem={(item: any, index: any) => {
                  return (
                    <ContactSection
                      ref={sections[item].ref}
                      key={index}
                      letter={item}
                      items={sections[item].items}
                      setID={setItemID}
                      showAssets={setAssetList}
                      itemsPre={itemsPreselect}
                      setPre={setPreselectItems}
                      setMaxValue={props.setMaxValue}
                      maxValue={props.Qtys}
                    />
                  );
                }}
                renderWhenEmpty={() => <p className="result-message">Without results</p>}
              />
            </div>
          </div>
        </div>

        <div className="col-12 " style={{ marginTop: '2%' }} >
          <div className="row justify-content-evenly">
            <button
              className="btn btn-success  text-white col-auto poppinsText"
              onClick={async () => {
                props.toggleAddingPackage()
                props.setView(false)
                getItemsRates(itemsPreselect)
                dispatch(SelectItems([]))
                setPreselectItems([])
              }}
              style={{
                width: '124px', height: '37px',
                fontSize: '12px', textTransform: 'uppercase'
              }}
            >
              Select Items
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PackageModal;
