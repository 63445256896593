import React, { useEffect, useState, Ref } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import {
  cleanAssetData,
} from "../../../store/stockpile/stockpileSlice";
import { getSections } from "../../../util/index";
import Scanner from "../../Scanner/scanner";
import ItemModel from "src/models/ItemModel";

import { useHistory } from "react-router-dom";
import {
  setDataAsset,
} from "src/store/stockpile/selectAssetsSlice";
import useQuery from "src/hooks/useQuery";
import { setShowAddUploadCSV } from "src/store/stockpile/RecursosVisualeSlice";
import { useToast } from "src/hooks/useToast";
import ItemListComponent from "src/components/Molecules/ItemListComponent";
import HeaderButtons from "src/components/Molecules/StockpileItemList/HeaderButtons"
import SearchList from "src/components/Molecules/StockpileItemList/SearchList"
import AdvanceSearchList from "src/components/Molecules/StockpileItemList/AdvanceSearchList"
import AlphabeticBar from "src/components/Molecules/StockpileItemList/AlphabeticBar"
import { filteredList } from "src/components/Molecules/StockpileItemList/hooks/Filters";

interface props {
  data: string;
  onAddContact: any;
  onAddService: any;
  toggleAddingkit: any;
}

const ItemList: React.FC<any> = (props) => {
  const history = useHistory();
  const [itemSearch, setItemSearch] = useState<string>("");
  const [shotby, setShortBy] = useState<string>("name");
  const [code, setCoder] = useState<any>("");
  const [scanModal, setScanModal] = useState<boolean>(false);
  const query = useQuery();
  const [advcCategory, setAdvanceCategory] = useState<any>(false);
  const [words, setWords] = useState<any>("");
  const [type, setType] = useState<any>("");

  const ItemData = useSelector( (state: RootState) => state.stockpileSlice.ItemData);
  const LoadingItems = useSelector((state: RootState) => state.stockpileSlice.LoadingItems);
  const categorySelected: any = useSelector((state: RootState) => state.categorieSlice.categorySelectedFilter);
  const SubCategory: any = useSelector((state: RootState) => state.categorieSlice.sub_categorySelectedFilter);
  const ShowAddUploadCSV = useSelector( (state: RootState) => state.RecursosVisualeSlice.ShowAddUploadCSV);


  const dispatch = useDispatch();


  useEffect(() => {
    cleanAssetData();
  }, []);


  useEffect(() => {
    dispatch(setDataAsset({}));
  }, []);


  //  const sections = getSections(FWords(filteredList(ItemData, itemSearch, code, categorySelected, advcCategory, type, words), words), 'name')
  const sections = getSections<ItemModel, HTMLDivElement>(
    [
      ...filteredList(
        ItemData,
        itemSearch,
        code,
        categorySelected,
        SubCategory,
        advcCategory,
        type,
        words
      ),
    ] || [],
    (item) => shotby
  );

  const results = filteredList(
    ItemData,
    itemSearch,
    code,
    categorySelected,
    SubCategory,
    advcCategory,
    type,
    words
  );

  const SelectCategory = (category: any) => {
    props.setTypeForm(category);
    props.onAddContact(category);
  };

  const toggleAddingCSV: any = (category: any) => {
    if (ShowAddUploadCSV) {
      query.delete("UploadCSV");
      history.replace({ search: query.toString() });
    } else {
      query.set("UploadCSV", category || "");
      history.push({ search: query.toString() });
    }
    dispatch(setShowAddUploadCSV((prev: any) => !prev));
  };


  const Equipment = categorySelected == "67807598690173953";


  return (
    <div className="d-flex flex-column mw-100 justify-content-center ps-1 my-sm-5 my-lg-0 h-100">
      <div className="d-flex flex-column ">

        <HeaderButtons
          setScanModal={setScanModal}
          setShortBy={setShortBy}
          toggleAddingCSV={toggleAddingCSV}
          toggleAddingkit={props.toggleAddingkit}
          onAddService={props.onAddService}
          SelectCategory={SelectCategory}
        />

        <SearchList setItemSearch={setItemSearch} itemSearch={itemSearch} />

        <Scanner
          isOpen={scanModal}
          setCode={setCoder}
          setStateModal={setScanModal}
        />

        <AdvanceSearchList
          setType={setType}
          setAdvanceCategory={setAdvanceCategory}
          setWords={setWords}
        />

      </div>

      <div className={`row p-0 contact-container w-100 ${Equipment && "mt-0"}`}>
       
          <ItemListComponent
            LoadingItems={LoadingItems}
            ItemData={ItemData}
            sections={sections}
            results={results}
            setID={props.setID}
            showAssets={props.showAssets}
          />

        <AlphabeticBar sections={sections} />

      </div>
    </div>
  );
};

export default ItemList;
