import { useEffect, useState } from "react";
import ButtonIconText from "../buttons/ButtonIconText";
import IconButton from "../buttons/IconButton";


interface Props {
    name: string
    options: any,
    type: any,
    disabled?: boolean
}

const DropdownCustom: React.FC<Props> = (props) => {


    return (
        <div className="dropdown">
            {
                props.type?.type === "Icon" || props.type?.type === "IconCustom" ?
                    <IconButton
                        color="primary"
                        icon={props.type?.type === 'Icon' ? props.type?.icon : false}
                        //  tooltip="Add new contact"
                        buttonProps={{
                            'data-bs-toggle': 'dropdown',
                            'data-toggle': 'tooltip',
                        }}
                        custom={props.type?.type === "IconCustom" ? props.type?.icon : false}
                        customStyles={{ width: '9.5px', height: '9.5px' }}
                    />
                    :
                    <ButtonIconText
                        title={props.name}
                        iconright={'bi bi-caret-down-fill'}
                        data-bs-toggle="dropdown"
                        onClick={() => { }}
                    />
            }

            <ul className="dropdown-menu">
                {
                    props.options.map((op: any, index: any) => {
                        return (
                            <li key={index} onClick={op.action} className={`dropdown-item typo-body user-select-none`}
                                style={props.disabled ? { opacity: 0, color: 'green' } : {}}>
                                {op.name}
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default DropdownCustom