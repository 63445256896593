import React from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { forwardRef, useEffect, useImperativeHandle } from "react";

import { RootState } from "src/store/store";
import { useToast } from "src/hooks/useToast";
import { TabsContent } from "../../../AddContact";
import { useFieldArray } from "src/hooks/useFieldArray";
import Checkbox from "src/components/UI/forms/Checkbox";
import InputSelect from "src/components/UI/forms/InputSelect";
import SimpleButton from "src/components/UI/buttons/SimpleButton";
import { createContactForm } from "src/store/blackbook/createContactSlice";
import InputFloatingLabel from "src/components/UI/forms/InputFloatingLabel";
import FreelancePersonalValidation, {
  FreelanceData,
} from "../PersonalInformation/validation";
import IconButton from "src/components/UI/buttons/IconButton";

type PartiallyRequired<T, K extends keyof T> = Omit<T, K> &
  Required<Pick<T, K>>;
export type FormData = PartiallyRequired<
  Partial<FreelanceData>,
  "emergency_contacts"
>;

const defaultContact: FormData["emergency_contacts"][number] = {
  name: "",
  relationship: "",
  emails: [{ address: "", type: "work" }],
  phone_numbers: [{ number: "", type: "mobile", primary: true }],
};

const EmergencyContacts = forwardRef((_props, ref: TabsContent["ref"]) => {
  const dispatch = useDispatch();
  const data = useSelector(
    (state: RootState) => state.createContactFormSlice.data
  );

  const toast = useToast();

  const formik = useFormik<FormData>({
    initialValues: {
      emergency_contacts: [],
      ...(data.data || {}),
    },
    validationSchema: FreelancePersonalValidation.pick(["emergency_contacts"]),
    onSubmit: () => {},
  });

  useImperativeHandle(ref, () => ({
    async handleValidation() {
      try {
        const errors = await formik.validateForm();
        if (errors && Object.keys(errors).length > 0) {
          return false;
        }

        return true;
      } catch {
        return false;
      }
    },
  }));

  useEffect(() => {
    if (
      data.initialValues !== undefined &&
      data.appliedInitialValues === false
    ) {
      formik.setValues(data.initialValues || {});
      /* @ts-ignore */
      dispatch(createContactForm.setField("appliedInitialValues", true, ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChange = (fieldName: string, value: any, path = "data") => {
    formik.setFieldValue(fieldName, value);

    /* @ts-ignore */
    dispatch(createContactForm.setField(fieldName, value, path));
  };

  const emergencyContacts = useFieldArray<
    FormData,
    FormData["emergency_contacts"][number]
  >(formik, "emergency_contacts", handleChange);

  return (
    <div className="container-fluid px-3 mt-3">
      <div className="row">
        <div className="col-12">
          {formik.values.emergency_contacts?.map((contact, contact_index) => {
            const copy = JSON.parse(JSON.stringify(contact));

            const onDelete = () => {
              emergencyContacts.remove(contact_index);
              return toast.info({
                content: (
                  <>
                    Emergency contact removed successfully.
                    <button
                      title="Undo"
                      type="button"
                      className="btn btn-outline-primary mt-1"
                      onClick={() => {
                        emergencyContacts.set(contact_index, copy);
                      }}
                    >
                      Undo
                    </button>
                  </>
                ),
                timeout: 8000,
              });
            };

            return (
              <div className="p-0 py-3" key={contact_index}>
                <div className="d-flex gap-3 text-primary align-items-center mb-2">
                  <p className="text-20-percent mb-0">
                    Emergency Contact #{contact_index + 1}
                  </p>
                  <hr className="w-100" />
                  <IconButton
                    outline
                    color="danger"
                    icon="bi bi-x"
                    tooltip="Remove Emergency Contact"
                    onClick={onDelete}
                  />
                </div>

                <div className="row">
                  <div className="col-6">
                    <InputFloatingLabel
                      placeHolder="Name"
                      value={contact.name}
                      onChange={(val) =>
                        handleChange(
                          `emergency_contacts.${contact_index}.name`,
                          val
                        )
                      }
                    />
                  </div>

                  <div className="col-6">
                    <InputFloatingLabel
                      placeHolder="Relationship to employee"
                      value={contact.relationship}
                      onChange={(val) =>
                        handleChange(
                          `emergency_contacts.${contact_index}.relationship`,
                          val
                        )
                      }
                    />
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-6">
                    {(contact.emails || []).map((_, index) => (
                      <div className="mb-2">
                        <InputSelect
                          select={{
                            value:
                              formik.values.emergency_contacts?.[contact_index]
                                ?.emails?.[index].type || "",
                            onChange: (value: string) => {
                              const copyContact = JSON.parse(
                                JSON.stringify(contact)
                              );
                              const email = copyContact.emails?.[index];
                              email!.type = value;
                              if (copyContact.emails) {
                                copyContact.emails[index] = email!;
                              }

                              emergencyContacts.set(contact_index, copyContact);
                            },
                            options: [
                              { value: "work", label: "Work" },
                              { value: "personal", label: "Personal" },
                              { value: "other", label: "Other" },
                            ],
                          }}
                          input={{
                            isRequired: true,
                            placeHolder: "Email",
                            value:
                              formik.values.emergency_contacts?.[contact_index]
                                ?.emails?.[index].address || "",
                            /* @ts-ignore */
                            errorMessage:
                              formik.errors.emergency_contacts?.[contact_index]
                                ?.emails?.[index]?.address,
                            onChange: (val) => {
                              /* "contact" will be a readonly object so we need to copy it and then rewrite properties. */
                              const copyContact = JSON.parse(
                                JSON.stringify(contact)
                              );
                              const email = copyContact.emails?.[index];
                              email!.address = val;
                              if (copyContact.emails) {
                                copyContact.emails[index] = email!;
                              }

                              emergencyContacts.set(contact_index, copyContact);
                            },
                          }}
                        />

                        <div className="row "  >
                          <div className="col-3">
                            <SimpleButton
                              type={
                                (contact.emails?.length || 0) > index + 1
                                  ? "danger"
                                  : "default"
                              }
                              onClick={() => {
                                const copyContact = JSON.parse(
                                  JSON.stringify(contact)
                                );

                                if (
                                  (copyContact.emails?.length || 0) >
                                  index + 1
                                ) {
                                  copyContact.emails!.splice(index, 1);
                                  emergencyContacts.set(
                                    contact_index,
                                    copyContact
                                  );
                                } else {
                                  copyContact.emails!.push({
                                    address: "",
                                    type: "work",
                                  });
                                  emergencyContacts.set(
                                    contact_index,
                                    copyContact
                                  );
                                }
                              }}
                              icon={
                                (contact.emails?.length || 0) > index + 1
                                  ? "bi-dash-circle-fill"
                                  : "bi-plus-circle-fill"
                              }
                            >
                              {(contact.emails?.length || 0) > index + 1
                                ? "REMOVE"
                                : "ADD EMAIL"}
                            </SimpleButton>
                          </div>
                          <div className="col ps-0 pe-3"></div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="col-6">
                    {(contact.phone_numbers || []).map((phone, index) => (
                      <div className="mb-2">
                        <InputSelect
                          select={{
                            value:
                              formik.values.emergency_contacts?.[contact_index]
                                ?.phone_numbers?.[index].type || "",
                            onChange: (value: string) => {
                              const copyContact = JSON.parse(
                                JSON.stringify(contact)
                              );
                              const phone_number =
                                copyContact.phone_numbers?.[index];
                              phone_number!.type = value;
                              if (copyContact.phone_numbers) {
                                copyContact.phone_numbers[index] =
                                  phone_number!;
                              }

                              emergencyContacts.set(contact_index, copyContact);
                            },
                            options: [
                              { value: "mobile", label: "Mobile" },
                              { value: "home", label: "Home" },
                              { value: "office", label: "Office" },
                            ],
                          }}
                          input={{
                            isRequired: true,
                            placeHolder: "Phone",
                            value:
                              formik.values.emergency_contacts?.[contact_index]
                                ?.phone_numbers?.[index].number || "",
                            /* @ts-ignore */
                            errorMessage:
                              formik.errors.emergency_contacts?.[contact_index]
                                ?.phone_numbers?.[index]?.number,
                            onChange: (val) => {
                              const copyContact = JSON.parse(
                                JSON.stringify(contact)
                              );
                              const phone_number =
                                copyContact.phone_numbers?.[index];
                              phone_number!.number = val;
                              if (copyContact.phone_numbers) {
                                copyContact.phone_numbers[index] =
                                  phone_number!;
                              }

                              emergencyContacts.set(contact_index, copyContact);
                            },
                          }}
                        />

                        <div className="row " >
                          <div className="col-3">
                            <SimpleButton
                              type={
                                (contact.phone_numbers?.length || 0) > index + 1
                                  ? "danger"
                                  : "default"
                              }
                              onClick={() => {
                                const copyContact = JSON.parse(
                                  JSON.stringify(contact)
                                );

                                if (
                                  (copyContact.phone_numbers?.length || 0) >
                                  index + 1
                                ) {
                                  copyContact.phone_numbers!.splice(index, 1);
                                  emergencyContacts.set(
                                    contact_index,
                                    copyContact
                                  );
                                } else {
                                  copyContact.phone_numbers!.push({
                                    number: "",
                                    type: "mobile",
                                    primary: false,
                                  });
                                  emergencyContacts.set(
                                    contact_index,
                                    copyContact
                                  );
                                }
                              }}
                              icon={
                                (contact.phone_numbers?.length || 0) > index + 1
                                  ? "bi-dash-circle-fill"
                                  : "bi-plus-circle-fill"
                              }
                            >
                              {(contact.phone_numbers?.length || 0) > index + 1
                                ? "REMOVE"
                                : "ADD PHONE"}
                            </SimpleButton>
                          </div>
                          <div className="col ps-0 pe-3">
                            <Checkbox
                              title="Primary phone"
                              value={phone.primary || false}
                              onChange={(value) => {
                                const phone_number =
                                  contact.phone_numbers?.[index];
                                phone_number!.primary = value;
                                if (contact.phone_numbers) {
                                  contact.phone_numbers[index] = phone_number!;
                                }

                                emergencyContacts.set(contact_index, contact);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-4">
          <SimpleButton onClick={() => emergencyContacts.add(defaultContact)}>
            ADD EMERGENCY CONTACT
          </SimpleButton>
        </div>
      </div>
    </div>
  );
});

export default EmergencyContacts;
