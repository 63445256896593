import { useState } from "react";
import Select, {
  components,
  GroupBase,
  PlaceholderProps,
  StylesConfig,
} from "react-select";

interface Props {
  onChange: any;
  value: any;
  options?: Array<any>;
  styles?: any;
  style?: any;
  placeholder?: any;
  searchable?: boolean;
  className?: any;
  height?: any;
  isMulti?: any;
  isClearable?: any;
  isOpen?: any;
  setIsOpen?:any;
  id?:any;
  isDisabled?:any
}

const CaretDownIcon = () => {
  return (
    <>
      <svg
        enable-background="new 0 0 29 14"
        height="10px"
        id="Layer_1"
        version="1.1"
        viewBox="0 0 29 14"
        width="7px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon fill="#8898AA" points="0.15,0 14.5,14.35 28.85,0 " />
      </svg>
    </>
  );
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};

const IndicatorSeparator: any = (props: any) => {
  return "";
};

const SelectInput: React.FC<Props> = (props) => {
  const [first, setfirst] = useState(false)
  const colourStyles: StylesConfig<any, false, GroupBase<any>> = {
    option: (styles: any, state) => {
      const { isFocused } = state;

      return {
        ...styles,
        background: "white",
        display: "flex",
        alignItems: "center",
        color: "#6B8AB8",
        ...props?.style,
        "&:hover": {
          color: isFocused ? "white" : "#6B8AB8",
          backgroundColor: isFocused ? "#6B8AB8" : null,
        },
        ...(props?.styles?.option && props?.styles?.option(state?.data)),
      };
    },
    placeholder: (base) => ({
      ...base,
      fontSize: "13px",
      color: "#6B8AB8",
      fontWeight: 400,
      display: "flex",
      alignItems: "center",
      ...props?.styles?.placeholder,
    }),
    menu: (provided) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: "#6B8AB8",
    }),
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #6B8AB8" : "1px solid #DEE2E6",
      boxShadow: state.isFocused ? "0px 0px 6px #6B8AB8" : "none",
      color: "white",
      height: props.height ?? 42,
      minHeight: props.height ?? 42,
      whiteSpace: "nowrap"
    }),
    valueContainer: (base) => ({
      ...base,
      flexWrap: "nowrap",
      ...props?.styles?.valueContainer,
    }),
    multiValue: (base) => ({
      ...base,
      fontSize: "13px",
      backgroundColor: "#BCCADF",
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: "#53607F",
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: "#53607F",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "13px",
      color: "#6B8AB8",
      fontWeight: 400,
      display: "flex",
      alignItems: "center",
      ...(state?.data && props?.styles?.singleOption && props?.styles?.singleOption(state?.data)),
    }),
  };

  return (
    <>
      <Select
        components={{ DropdownIndicator, IndicatorSeparator }}
        closeMenuOnSelect={true}
        placeholder={props.placeholder}
        onChange={(newObject) => {
          props.onChange(props.isMulti ? newObject.map((x: any) => x.value) : newObject.value)
         props.setIsOpen && props.setIsOpen(!props.isOpen)
        }}
        value={props.isMulti ? props.options?.filter((x: any) => props.value.includes(x.value)) : props.options?.find((x: any) => x.value === props.value)}
        styles={colourStyles}
        options={props.options}
        isSearchable={props.searchable}
        className={props.className}
        isMulti={props.isMulti}
        isClearable={props.isClearable}
        menuIsOpen={props.isOpen}
        id={props.id}
        isDisabled={props.isDisabled}
      />
    </>
  );
};

export default SelectInput;
