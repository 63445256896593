import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import ItemList from "./ItemList";
import AssetList from "./AssetList";
import { useParams } from "react-router-dom";
import { setShowAssetLis } from "src/store/stockpile/RecursosVisualeSlice";
import { useRequest } from "src/hooks/useRequest";
import BackHeaderList from "../../Molecules/BackHeaderList";
import { setIDroute } from "../../../store/factory/ViewResources";

interface Props {
  onAddContact: () => void;
  onAddService: () => void;
  setTypeForm: any;
  toggleAddingkit: any;
}

const StockList: React.FC<Props> = (props) => {
  const assetList = useSelector(
    (state: RootState) => state.RecursosVisualeSlice.ShowAssetList
  );

  const itemID = useSelector(
    (state: RootState) => state.ViewResources.IDroute
  );

  const setItemID = (idData:any) => {
    dispatch(setIDroute(idData))
  }

  const [cat, setCat] = useState<string>("");

  const dispatch = useDispatch();
  const params = useParams<{ itemId: string }>();

  const { data: ITEM_DATA } = useRequest<any>({
    url: "stockpile/items/" + params.itemId,
  });

  const setAssetList = (val: boolean) => {
    dispatch(setShowAssetLis(val));
  };


  useEffect(() => {
    setCat(ITEM_DATA?.category?._id);
  }, [ITEM_DATA?.category?._id, assetList]);


  return (
    <>
      {cat == "67807598690173953" ||
        (cat != "67807598690173956" &&
          cat != "67807598690173950" &&
          params.itemId) ? (
        <div className="p-0 m-0 pb-0 ">
          <BackHeaderList setItemID={setItemID} />
          <AssetList itemID={itemID} />
        </div>
      ) : (
        <ItemList
          showAssets={setAssetList}
          setID={setItemID}
          onAddContact={props.onAddContact}
          onAddService={props.onAddService}
          setTypeForm={props.setTypeForm}
          toggleAddingkit={props.toggleAddingkit}
        />
      )}
    </>
  );
};

export default StockList;
