import { useState, useRef, useEffect } from 'react';
import ConfirmModal from '../../Modals/ConfirmModal';
import Checkbox from '../../UI/forms/Checkbox';
import ProgressbarTitle from '../../UI/ProgressBarForm';
import AssetData from './tabs/AssetData/AssetData'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store'
import checkFields from '../../../util/CheckFields'
import { CreateItem, CreateAsset, CreateAccesories, CreateRates, RefreshForNewItem, UpdateAssetForId, getAssetForItem } from '../../../store/stockpile/stockpileSlice';
import { AccesoriesModel, AssetModel, PickupInfo, RatesModel } from '../../../models/ItemModel';
import StepButton from 'src/components/UI/buttons/stepButton';
import { setShowAddItem, setCheckItemFormAlert } from 'src/store/stockpile/RecursosVisualeSlice';
import { EmptyForm, updateValuesAssets } from 'src/store/stockpile/EditFormSlice';
import { useToast } from 'src/hooks/useToast';
import { useRequest } from 'src/hooks/useRequest';
import { useHistory, useParams } from 'react-router-dom';
import moment, { updateLocale } from 'moment';
import { setAllassets } from 'src/store/stockpile/EditFormSlice';
import { getAssetForID, setDataAsset } from 'src/store/stockpile/selectAssetsSlice';


interface Props {
  onFinish: () => void;
  TypeForm?: any;
}

const tabs: string[] = [
  'Asset data'
];


const EditAsset: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const toast = useToast()
  const params = useParams<{ itemId: string; assetId: string }>();

  const history = useHistory()


  const AssetRef: any = useRef()


  const [currentTab, setCurrentTab] = useState<number>(0);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [images, setImagesForm] = useState<Array<any>>([]);
  const [resetAllInputs, setResetAllInputs] = useState<any>(false);
  const [checkItemForm, setCheckItemForm] = useState<any>(false);
  const { data: ITEM_DATA } = useRequest<any>({ url: 'stockpile/items/' + params.itemId }) || [];
  const { data: ITEM_ASSETS } = useRequest<any>({ url: 'stockpile/items/' + params.itemId + '/assets' }) || [];


  const [dateCount, setDateCount] = useState<
    { type: string; date: string }[]
  >([{ type: 'date of purchase', date: '' }]);

  const [objectPick, setObjPick] = useState<PickupInfo>({
    country: '',
    state: '',
    city: '',
    zip: '',
    adress: ''
  })

  ///Loading
  const Loading = useSelector((state: RootState) => state.stockpileSlice.loading);


  /////CREATE ITEM CHECK
  const ItemID = useSelector((state: RootState) => state.stockpileSlice.ItemResponse);

  /////Errors 
  const Gdta = useSelector((state: RootState) => state.createFormSlice.GeneralErrors);
  const Asst = useSelector((state: RootState) => state.createFormSlice.AssetsErrors);
  const Rtes = useSelector((state: RootState) => state.createFormSlice.RatesErrors);

  /////FORMS
  const data = useSelector((state: RootState) => state.createFormSlice.data);
  const sizesG = useSelector((state: RootState) => state.createFormSlice.sizesG);
  const rates: any = useSelector((state: RootState) => state.createFormSlice.RatesData);
  const mandatory = useSelector((state: RootState) => state.createFormSlice.AccesoriesMandatoryData)
  const optional = useSelector((state: RootState) => state.createFormSlice.AccesoriesOptionalData)
  const Accesories = {
    mandatory: [...mandatory],
    optional: [...optional]
  } as AccesoriesModel

  //const accesories = useSelector((state: RootState) => state.createFormSlice.AccesoriesData);
  const Type: any = useSelector((state: RootState) => state.EditAssetForm.isExternal);

  const AssetsBack: any = useSelector((state: RootState) => state.EditAssetForm.Asset);
  const ExternalAssets = useSelector((state: RootState) => state.createFormSlice.AssetsDataExternal);



  useEffect(() => {
    // dispatch(EmptyForm([]))
    setResetAllInputs(true)
    dispatch(setAllassets([]))
  }, [])

  useEffect(() => {
    [data].map((item) => {
      if (item.category) {
        setCheckItemForm(true)
      } else { setCheckItemForm(false) }

      if (item.name) {
        setCheckItemForm(true)
      } else { setCheckItemForm(false) }
    })

  }, [checkItemForm, data])

  useEffect(() => {
    AssetRef?.current?.ChargeAssetData()
  }, [AssetRef, ITEM_DATA, ITEM_ASSETS])
  // const percenCompletion = (1 - checkFields(progress).percent)
  const percenCompletion = 0.05

  const tabsContent: JSX.Element[] = [
    <AssetData
      images={images}
      objectPick={objectPick}
      setObjectPick={setObjPick}
      dateCount={dateCount}
      setDateCount={setDateCount}
      ASSETS_ALL={ITEM_ASSETS}
      ref={AssetRef}
    />
  ];

  const onCancelModalClose = (value?: any) => {
    setIsCancelling(false);

    if (value == '') {
      props.onFinish()
      return;
    }


  };

  const prevStep = () => {
    setCurrentTab((prev) => {
      if (prev > 0) {
        return prev - 1;
      }
      return prev;
    });
  };

  const nextStep = () => {
    setCurrentTab((prev) => {
      if (prev < tabs.length - 1) {
        return prev + 1;
      }
      return prev;
    });
  };

  const deleteEmptyProps = (obj: any) => {
    const end = Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != ""));
    return end
  }



  const isEmptyObject = (obj: any) => {
    return Object.keys(obj).length === 0;
  }

  const setItem = async () => {
    if (true) {
      ////Create Assets
      let asset = Type ? ExternalAssets[0] : AssetsBack
      if (Object.keys(asset).length > 0) {
        const assetWithoutEmptyFields: any = {
          ...deleteEmptyProps(asset),
          purchase: {
            ...deleteEmptyProps({
              ...asset.purchase,
              price: parseInt(asset.purchase?.price),
              date: moment(asset.purchase?.date).format()
            })
          },
          end_of_life: asset?.end_of_life ? moment(asset?.end_of_life).format() : null,
          warranty_expiration: asset?.warranty_expiration ? moment(asset?.warranty_expiration).format() : null,
          item: ITEM_DATA?._id,
          external: {
            prices: {
              hour: asset.external?.prices?.hour || null,
              daily: asset.external?.prices?.daily || null,
              week: asset.external?.prices?.week || null,
              sale: asset.external?.prices?.sale || null,
              holiday_hour: asset.external?.prices?.holiday_hour || null,
              holiday_day: asset.external?.prices?.holiday_day || null,
              overtime_hour: asset.external?.prices?.overtime_hour || null,
              holiday_overtime_hour: asset.external?.prices?.holiday_overtime_hour || null
            },
            company_owner: asset.external?.company_owner || null,
            pickup_location: asset.external?.pickup_location || null,
          }
        }

        const resAssets: any = await dispatch(UpdateAssetForId({
          update: assetWithoutEmptyFields, id: params.assetId
        }))
        dispatch(setDataAsset({ ...resAssets?.payload?.data }))
        AssetRef?.current?.ChargeAssetData()
        dispatch(getAssetForID(resAssets?.payload?.data?._id))
      }


      toast.success('Save Assets succesfull')
      props.onFinish()


    } else {
      if (Asst) {
        let cpy = [Asst]
        cpy.map((error) => {
          toast.error('error ' + Object.keys(error)[0] + ' in Asset Data')
        })
      }
    }

  }



  return (
    <>
      {isCancelling && (
        <ConfirmModal
          title="Are you sure?"
          description="This new item will be discarted"
          confirmText="KEEP EDITING"
          cancelText="DISCARD ITEM"
          onClose={onCancelModalClose}
        />
      )}

      <div className="container-fluid h-100">
        <div className="row" style={{ height: '90vh' }}>
          <div className="col-12 card h-100" >

            {/* Top actions */}
            <div className="row justify-content-between align-items-center p-2 col-lg-12 col-md-auto col-sm-12 col-12 align-self-center
"
              style={{ position: 'absolute', background: 'white', zIndex: 1 }}>

              <div className='p-0 m-0 border-0 col-auto row'>

                <button
                  onClick={setIsCancelling.bind(null, true)}
                  className="btn btn-danger text-white typo-body col-auto border-0">
                  Cancel
                </button>

              </div>

              <h4
                className='text-primary col-auto' >Edit Asset</h4>

              <div className='p-0 m-0 border-0 col-auto row justify-content-between'>



                <button onClick={setItem} className="btn text-white btn-success typo-body col-auto border-0">
                  Save asset
                </button>

              </div>

            </div>

            {/* Tab View */}

            <div className='custom-scrollbar overflow-y h-100 mt-4'>
              <div className={`container ${Loading && 'disabledElement'} mt-5`}  >
                <div className="row mt-4">
                  <div className="col">
                    <ul className="nav nav-tabs mt-3 justify-content-between">
                      <div className="row">
                        {tabs.map((item, index) => {
                          return (
                            <li
                              key={index}
                              className="nav-item col-auto p-0"
                              onClick={!Gdta ? !checkItemForm ? () => { dispatch(setCheckItemFormAlert(true)) } : setCurrentTab.bind(null, index) : () => { }}
                            >
                              <p
                                className={`nav-link typo-body user-select-none ${currentTab === index ? 'active' : !Gdta ? !checkItemForm ? 'disabled' : '' : 'disabled'
                                  }`}
                                aria-current="page"
                              >
                                {item}
                              </p>
                            </li>
                          );
                        })}
                      </div>
                    </ul>

                    <div className="row justify-content-end mt-2">
                      <div className="col-auto">
                        <ProgressbarTitle value={
                            [
                              AssetsBack?.serial,
                              AssetsBack?.age,
                              AssetsBack?.condition,
                              AssetsBack?.digital_receipt,
                              AssetsBack?.end_of_life,
                              AssetsBack?.has_digital_receipt,
                              AssetsBack?.is_external,
                              AssetsBack?.purchase?.price,
                              AssetsBack?.purchase?.origin,
                              AssetsBack?.warehouse?.shelf_id,
                              AssetsBack?.warehouse?.location,
                              AssetsBack?.warehouse?.warehouse,
                              AssetsBack?.warranty_expiration,
                              AssetsBack?.sizes?.height,
                              AssetsBack?.sizes?.weight,
                              AssetsBack?.sizes?.width,
                              AssetsBack?.sizes?.length
                            ]
                        } />
                        <div style={{ marginLeft: '14%' }}>
                          <Checkbox
                            title="Mark as completed"
                            value={false}
                            onChange={(value) => { }}
                          />
                        </div>
                      </div>
                    </div>

                    {tabsContent[currentTab]}

                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAsset;
