import React, { useState } from "react";
import { QuoteRowType } from "../../../../../models/QuotesModel";
import AssingAsset from "../../../FactoryView/createQuoteForm/modals/AssingAsset";
import HeaderQuote from "./Header";

interface Props {
    headers: any[];
    handleChangeRow: (path: string, value, type) => void;
    handleDeleteRow: (path: string) => void;
    handleAddSubHeader: (path: string) => void;
    handleAddAssets: (path, assets) => void;
    handleAddItems: (path: string, type) => void;
    handleDragRow: (e, path, type) => void;
    handleDropRow: (e, path, type) => void;
}

const QuoteTable: React.FC<Props> = (props) => {
  const {headers, handleChangeRow, handleDeleteRow, handleAddSubHeader, handleAddItems, handleDragRow, handleDropRow, handleAddAssets} = props;
  const [isOpenAssignAssetModal, setIsOpenAssignAssetModal] = useState<boolean>(false);
  const [itemSelected, setItemSelected]=useState<{path: string, id: string}>({path: '', id: ''});

  const handleSelectAssets = (path: string, item: string) => {
    setItemSelected({path, id: item});
    setIsOpenAssignAssetModal(true);
  }

  const handleAssignAssets = (assets) => {
    handleAddAssets(itemSelected.path, assets);
    setIsOpenAssignAssetModal(false);
    setItemSelected({path: '', id: ''});
  }

  return (
    <div className="w-100 custom-scrollbar overflow-x mt-3 custom-table">
        <table className="table table-borderless">
        <thead>
            <tr className="text-primary typo-body align-middle">
                <th style={{width: "4%"}}><i className="bi bi-arrows-angle-expand me-3 fs-5 cursor-pointer"></i></th>
                <th style={{width: "24%"}}><span className="required">ITEM</span></th>
                <th style={{width: "8%"}}>QTY</th>
                <th style={{width: "10%"}}>RATE</th>
                <th style={{width: "12%"}}>UNITS</th>
                <th style={{width: "10%"}}>DAYS</th>
                <th style={{width: "12%"}}>OUT DATE - IN DATE W/HOURS</th>
                <th style={{width: "10%"}}>DISCOUNT</th>
                <th style={{width: "10%"}}>TOTAL</th>
                <th style={{width: "4%"}}></th>
            </tr>
        </thead>
        <tbody>
            {
                headers.map((e, index) => //aumentar logica para las funciones repetidas on change
                    <HeaderQuote 
                        name={e.name}
                        subTotal={e.sub_total}  
                        key={index} 
                        sub_headers={e.sub_headers} 
                        elements={e.elements} 
                        onChange={ (name, value) => handleChangeRow(`${index}.${name}`, value, QuoteRowType.HEADER)} 
                        onChangeItem={ (itemIndex, name, value) => handleChangeRow(`${index}.elements.${itemIndex}.${name}`, value, QuoteRowType.HEADER)}
                        onChangeSubHeader={ (subHeaderIndex, name, value) => handleChangeRow(`${index}.sub_headers.${subHeaderIndex}.${name}`, value, QuoteRowType.SUB_HEADER)}
                        onChangeSubHeaderRow={ (subHeaderIndex, subHeaderRowIndex, name, value) => handleChangeRow(`${index}.sub_headers.${subHeaderIndex}.elements.${subHeaderRowIndex}.${name}`, value, QuoteRowType.SUB_HEADER)}
                        onDelete={ () => handleDeleteRow(`${index}`)} 
                        onDeleteItem={ (itemIndex) => handleDeleteRow(`${index}.elements.${itemIndex}`)}
                        onDeleteSubHeader={ (subHeaderIndex) => handleDeleteRow(`${index}.sub_headers.${subHeaderIndex}`)}
                        onDeleteSubHeaderRow={ (subHeaderIndex, subHeaderRowIndex) => handleDeleteRow(`${index}.sub_headers.${subHeaderIndex}.elements.${subHeaderRowIndex}`)}
                        onAddSubHeader={() => handleAddSubHeader(`${index}.sub_headers`)}
                        onAddItems = {() => handleAddItems(`${index}.elements`, QuoteRowType.HEADER)}
                        onAddSubHeaderItems = {(subHeaderIndex) => handleAddItems(`${index}.sub_headers.${subHeaderIndex}.elements`, QuoteRowType.SUB_HEADER)}
                        onDrag = {(e) => handleDragRow(e, `${index}`, QuoteRowType.HEADER)}
                        onDrop = {(e) => handleDropRow(e,`${index}`, QuoteRowType.HEADER)}
                        onDragItem = {(e, path) => handleDragRow(e, `${index}.${path}`, QuoteRowType.ITEM)}
                        onDropItem = {(e, path) => handleDropRow(e,`${index}.${path}`, QuoteRowType.ITEM)}
                        onDropSubHeader = {(e, subHeaderIndex) => handleDropRow(e,`${index}.sub_headers.${subHeaderIndex}`, QuoteRowType.SUB_HEADER)}
                        onDragSubHeader = {(e, subHeaderIndex) => handleDragRow(e,`${index}.sub_headers.${subHeaderIndex}`, QuoteRowType.SUB_HEADER)}
                        onSelectAssetsItem = {(itemIndex, item) => handleSelectAssets(`${index}.elements.${itemIndex}`, item)}
                        onSelectAssetsSubHeaderRow = {(subHeaderIndex, subHeaderRowIndex, item) => handleSelectAssets(`${index}.sub_headers.${subHeaderIndex}.elements.${subHeaderRowIndex}`, item)}
                    />
                )
            }
        </tbody>
        </table>
        <AssingAsset
            isOpen={isOpenAssignAssetModal}
            onClose={() => setIsOpenAssignAssetModal((prev) => !prev)}
            onSelect={handleAssignAssets}
            item={itemSelected.id}
        />
    </div>
  );
};

export default QuoteTable;
