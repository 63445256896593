import clsx from "clsx";
import { useState } from "react";
import TrashIcon from 'src/assets/icons/trash.svg';
import EditIcon from 'src/assets/icons/edit.svg'

import ConfirmDuplicate from "../Modals/Contact/ConfirmDuplicate";

interface Props {
  title: string;
  count?: number;
  texts: {
    isFile?: boolean;
    fileHref?: any;
    text: React.ReactNode;
    isUnderline?: boolean;
    action?: () => {}
  }[];
  style?: any;
  removableItem?: {
    removableArg: boolean;
    confirmD: any;
    setConfirmD: any;
    onCancelModalClose: any
    DeletedConfirmed: any
    handleDelete: any
    textEmpty: any
  };
  editView?: any;
  setEditModal?: any
}

const InfoDisplay: React.FC<Props> = (props) => {
  const { removableItem, editView, setEditModal } = props
  const [deleted, setDeleted] = useState<boolean>(false)

  const [editview, seteditview] = useState(false)


  return (
    <div className="pb-3">
      <div className="d-flex align-items-center mb-1">
        <p className="m-0 typo-body text-muted text-uppercase" >{props.title}</p>
        {props.count !== undefined && (
          <p style={{ color: '#8898AA' }}
            className="m-0 typo-body ms-2 bg-primary text-white px-2 rounded">{props.count}</p>
        )}
      </div>

      {props.texts.map((item, index) => {
        const Tag = (!!item?.fileHref) ? "a" : "p";

        return ((
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {item?.fileHref ?
              <Tag
                key={index}
                target="_blank"
                href={item?.fileHref}
                rel="noopener noreferrer"
                style={{ cursor: 'pointer', color: '#344469', fontSize: '13.5px' }}
                download={item.isFile ? item.text : undefined}
                className={clsx("m-0 typo-body d-block", {
                  'text-decoration-underline': item.isUnderline,
                  'text-decoration-underline cursor-pointer': item.isFile && item.fileHref,
                })}
                onMouseOver={() => { seteditview && seteditview(true) }}
                onMouseOut={() => { seteditview && seteditview(false) }}
              >
                {deleted ? removableItem?.textEmpty : item.text}

              </Tag>
              :
              <div
              //  target="_blank"
                //   href={item?.fileHref}
              ///  rel="noopener noreferrer"
                style={item?.action ? { cursor: 'pointer', color: '#344469', fontSize: '13.5px' } : { color: '#344469', fontSize: '13.5px' }}
             //   download={item.isFile ? item.text : undefined}
                className={clsx("m-0 typo-body d-block", {
                  'text-decoration-underline': item.isUnderline,
                  'text-decoration-underline cursor-pointer': item.isFile && item.fileHref,
                })}
            //    onMouseOver={() => { seteditview && seteditview(true) }}
             //   onMouseOut={() => { seteditview && seteditview(false) }}
                onClick={item?.action}
              >
                {deleted ? removableItem?.textEmpty : item.text}

              </div>

            }
            {
              removableItem && removableItem?.removableArg && !deleted && (
                <img
                  src={TrashIcon}
                  alt=""
                  style={{ height: 13, width: 13, border: 'white', cursor: 'pointer', marginLeft: 10 }}
                  onClick={() => {
                    removableItem?.handleDelete()
                  }}
                />
              )

            }
          </div>)
        );
      })}
      {
        !props.texts && ('---')
      }
      {props.children}

      {removableItem?.confirmD && (<ConfirmDuplicate
        title="Are you sure?"
        description="This file will be deleted"
        confirmText="Delete"
        cancelText="Cancel"
        onClose={removableItem.onCancelModalClose}
        onConfirm={() => {
          removableItem.DeletedConfirmed()
          setDeleted(true)
        }}
      />)}
    </div>
  );
};

export default InfoDisplay;
