import React, { useEffect, useState } from "react";
//import InputSearch from "../../../components/UI/forms/InputSearch";
//import TableFatory from "./tableFactory/tableFactory";
import FactoryModal from "../../../Modals/FactoryModal";
import FactoryModalFilter from "../../../Modals/factoryModalFilter";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { getAllProject } from "../../../../store/factory/factorySlice";
import CounterBar from "./CounterBar";
import TableFatory from "../tableFactory/tableFactory";
import { UncontrolledDropdown } from "reactstrap";
//import { Link } from "react-router-dom";
//import FilterIcon from '../../../assets/icons/filter.svg'

interface Props {
  openModal: boolean;
  setOpenModal: any;
  activeControlView: any;
  setActiveControlView: any;
  selectJob: string | null;
  setSelectJob: any;
  activeTask: string | null;
  setActiveTask: any;
  selectProject: string | null;
  setSelectProject: any;
  closeModalJob: boolean;
  setCloseModalJob: any;
  viewOptions: any;
  setViewOptions: any;
}

interface Count {
  count1: number;
  count2: number;
  count3: number;
  count4: number;
  count5: number;
  count6: number;
}

const FactoryView: React.FC<Props> = (props) => {
  const [count, setCount] = useState<Count>({
    count1: 0,
    count2: 0,
    count3: 0,
    count4: 0,
    count5: 0,
    count6: 0,
  });

  const ProjectData = useSelector((state: RootState) => state.factorySlice);

  const { loading, projectData, ProjectResponse } = ProjectData;

  const dispatch = useDispatch();

  const countData = () => {
    if (projectData) {
      let counts: number = 0;
      let counts1: number = 0;
      let counts2: number = 0;
      let counts3: number = 0;
      let counts4: number = 0;
      let counts5: number = 0;

      projectData.forEach((e: any) => {
        if (e.status === "Proposal") {
          counts++;
        } else if (e.status === "Approved") {
          counts1++;
        } else if (e.status === "Active") {
          counts2++;
        } else if (e.status === "On Hold") {
          counts3++;
        } else if (e.status === "Complete") {
          counts4++;
        } else if (e.status === "Lost") {
          counts5++;
        }
      });

      setCount({
        count1: counts,
        count2: counts1,
        count3: counts2,
        count4: counts3,
        count5: counts4,
        count6: counts5,
      });
    }
  };

  useEffect(() => {
    countData();
  }, [projectData]);

  useEffect(() => {
    dispatch(getAllProject());
  }, []);

  useEffect(() => {
    if (ProjectResponse !== null) {
      dispatch(getAllProject());
    }
  }, [ProjectResponse, loading]);

  const openModalCreate = () => {
    props.setOpenModal(true);
    props.setCloseModalJob(true);
    props.setViewOptions(true);
  };
  

  return (
    <div className="col overflow-y overflow-x-none custom-scrollbar trans-all p-0">
      {/** COUNTER BAR ////// */}
      <div className="col-lg-12 ps-0 ">
        <p className="p-3 pb-0 mb-0 pe-5 poppinsText" style={{color:'#6B8AB8', opacity: 1, fontWeight: 'bold' , fontSize: '13px', marginLeft: '-2.5%' }}>Status</p>
        <CounterBar count={count} />
      </div>

    </div>
  );
};

export default FactoryView;
