import FlatList from "flatlist-react";
import React, { useEffect, useState, Ref } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Row, Input } from "reactstrap";
import AdvanceSearch from "../../../../Stock/StockList/AdvanceSearch";
import ContactSection from "../../../../Stock/StockList/ContactSection";
import IconButton from "../../../../UI/buttons/IconButton";
import { RootState } from "../../../../../store/store";
import {
    getAllItems,
    cleanAssetData,
} from "../../../../../store/stockpile/stockpileSlice";
//import { getSections } from "../../../util/ItemsFunctions";
import { ABC, getSections } from "../../../../../util/index";
import Scanner from "../../../../Scanner/scanner";
import DropdownCustom from "../../../../UI/Dropdown/dropdown";
import ButtonIconText from "../../../../UI/buttons/ButtonIconText";
import ItemModel from "src/models/ItemModel";
import PlusIcon from "../../../../../assets/icons/add.svg";
import CopyIcon from "../../../../../assets/icons/copy.svg";
import BarcodeIcon from "../../../../../assets/icons/barcode.svg";
import SearchIcon from "../../../../../assets/icons/search.svg";
import Kit from "../../../../../assets/icons/kit.svg";
import FilterIcon from '../../../../../assets/icons/filter.svg'

import LetterSection from "src/components/DashboardLayout/MidCard/LetterSection";
import ListItem from "src/components/DashboardLayout/MidCard/ListItem";
import { useHistory } from "react-router-dom";
import {
    cleanAsset,
    setDataAsset,
    setIdItem,
} from "src/store/stockpile/selectAssetsSlice";
import useQuery from "src/hooks/useQuery";
import { setShowAddUploadCSV } from "src/store/stockpile/RecursosVisualeSlice";
import { setData } from "src/store/stockpile/createFormSlice";
import { useToast } from "src/hooks/useToast";
import {
    setCategorieActive,
    setCategoryFilter,
} from "src/store/stockpile/categorieSlice";
import ItemListComponent from "src/components/Molecules/JobListComponent";

import { JobsModel } from "../../../../../models/factoryModel";

interface props {
    data: string;
    onAddContact: any;
    onAddService: any;
    toggleAddingkit: any;
}

const JobList: React.FC<any> = (props) => {
    const toast = useToast();
    const history = useHistory();
    const [itemSearch, setItemSearch] = useState<string>("");
    const [itemsFiltered, setItemsFiltered] = useState<any>([]);
    const [shotby, setShortBy] = useState<string>("name");
    const [code, setCoder] = useState<any>("");
    const [scanModal, setScanModal] = useState<boolean>(false);
    const [test, settest] = useState<boolean>(false);
    const query = useQuery();
    const [advcCategory, setAdvanceCategory] = useState<any>(false);
    const [words, setWords] = useState<any>("");
    const [type, setType] = useState<any>("");
    const { innerHeight } = window;

    const [initialEmpty, setInitialEmpty] = useState<any>(false);
    const [alphSelected, setAlphSelected] = useState<any>();

    const { jobsData, loading, QuotesId, QuoteVersion } = useSelector(
        (state: RootState) => state.getJobsTypeSlice
    );

    const jobsDataId: Array<any> = useSelector(
        (state: RootState) => state.getJobsTypeSlice.jobsDataId
    );

    const ItemData = useSelector(
        (state: RootState) => state.stockpileSlice.ItemData
    );
    const LoadingItems = useSelector(
        (state: RootState) => state.stockpileSlice.LoadingItems
    );
    const categorySelected: any = useSelector(
        (state: RootState) => state.categorieSlice.categorySelectedFilter
    );
    const SubCategory: any = useSelector(
        (state: RootState) => state.categorieSlice.sub_categorySelectedFilter
    );

    const subCategorySelected: any = useSelector(
        (state: RootState) => state.categorieSlice.categorySelectedFilter
    );
    const ShowAddUploadCSV = useSelector(
        (state: RootState) => state.RecursosVisualeSlice.ShowAddUploadCSV
    );

    const ASSET_SELECTED = useSelector(
        (state: RootState) => state.selectAssetsSlice.data
    );

    const [indexList, setIndex] = useState("");

    const checkSelect = (indexone: any, indextwo: any) => {
        if (indexone == indextwo) {
            return true;
        }
        return false;
    };

    const dispatch = useDispatch();

    const abc: any = [
        "A",
        "B",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "Ñ",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
    ];

    const getData = () => {
        dispatch(getAllItems());
    };

    useEffect(() => {
        getData();
        cleanAssetData();
        // dispatch(setIdItem(''))
    }, []);

    useEffect(() => { }, [ItemData]);

    useEffect(() => {
        dispatch(setDataAsset({}));
    }, []);

    useEffect(() => {
        console.log("changed");
    }, [ItemData]);

    const handleSearch = (e: any) => {
        setItemSearch(e.target.value);
    };

    const byCategoryForID = (item: any, category: any) => {
        if (category) {
            if (category == "any") {
                return true;
            } else {
                const result = item.category?._id == category ? true : false;

                return result;
            }
        } else return true;
    };

    const bySubCategoryForID = (item: any, sub_category: any) => {
        if (sub_category) {
            if (sub_category == "any") {
                return true;
            } else {
                const result =
                    item?.category?.sub_category?._id == sub_category ? true : false;

                return result;
            }
        } else return true;
    };

    const byType = (item: any, type: any) => {
        if (type) {
            if (type == 0) {
                return item.internal_assets > 0;
            }
            if (type == 1) {
                return item.external_assets > 0;
            }
        } else return item;
    };

    const byWordsName = (item: any, word: any) => {
        if (word) {
            return item.name.toLowerCase().includes(word.toLowerCase());
        } else return item;
    };

    const byWordsDesc = (item: any, word: any) => {
        if (word) {
            return item.description.toLowerCase().includes(word.toLowerCase());
        }
    };

    const bySearch = (item: any, search: any) => {
        if (search) {
            return item.name.toLowerCase().includes(search.toLowerCase());
        } else return item;
    };
    const FWords = (item: any, words: any) => {
        if (words) {
            return item.name.toLowerCase().includes(words.toLowerCase());
        } else return item;

        /* if (words.length > 0) {
                 const mp = words?.reduce((acc: Array<object>, word: any) => {
                     return acc.concat(items
                         .filter((item: any) => byWordsName(item, word))
                         .filter((item: any) => byWordsDesc(item, word))
                     )
                 }, [])
                 return mp
             } else return items*/
    };

    const filteredList = (
        items: any,
        search: any,
        cod: any,
        category: any,
        subCategory: any,
        AdvanceCategory: any,
        type: any,
        words: any
    ) => {
        const t = items
        //     .filter((item: any) => byCategoryForID(item, category))
        //    .filter((asset: any) => bySearch(asset, cod))
        //   .filter((item: any) => bySearch(item, search))
        //  .filter((item: any) => byCategoryForID(item, AdvanceCategory))
        //  .filter((item: any) => byType(item, type))
        // .filter((item: any) => bySubCategoryForID(item, subCategory))
        //  .filter((item: any) => FWords(item, words));

        return t;
    };

    const messageComming = (message: any) => {
        toast.info(message + " is coming soon!");
    };
    //  const sections = getSections(FWords(filteredList(ItemData, itemSearch, code, categorySelected, advcCategory, type, words), words), 'name')
    const sections = getSections<JobsModel, HTMLDivElement>(
        [
            ...filteredList(
                jobsDataId,
                itemSearch,
                code,
                categorySelected,
                SubCategory,
                advcCategory,
                type,
                words
            ),
        ] || [],
        (item) => shotby
    );

    console.log('/////////', jobsDataId);


    const results = jobsDataId && jobsDataId /*
    filteredList(
        jobsDataId,
        itemSearch,
        code,
        categorySelected,
        SubCategory,
        advcCategory,
        type,
        words
    );*/

    const SelectCategory = (category: any) => {
        props.setTypeForm(category);
        props.onAddContact(category);
    };

    const toggleAddingCSV: any = (category: any) => {
        if (ShowAddUploadCSV) {
            query.delete("UploadCSV");
            history.replace({ search: query.toString() });
        } else {
            query.set("UploadCSV", category || "");
            history.push({ search: query.toString() });
        }
        dispatch(setShowAddUploadCSV((prev: any) => !prev));
    };

    const onlyUnique = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
    };

    const Equipment = categorySelected == "67807598690173953";

    const goTo = (letter: ABC) => {
        // setPaddingView(false)
        if (sections[letter]) {
            sections[letter].ref.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        console.log(ItemData);
        console.log(sections);
    }, [sections]);

    return (
        <div className="d-flex flex-column  justify-content-center ps-0 my-0 h-100
        m-0 mt-n5"
            style={{ minWidth: '100%', maxHeight: '50%', marginTop: '0%' }}
        >

            <div className="me-3 pb-0 mb-0 backred ps-2" style={{
                background: '#F3F3F3', height: '30px', width: '100%',
                fontSize: '19px', marginTop: '0%'
            }}>
                <p className="mt-0 mb-0" style={{ color: '#8898AA' }}>JOBS</p>
            </div>

            <div className={`row p-0 contact-container w-100  ${Equipment && "mt-0"}`} style={{ overflow: 'hidden' }}>

                <div className="col  h-100 custom-scrollbar contact-list p-0 m-0 w-100" >

                    <ItemListComponent
                        LoadingItems={LoadingItems}
                        ItemData={jobsDataId}
                        sections={sections}
                        results={results}
                        setID={props.setID}
                        showAssets={props.showAssets}
                    />
                </div>


            </div>
        </div>
    );
};

export default JobList;
