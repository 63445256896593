import { FormikProvider, Form, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import ComboBox from "../../../../UI/forms/ComboBox";
import InputFloatingLabel from "../../../../UI/forms/InputFloatingLabel";
import InputSelect from "../../../../UI/forms/InputSelect";
import ItemModel, { AssetModel, warehouse, purchase, External, PickupInfo } from '../../../../../models/ItemModel'
import { updateValuesExternalAssets, setAsset, setValuesPickup, changeAssetsErrors, DeleteAssetExternal } from "../../../../../store/stockpile/createFormSlice";
import { Button } from "reactstrap";
import { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import AssetScheme from "./validation";
import checkFields from "../../../../../util/CheckFields";
import { RootState } from '../../../../../store/store';

import IconButton from '../../../../UI/buttons/IconButton'
import { setIdItem } from "../../../../../store/stockpile/selectAssetsSlice";
import SelectInput from "src/components/UI/forms/Select";
import { ConvertSize } from '../../../../../util/sizesConvert'

interface Props {
  setImages: (element: any) => void,
  index: any
}

const FormAssetExternal = forwardRef((props: Props, ref) => {




  const data = useSelector((state: RootState) => state.createFormSlice.AssetsDataExternal);
  const ItemID: any = useSelector((state: RootState) => state.stockpileSlice.ItemResponse);

  const ValuesData = data[props.index] as AssetModel

  const ValuesDataExternal = data[props.index]?.external as External

  const PickData = useSelector((state: RootState) => state.createFormSlice.pickupData);

  const objectPick = PickData[props.index]

  const dispatch = useDispatch()

  const base = {
    item: ItemID?._id,
    serial: '',
    purchase: {
      date: '',
      origin: '',
      price: ''
    },
    warehouse: {
      warehouse: '',
      location: '',
      shelf_id: ''
    },
    warranty_expiration: '0000-00-00',
    is_external: true,
    condition: '',
    end_of_life: '0000-00-00',
    external: {
      prices: {
        hour: '',
        daily: '',
        week: '',
        sale: '',
        holiday_hour: '',
        holiday_day: '',
        overtime_hour: '',
        holiday_overtime_hour: ''
      },
      company_owner: '',
      pickup_location: {
        country: '',
        state: '',
        city: '',
        zip: '',
        adress: ''
      },
    },
    sizes: {
      name: 'dw',
      width: '',
      height: '',
      weight: '',
      length: '',
      weight_unit: 'centimeters',
      sizes_unit: 'centimeters'
    }
  }

  const formik = useFormik({
    validationSchema: AssetScheme,
    initialValues: {
      ...base
    },
    onSubmit: (values) => {
    }
  })

  const { errors, handleSubmit, setFieldValue } = formik

  useEffect(() => {

  }, [data])

  const updateCamp = (value: AssetModel) => {
    const ret = data.slice(0);
    ret[props.index] = {
      ...ret[props.index], ...value,
      warranty_expiration: '30/08/2000',
      is_external: true,
    } as AssetModel;
    dispatch(updateValuesExternalAssets(ret));
  }

  const updateNestedCamp = (value: External) => {
    const ret = data.slice(0);
    ret[props.index] = { ...ret[props.index], external: { ...value } as External } as AssetModel;
    dispatch(updateValuesExternalAssets(ret));
  }

  const updatePickupInfo = (value: any) => {
    const ret = PickData.slice(0);
    ret[props.index] = { ...ret[props.index], ...value };
    dispatch(setValuesPickup(ret))
    updateNestedCamp({ ...ValuesData?.external, pickup_location: JSON.stringify(ret[props.index]) } as External)
  }

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      dispatch(changeAssetsErrors(true))
    } else {
      dispatch(changeAssetsErrors(false))
    }
  }, [errors])


  return (
    <>

          <div className="row">
          <div className="d-flex gap-3 text-primary align-items-center m-2">
              <p style={{ whiteSpace: 'nowrap' }} className="mb-0">Asset #{props.index + 1}</p>
              <hr className="w-100" />
              {
               props.index > 0 && 
               <IconButton
            outline
            color="danger"
            icon="bi bi-x"
            tooltip="Remove skillset"
            onClick={() => { dispatch(DeleteAssetExternal(props.index)) }}
          />
              }
            </div>
            <div className="col-lg-6 col-sm-12 col-md-12 col-12">
              <InputFloatingLabel
                placeHolder="External company owner"
                value={ValuesData?.external?.company_owner}
                onChange={(value) => {
                  updateNestedCamp({ ...ValuesData?.external, company_owner: value } as External)
                  setFieldValue('external.company_owner', value)
                }}
                errorMessage={errors?.external?.company_owner}
                isRequired
              />
            </div>

            <div className="col-lg-6 col-sm-12 col-md-12 col-12">
              <InputFloatingLabel
                placeHolder="Serial number"
                value={ValuesData?.serial}
                onChange={(value) => {
                  updateCamp({ serial: value } as AssetModel)
                  setFieldValue('serial', value)
                }}
                errorMessage={errors.serial}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Hour rate"
                value={ValuesData?.external?.prices?.hour}
                onChange={(value) => {
                  updateNestedCamp({ ...ValuesData?.external, prices: { ...ValuesData?.external?.prices, hour: value } } as External)
                  setFieldValue('external.prices.hour', value)
                }}
                errorMessage={errors?.external?.prices?.hour}
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Day rate"
                value={ValuesData?.external?.prices?.daily}
                onChange={(value) => {
                  updateNestedCamp({ ...ValuesData?.external, prices: { ...ValuesData?.external?.prices, daily: value } } as External)
                  setFieldValue('external.prices.day', value)
                }}
                errorMessage={errors?.external?.prices?.daily}
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Week rate"
                value={ValuesData?.external?.prices?.week}
                onChange={(value) => {
                  updateNestedCamp({ ...ValuesData?.external, prices: { ...ValuesData?.external?.prices, week: value } } as External)
                  setFieldValue('external.prices.week', value)
                }}
                errorMessage={errors?.external?.prices?.week}
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Sell rate"
                value={ValuesData?.external?.prices?.sale}
                onChange={(value) => {
                  updateNestedCamp({ ...ValuesData?.external, prices: { ...ValuesData?.external?.prices, sale: value } } as External)
                  setFieldValue('external.prices.sell', value)
                }}
                errorMessage={errors?.external?.prices?.sale}
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Overtime hour rate"
                value={ValuesData?.external?.prices?.overtime_hour}
                onChange={(value) => {
                  updateNestedCamp({ ...ValuesData?.external, prices: { ...ValuesData?.external?.prices, overtime_hour: value } } as External)
                  setFieldValue('external.prices.overtime_hour', value)
                }}
                errorMessage={errors?.external?.prices?.overtime_hour}
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Holiday hour rate"
                value={ValuesData?.external?.prices?.holiday_hour}
                onChange={(value) => {
                  updateNestedCamp({ ...ValuesData?.external, prices: { ...ValuesData?.external?.prices, holiday_hour: value } } as External)
                  setFieldValue('external.prices.holiday_hour', value)
                }}
                errorMessage={errors?.external?.prices?.holiday_hour}
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Holiday day rate"
                value={ValuesData?.external?.prices?.holiday_day}
                onChange={(value) => {
                  updateNestedCamp({ ...ValuesData?.external, prices: { ...ValuesData?.external?.prices, holiday_day: value } } as External)
                  setFieldValue('external.prices.holiday_day', value)
                }}
                errorMessage={errors?.external?.prices?.holiday_day}
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Holiday overtime rate"
                value={ValuesData?.external?.prices?.holiday_overtime_hour}
                onChange={(value) => {
                  updateNestedCamp({ ...ValuesData?.external, prices: { ...ValuesData?.external?.prices, holiday_overtime_hour: value } } as External)
                  setFieldValue('external.prices.holiday_overtime_hour', value)
                }}
                errorMessage={errors?.external?.prices?.holiday_overtime_hour}
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-6 col-md-3 col-sm-3 col">
              <SelectInput
                placeholder={'Condition'}
                onChange={(value: any) => {
                  updateCamp({ condition: value } as AssetModel)
                }}
                value={ValuesData?.condition}
                options={[
                  { label: 'Good', value: 'good' },
                  { label: 'Excellent', value: 'excellent' },
                  { label: 'Fair', value: 'fair' },
                  { label: 'Poor', value: 'poor' },
                ]}
              />
            </div>

          </div>

          <div className="row mt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <p className="m-0 text-primary typo-body">Warehouse Location</p>
              <div className=" mt-1">
                <InputSelect
                  select={{
                    onChange: (value) => {
                      updateCamp({ sizes: { ...ValuesData.sizes, weight_unit: value } } as AssetModel)
                    },
                    options: [
                      {
                        label: 'Kg',
                        value: 'kg',
                      },
                      {
                        label: ConvertSize('centimeters'),
                        value: 'centimeters',
                      },
                      {
                        label: 'Feet',
                        value: 'feet',
                      },
                      {
                        label: 'Inches',
                        value: 'inches',
                      },
                      {
                        label: 'Meters',
                        value: 'meters',
                      },
                    ],
                    value: ValuesData?.sizes?.weight_unit ? ValuesData?.sizes?.weight_unit :'centimeters' ,
                  }}
                  input={{
                    onChange: (value) => {
                      updateCamp({ sizes: { ...ValuesData.sizes, weight: value } } as AssetModel)
                      setFieldValue('sizes.weight', value)
                    },
                    placeHolder: 'Weight',
                    value: ValuesData?.sizes?.weight,
                    errorMessage: errors?.sizes?.weight
                  }}
                />
              </div>

              <div className="mt-2">
                <InputSelect
                  select={{
                    onChange: (value) => {
                      updateCamp({ sizes: { ...ValuesData.sizes, sizes_unit: value } } as AssetModel)
                    },
                    options: [
                      {
                        label: 'Kg',
                        value: 'kg',
                      },
                      {
                        label: ConvertSize('centimeters'),
                        value: 'centimeters',
                      },
                      {
                        label: 'Meter',
                        value: 'meters',
                      },
                      {
                        label: 'Inches',
                        value: 'inches',
                      },
                      {
                        label: 'Feet',
                        value: 'feet',
                      },
                    ],
                    value: ValuesData?.sizes?.sizes_unit ? ValuesData?.sizes?.sizes_unit :'centimeters' ,
                  }}
                  input={{
                    onChange: (value) => {
                      updateCamp({ sizes: { ...ValuesData.sizes, length: value } } as AssetModel)
                      setFieldValue('sizes.length', value)
                    },
                    placeHolder: 'Length',
                    value: ValuesData?.sizes?.length,
                    errorMessage: errors?.sizes?.length
                  }}
                />
              </div>

              <div>

              </div>
              <div className="mt-2 col-md-10 float-end" style={{ paddingLeft: '7.5%', paddingRight: '.5%' }}>
                <InputFloatingLabel
                  placeHolder="Width"
                  value={ValuesData?.sizes?.width}
                  onChange={(value) => {
                    updateCamp({ sizes: { ...ValuesData.sizes, width: value } } as AssetModel)
                    setFieldValue('sizes.width', value)
                  }}
                  errorMessage={errors?.sizes?.width}
                />
              </div>

              <div className="mt-2 col-md-10 float-end" style={{ paddingLeft: '7.5%', paddingRight: '.5%' }}>
                <InputFloatingLabel
                  placeHolder="Height"
                  value={ValuesData?.sizes?.height}
                  onChange={(value) => {
                    updateCamp({ sizes: { ...ValuesData.sizes, height: value } } as AssetModel)
                    setFieldValue('sizes.height', value)
                  }}
                  errorMessage={errors?.sizes?.height}
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <p className="m-0 text-primary typo-body">PICKUP INFORMATION</p>
              <div className="col-12">
                <InputFloatingLabel
                  placeHolder="Country"
                  value={PickData[props.index]?.country}
                  onChange={(value) => {
                    updatePickupInfo({ country: value } as PickupInfo)
                    setFieldValue('external.pickup_location.country', value)
                  }}
                  errorMessage={errors?.external?.pickup_location?.country}
                />
              </div>
              <div className="col-12">
                <InputFloatingLabel
                  placeHolder="City"
                  value={objectPick?.city}
                  onChange={(value) => {
                    updatePickupInfo({ city: value } as PickupInfo)
                    setFieldValue('external.pickup_location.city', value)
                  }}
                  errorMessage={errors?.external?.pickup_location?.city}
                />
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 col-12">

                <InputFloatingLabel
                  placeHolder="State / Territory"
                  value={objectPick?.state}
                  onChange={(value) => {
                    updatePickupInfo({ state: value } as PickupInfo)
                    setFieldValue('external.pickup_location.state', value)
                  }}
                  errorMessage={errors?.external?.pickup_location?.state} />
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 col-12">

                <InputFloatingLabel
                  placeHolder="Postal Code"
                  value={objectPick?.zip}
                  onChange={(value) => {
                    updatePickupInfo({ zip: value } as PickupInfo)
                    setFieldValue('external.pickup_location.zip', value)
                  }}
                  errorMessage={errors?.external?.pickup_location?.zip}
                />
              </div>

              <div className="col-12">
                <InputFloatingLabel
                  placeHolder="Address"
                  value={objectPick?.adress}
                  onChange={(value) => {
                    updatePickupInfo({ adress: value } as PickupInfo)
                    setFieldValue('external.pickup_location.adress', value)
                  }}
                  errorMessage={errors?.external?.pickup_location?.adress}
                />
              </div>
            </div>
          </div>
    </>)
})

export default FormAssetExternal
