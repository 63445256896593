import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ICategory } from 'src/models/Category';
import { setCategorieActive } from '../../../store/stockpile/categorieSlice'
import { BlackbookService } from '../../../services/blackbook'
import DoubleClick from '../../Behaviors/DoubleClicks'
import ConfirmDelete from '../../Modals/Categories/ConfirmDelete'
import { useToast } from '../../../hooks/useToast'
import { useHistory } from 'react-router-dom';

interface Props {
  category: ICategory;
  selected?: ICategory | boolean;
  onSelect?: (category: ICategory) => void;
  index?: any,
  count?: any
}

const CategoryItem: React.FC<Props> = ({
  category,
  onSelect,
  selected,
  index,
  count
}) => {
  const dispatch = useDispatch()
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toast = useToast()

  const handleSelect = (cat: ICategory, indexCat: any) => {
    if (cat._id === category._id) setDropdownOpen(!dropdownOpen);

    onSelect && onSelect(cat, indexCat);
  }

  const [confirmD, setConfirmD] = useState<boolean>(false)
  const [deleteC, setDeletec] = useState<ICategory>(null)

  const history = useHistory()





  const Delete = (id: any) => {

    BlackbookService.DeleteCategory(id).then(
      () => {
        history.go(0)
        setConfirmD(false)
        toast.success('categorie was deleted')
      }
    )

  }


  const onCancelModalClose = () => {
    setConfirmD(false)
  }


  return (
    <ul className="p-0">
      <DoubleClick onDoubleClick={() => {
        setConfirmD(true)
        setDeletec(category)
      }} >
        <li
          onClick={() => handleSelect(category, index)}
          className={
            "text-primary typo-body mb-1 row p-0 justify-content-between align-items-center dashboard-categories-item" +
            ((typeof selected === 'boolean' && selected) || (typeof selected !== 'boolean' && selected?._id === category._id) ? 'activeBlackbbok' : '')
          }
        >
          <p style={(typeof selected === 'boolean' && selected) || (typeof selected !== 'boolean' && selected?._id === category._id) ? { fontWeight: 'bold', color: '#6b8ab8' } : { color: '#344469' }} className={`col-auto m-0 ${count && 'dropdown-toggle'} `}>{category.name}</p>
          {/* Should only show when the category returns the total items in it */}
          {/* {count && (
          <p className="m-0 col-auto contact-trailing typo-body bg-primary me-1">
            {count}
          </p>
        )} */}

          {category?.element_count != "0" && category?.element_count && selected == index && (
            <p className="m-0 col-auto contact-trailing typo-body bg-primary me-3 ">
              {category?.element_count}
            </p>
          )}


        </li>
      </DoubleClick>

      {
        dropdownOpen &&
        (category?.sub_categories || [])?.map((cat) => (
          <DoubleClick onDoubleClick={() => {
            setConfirmD(true)
            setDeletec(cat)
          }} >
            <li
              style={{ marginLeft: '3%', color: '#344675' }}
              onClick={() => handleSelect(cat)}
              className={
                "text-primary typo-body mb-1 row p-0 justify-content-between align-items-center dashboard-categories-item " +
                ((typeof selected === 'boolean' && selected) || (typeof selected !== 'boolean' && selected?._id === cat._id) ? ' activeSubBlackbbok' : 'desactiveBlackCat')
              }
            >
              <p className="col-auto m-0">{cat.name}</p>
            </li>
          </DoubleClick>
        ))
      }

      {confirmD && (<ConfirmDelete
        title="Are you sure?"
        description={deleteC?.name + " will be deleted"}
        confirmText="Delete"
        cancelText="cancel"
        onClose={onCancelModalClose}
        onConfirm={() => Delete(deleteC._id)}
      />)}
    </ul>
  );
};

export default CategoryItem;
