import { useEffect, useState } from 'react';

// Tabs Components

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store'
import { AssetModel } from '../../../models/ItemModel';
import { setFullWidth, setselectedState, setShowAddAsset, setShowAssetLis, setShowEditAsset, setShowEditItem } from '../../../store/stockpile/RecursosVisualeSlice';
import { AllWareHouses, cleanItemGetted, DeleteAsset, DeleteItem, GetAllRateForItem, getAssetForItem, GetItemForID, RefreshForDeleteAsset, RefreshForDeleteItem } from 'src/store/stockpile/stockpileSlice';
import { useToast } from 'src/hooks/useToast';


import { useHistory, useParams } from 'react-router-dom';
import { getAssetForID, setDataAsset, setIdItem } from 'src/store/stockpile/selectAssetsSlice';
import { useRequest } from 'src/hooks/useRequest';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import useQuery from 'src/hooks/useQuery';
import { setCategoryFilter } from 'src/store/stockpile/categorieSlice';
import { EmptyForm, updateValuesAssets } from 'src/store/stockpile/EditFormSlice';

import HeaderStockList from 'src/components/Molecules/StockpileStockView/HeaderStockList';
import ProfileItem from 'src/components/Molecules/StockpileStockView/ProfileItem';
import StockQty from 'src/components/Molecules/StockpileStockView/StockQty'
import TopButtons from 'src/components/Molecules/StockpileStockView/TopButtons'
import TabComponent from 'src/components/Molecules/StockpileStockView/TabComponent'


interface Props {
  isOpen: boolean;
  toggleIsOpen: () => void;
}



const StockView: React.FC<Props> = (props) => {
  const toast = useToast()

  const dispatch = useDispatch()
  const params = useParams<{ itemId: string; }>();
  const history = useHistory();
  const query = useQuery()

  const [optionsOpen, setOptionsOpen] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false)
  const [isCancellingAsset, setIsCancellingAsset] = useState(false)

  const { data: ITEM_DATA } = useRequest<any>({ url: 'stockpile/items/' + params.itemId });

  const ASSET_DATA: any = useSelector((state: RootState) => state.selectAssetsSlice.data);

  const AllAssetForItem: any = useSelector((state: RootState) => state.stockpileSlice.AssetData) || [];

  const ShowEdit: any = useSelector((state: RootState) => state.RecursosVisualeSlice.ShowEditItem);
  const ShowAddAsset: any = useSelector((state: RootState) => state.RecursosVisualeSlice.ShowAddAsset);
  const ShowEditAsset: any = useSelector((state: RootState) => state.RecursosVisualeSlice.ShowEditAsset);


  const ItemGetted: any = useSelector((state: RootState) => state.stockpileSlice.ItemGetted) || [];


  const toggleOptionsState = () => setOptionsOpen((prev) => !prev);

  useEffect(() => {
    dispatch(setDataAsset({}))
    dispatch(cleanItemGetted())
    dispatch(GetAllRateForItem(params.itemId))
    dispatch(AllWareHouses())
  }, [])

  useEffect(() => {
    dispatch(GetItemForID(params.itemId))
    dispatch(getAssetForItem(params.itemId))
  }, [params.itemId])

  useEffect(() => {
    dispatch(setDataAsset({ ...ASSET_DATA, item: ItemGetted }))
  }, [ItemGetted, params.itemId])

  useEffect(() => {
    if (AllAssetForItem.length > 0) {
      dispatch(getAssetForID(AllAssetForItem[0]._id))
    }
  }, [AllAssetForItem])



  const toggleEditItem: any = (category: any) => {
    if (ShowEdit) {
      query.delete('editItem');
      history.replace({ search: query.toString() })
    } else {
      query.set('editItem', category || '');
      history.push({ search: query.toString() })
    }
    dispatch(setShowEditItem((prev: any) => !prev));
  };

  const toggleAddingAsset: any = (category: any) => {
    if (ShowAddAsset) {
      query.delete('AddAsset');
      history.replace({ search: query.toString() })
    } else {
      query.set('AddAsset', category || '');
      history.push({ search: query.toString() })
    }
    dispatch(setShowAddAsset((prev: any) => !prev))
  };


  const toggleEditingAsset: any = (category: any) => {
    if (ShowEditAsset) {
      query.delete('EditAsset');
      history.replace({ search: query.toString() })
    } else {
      query.set('', category || '');
      history.push('/stockpile/' + params.itemId + '/' + query.toString().substring(1))
    }
    dispatch(setShowEditAsset((prev: any) => !prev));
    dispatch(EmptyForm([]))
  };



  const onCancelModalClose = async (value?: boolean) => {
    if (!value) {
      dispatch(setCategoryFilter(ITEM_DATA?.category?._id))

      const response: any = await dispatch(DeleteItem(ITEM_DATA?._id))

      if (response.payload == '200') {
        dispatch(setShowAssetLis(false))
        dispatch(RefreshForDeleteItem(ITEM_DATA?._id))
        toast.success('Item has been deleted')
        history.push('/stockpile')
      } else {
        if (!response.payload || response.payload == '403') {
          toast.error('You are not allowed to delete this Item.')

        } else {
          toast.error('error for delete item')

        }
      }
    }
    setIsCancelling(false);
  };

  const onCancelModalAssetClose = async (value?: boolean) => {

    if (!value) {
      dispatch(setCategoryFilter(ASSET_DATA?.item?.category?._id))

      const response: any = await dispatch(DeleteAsset(ASSET_DATA?._id))




      if (response.payload == '200') {
        dispatch(setDataAsset({ ...AllAssetForItem[0] }))
        dispatch(setselectedState(0))
        dispatch(RefreshForDeleteAsset(ASSET_DATA?._id))
        toast.success('Asset has been deleted')
        //  history.push('/stockpile')
      } else {
        if (response.payload == '403') {
          toast.error('You are not allowed to delete this asset.')

        } else {
          toast.error('error for delete Asset')

        }

      }
    }
    setIsCancellingAsset(false);

  };


  return (
    <div className="d-flex flex-column vh-100 px-2 my-sm-5 my-lg-0">

      <HeaderStockList
        ASSET_DATA={ASSET_DATA}
        toggleAddingAsset={toggleAddingAsset}
        toggleEditItem={toggleEditItem}
        toggleEditingAsset={toggleEditingAsset}
        setIsCancelling={setIsCancelling}
        setIsCancellingAsset={setIsCancellingAsset} />

      <div className="w-100 mt-3 d-flex justify-content-between ps-1 ">

        <ProfileItem ASSET_DATA={ASSET_DATA} AllAssetForItem={AllAssetForItem} />

        <StockQty AllAssetForItem={AllAssetForItem} />

      </div>

      <TopButtons />

      <TabComponent />


      {isCancelling && (
        <ConfirmModal
          title="Are you sure?"
          description="This item will be deleted"
          confirmText="CANCEL"
          cancelText="DELETE ITEM"
          onClose={onCancelModalClose}
        />
      )}

      {isCancellingAsset && (
        <ConfirmModal
          title="Are you sure?"
          description={"This asset will be deleted (" + ASSET_DATA?.serial + ")"}
          confirmText="CANCEL"
          cancelText="DELETE ASSET"
          onClose={onCancelModalAssetClose}
        />
      )}
    </div>
  );
};

export default StockView;
