import { useToast } from "src/hooks/useToast";

import PlusIcon from "../../../assets/icons/add.svg";
import CopyIcon from "../../../assets/icons/copy.svg";
import BarcodeIcon from "../../../assets/icons/barcode.svg";
import Kit from "../../../assets/icons/kit.svg";

import IconButton from "../../UI/buttons/IconButton";
import DropdownCustom from "../../UI/Dropdown/dropdown";
import ButtonIconText from "../../UI/buttons/ButtonIconText";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";


interface Props {
  setScanModal:any, 
  setShortBy:any, 
  SelectCategory:any , 
  toggleAddingCSV:any,
  toggleAddingkit:any,
  onAddService:any
}

const HeaderButtons: React.FC<Props> = (props) => {
    const { setScanModal, setShortBy , SelectCategory , toggleAddingCSV , onAddService, toggleAddingkit } = props

    const toast = useToast();

    const categorySelected: any = useSelector(
      (state: RootState) => state.categorieSlice.categorySelectedFilter
    );

    const Equipment = categorySelected == "67807598690173953";

    
    const messageComming = (message: any) => {
        toast.info(message + " is coming soon!");
      };

    return (
        <div className="row  align-items-center mt-3 mx-1 ">
        <div className="ps-1 col-auto">
          <ButtonIconText
            title={"Scan code"}
            custom={BarcodeIcon}
            customStyle={{ width: "15px", height: "12px", marginTop: "3.5%" }}
            onClick={() => setScanModal(true)}
          />
        </div>

        <div className={`p-0 col-auto m-0 ${Equipment ? "" : "d-none"} `}>
          <IconButton
            color="primary"
            custom={Kit}
            customStyles={{ width: "9.5px", height: "11px" }}
            onClick={toggleAddingkit}
            tooltip="Duplicate"
            outline
          />
        </div>
        <div
          className={`col-auto pe-0 ps-0${
            Equipment ? "me-1" : "ms-4 me-1 ps-1"
          }`}
          //style={categorySelected._id == "67807598690173953" ? {} : {}}
        >
          <DropdownCustom
            name="Sort by"
            options={[
              { name: "Name", action: () => setShortBy("name") },
              { name: "Brand", action: () => setShortBy("brand") },
              { name: "Category", action: () => setShortBy("category.name") },
            ]}
            type="text"
          />
        </div>
        <div className="p-0 col-auto">
          <IconButton
            color="primary"
            tooltip="Duplicate"
            custom={CopyIcon}
            customStyles={{ width: "9.5px", height: "11px" }}
            onClick={() => messageComming("Duplicate")}
            outline
          />
        </div>
        <div className={`p-0 col-auto ${Equipment && "mt-0"}`}>
          <DropdownCustom
            name=""
            options={[
              { name: "Inventory", action: () => messageComming('Inventory') },
              {
                name: "Equipment",
                action: () => SelectCategory("67807598690173953"),
              },
              { name: "Sales", action: () => messageComming('Sales')},
              {
                name: "Service",
                action: () => onAddService("9854945"),
              },
              { name: "Transport", action: () => messageComming('Transport') , disabled: true },
              {
                name: "Upload CSV file",
                action: () => toggleAddingCSV("28738239230"),
              },
            ]}
            type={{ type: "IconCustom", icon: PlusIcon }}
          />
        </div>
      </div>
    )

}

export default HeaderButtons;

