import ComboBox from '../../../../UI/forms/ComboBox';
import InputFloatingLabel from '../../../../UI/forms/InputFloatingLabel';
import InputSelect from '../../../../UI/forms/InputSelect';
import AssetScheme from './validation';
import { useFormik, Form, FormikProvider } from 'formik';
import { AssetModel, warehouse, purchase, External, PickupInfo } from '../../../../../models/ItemModel';
import IconButton from '../../../../UI/buttons/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { CreateAsset } from '../../../../../store/stockpile/stockpileSlice';
import { setAsset, updateValuesAssets, setFormNameFile, setAssetExternal, setFormDate, setFormsCount, setFormsExternalCount } from '../../../../../store/stockpile/createFormSlice';
import FormAsset from './Form';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';

import AddMore from '../../../../UI/AddMore';
import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import FormAssetExternal from './FormExternal';
import Tooltip from '@mui/material/Tooltip';

interface Props {
  images: any,
  objectPick?: any,
  setObjectPick?: any,
  dateCount?: any,
  setDateCount?: any,
  scrollAction?:any,
  currentTab?:any,
  setCurrentTab?:any
  refd?:any
}

const AssetData = (props: Props) => {

  const dispatch = useDispatch()
  const data = useSelector((state: RootState) => state.createFormSlice.AssetsData);
  const formsCountDirect = useSelector((state: RootState) => state.createFormSlice.formsCount);
  const formsCountExternal = useSelector((state: RootState) => state.createFormSlice.formsExternalCount);

  const ArrayArrays = useSelector((state: RootState) => state.createFormSlice.DatesData);

  const DirectAssets = useSelector((state: RootState) => state.createFormSlice.AssetsData);

  const images: Array<string> = props.images
const currentTab = props.currentTab 
const setCurrentTab = props.setCurrentTab
  const formsCount = currentTab == 0 ? formsCountDirect : formsCountExternal


  const [submitState, setSubmit] = useState<any>(() => { });
  const dateCount = props.dateCount
  const setDateCount = props.setDateCount

  const fieldRef:any = useRef();

  const setImages = (element: any) => {
    images.length == DirectAssets.length ? images[images.length - 1] = element : images.push(element);

  }



  const tabsContent: (index: number, ref: any) => JSX.Element[] = (index, ref) => [
    <FormAsset
      setImages={setImages}
      dateCount={dateCount}
      setDateCount={setDateCount}
      index={index}
      ref={ref}
    />,
    <FormAssetExternal
      index={index}
      setImages={setImages}
    />,
  ];
  const styleFab: any = {
    marginTop: '-10%',
    right: '5%',
    bottom: "50%",
    marginLeft: '100%',
    position: 'absolute',
    background: '#6b8ab8'
  };

  const [tooltipOpen, setTooltip] = useState(false)

  const toggle = () => {
    setTooltip(!tooltipOpen)
  }

  return (
    <Box>
      <div className="container-fluid px-1 mt-5 m-0 hiddenScroll"
       style={
        {
          maxHeight: '65vh',
          overflowY: 'scroll',
          overflowX: 'hidden'
        }
      }
      ref={props.refd}
      >
        <div className='pe-5' style={{ marginBottom: 10, marginLeft: '-.6%' }} >
          <button onClick={setCurrentTab.bind(null, 0)} className="btn btn-primary col-auto mx-2 btn-fitcontent">
            Direct Owner
          </button>
          <button onClick={setCurrentTab.bind(null, 1)} className="btn btn-default col-auto mx-2 btn-fitcontent">
            External Owner 
          </button>
        </div>

        {
          formsCount.map((item, index) => {

            return (
              <div className='p-0 m-0' >
                {tabsContent(index, index == 1 ? fieldRef:null)[currentTab]}
                <hr />
              </div>
            )
          })
        }

      </div>

    </Box>
  );
};

export default AssetData;
