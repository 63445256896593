type ListItemProps = {
  image: string;
  title: string;
  itemId: string;
  count1?: number;
  count2?: number;
  active?: boolean;
  subtitle?: string;
  onClick?: (itemId: string) => void;
} & ({
  onCheckboxSelect: (id: string) => void;
  onCheckboxUnselect: (id: string) => void;
} | {
  onCheckboxSelect?: undefined;
  onCheckboxUnselect?: undefined;
})

const ListItem: React.FC<ListItemProps> = ({
  itemId,
  image,
  title,
  active,
  count1,
  count2,
  subtitle,
  onClick,
  onCheckboxSelect,
  onCheckboxUnselect,
}) => {
  return (
    <div className={"container-fluid border-top p-3 py-2 dashboard-listitem-item cursor-pointer" + (active ? " active" : "")} onClick={() => { onClick?.(itemId) }}>
      <div className="row align-items-center">
        {onCheckboxSelect && onCheckboxUnselect && (
          <div className="col-auto p-0 ps-2">
            <input onChange={(e) => e.target.checked ? onCheckboxSelect(itemId) : onCheckboxUnselect(itemId)} className="form-check-input" type="checkbox" />
          </div>
        )}

        <div
          className={`col d-flex p-0 text-truncate ${
            (onCheckboxSelect || onCheckboxUnselect) ? 'ms-3' : 'ms-2'
          }`}
        >
          {image && image !== '' ? (
            <img src={image} alt={`${title} Avatar`} className="avatar2" />
          ) : (
            <span className="align-items-center d-flex fs-4 justify-content-center avatar2 text-primary bg-contrast">
              {title[0]}
            </span>
          )}

          <div className="ms-2">
            <p className="m-0 text-black typo-body dashboard-listitem-title">
              {title}
            </p>
            {subtitle && (
              <p className="m-0 text-black typo-body dashboard-listitem-subtitle">
                {
                subtitle
                }
              </p>
            )}
          </div>
        </div>

        {(count1 || count2) && (
          <div className="col-auto p-0 pe-1">
            {count1 && (
              <p className="m-0 dashboard-listitem-trailing typo-body bg-primary my-1">
                {count1}
              </p>
            )}
            {count2 && (
              <p className="m-0 dashboard-listitem-trailing typo-body bg-success my-1">
                {count2}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ListItem;
