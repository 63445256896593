
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    FullWidth: false,
    ShowAddItem: false,
    ShowEditItem: false,
    ShowAddItemService: false,
    ShowEditItemService: false,
    ShowEditItemPackage: false ,
    ShowAssetList: false,
    ShowAddUploadCSV: false,
    ShowAddAsset: false,
    ShowEditAsset: false,
    checkItemFormGeneralDataAlert: false,
    EquipmentItem: false,
    selectedState: 0,
    AccesoryEdit: false,
    PreviewCanvas: [
        /// objeto por cada form con dropzone
    ]
};

const recursosVisualesSlice = createSlice({
    name: 'RecursosVisuales',
    initialState,
    reducers: {
        setFullWidth: (state, action) => {
            state.FullWidth = action.payload
        },
        setShowAddItem: (state, action) => {
            state.ShowAddItem = action.payload
        },
        setShowAddItemService: (state, action) => {
            state.ShowAddItemService = action.payload
        },
        setShowAddUploadCSV: (state, action) => {
            state.ShowAddUploadCSV = action.payload
        },
        setShowAssetLis: (state, action) => {
            state.ShowAssetList = action.payload
        },
        setCheckItemFormAlert: (state, action) => {
            state.checkItemFormGeneralDataAlert = action.payload
        },
        setShowEditItem: (state, action) => {
            state.ShowEditItem = action.payload
        },
        setShowEditItemService: (state, action) => {
            state.ShowEditItemService = action.payload
        },
        setShowEditItemPackage: (state, action) => {
            state.ShowEditItemPackage = action.payload
        },
        setShowAddAsset: (state, action) => {
            state.ShowAddAsset = action.payload
        },
        setShowEditAsset: (state, action) => {
            state.ShowEditAsset = action.payload
        },
        setEquipmentItem: (state, action) => {
            state.EquipmentItem = action.payload
        },
        setselectedState: (state, action) => {
            state.selectedState = action.payload
        },
        setAccesoryEditView: (state, action) => {
            state.AccesoryEdit = action.payload
        },
        setPreviewCanvas: (state,action) => {
            state.PreviewCanvas = action.payload
        }
    }
});

export const { setPreviewCanvas , setFullWidth, setShowEditItemPackage ,setShowAddItem, setAccesoryEditView ,setShowAddAsset, setselectedState, setEquipmentItem, setShowEditAsset, setCheckItemFormAlert, setShowEditItemService, setShowAddItemService, setShowEditItem, setShowAssetLis, setShowAddUploadCSV } = recursosVisualesSlice.actions;
export default recursosVisualesSlice.reducer;
