import React from 'react';

interface Props {
    onSubmitControlForm: any;
    setOnSubmitControlForm: any;
}

const AssetsTab: React.FC<Props> = (props: Props) => {
    return (
        <div className='d-flex justify-content-center align-items-between'>
        <div className="col-md-6 mt-5">
            <div className="row">
            <div className="d-flex col-md-12 container-input-file-file">
                <div className="custom-input-file-file col-md-6 col-sm-6 col-xs-6">
                <input
                    type="file"
                    id=""
                    className="input-file-file "
                    value=""
                    onChange={(val) =>
                    props.setOnSubmitControlForm({
                        ...props.onSubmitControlForm,
                        project_asset_1: val.currentTarget.files?.item(0),
                    })
                    }
                />
                <span>Select file </span>
                </div>
            </div>
            </div>
            <div className="cursor-pointer mt-2">
                <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6"></i>
                <small className=" text-primary "> ADD PROJECT ASSETS</small>
            </div>
        </div>
        <div className="col-md-6 mt-5">
            <div className="row">
            <div className="d-flex col-md-12 container-input-file-file">
                <div className="custom-input-file-file col-md-6 col-sm-6 col-xs-6">
                <input
                    type="file"
                    id=""
                    className="input-file-file "
                    value=""
                    onChange={(val) =>
                    props.setOnSubmitControlForm({
                        ...props.onSubmitControlForm,
                        project_asset_2: val.currentTarget.files?.item(0),
                    })
                    }
                />
                <span>Select file </span>
                </div>
            </div>
            </div>
        </div>
      </div>
    );
};

export default AssetsTab;
