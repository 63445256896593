import { useState, useRef, useEffect } from 'react';
import ConfirmModal from '../../Modals/ConfirmModal';
import Checkbox from '../../UI/forms/Checkbox';
import ProgressbarTitle from '../../UI/ProgressBarForm';
import AssetData from './tabs/AssetData/AssetData'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store'
import checkFields from '../../../util/CheckFields'
import { CreateItem, CreateAsset, CreateAccesories, CreateRates, RefreshForNewItem, setManualAssetData } from '../../../store/stockpile/stockpileSlice';
import { AccesoriesModel, AssetModel, External, PickupInfo, RatesModel } from '../../../models/ItemModel';
import StepButton from 'src/components/UI/buttons/stepButton';
import { setShowAddItem, setCheckItemFormAlert, setselectedState } from 'src/store/stockpile/RecursosVisualeSlice';
import { EmptyForm, setAsset, setAssetExternal, setFormDate, setFormNameFile, setFormsCount, setFormsExternalCount } from 'src/store/stockpile/createFormSlice';
import { useToast } from 'src/hooks/useToast';
import { useRequest } from 'src/hooks/useRequest';
import { useParams } from 'react-router-dom';
import { getAssetForID, getCommentsForAsset, setDataAsset } from 'src/store/stockpile/selectAssetsSlice';
import moment from 'moment';
import { Fab, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';


interface Props {
  onFinish: () => void;
  TypeForm?: any;
}

const tabs: string[] = [
  'Asset data'
];


const AddAsset: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const toast = useToast()
  const params = useParams<{ itemId: string; }>();

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [images, setImagesForm] = useState<Array<any>>([]);
  const [resetAllInputs, setResetAllInputs] = useState<any>(false);
  const [checkItemForm, setCheckItemForm] = useState<any>(false);
  const { data: ITEM_DATA } = useRequest<any>({ url: 'stockpile/items/' + params.itemId });
  const AssetsActualItem: Array<any> = useSelector((state: RootState) => state.stockpileSlice.AssetData);
  const [markasComplete, setMarkasComplete] = useState<boolean>(false);

  const DataAsset: Array<any> = useSelector((state: RootState) => state.createFormSlice.AssetsData);


  const [dateCount, setDateCount] = useState<
    { type: string; date: string }[]
  >([{ type: 'date of purchase', date: '' }]);

  const [objectPick, setObjPick] = useState<PickupInfo>({
    country: '',
    state: '',
    city: '',
    zip: '',
    adress: ''
  })

  ///Loading
  const Loading = useSelector((state: RootState) => state.stockpileSlice.loading);


  /////CREATE ITEM CHECK
  const ItemID = useSelector((state: RootState) => state.stockpileSlice.ItemResponse);

  /////Errors 
  const Gdta = useSelector((state: RootState) => state.createFormSlice.GeneralErrors);
  const Asst = useSelector((state: RootState) => state.createFormSlice.AssetsErrors);
  const Rtes = useSelector((state: RootState) => state.createFormSlice.RatesErrors);

  /////FORMS
  const data = useSelector((state: RootState) => state.createFormSlice.data);
  const sizesG = useSelector((state: RootState) => state.createFormSlice.sizesG);
  const rates: any = useSelector((state: RootState) => state.createFormSlice.RatesData);
  const mandatory = useSelector((state: RootState) => state.createFormSlice.AccesoriesMandatoryData)
  const optional = useSelector((state: RootState) => state.createFormSlice.AccesoriesOptionalData)
  const Accesories = {
    mandatory: [...mandatory],
    optional: [...optional]
  } as AccesoriesModel

  //const accesories = useSelector((state: RootState) => state.createFormSlice.AccesoriesData);
  const DirectAssets = useSelector((state: RootState) => state.createFormSlice.AssetsData);
  const ExternalAssets = useSelector((state: RootState) => state.createFormSlice.AssetsDataExternal);

  const progress = {
    ...data, ...rates, ...DirectAssets, ...ExternalAssets
  }

  const [currentTabType, setCurrentTabType] = useState<number>(0);

  useEffect(() => {
    dispatch(EmptyForm([]))
    setResetAllInputs(true)
  }, [])

  useEffect(() => {
    [data].map((item) => {
      if (item.category) {
        setCheckItemForm(true)
      } else { setCheckItemForm(false) }

      if (item.name) {
        setCheckItemForm(true)
      } else { setCheckItemForm(false) }
    })

  }, [checkItemForm, data])


  const red: any = useRef<HTMLAreaElement>()

  const scrollAction = () => {
    setTimeout(() => {
      red?.current?.scrollTo({
        behavior: 'smooth',
        top: 15000
      })
    }, 500)
  }

  // const percenCompletion = (1 - checkFields(progress).percent)
  const percenCompletion = 0.05

  const tabsContent: JSX.Element[] = [
    <AssetData
      images={images}
      objectPick={objectPick}
      setObjectPick={setObjPick}
      dateCount={dateCount}
      setDateCount={setDateCount}
      scrollAction={scrollAction}
      currentTab={currentTabType}
      setCurrentTab={setCurrentTabType}
    />
  ];

  const onCancelModalClose = (value?: any) => {
    setIsCancelling(false);

    if (value == '') {
      props.onFinish()
      return;
    }

  };

  const prevStep = () => {
    setCurrentTab((prev) => {
      if (prev > 0) {
        return prev - 1;
      }
      return prev;
    });
  };

  const nextStep = () => {
    setCurrentTab((prev) => {
      if (prev < tabs.length - 1) {
        return prev + 1;
      }
      return prev;
    });
  };

  const ActionSelectAsset = async (ItemId: any, index: any) => {
    const response: any = dispatch(getAssetForID(ItemId))
    if (response?.payload) {
      dispatch(getCommentsForAsset(ItemId))
    }
    dispatch(setselectedState(index))
  }

  const setItem = async () => {
    if (!Asst) {

      ////Create Assets
      let TotalAssets = [...DirectAssets, ...ExternalAssets]

      if (TotalAssets.length > 0) {
        let AssetsWithItem = TotalAssets.map((asset: any) => {
          const entries = Object.entries(asset).length
          asset = {
            ...asset,
            purchase: {
              ...asset?.purchase,
              price: parseInt(asset?.purchase?.price),
              date: moment(asset.purchase?.date).format()
            },
            end_of_life: asset?.end_of_life ? moment(asset?.end_of_life).format() : null,
            warranty_expiration: asset?.warranty_expiration ? moment(asset?.warranty_expiration).format() : null,
            item: ITEM_DATA?._id
          }
          if (entries) { return asset }
        });
        const resAssets: any = await dispatch(CreateAsset([
          AssetsWithItem, images
        ]))
        if (resAssets?.payload) {
          resAssets?.payload.map((asset: any) => {
            dispatch(setManualAssetData(asset))
          })
          ActionSelectAsset(resAssets?.payload[0]?._id, resAssets?.payload.length - 1)
          dispatch(setDataAsset({ ...resAssets?.payload[0]?.data }))

        }

      }

      toast.success('Save Assets succesfull')
      props.onFinish()
      dispatch(EmptyForm([]))


    } else {
      if (Asst) {
        let cpy = [Asst]
        cpy.map((error) => {
          toast.error('error ' + Object.keys(error)[0] + ' in Asset Data')
        })
      }
    }

  }


  const base = {
    serial: '65656565',
    is_external: 'false'
  }

  const baseExternal = {
    serial: '',
    purchase: {
      date: '',
      origin: '',
      price: ''
    },
    warehouse: {
      warehouse: '',
      location: '',
      shelf_id: ''
    },
    warranty_expiration: '',
    is_external: true,
    condition: '',
    external: {
      prices: {
        hour: '',
        daily: '',
        week: '',
        sale: '',
        holiday_hour: '',
        holiday_day: '',
        overtime_hour: '',
        holiday_overtime_hour: ''
      },
      company_owner: '',
      pickup_location: '',
    } as External,
    sizes: {
      name: 'dw',
      width: '',
      height: '',
      weight: '',
      length: '',
      weight_unit: '',
      sizes_unit: ''
    }
  } as AssetModel

  const AddOtherForm = () => {
    let dat = dispatch(setAsset(base))
  }

  const AddOtherFormExternal = () => {
    let dat = dispatch(setAssetExternal(baseExternal))

  }

  const AddForm = () => {

    if (currentTabType == 0) {
      AddOtherForm()
      dispatch(setFormsCount([]))
      dispatch(setFormDate([]))
      dispatch(setFormNameFile([]))

    }
    if (currentTabType == 1) {
      AddOtherFormExternal()
      dispatch(setFormsExternalCount([]))
    }

    scrollAction()


  }
  const styleFab: any = {
    marginTop: '-10%',
    right: '4%',
    bottom: "4%",
    marginLeft: '100%',
    position: 'absolute',
    background: '#6b8ab8'
  };

  const [tooltipOpen, setTooltip] = useState(false)

  const toggle = () => {
    setTooltip(!tooltipOpen)
  }

  return (
    <>
      {isCancelling && (
        <ConfirmModal
          title="Are you sure?"
          description="This new item will be discarted"
          confirmText="KEEP EDITING"
          cancelText="DISCARD ITEM"
          onClose={onCancelModalClose}
        />
      )}

      <div className="container-fluid h-100">
        <div className="row" style={{ height: '92vh'  }}>
          <Tooltip title="Add Asset" placement="top-start">
            <Fab color="primary" aria-label="add" style={styleFab} onClick={
              () => {
                AddForm()
              }
              
            } onMouseDown={toggle} className='mb-1 ' >
              <AddIcon />
            </Fab>
          </Tooltip>
          <div className="col-12 card h-100" ref={red} >

            {/* Top actions */}
            <div className="row justify-content-between align-items-center p-2
            col-lg-12 col-md-auto col-sm-12 col-12 align-self-center
            "
              style={{ position: 'absolute', background: 'white', zIndex: 1 }}>

              <div className='p-0 m-0 border-0 col-auto row'>

                <button
                  onClick={setIsCancelling.bind(null, true)}
                  className="btn btn-danger text-white typo-body col-auto border-0">
                  Cancel
                </button>
            
              </div>

              <h4
                className='text-primary col-auto' >Create Asset</h4>

              <div className='p-0 m-0 border-0 col-auto row justify-content-between'>

           

                <button onClick={setItem} className="btn btn-success typo-body col-auto border-0 text-white">
                  Save asset
                </button>

              </div>

            </div>

            {/* Tab View */}
            <div className='h-100 custom-scrollbar overflow-y mt-3'>
            <div className={`container ${Loading && 'disabledElement'} mt-5 `}  >
              <div className="row mt-4">
                <div className="col">
                  <ul className="nav nav-tabs mt-3 justify-content-between">
                    <div className="row">
                      {tabs.map((item, index) => {
                        return (
                          <li
                            key={index}
                            className="nav-item col-auto p-0"
                            onClick={!Gdta ? !checkItemForm ? () => { dispatch(setCheckItemFormAlert(true)) } : setCurrentTab.bind(null, index) : () => { }}
                          >
                            <p
                              className={`nav-link typo-body user-select-none ${currentTab === index ? 'active' : !Gdta ? !checkItemForm ? 'disabled' : '' : 'disabled'
                                }`}
                              aria-current="page"
                            >
                              {item}
                            </p>
                          </li>
                        );
                      })}
                    </div>
                  </ul>

                  <div className="row justify-content-end mt-2">
                    <div className="col-auto">
                      <ProgressbarTitle value={
                        [
                          DirectAssets[0]?.serial,
                          DirectAssets[0]?.age,
                          DirectAssets[0]?.condition,
                          DirectAssets[0]?.digital_receipt,
                          DirectAssets[0]?.end_of_life,
                          DirectAssets[0]?.has_digital_receipt,
                          DirectAssets[0]?.is_external,
                          DirectAssets[0]?.purchase?.price,
                          DirectAssets[0]?.purchase?.origin,
                          DirectAssets[0]?.warehouse?.shelf_id,
                          DirectAssets[0]?.warehouse?.location,
                          DirectAssets[0]?.warehouse?.warehouse,
                          DirectAssets[0]?.warranty_expiration,
                          DirectAssets[0]?.sizes?.height,
                          DirectAssets[0]?.sizes?.weight,
                          DirectAssets[0]?.sizes?.width,
                          DirectAssets[0]?.sizes?.length
                        ]
                      } 
                      markedComplete={markasComplete}
                      />
                      <div style={{ marginLeft: '14%' }}>
                        <Checkbox
                          title="Mark as completed"
                          value={markasComplete}
                          onChange={(value) => { setMarkasComplete(value) }}
                        />
                      </div>
                    </div>
                  </div>

                  {tabsContent[currentTab]}

            
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default AddAsset;
