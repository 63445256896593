import { useState } from "react";
import ItemModel from "src/models/ItemModel";


interface UseCustomState {
    cargado: boolean;
    selectedItems: ItemModel[];
    search: string;
    scanModal: boolean;
    code: any;
    supFilter: any;
    itemSelected: string;
    dataSelected: any;
    rangeSupplier: string;
    rangeClient: any;
    totalQuote: number;
    qty: number;
    costPerUnit: number;
    chargePerUnit: string;
    marginPerUnit: number;
    totalMargin: any;
    totalCost: number;
    exempt: boolean;
    discount: boolean;
    quickadd: boolean;
    ///seters 
    setCargado: (value: boolean) => void;
    setSelectedItems: (items: ItemModel[]) => void;
    setSearch: (value: string) => void;
    setScanModal: (value: boolean) => void;
    setCode: (value: any) => void;
    setSupFilter: (value: any) => void;
    setItemSelected: (value: string) => void;
    setDataSelected: (value: any) => void;
    setRangeSupplier: (value: string) => void;
    setRangeClient: (value: any) => void;
    setTotalQuote: (value: number) => void;
    setQty: (value: number) => void;
    setCostPerUnit: (value: number) => void;
    setChargePerUnit: (value: string) => void;
    setMarginPerUnit: (value: number) => void;
    setTotalMargin: (value: any) => void;
    setTotalCost: (value: number) => void;
    setExempt: (value: boolean) => void;
    setDiscount: (value: boolean) => void;
    setQuickAdd: (value: boolean) => void;
  }

const useCustomState = (): UseCustomState => {
    const [cargado, setCargado] = useState(false);
    const [selectedItems, setSelectedItems] = useState<ItemModel[]>([]);
    const [search, setSearch] = useState<string>("");
    const [scanModal, setScanModal] = useState<boolean>(false);
    const [code, setCode] = useState<any>("");
    const [supFilter, setSupFilter] = useState<any>("");
    const [itemSelected, setItemSelected] = useState<string>('');
    const [dataSelected, setDataSelected] = useState<any>({});
    const [rangeSupplier, setRangeSupplier] = useState<string>('');
    const [rangeClient, setRangeClient] = useState<any>('');
    const [totalQuote, setTotalQuote] = useState<number>(0);
    const [qty, setQty] = useState<number>(0);
    const [costPerUnit, setCostPerUnit] = useState<number>(0);
    const [chargePerUnit, setChargePerUnit] = useState<string>("0");
    const [marginPerUnit, setMarginPerUnit] = useState<number>(0);
    const [totalMargin, setTotalMargin] = useState<any>('');
    const [totalCost, setTotalCost] = useState<number>(0);
    const [exempt, setExempt] = useState<boolean>(false);
    const [discount, setDiscount] = useState<boolean>(false);
    const [quickadd, setQuickAdd] = useState<boolean>(false)
  
    return {
      cargado,
      setCargado,
      selectedItems,
      setSelectedItems,
      search,
      setSearch,
      scanModal,
      setScanModal,
      code,
      setCode,
      supFilter,
      setSupFilter,
      itemSelected,
      setItemSelected,
      dataSelected,
      setDataSelected,
      rangeSupplier,
      setRangeSupplier,
      rangeClient,
      setRangeClient,
      totalQuote,
      setTotalQuote,
      qty,
      setQty,
      costPerUnit,
      setCostPerUnit,
      chargePerUnit,
      setChargePerUnit,
      marginPerUnit,
      setMarginPerUnit,
      totalMargin,
      setTotalMargin,
      totalCost,
      setTotalCost,
      exempt,
      setExempt,
      discount,
      setDiscount,
      quickadd,
      setQuickAdd
    };
  };

  export default useCustomState