import { Modal } from "reactstrap";
import InputFloatingLabel from "../../UI/forms/InputFloatingLabel";
import { BlackbookService } from "src/services/blackbook";
import { useState } from "react";
import { useToast } from "../../../hooks/useToast";
import SelectInput from "src/components/UI/forms/Select";
import { ICategory } from 'src/models/Category';
import { useRequest } from 'src/hooks/useRequest';

interface Props {
    view: boolean;
    setView: any;
    subCategory?: any
    category?: any
}

const AddCategory: React.FC<Props> = (props) => {

    const toast = useToast();


    const [name, setname] = useState('')

    const [type, settype] = useState(true)

    const [isOpne, setopen] = useState(true)

    const [subcategory, setsubcategory] = useState<any>(false)


    const { data: categories, error } = useRequest<ICategory[]>({ url: 'categories/contacts/all' });


    const Create = () => {

        try {
            type && BlackbookService.AddCategory({
                name: name
            })

            ///Create Sub category

            !type && BlackbookService.AddCategory({
                category: subcategory ,
                name: name
            })

            toast.success("Category created successfully.");

        } catch (error) {

            toast.error(error);

        }

    }
    const handleType = (e: any) => {
        settype(e)
    }


    return (
        <Modal isOpen={props.view} toggle={() => { }} centered>
            <div className="container text-center">
                <div className="row p-4">
                    <div className="col-12">
                        <p className="ModalTitle"
                            style={{
                                fontSize: '24px', fontWeight: 'bold',
                                justifyContent: 'center', alignSelf: 'center',
                                display: 'flex', marginLeft: '1%', color: '#344469'
                            }}
                        >
                            Create category
                        </p>
                    </div>
                    <div className="col-12 mt-1">
                        <SelectInput
                            value={type}
                            placeholder="Select a type category"
                            onChange={(val: string) => handleType(val)}
                            options={
                                [{
                                    label: 'category', value: true
                                },
                                {
                                    label: 'subcategory', value: false
                                }]
                            }
                        />
                    </div>
                    {
                        !type && (
                            <div className="col-12 mt-1">
                                <SelectInput
                                    value={type}
                                    placeholder="Select an category"
                                    onChange={(val: string) => 
                                        {setsubcategory(val)
                                         setopen(false)
                                         }}
                                    options={
                                        categories?.map((category) => ({
                                            label: category.name,
                                            value: category._id,
                                        }))}
                                    isOpen={isOpne}
                                />
                            </div>
                        )
                    }
                    <div className="col-12 mt-3">
                        <InputFloatingLabel
                            onChange={(e) => { setname(e) }}
                            value={name}
                            placeHolder="category name"
                            isRequired
                        />
                    </div>
                    <div className="col-12 mt-3">
                        <div className="row justify-content-evenly">
                            <button
                                className="btn btn-danger text-white col-auto"
                                onClick={() => props.setView(false)}
                            >
                                Close
                            </button>

                            <button
                                className="btn btn-success text-white col-auto"
                                onClick={() => {
                                    Create()
                                    props.setView(false)
                                }
                                }
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AddCategory