
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    nameFileCSV: '',
    FileValueCSV: '',
    files:[]
};

const CreateAssetCsvForm = createSlice({
    name: 'CreateAssetsCsvForm',
    initialState,
    reducers: {
        setCSV: (state, action) => {
            state.FileValueCSV = action.payload
        },
        setNameFileCSV:(state, action) => {
            state.nameFileCSV = action.payload
        },
        setFiles:(state, action) => {
            state.files = action.payload
        }
    }
});

export const { setNameFileCSV , setCSV , setFiles } = CreateAssetCsvForm.actions;
export default CreateAssetCsvForm.reducer;
