import { Modal } from 'reactstrap';
import InputFloatingLabel from 'src/components/UI/forms/InputFloatingLabel';
import Checkbox from '../../UI/forms/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import ClientPersonalValidation, { ClientData } from '../Contacts/AddContact/tabs/Client/PersonalInformation/validation';
import { RootState } from "src/store/store";
import { toBase64 } from "src/util/converToBase64";
import { setArray, setClientType, setSupplierType, setVenueType } from "src/store/blackbook/ViewBlackbookResources";
import { BlackbookService } from 'src/services/blackbook'
import { createContactForm } from "src/store/blackbook/createContactSlice";
import { useToast } from 'src/hooks/useToast';
import { AxiosResponse } from 'axios';
import { ApiError } from '../../../models';
import InputSelect from 'src/components/UI/forms/InputSelect';
import { useEffect, useState } from 'react';

type Props = {
    onClose: (value?: boolean) => void;
    onConfirm: (value?: boolean) => void;
    title: string;
    description: string;
    organizationId?: any;
    seter?: any
} & ({
    confirmText: string;
    cancelText: string;
    buttons?: undefined;
} | {
    buttons: React.ReactNode;
    confirmText?: undefined;
    cancelText?: undefined;
})

const CreateClientForOrganization: React.FC<Props> = ({
    onClose,
    onConfirm,
    title,
    buttons,
    cancelText,
    confirmText,
    organizationId,
    seter
}) => {

    const dispatch = useDispatch();
    const toast = useToast();
    type EmailKeys = 'primary' | 'personal';

    const data = useSelector((state: RootState) => state.createContactFormSlice.data);
    const { clientType, supplierType, venueType, ArrayTypes } = useSelector((state: RootState) => state.ViewBlackbookResources);
    const [emails, setEmails] = useState<{ type: EmailKeys }[]>([{ type: 'primary' }, { type: 'personal' }]);


    const formik = useFormik<ClientData>({
        initialValues: {
            email: '',
            first_name: '',
            last_name: '',
            contact_since: data.contact_since || '',
            ...(data.data || {}),
        },
        validationSchema: ClientPersonalValidation,
        onSubmit: () => { },
    });


    const handleChange = (fieldName: string, value: any, path = 'data') => {
        formik.setFieldValue(fieldName, value);

        /* Files will be saved as strings instead of File, and then they will be re-coded to Files in the request. */
        if (value instanceof File) {
            toBase64(value as File).then(base64 => {
                /* @ts-ignore */
                dispatch(createContactForm.setField(fieldName, base64 as string, 'data'));
                /* @ts-ignore */
                dispatch(createContactForm.setField(fieldName, value.name, 'files'));
            }).catch(err => {
                console.log(err);
            })

            return;
        }

        /* @ts-ignore */
        dispatch(createContactForm.setField(fieldName, value, path));
    }

    const handleChangeType = (fieldName: string, value: any) => {

        let arrayTy = ArrayTypes
        let resultado: Array<any> = []


        if (arrayTy?.includes(value)) {
            resultado = arrayTy.filter(type => type !== value);
        } else {
            resultado = [value, ...arrayTy]
        }

        formik.setFieldValue(fieldName, resultado);
        dispatch(setArray(resultado))
        /* @ts-ignore */
        dispatch(createContactForm.setField(fieldName, resultado));

    }

    const CreateData = () => {

        const dataCopy = JSON.parse(JSON.stringify(data.data));


        BlackbookService.CreateContact(
            {
                data: dataCopy,
                category: '104861306456114166',
                contact_since: data.contact_since,
                ...(data.note ? { note: data.note } : {}),
            },
            data.files
        )
            .then((contact) => {
                //  setorganizations([...organizations, contact])
                seter([contact])
                dispatch(createContactForm.clearData({}));
                dispatch(setSupplierType(false))
                dispatch(setVenueType(false))
                dispatch(setClientType(false))



                toast.success("Client created successfully.");

                //Trigger Modal
                return onClose()

                // return history.push(`/blackbook/${contact._id}`);
            })
            .catch((err: AxiosResponse<ApiError>) => {
                return toast.error(err.data.message);
            });
    }

    useEffect(() => {
        dispatch(createContactForm.setField('organization', organizationId))
    }, [])


    return (
        <Modal isOpen={true} toggle={onClose.bind(null, undefined)} centered>
            <div className="container text-center">
                <div className="row p-4">
                    <div className="col-12">
                        <h2 className="font-weight-bold text-secondary typo-display">
                            {title}
                        </h2>
                    </div>
                    <div className="col-12 mt-3">
                        <InputSelect
                            select={{
                                value: `${formik.values.title}`,
                                onChange: (value: string) => { handleChange('title', value) },
                                options: [
                                    { value: 'Mr', label: 'Mr' },
                                    { value: 'Ms', label: 'Ms' },
                                    { value: 'Mrs', label: 'Mrs' },
                                    { value: 'Miss', label: 'Miss' },
                                ],
                            }}
                            input={{
                                isRequired: true,
                                placeHolder: 'First name',
                                value: formik.values.first_name,
                                errorMessage: formik.errors.first_name,
                                onChange: (val) => { handleChange('first_name', val) },
                            }}
                        />
                    </div>
                    <div>
                        {emails.map((email, index) => {
                            const key = email.type === 'primary' ? 'email' : 'personal_email';
                            return (
                                <InputSelect
                                    key={email.type}
                                    select={{
                                        value: `${email.type}`,
                                        onChange: (value: string) => {
                                            if (emails.some(email => email.type === value)) {
                                                toast.warn('Email type already added.');
                                                return;
                                            }

                                            const val = formik.values?.[key];
                                            handleChange(key, val);

                                            emails.splice(index, 1, { type: value as EmailKeys });
                                        },
                                        options: [
                                            { value: 'primary', label: 'Work' },
                                            { value: 'personal', label: 'Personal' },
                                        ],
                                    }}
                                    input={{
                                        placeHolder: 'Email',
                                        value: formik.values?.[key],
                                        errorMessage: formik.errors?.[key],
                                        isRequired: email.type === 'primary',
                                        onChange: (val) => { handleChange(key, val) },
                                    }}
                                />
                            )
                        })}
                    </div>
                    <div className="col-12">
                        <InputFloatingLabel
                            isRequired
                            placeHolder="Phone"
                            value={formik.values.phone}
                            errorMessage={formik.errors.phone}
                            onChange={(val) => handleChange('phone', val)}
                        />
                    </div>

                </div>


                <div className="col-12 mt-3 mb-2">
                    <div className="row justify-content-evenly">
                        {buttons !== undefined ? buttons : (
                            <>
                                <button
                                    className="btn btn-success text-white col-auto"
                                    onClick={() => {
                                        CreateData()
                                    }}
                                >
                                    {confirmText}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CreateClientForOrganization;
