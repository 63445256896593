import { useCallback, useEffect, useMemo, useState } from 'react'
import Dropzone, { useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux';
import { Progress } from "reactstrap";
import ImageCropper from 'src/components/ImageCropper';
import { setPreviewCanvas } from 'src/store/stockpile/RecursosVisualeSlice';
import { RootState } from 'src/store/store';
import { setTimeout } from 'timers';
import { sha1 } from 'crypto-hash';
import generateID from 'src/util/generateID';
import TrashIcon from "../../../assets/icons/trash.svg";
import EditIcon from "../../../assets/icons/edit.svg";



interface Props {
    setFile: any,
    files: any,
    maxFiles?: any,
    errors: any,
    file?: any,
    setImage?: any,
    eventCropper?: any,
    vieCropper?: any,
    setvieCropper?: any,
    nameFile?: any
    simpleFile?: any
    Delete?: any
    setDelete?: any
}

const baseStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    paddingLeft: '0px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#6b8ab8'
};

const acceptStyle = {
    borderColor: '#6b8ab8'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const thumbsContainer: any = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb: any = {
    display: 'inline-flex',
    borderRadius: 10,
    border: '3px solid #eaeaea',
    marginBottom: 8,
    marginRight: '-1.5%',
    minWidth: 110,
    maxWidth: 110,
    height: 100,
    padding: 0,
    boxSizing: 'border-box'
};

const thumbInner: any = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    borderRadius: 10
};

const img = {
    display: 'block',
    maxWidth: '500px',
    height: '100%'
};

const DropZone: React.FC<Props> = (props) => {

    const dispatch = useDispatch()



    const [percent, setPercent] = useState<any>(null)
    const [previewCanvasRef, setpreviewCanvasRef] = useState<any>(false)
    const [RejectesFiles, setRejectesFiles] = useState<Array<any>>([])
    const [editi, setEditi] = useState<any>(false)

    //  const previewCanvasRef = useSelector((state:RootState) => state.RecursosVisualeSlice.PreviewCanvas) 

    const maxSize = 20000000000

    useEffect(() => {

    }, [])



    const files = props.files
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        acceptedFiles,
    } = useDropzone({
        maxFiles: props.maxFiles,
        capture: 'camera',
        onDrop: (acceptedFiles) => {
            props.setFile(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })))
        }        
    });


    const thumbs = files?.map((file: any) => (
        <>

            <div style={thumb} key={file?.name}>
                <div style={thumbInner} >
                    {
                        !props.setImage && (<img
                            src={file.preview}
                            style={img}
                            // Revoke data uri after image is loaded
                            onLoad={() => { URL.revokeObjectURL(file.preview) }}
                        />)
                    }
                    {
                        props.setImage && previewCanvasRef && (
                            <img
                                src={previewCanvasRef}
                                style={{
                                    width: 120,
                                    height: 120,
                                }}
                            />
                        )
                    }
                </div>

            </div>
            <div className='row' style={{flexDirection: 'column', marginLeft: '2%', paddingTop: '2%'}}>
                <div className="col p-0 justify-content-center ">
                    {props.Delete && <img
                        className="mx-0 "
                        src={TrashIcon}
                        alt=""
                        style={{
                            height: "25.8px",
                            width: "25.8px",
                            color: " #0000",
                            marginTop: "-20px",
                            cursor: 'pointer',
                            marginLeft: '-250px'
                        }}
                        onClick={(e) => {
                            props.setDelete()
                        }}
                    />}
                </div>

                <div className="col p-0 justify-content-center ">
                    {
                        <img
                            className="me-0 "
                            src={EditIcon}
                            alt=""
                            style={{
                                height: "25.8px",
                                width: "25.8px",
                                color: " #0000",
                                cursor: 'pointer',
                                marginTop: '-40px'
                            }}
                            onClick={(e) => {
                                //setEditi(true)
                                props.setFile(files)
                            }}
                        />
                    }
                </div>
            </div>

        </>
    ));




    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach((file: any) => URL.revokeObjectURL(file?.preview));


    }, []);

    const style: any = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject || props.errors ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject,
        props.errors
    ]);

    const WarningMaxFile = () => {

        if (acceptedFiles[0] && acceptedFiles[0].size > maxSize) {
            return true
        }

        return false
    }

    //  const isTooLarge:any = WarningMaxFile()



    return (<>
        <section className="">

            {props.Delete && props.file && <img
                src={TrashIcon}
                alt=""
                style={{
                    height: "25.8px",
                    width: "25.8px",
                    color: " #0000",
                    cursor: 'pointer',
                    zIndex: 1,
                    marginLeft: '96%',
                    marginBottom: '2%'
                }}
                onClick={(e) => {
                    props.setDelete()
                }}
            />}

            <div {...getRootProps({ style })} >



                <input {...getInputProps()} capture="environment" accept='image/*' />
                <p>Drag 'n' drop some files here, or click to select files</p>
                <p className={!props.errors ? `text-primary` : `validation`}>
                    {
                        props.nameFile ? props.nameFile : files.map((file: any, index: any) => (
                            <>
                                {file?.name + ' '}{files.length > 0 &&
                                    index != files.length - 1 && ','}
                            </>
                        ))
                    }</p>

            </div>
            {<Progress color="info" value={percent} />
            }

            {previewCanvasRef == null || props.file ? (<></>) : (<aside style={thumbsContainer}>
                {!props.errors && thumbs}
            </aside>)}


            <p className='validation'>{props.errors}</p>
        </section>
        {!props.file && (<ImageCropper setview={props.setvieCropper} view={props.vieCropper} e={{
            target: {
                files: files
            }
        } as any} setImage={props.setImage} setpreviewCanvasRef={setpreviewCanvasRef}

            setFiles={props.setFile} preview={previewCanvasRef}
        />)}



    </>
    )
}

export default DropZone;

