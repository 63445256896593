import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../../UI/forms/Select";
import { AxiosResponse } from "axios";
import { ApiError } from "../../../../models";
import { UploadError } from "../../../../util";
import { factoryService } from "../../../../services/factory";
import {
  getJobById,
  getQuotesId,
  GetTermsAndConditions,
  GetQuoteVersion,
} from "../../../../store/factory/factoryJobSlice";
import { layoutConfig } from '../../../../store/layout';
import { RootState } from "../../../../store/store";
import { useToast } from "../../../../hooks/useToast";
import { RateCardService } from "../../../../services/rates";
import QuoteEmailModal from "../../../Modals/Factory/Quotes/QuoteEmailModal";
import InputFloatingLabel from "../../../UI/forms/InputFloatingLabel";
import InputDate from "../../../UI/forms/InputDate";
import TextArea from "../../../UI/forms/TextArea";
import Checkbox from "../../../UI/forms/Checkbox";
import moment from "moment";
import QuoteTable from "./Table";
import { ICreateQuote, IElementQuote, QuoteRowType, QuoteUnitsType } from "../../../../models/QuotesModel";
import RateModel, { IRates, RateCardItemModel } from "../../../../models/RateModel";
import SelectRateCard from "../../../Modals/SelectRateCard";
import SelectItem from "../../../Modals/SelectItem";
import ItemModel from "../../../../models/ItemModel";
import QuoteSummary from "./Table/Summary";
import set from "lodash.set";
import get from "lodash.get";
import isEqual from 'lodash.isequal';
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import ModalBrief from "../../FactoryView/tabs/modalBrief/ModalBrief";
import GlobalDiscount from "../../FactoryView/createQuoteForm/modal/applyGlobalDiscount/GlobalDiscount";
import ConfirmModal from "../../../Modals/ConfirmModal";
import EmptyCircle from "../../../UI/EmptyCircle";
import { Col, Row } from "reactstrap";
import TextEditQuill from "../../../UI/forms/TextEditQuill";
import ItemQuotePicker from "../../../Modals/ItemQuotePicker";

interface Props {
}

const CreateQuoteR: React.FC<Props> = (props) => {
  const { idquote }: any = useParams();
  const [initialHeaders, setInitialHeaders] = useState();
  const { state } = useLocation(); // Receive id Job if form is used to create Quote
  const history = useHistory();

  const toast = useToast();
  const dispatch = useDispatch();
  const [currentRateInfo, setCurrentRateInfo] = useState<{ id: string, isChange: boolean, isClientRateCard: boolean, fieldsChanged: any[], rateCardVersion: string, rateCardName: string }>({ id: '', isChange: false, isClientRateCard: false, fieldsChanged: [], rateCardName: '', rateCardVersion: '' });
  const [isOpenCreateNewRateCardModal, setIsOpenCreateNewRateCardModal] = useState<boolean>(false);
  const [isOpenSendEmailModal, setIsOpenSendEmailModal] = useState<boolean>(false);
  const [isOpenSelectRateModal, setIsOpenSelectRateModal] = useState<boolean>(false);
  const [isOpenSelectItemsModal, setIsOpenSelectItemsModal] = useState<boolean>(false);
  const [isOpenBriefModal, setIsOpenBriefModal] = useState<boolean>(false);
  const [isOpenGlobalDiscountModal, setIsOpenGlobalDiscountModal] = useState<boolean>(false);
  const [excemptsTaxes, setExcemptsTaxes] = useState(0)
  const [currentMainRow, setCurrentMainRow] = useState<{ path: string, type: QuoteRowType }>({ path: '', type: QuoteRowType.HEADER });
  const [injectedSub , injectSub] = useState<any>(0)
  //temp
  const [termsInfo, setTermsInfo] = useState<{ terms_and_conditions: string[], payment_terms: string[] }>({ terms_and_conditions: [], payment_terms: [] });
  const handleChangeTerms = (field: string, value: string[]) => {
    const termIdJustAdded = value[value.length - 1];
    const termContent = Term_conditions.find(x => x._id === termIdJustAdded)?.content;

    setTermsInfo({ ...termsInfo, [field]: value });
    handleChangeData(field, newQuoteData[field].concat(termContent));
  }
  //temp
  const [currentRateCardItems, setCurrentRateCardItems] = useState<RateCardItemModel[]>([]);
  const initialData: ICreateQuote = {
    name: '',
    description: '',
    job: {
      id: '',
      name: '',
      client_brief: ''
    },
    due_date: '',
    target_budget: 0,
    expiration_date: '',
    quote_budget: 0,
    sub_total: 0,
    discount: 0,
    agency_fee: 0,
    tax: 0,
    terms_and_conditions: '',
    payment_terms: '',
    headers: [
      {
        name: '',
        elements: [],
        sub_total: 0,
        sub_headers: [
          {
            name: '',
            elements: [],
            sub_total: 0
          }
        ],
      }
    ]
  };
  const [newQuoteData, setNewQuoteData] = useState(initialData);
  const {
    Term_conditions, //CAMBIAR LOGICA DE CONSULTA API
    QuotesId,
    QuoteVersion,
    jobDataById,
  } = useSelector((state: RootState) => state.getJobsTypeSlice);

  const termOptions = Term_conditions.map(e => ({ label: e.title, value: e._id }));
  const updateRates = () => {
    let newSubTotal = 0;
    const newHeaders = newQuoteData.headers.map(e => ({
      ...e,
      elements: e.elements.map(x => {
        const currentRate = findRateByItem(x.item)?.[x.units_type] ?? 0;
        newSubTotal += currentRate * x.quantity * x.units_number;
        return {
          ...x,
          rate: currentRate,
        }
      }),
      sub_headers: e.sub_headers.map(sh => ({
        ...sh,
        elements: sh.elements.map(x => {
          const currentRate = findRateByItem(x.item)?.[x.units_type] ?? 0;
          newSubTotal += currentRate * x.quantity * x.units_number;
          return {
            ...x,
            rate: currentRate,
          }
        }),
      })),
    }));

    setNewQuoteData({
      ...newQuoteData,
      headers: newHeaders,
      sub_total: newSubTotal,
    });
  };

  const wereHeadersEdited = useMemo(() => {
    if (!initialHeaders) return false
    return !(isEqual(newQuoteData.headers, initialHeaders))
  }, [initialHeaders, newQuoteData]);

  //Revisar flujo
  useEffect(() => {
    dispatch(GetTermsAndConditions('quote'));
    dispatch(getQuotesId(idquote));
  }, [dispatch, idquote])

  //Agregar
  useEffect(() => {
    if (state?.idJob) {
      dispatch(getJobById(state.idJob));
    }
  }, [state]);


  useEffect(() => {
    setNewQuoteData({ ...initialData, job: { id: jobDataById?._id, name: jobDataById?.name }, project: { ...jobDataById?.project } });
    if (jobDataById) {
      dispatch(layoutConfig.setPath([
        { label: jobDataById.project.name, redirect: `/factory/project/detail/${jobDataById.project._id}` },
        { label: jobDataById.name, redirect: `/factory/project/detail/$${jobDataById.project._id}` },
      ]));
    }
  }, [jobDataById]);
  //Fin - Agregar

  useEffect(() => {
    updateRates();
  }, [currentRateCardItems]);

  useEffect(() => {
    if (QuotesId) {
      dispatch(layoutConfig.setPath([
        { label: QuotesId.project.name, redirect: `/factory/project/detail/${QuotesId.project._id}` },
        { label: QuotesId.job.name, redirect: `/factory/project/detail/$${QuotesId.project._id}` },
        { label: QuotesId.name, redirect: `/factory/quotes/detail/${QuotesId._id}` },]));
    }
    const lastVersion = QuotesId?.quote_versions?.slice(-1)[0];
    dispatch(GetQuoteVersion(lastVersion?._id));
    setNewQuoteData({ ...initialData, ...QuotesId, headers: [], term: QuotesId?.term?._id, job: { id: QuotesId?.job?._id, name: QuotesId?.job?.name, client_brief: QuotesId?.job?.client_brief } });
  }, [QuotesId]);

  //Mejorar procesado de Quotes
  useEffect(() => {
    const formatedHeaders = QuoteVersion?.map((e) => ({
      name: e.name,
      sub_total: e.sub_total,
      elements: e.elements.map((x, index) => ({
        item: x.item,
        name: e.items?.[index]?.name,
        sub_total: e.sub_total,
        category: '',
        quantity: x.quantity,
        rate: x.rate,
        units_number: x.units_number,
        units_type: x.units_type
      })),
      sub_headers: e.sub_headers.map((x) => ({
        name: x.name,
        elements: x.elements.map((z, index) => ({
          item: z.item,
          name: x.items?.[index]?.name,
          sub_total: e.sub_total,
          category: '',
          quantity: z.quantity,
          rate: z.rate,
          units_number: z.units_number,
          units_type: z.units_type
        })),
      })),
    }));
    setNewQuoteData({ ...newQuoteData, headers: formatedHeaders });
    setInitialHeaders(formatedHeaders);
  }, [QuoteVersion]);
  //Revisar flujo

  //actualizarlo para modificar varios campos a la vez
  const handleChangeData = (name: string, value: any) => {
    setNewQuoteData({
      ...newQuoteData,
      [name]: value,
    });
  }

  //Tree logic - Improve
  const calculateNewSubTotal = (currentSubTotal, items: (IElementQuote & { action: boolean })[]) => {
    return currentSubTotal + items.reduce((acc, current) => {
      const currentSubTotal = current.rate * current.quantity * current.units_number * (1 - ((current.discount || 0) / 100)) || 0;

      return current.action ? acc + currentSubTotal : acc - currentSubTotal;
    }, 0);
  };

  const handleDragRow = (e: React.DragEvent, path: string, type: QuoteRowType) => {
    e.dataTransfer.setData('data', JSON.stringify(get(newQuoteData, `headers.${path}`)));
    e.dataTransfer.setData('path', `headers.${path}`);
    e.dataTransfer.setData('type', type);
  };

  const handleDropRow = (e: React.DragEvent, path: string, type: QuoteRowType) => {
    const [previewPath, previewPathArray, previewIndex] = extractPathArrayAndIndex(e.dataTransfer.getData('path'));
    const [currentPath, currentPathArray, currentIndex] = extractPathArrayAndIndex(`headers.${path}`);

    if (!previewPath || !currentPath || !previewPathArray || !currentPathArray || previewPath === currentPath) return;

    const previewType = e.dataTransfer.getData('type');
    let data = JSON.parse(e.dataTransfer.getData('data'));
    const updatedQuoteData = { ...newQuoteData };
    let targetPathArray = currentPathArray;
    let targetIndex = currentIndex;

    if (previewType === QuoteRowType.ITEM && type !== QuoteRowType.ITEM) {
      targetPathArray = `${currentPath}.elements`;
      targetIndex = '0';
    }
    if (previewType === QuoteRowType.SUB_HEADER && type === QuoteRowType.ITEM) {
      targetPathArray = currentPathArray.replace('elements', 'sub_headers');
      targetIndex = '0';
    }
    if (previewType === QuoteRowType.SUB_HEADER && type === QuoteRowType.HEADER) {
      targetPathArray = `${currentPath}.sub_headers`;
      targetIndex = '0';
    }
    if (previewType === QuoteRowType.HEADER && type !== QuoteRowType.HEADER) {
      if (currentPath.includes(previewPath)) return;
      targetPathArray = currentPathArray.replace('elements', 'sub_headers');
      targetIndex = '0';
    }

    updateArrayAtPath(updatedQuoteData, targetPathArray, targetIndex, previewPathArray, previewIndex, data);
    setNewQuoteData(updatedQuoteData);
  };

  const extractPathArrayAndIndex = (path: string) => {
    const match = /(.+)\.([0-9]+)$/.exec(path);
    return match ?? [];
  }

  const extractPathArrayAndField = (path: string) => {
    const match = /^([0-9]+)\.(.+)\.(.+)$/.exec(path);
    return match ? match.slice(1) : [];
  }

  const extractSubHeaderIndex = (path: string) => {
    const match = /^[0-9]+\.sub_headers\.([0-9]+)/.exec(path);
    return match ? match[1] : undefined;
  }

  const extractHeaderIndex = (path: string) => {
    const match = /^([0-9]+)[\.\w*]*$/.exec(path);
    return match ? match[1] : undefined;
  }


  const updateArrayAtPath = (data, currentPathArray, currentIndex, previewPathArray, previewIndex, newItem) => {
    const mainArray = [...get(data, currentPathArray)];
    mainArray.splice(currentPathArray === previewPathArray && previewIndex < currentIndex ? (+currentIndex) + 1 : (+currentIndex), 0, newItem);
    set(data, currentPathArray, mainArray);
    const previewMainArray = [...get(data, previewPathArray)];
    previewMainArray.splice(currentPathArray === previewPathArray && previewIndex > currentIndex ? (+previewIndex) + 1 : (+previewIndex), 1);
    set(data, previewPathArray, previewMainArray);
  }

  const handleChangeRow = (path: string, value: any, type: QuoteRowType) => {
    const [headerIndex, rowPath, fieldToChange] = extractPathArrayAndField(path);
    const subHeaderIndex = extractSubHeaderIndex(path);
    let differenceSubTotal = 0;
    let newHeader;
    if (['rate', 'quantity', 'units_number', 'units_type', 'discount'].includes(fieldToChange)) { //It's about items change
      const itemToChange = get(newQuoteData, `headers.${headerIndex}.${rowPath}`);
      const newItem = { ...itemToChange, [fieldToChange]: value }

      if (fieldToChange === 'rate') {
        setCurrentRateInfo({ ...currentRateInfo, isChange: true, fieldsChanged: [...currentRateInfo.fieldsChanged, { item: itemToChange.item, [fieldToChange]: value }] })
      }

      if (fieldToChange === 'units_type') {
        newItem['rate'] = findRateByItem(itemToChange.item)?.[value] ?? 0;
      }
      newItem["sub_total"] = (newItem.rate * newItem.quantity * newItem.units_number) * (1 - ((newItem.discount || 0) / 100));

      newHeader = { ...get(newQuoteData, `headers.${headerIndex}`) }
      differenceSubTotal = calculateNewSubTotal(0, [{ ...itemToChange, action: false }, { ...newItem, action: true }]);
      set(newHeader, "sub_total", (get(newHeader, "sub_total") || 0) + differenceSubTotal)

      if (type === QuoteRowType.SUB_HEADER) {
        const subHeaderPath = `sub_headers.${subHeaderIndex}.sub_total`;
        set(newHeader, subHeaderPath, (get(newHeader, subHeaderPath) || 0) + differenceSubTotal);
      }

      set(newHeader, rowPath, newItem);
    }

    setNewQuoteData({
      ...set({ ...newQuoteData }, newHeader ? `headers.${headerIndex}` : `headers.${path}`, newHeader || value),
      sub_total: newQuoteData.sub_total + differenceSubTotal,
    });
  }

  //modificar logica de eliminacion con regex
  const handleDeleteRow = (path: string) => {
    const extendedPath = `headers.${path}`
    const rowToDelete = get(newQuoteData, extendedPath);
    if (!rowToDelete) return;

    const currentData = { ...newQuoteData };
    const headerIndex = extractHeaderIndex(path);
    const subHeaderIndex = extractSubHeaderIndex(path);
    const elements = get(rowToDelete, 'elements');
    const differenceSubTotal = elements ? -get(currentData, `${extendedPath}.sub_total`, 0)
      : calculateNewSubTotal(0, ([rowToDelete]).map(e => ({ ...e, action: false })));

    set(currentData, `headers.${headerIndex}.sub_total`, get(currentData, `headers.${headerIndex}.sub_total`, 0) + differenceSubTotal)

    if (subHeaderIndex && !elements) {
      set(currentData, `headers.${headerIndex}.sub_headers.${subHeaderIndex}.sub_total`, get(currentData, `headers.${headerIndex}.sub_headers.${subHeaderIndex}.sub_total`, 0) + differenceSubTotal)
    }

    const [_, pathArray, index] = extractPathArrayAndIndex(extendedPath);
    const currentArray = get(currentData, pathArray, [])
    currentArray.splice(parseInt(index), 1)
    set(currentData, pathArray, currentArray)

    set(currentData, `sub_total`, get(currentData, `sub_total`) + differenceSubTotal)

    setNewQuoteData(currentData);
  }

  const handleAddMainRow = (path, data) => {
    const finalPath = path ? `headers.${path}` : 'headers';
    const currentHeaders = get(newQuoteData, finalPath);
    currentHeaders.push(data);
    setNewQuoteData(
      set({ ...newQuoteData }, finalPath, currentHeaders)
    );
  }

  //Tree logic
  const handleSelectRateCard = useCallback(async (rate: RateModel) => {
    const rateCardVersionId = rate.rate_card_version?.[0]._id;
    const rateCardItems = await RateCardService.GetRateCardItemsByVersion(rateCardVersionId);
    setCurrentRateInfo({
      fieldsChanged: [],
      rateCardVersion: rateCardVersionId,
      rateCardName: rate.name,
      isChange: false,
      isClientRateCard: rate.assigned_to ? true : false,
      id: rate._id,
    })
    setCurrentRateCardItems(rateCardItems);
    setIsOpenSelectRateModal(false);
  }, [])

  const handleSelectItems = (path, type) => {
    setIsOpenSelectItemsModal(true);
    setCurrentMainRow({ path, type });
  }

  const findRateByItem = (id: string): IRates | undefined => {
    // valor por defecto
    // indexacion para optimizar la busqueda
    return currentRateCardItems.find(e => e.item?._id === id)?.rates;
  }

  const handleAddItems = (items: ItemModel[]) => {
    const headerIndex = extractHeaderIndex(currentMainRow.path);
    const currentItems = get({ ...newQuoteData }, `headers.${currentMainRow.path}`);
    const currentData = { ...newQuoteData };
    const newItems: IElementQuote[] = items.map<IElementQuote>((e: any) => {

      console.log((e.total_for_rate?.total
        ? e.total_for_rate.total * (1 - (newQuoteData.discount ?? 0) / 100)
        : (findRateByItem(e._id)?.[QuoteUnitsType.DAY] ?? 0) * (1 - (newQuoteData.discount ?? 0) / 100)))

        let totalR =e?.total_for_rate?.total;
        let days = e?.total_for_rate?.days;
        let qtyI =  e?.total_for_rate?.qty;

        console.log(e);
        
      return ({
        item: e?._id,
        quantity: e?.quantity || 1,
        name: e.name,
        category: e.category?.name,
        rate:  totalR != null ? totalR: (findRateByItem(e._id)?.[QuoteUnitsType.DAY] ?? 0),
        units_number: 1,
        units_type: QuoteUnitsType.DAY,
        discount: newQuoteData.discount,
        sub_total: (e.total_for_rate?.total
          ? e.total_for_rate.total * (1 - (newQuoteData.discount ?? 0) / 100)
          : (findRateByItem(e._id)?.[QuoteUnitsType.DAY] ?? 0) * (1 - (newQuoteData.discount ?? 0) / 100)),
        assets: [],
        tax: e?.tax,
        discount_: e?.discount_,
        days: days && days
      }
      )
    });


    setExcemptsTaxes(items?.filter(item => item?.tax == false).length)
    const differenceSubTotal = calculateNewSubTotal(0, newItems.map(x => ({ ...x, action: true })));
    currentItems.push(...newItems);
    set(currentData, `headers.${currentMainRow.path}`, currentItems);
    set(currentData, `headers.${headerIndex}.sub_total`, (get(currentData, `headers.${headerIndex}.sub_total`) ?? 0) + differenceSubTotal);
    set(currentData, 'sub_total', (get(currentData, 'sub_total') ?? 0) + differenceSubTotal);

    if (currentMainRow.type === QuoteRowType.SUB_HEADER) {
      const headerIndex = extractHeaderIndex(currentMainRow.path);
      const subHeaderIndex = extractSubHeaderIndex(currentMainRow.path);
      set(currentData, `headers.${headerIndex}.sub_headers.${subHeaderIndex}.sub_total`, (get(currentData, `headers.${headerIndex}.sub_headers.${subHeaderIndex}.sub_total`) ?? 0) + differenceSubTotal);
    }

    setNewQuoteData(currentData);
    // setIsOpenSelectItemsModal(false);
    setCurrentMainRow({ path: '', type: QuoteRowType.HEADER });
  }

  const handleAddAssets = (path, assets) => {
    const currentAssets = get({ ...newQuoteData }, `headers.${path}.assets`) || [];
    const newAssets = assets.map(e => ({
      asset: e._id,
      serial: e.serial,
    }
    ));

    currentAssets.push(...newAssets)
    setNewQuoteData({
      ...set(newQuoteData, `headers.${path}.assets`, currentAssets)
    });
  }

  const handleSaveQuote = () => {

    handleChangeData('sub_total', injectedSub )

    setIsOpenCreateNewRateCardModal(currentRateInfo.isChange);

    if (!currentRateInfo.isChange) { //If no changes in rates, lets follow with normal flow
      if (state) {
        factoryService
          .CreateQuotes({ ...newQuoteData, target_budget: Number(newQuoteData.target_budget), job: newQuoteData.job.id, rate_card_version: currentRateInfo.rateCardVersion })
          .then((res) => {
            toast.success("Quote has been successfully created.");
            history.push('/factory/quotes/detail/' + res?._id)
            return;
          })
      }
      else {
        factoryService
          .EditQuotes(idquote, {
            ...newQuoteData,
            is_new_version: wereHeadersEdited,
            target_budget: Number(newQuoteData.target_budget),
            job: newQuoteData.job.id,
            rate_card_version: currentRateInfo.rateCardVersion
          })
          .then((res) => {
            toast.success("Quote has been successfully updated.");
            history.push('/factory/project/detail/' + idquote)

            return;
          })
          .catch((err: AxiosResponse<ApiError<UploadError[]>>) => {
            if (err?.data?.message) {
              toast.error("An error occurred could not update quote.");
              return;
            }
          })
          .catch((err: AxiosResponse<ApiError<UploadError[]>>) => {
            if (err?.data?.message) {
              toast.error("An error occurred could not update quote.");
              return;
            }
          });
      }
    }
  }
  const handleSaveRate = async () => {
    const newItemsData = currentRateCardItems.map(e => {
      const itemToUpdate = currentRateInfo.fieldsChanged.find(x => x.item === e.item);
      return { ...e, ...(itemToUpdate || { item: e.item._id }) }
    });

    currentRateInfo.isClientRateCard ?
      RateCardService.CreateRateCardVersion(currentRateInfo.id,
        { rate_card_items: newItemsData },
      )
        .then((rateCard) => {
          toast.success("RateCard version created successfully.");
          setCurrentRateInfo({ ...currentRateInfo, isChange: false });
          return;
        })

        .catch((err: AxiosResponse<ApiError>) => {
          toast.error(
            Array.isArray(err.data.message)
              ? err.data.message.join("\n")
              : err.data.message
          );
          return;
        })
      :
      RateCardService.CreateRateCard({
        name: 'Default Name',
        description: '',
        assigned_to: newQuoteData?.client?._id,
        rate_card_items: newItemsData,
      })
        .then((rateCard) => {
          toast.success("RateCard created successfully.");
          setCurrentRateInfo({ ...currentRateInfo, isChange: false });
          return;
        })

        .catch((err: AxiosResponse<ApiError>) => {
          toast.error(
            Array.isArray(err.data.message)
              ? err.data.message.join("\n")
              : err.data.message
          );
          return;
        });
  };
  
  useEffect(() => {
    handleChangeData('sub_total', injectedSub)    
    console.log('inyected');
    
  }, [injectedSub])

 useEffect(() => {
   console.log(newQuoteData.sub_total);
   
 }, [newQuoteData.sub_total])
 
  

  const messageComming = (message: any) => {
    toast.info(message + " is coming soon!");
  };

  return (
    <div className="h-100 card overflow-x-none custom-scrollbar trans-all">
      <div>
        <Row style={{ color: 'black' }} className="px-2 pt-4 pb-4">
          <Col lg="4" className="px-4">
            <Row className="pt-2 pb-4">
              <Col lg="3">
                <EmptyCircle title="Title" />
              </Col>
              <Col lg="9">
                <div>
                  <span className="fw-bolder me-2">Project:</span> {newQuoteData.project?.name}
                </div>
                <div>
                  <span className="fw-bolder me-2">Project ID:</span>projectid
                </div>
                <div>
                  <span className="fw-bolder me-2">Project #:</span> project#
                </div>
              </Col>
            </Row>
            <Col lg="12">
              <Row className="w-100 m-0">
                <Col lg="4" className="my-1 p-0 pe-1">
                  <Select
                    placeholder="Blank Template"
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { }}
                    value=""
                    options={[]}
                  />
                </Col>
                <Col lg="4" className="my-1 p-0 pe-1">
                  <Select
                    placeholder="Version 1"
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { }}
                    value=""
                    options={[]}
                  />
                </Col>
                <Col lg="4" className="my-1 p-0">
                  <Select
                    placeholder="Status"
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { }}
                    value=""
                    options={[]}
                  />
                </Col>
              </Row>
              <Row className="w-100 m-0">
                <Col lg="6" className="my-1 p-0 pe-1">
                  <Select
                    placeholder="Terms & Conditions"
                    onChange={(value) => { handleChangeTerms('terms_and_conditions', value) }}
                    value={termsInfo.terms_and_conditions}
                    options={termOptions}
                    isMulti
                    isClearable={false}
                  />
                </Col>
                <Col lg="6" className="my-1 p-0">
                  <Select
                    placeholder="Payment Terms"
                    onChange={(value) => { handleChangeTerms('payment_terms', value) }}
                    value={termsInfo.payment_terms}
                    options={termOptions}
                    isMulti
                    isClearable={false}
                  />
                </Col>
              </Row>
              <div className="ms-1">
                <p className="text-secondary lh-1 mb-0 d-flex align-items-center pt-3 ">
                  <div>
                    <span className="fw-bolder me-2">Quote number:</span>
                  </div>
                  147575509310505986
                </p>

                <p className="text-secondary lh-1 mb-0 d-flex align-items-center pt-3 ">
                  <div>
                    <span className="fw-bolder me-2">Validity:</span>
                  </div>

                </p>

                <p className="text-secondary mb-0 lh-1 d-flex align-items-center">
                  <div>
                    <span className="fw-bolder me-2 text-nowrap ">Expiration date:</span>
                  </div>
                  <InputDate
                    className="w-100"
                    placeholder="Please Select"
                    onChange={(e) => handleChangeData('expiration_date', e.valueText)}
                    value={newQuoteData.expiration_date}
                  />
                </p>

              </div>
            </Col>
          </Col>
          <Col lg="4" className="px-4">
            <Row className="pt-2 pb-4">
              <Col lg="3">
                <EmptyCircle title="Title" />
              </Col>
              <Col lg="9">
                <div>
                  <span className="fw-bolder me-2">Client:</span> project
                </div>
                <div>
                  <span className="fw-bolder me-2">Organization:</span>projectid
                </div>
                <div>
                  <span className="fw-bolder me-2">Rate Card:</span> {currentRateInfo.rateCardName || 'No Selected'}
                </div>
              </Col>
            </Row>
            <Col>
              <Row className="w-100 m-0">
                <Col lg="4" className="my-1 p-0 pe-1">
                  <button
                    type="button"
                    className="btn btn-primary btn-md border w-100"
                    onClick={() => messageComming('Compare')}
                  >
                    Compare
                  </button>
                </Col>
                <Col lg="4" className="my-1 p-0 pe-1">
                  <button
                    type="button"
                    className="btn btn-primary btn-md w-100"
                    onClick={() => { messageComming('Merge quotes') }}
                  >
                    Merge quotes
                  </button>
                </Col>
                <Col lg="4" className="my-1 p-0">
                  {idquote ? <Link to={`/quotes/public/${idquote}`} target="_blank">
                    <button
                      type="button"
                      className="btn btn-primary btn-md w-100"
                      onClick={() => { }}
                    >
                      Preview
                    </button>
                  </Link> :
                    <button
                      type="button"
                      className="btn btn-primary btn-md w-100"
                      onClick={() => { toast.info('This action is only available for editing an item') }}
                    >
                      Preview
                    </button>
                  }
                </Col>
              </Row>
              <div className="col-12 my-1">
                <button
                  type="button"
                  className="btn btn-success btn-md w-100 text-white"
                  onClick={handleSaveQuote}
                  style={{ height: '40px', fontSize: '15px', fontWeight: 'bold' }}
                >
                  Save quote
                </button>
              </div>
              <div className="ms-1">
                <p className="text-secondary lh-1 mb-0 d-flex align-items-center pt-3 ">
                  <div>
                    <span className="fw-bolder me-2">Deliver:</span>
                  </div>
                </p>

                <p className="text-secondary lh-1 mb-0 d-flex align-items-center pt-3 ">
                  <div>
                    <span className="fw-bolder me-2">Delivery history:</span>
                  </div>

                </p>

                <p className="text-secondary mb-0 lh-1 d-flex align-items-center pt-3">
                  <div>
                    <span className="fw-bolder me-2 text-nowrap ">Brief & notes:</span>
                  </div>

                </p>
                <p className="text-secondary mb-0 lh-1 d-flex align-items-center pt-3">
                  <div>
                    <span className="fw-bolder me-2 text-nowrap ">Milestones w/Dates:</span>
                  </div>

                </p>
              </div>

            </Col>
          </Col>
          <Col lg="4" className="px-4">
            <Row className="pt-2 pb-4">
              <Col lg="3">
                <EmptyCircle title="Title" />
              </Col>
              <Col lg="9">
                <div>
                  <span className="fw-bolder me-2">Job:</span> {newQuoteData.job.name}
                </div>
                <div>
                  <span className="fw-bolder me-2">Job Type:</span> Video
                </div>
                <div>
                  <span className="fw-bolder me-2">Last Activity:</span> project#
                </div>
              </Col>
            </Row>
            <Col lg="12">
              <p className="text-secondary lh-1 mb-0 d-flex align-items-center">
                <div>
                  <span className="fw-bolder me-2 text-nowrap">Target budget:</span>
                </div>
                <input
                  type="number"
                  className="form-control h-25"
                  style={{ height: "32px" }}
                  value={newQuoteData.target_budget}
                  min={0}
                  onChange={(e) => handleChangeData('target_budget', e.target.value)}
                />
              </p>
              <p className="text-secondary mb-0 lh-1 d-flex align-items-center pt-3">
                <div>
                  <span className="fw-bolder me-2 text-nowrap ">Actual quote:</span>
                </div>
                ${newQuoteData.sub_total}
              </p>
              <p className="text-secondary lh-1 mb-0 d-flex align-items-center pt-3">
                <div>
                  <span className="fw-bolder me-2">Difference:</span>
                </div>
                ${Number(newQuoteData.target_budget) - Number(newQuoteData.sub_total)}
              </p>
              <p className="text-secondary lh-1 mb-0 d-flex align-items-center pt-3">
                <div>
                  <span className="fw-bolder me-2">Cost:</span>
                </div>
                ${Number(newQuoteData.target_budget) - Number(newQuoteData.sub_total)}
              </p>
              <p className="text-secondary lh-1 mb-0 d-flex align-items-center pt-3 ">
                <div>
                  <span className="fw-bolder me-2">Margin:</span>
                </div>

              </p>

              <p className="text-secondary lh-1 mb-0 d-flex align-items-center pt-3 ">
                <div>
                  <span className="fw-bolder me-2">Match target:</span>
                </div>

              </p>

              <p className="text-secondary mb-0 lh-1 d-flex align-items-center pt-3">
                <div>
                  <span className="fw-bolder me-2 text-nowrap ">Global % discount:</span>
                </div>

              </p>
            </Col>
          </Col>
        </Row>

        <div className="d-flex flex-column py-1">
          {/* Segunda fila */}
          <div className="row d-flex mx-2 pt-0">
            <div className="col-12 pb-1">
              <InputFloatingLabel
                placeHolder="Quote name"
                onChange={(value) => handleChangeData('name', value)}
                value={newQuoteData.name}
                errorMessage={""}
                id={'quote-name-input'}
              />
            </div>
            <div className="col-3 pb-1 d-none">
              <Select
                placeholder="Terms & Conditions"
                onChange={(value) => handleChangeData('term', value)}
                value={newQuoteData.term}
                options={termOptions}
                id={'term-conditions-input'}
              />
            </div>
            <div className="col-3 pb-1 d-none">
              <Select
                placeholder="Payment Terms"
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { }}
                value={newQuoteData.term}
                options={[]}
                id={'term-payment-input'}

              />
            </div>
          </div>
          {/* Tercera fila */}
          <div className="row d-flex mx-2 align-items-center d-none">
            <div className="col-3">
              <p className="text-secondary lh-1 mb-0 d-flex align-items-center">
                <div>
                  <span className="fw-bolder me-2">Organization:</span>
                </div>
                {newQuoteData.organization?.entity?.name}
              </p>
            </div>
            <div className="col-3 ">
              <p className="text-secondary lh-1 mb-0 d-flex align-items-center">
                <div>
                  <span className="fw-bolder me-2">Project:</span>
                </div>
                {newQuoteData.project?.name}
              </p>
            </div>
            <div className="col-3">
              <p className="text-secondary lh-1 mb-0 d-flex align-items-center">
                <div>
                  <span className="fw-bolder me-2">Quote number:</span>
                </div>
                147575509310505986
              </p>
            </div>
            <div className="col-3">
              <p className="text-secondary lh-1 mb-0 d-flex align-items-center">
                <div>
                  <span className="fw-bolder me-2 text-nowrap ">Quote due date:</span>
                </div>
                <InputDate
                  placeholder="Please Select"
                  onChange={(e) => handleChangeData('due_date', e.valueText)}
                  value={newQuoteData.due_date}
                  height={32}
                  id={'due-date-input'}
                />
              </p>
            </div>
          </div>
          {/* 4° fila */}
          <div className="row d-flex mx-2 align-items-center d-none">
            <div className="col-3">
              <p className="text-secondary lh-1 mb-0 d-flex align-items-center">
                <div>
                  <span className="fw-bolder me-2">Client:</span>
                </div>
                {newQuoteData.client?.entity.first_name} {newQuoteData.client?.entity.last_name}
              </p>
            </div>
            <div className="col-3">
              <p className="text-secondary lh-1 mb-0 d-flex align-items-center">
                <div>
                  <span className="fw-bolder me-2">Job:</span>
                </div>
                {newQuoteData.job.name}
              </p>
            </div>
            <div className="col-3">
              <p className="text-secondary mb-0 lh-1 d-flex align-items-center">
                <div>
                  <span className="fw-bolder me-2">Date:</span>
                </div>
                {moment(new Date()).format('lll')}
              </p>
            </div>
            <div className="col-3">
              <p className="text-secondary mb-0 lh-1 d-flex align-items-center">
                <div>
                  <span className="fw-bolder me-2 text-nowrap ">Expiration date:</span>
                </div>
                <InputDate
                  placeholder="Please Select"
                  onChange={(e) => handleChangeData('expiration_date', e.valueText)}
                  value={newQuoteData.expiration_date}
                  height={32}
                  id={'due-date-input'}
                />
              </p>
            </div>
          </div>
          {/* 5° fila */}
          <div className="row d-flex mx-2 align-items-center d-none">
            <div className="col-3">

            </div>
            <div className="col-3">
              <p className="text-secondary lh-1 mb-0 d-flex align-items-center">
                <div>
                  <span className="fw-bolder me-2">Quote budget:</span>
                </div>
                ${newQuoteData.sub_total}
              </p>
            </div>
            <div className="col-3">

            </div>
            <div className="col-3">
              <p className="text-secondary lh-1 mb-0 d-flex align-items-center">
                <div>
                  <span className="fw-bolder me-2">Job type:</span>
                </div>
                Video
              </p>
            </div>
          </div>
          {/* 5° fila */}
          <div className="row d-flex mx-2 align-items-center">
            <div className="col-12">
              <TextArea
                className="pb-2"
                placeHolder="Quote description"
                onChange={(value) => handleChangeData('description', value)}
                value={newQuoteData.description}
                errorMessage={""}
                id={'quote-description-input'}
              />
            </div>
            <div className="col-12">
              <p
                className="text-primary text-decoration-underline cursor-pointer mb-2"
                onClick={() => { setIsOpenBriefModal(true) }}
                style={{ fontSize: "14px" }}
              >
                REVIEW CLIENT BRIEF
              </p>
            </div>
          </div>
          {/* 6° fila */}
          <div className="row d-flex mx-2 justify-content-between">
            <div className="col-6" style={{ fontSize: "14px" }}>
              <div className="col-12">
                <p className="text-gray mb-2">RATE CARD</p>
              </div>
              <div className="col-12 d-flex justify-content-between">
                <div className="col-4">
                  <span className="text-secondary me-2 fw-bold">{currentRateInfo.rateCardName || 'No Selected'}</span>
                  <button
                    type="button"
                    className="btn btn-light-gray typo-body btn-sm"
                    style={{
                      fontSize: "10px",
                    }}
                    onClick={() => setIsOpenSelectRateModal(true)}
                  >
                    CHANGE
                  </button>
                </div>
                <div className="cursor-pointer col-4" onClick={() => handleAddMainRow('', { ...initialData.headers[0], sub_headers: [] })}>
                  <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6"></i>
                  <small className=" text-primary ms-2"> ADD NEW HEADER</small>
                </div>
                <div className="cursor-pointer col-4" onClick={() => { }}>
                  <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-6"></i>
                  <small className=" text-primary ms-2 text-nowrap"> ADD JOB MILESTONES AS HEADERS</small>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="col-12 d-flex mb-2">
                <Checkbox
                  title="Match target budget"
                  value={false}
                  className="ms-4"
                  onChange={(value) => { }}
                />
              </div>
              <div className="col-12 d-flex justify-content-between">
                <div className="col-6">
                  <button
                    type="button"
                    className="btn btn-primary text-white btn-md w-100"
                    onClick={() => setIsOpenGlobalDiscountModal(true)}
                  >
                    APPLY GLOBAL DISCOUNT
                  </button>
                </div>
                <div className="col-5">
                  <button
                    type="button"
                    className="btn btn-primary text-white btn-md w-100"
                    onClick={() => { }}
                  >
                    EDIT COLUMNS
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <QuoteTable
          headers={newQuoteData.headers}
          handleChangeRow={handleChangeRow}
          handleDeleteRow={handleDeleteRow}
          handleAddSubHeader={(path) => handleAddMainRow(path, { ...initialData.headers[0].sub_headers[0] })}
          handleAddItems={handleSelectItems}
          handleDragRow={handleDragRow}
          handleDropRow={handleDropRow}
          handleAddAssets={handleAddAssets}
        />
        <QuoteSummary
          data={(({ sub_total, discount, agency_fee, tax, headers }) => ({ sub_total, discount, agency_fee: agency_fee || 0, tax, headers }))(newQuoteData)}
          onChange={handleChangeData}
          excempt_taxes={excemptsTaxes}
          injectSub={injectSub}
        />
        <div className="text-center">
          <h5 className="text-secondary mt-2 ">Term & Conditions</h5>
          <TextEditQuill
            value={newQuoteData.terms_and_conditions}
            placeHolder="Write the Terms and Conditions"
            onChange={(value) => handleChangeData('terms_and_conditions', value)}
            className="m-4"
          />
        </div>
        <div className="text-center">
          <h5 className="text-secondary mt-2 ">Payment Terms</h5>
          <TextEditQuill
            value={newQuoteData.payment_terms}
            placeHolder="Write the Payment Terms"
            onChange={(value) => handleChangeData('payment_terms', value)}
            className="m-4"
          />
        </div>

        {/* <div className="text-center">
          <h2 className="text-secondary fw-bold mt-2 ">Term & Conditions</h2>

          <div className="mx-4" style={{ textAlign: "justify" }}>
            <p className="text-secondary">{Term_conditions.find(x => x._id === newQuoteData.terms_and_conditions)?.content}</p>
          </div>
        </div> */}
      </div>
      <QuoteEmailModal
        isOpen={isOpenSendEmailModal}
        onClose={() => setIsOpenSendEmailModal((prev) => !prev)}
        width={"460px"}
      />
      <SelectRateCard
        isOpen={isOpenSelectRateModal}
        onClose={() => setIsOpenSelectRateModal((prev) => !prev)}
        onSelectRate={handleSelectRateCard}
        title='Select rate card'
      />
      {/** Este es el modal para agregar items a un header */}
      <ItemQuotePicker
        isOpen={isOpenSelectItemsModal}
        onClose={() => setIsOpenSelectItemsModal((prev) => !prev)}
        onSelectItems={handleAddItems}
      />
      {/**
     
     <SelectItem
        isOpen={isOpenSelectItemsModal}
        onClose={() => setIsOpenSelectItemsModal((prev) => !prev)}
        onSelectItems={handleAddItems}
      />
     
     *  */  }
      <ModalBrief
        view={isOpenBriefModal}
        setView={setIsOpenBriefModal}
        toggle={isOpenBriefModal}
        brief={newQuoteData.job.client_brief}
      />
      <GlobalDiscount
        isOpen={isOpenGlobalDiscountModal}
        onClose={() => setIsOpenGlobalDiscountModal((prev) => !prev)}
        onApply={(value) => handleChangeData('discount', value)}
      />
      {isOpenCreateNewRateCardModal && (
        <ConfirmModal
          onClose={(value) => { if (value) handleSaveRate(); setIsOpenCreateNewRateCardModal(false) }}
          title={`Create new rate card ${currentRateInfo.isClientRateCard ? 'version' : ''}`}
          description={`Changes have been made in the quote. Do you want to create a new rate card ${currentRateInfo.isClientRateCard ? 'version' : ''}?`}
          confirmText="Create"
          cancelText="Cancel"
        />)}
    </div>
  );
};

export default CreateQuoteR;
