import moment from "moment";
import { useEffect, useMemo, useState } from "react";

import { SocialAndComm } from "../SocialAndComm";
import InfoDisplay from "src/components/UI/InfoDisplay";
import { ContactType, IContact } from "src/models/ContactModel";
import { IContactContactTypeEx } from "src/util/ContactsFunctions";
import { addUrlProtocol } from "src/util";
import SimpleButton from "../../../../UI/buttons/SimpleButton";
import CreateClientForOrganization from "../../../../Modals/CreateClientForOrganization";
import useContacts from "../../../../../hooks/useContacts";
import { GetAllContacts } from "src/store/blackbook/getContactSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { useHistory } from "react-router-dom";

const OrganizationContactData: React.FC<{ contact: IContact, extractor: IContactContactTypeEx }> = ({ contact, extractor }) => {


  const dispatch = useDispatch()

  const history = useHistory();


  const [addClientModal, setaddClientModal] = useState<boolean>(false)
  const [emailData, setEmailData] = useState<any>([]);

  const contactSinceMoment = useMemo(() => moment(contact.contact_since), [contact.contact_since]);
  const GetContact: any = useSelector((state: RootState) => state.getContactAll.getContact);

  useEffect(() => {
    dispatch(GetAllContacts());
  }, [])




  useEffect(() => {
    setEmailData(GetContact?.filter((contactD: any) => contactD?.entity?.organization == contact?._id))
    console.log(emailData);
    console.log('ID organization', contact?.entity?._id);
  }, [GetContact, contact])


  /* This is just for typescript to know that the "entity" field is for OrganizationData */
  if (contact.type !== ContactType.ORGANIZATION) return null;




  return (
    <div className="container-fluid px-3">
      <div className="row border-bottom p-0 py-3 ">
        <InfoDisplay
          title="general note"
          texts={[
            {
              text: contact.note || 'No note.',
            },
          ]}
        />
      </div>

      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <InfoDisplay title="office phone number" texts={[{ text: contact.entity.office_number || 'Not configured.' }]} />
        </div>
        <div className="col-6">
          <InfoDisplay
            title="home url"
            texts={[{
              isFile: false,
              text: contact.entity.home_url ? addUrlProtocol(contact.entity.home_url) : 'Not configured.',
              fileHref: contact.entity.home_url ? addUrlProtocol(contact.entity.home_url) : undefined,
            }]}
          />
        </div>
        <div className="col-6">
          <InfoDisplay title="mobile phone number" texts={[{ text: contact.entity.mobile_number || 'Not configured.' }]} />
        </div>
      </div>

      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <InfoDisplay
            title="contact since"
            texts={[
              { text: contactSinceMoment.format('LL') },
              { text: contactSinceMoment.fromNow() }
            ]}
          />
        </div>
      </div>

      <div className="row border-bottom p-0 py-3">
        {contact.entity.addresses && contact.entity.addresses.length > 0 ? (
          contact.entity.addresses.map((address, index) => (
            <div className="col-6">
              <InfoDisplay
                title={`${address.type} address`}
                texts={[{ text: address.address || '' }, { text: `${address.city || 'No city'}, ${address.state || ''} ${address.zip || ''}` }, { text: address.country || '' }, { text: address.continent || '' }]}
              />
            </div>
          ))
        ) : (
          <div className="col-6">
            <InfoDisplay
              title="physical address"
              texts={[{ text: 'Without configured addresses' }]}
            />
          </div>
        )}
        s
        <div className="col-6">
          <InfoDisplay
            title="Clients"
            texts={
              emailData?.map((data: any) => ({
                text: data?.entity?.last_name && data?.entity?.first_name ?data?.entity?.first_name + " " + data?.entity?.last_name : data?.entity?.first_name  ,
                action: () => {
                  history.push(`/blackbook/${data?._id}`)
                }
              }))
            }
          />
          <SimpleButton onClick={() => {
            //CreateClientForOrganization
            setaddClientModal(true)
          }} >
            ADD CLIENT
          </SimpleButton>
        </div>
      </div>

      <SocialAndComm
        social={contact.entity.social}
      />
      {addClientModal &&
        <CreateClientForOrganization
          onClose={() => setaddClientModal(false)}
          confirmText="Create"
          organizationId={contact?._id}
          seter={(value: any) => { setEmailData([...emailData, ...value]) }}
        />}
    </div>
  );
};

export default OrganizationContactData;
