import { useState } from 'react'
import { ABC } from '../../../util/index'
import { abc } from './Letters'

interface Props {
    sections:any
}

const AlphabeticBar:React.FC<Props>  = (props) => {

    const [alphSelected, setAlphSelected] = useState<any>();


    const { sections  } = props

    const goTo = (letter: ABC) => {
        // setPaddingView(false)
        if (sections[letter]) {
          sections[letter].ref.current?.scrollIntoView({ behavior: 'smooth' });
        }
      };

    return(
        <div className="list-linked overflow-y hide-scrollbar p-0 h-100">
        {[...Object.keys(sections), ...abc]
          .filter((item, index) => {
            return [...Object.keys(sections), ...abc].indexOf(item) === index;
          })
          .map((item: any, index: any) => {
            return (
              <p
                onClick={goTo.bind(null, item)}
                key={index}
                className="m-0 p-0 typo-body text-primary"
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  fontWeight: alphSelected == index ? "bold" : "",
                }}
              >
                <span
                  onClick={() => {
                    setAlphSelected(index);
                  }}
                >
                  {item}
                </span>
              </p>
            );
          })}
      </div>
    )
}

export default AlphabeticBar