import FlatList from 'flatlist-react';
import { Modal } from 'reactstrap';
import { getSections } from '../../util/RateFunctions';
import RateSection from '../Rates/RateList/RateSection';
import RateListEmpty from '../Rates/RateList/RateListEmpty';
import RateItem from '../Rates/RateList/RateItem';
import { useEffect, useState, Fragment, useCallback } from 'react';
import useRates from 'src/hooks/useRates';
import RateModel from 'src/models/RateModel';
import { RateCardService } from '../../services/rates';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSelectRate: (rate: RateModel) => void;
  title: string;
}

const SelectRateCard: React.FC<Props> = ({isOpen, onClose, onSelectRate, title}) => {
  const [data, setdata] = useState<RateModel[]>([]);
  const [rates, setRates] = useState<RateModel[]>([]);
  const [typeRateCards, setTypeRateCards] = useState<string>("system");
  const [search, setSearch] = useState<string>("");
  const [standartRateCard, setStandartRateCard] = useState<RateModel>();
  const getAllRates = useCallback(async () => {
    const data = await RateCardService.GetRateCards();
    const standardRate = data.find(
      (item) => !item.assigned_to && item.blocked
    );
    onSelectRate(standardRate);
    setRates(data);
  }, [])

  useEffect(
    () => {
      getAllRates();
    }
  ,[getAllRates])

  useEffect(() => {
    //Mejorar forma de identificar tipos de rate card
    if (Array.isArray(rates) && rates.length) {
      if (typeRateCards === "system") {
        let systemRateCardList = rates
          .filter( (item) => !item.assigned_to && !item.blocked)
          .filter((item) => item.name.toLowerCase().includes(search));
        const standardRate = rates.find(
          (item) => !item.assigned_to && item.blocked && item.name.toLowerCase().includes(search)
        );
        setStandartRateCard(standardRate);
        setdata(systemRateCardList);

      } else if (typeRateCards === "clients") {
        let clientsRateCardList = rates
          .filter((item) => item.assigned_to)
          .filter((item) => item.name.toLowerCase().includes(search));
        setdata(clientsRateCardList);
        setStandartRateCard(null);
      }
    }
  },[typeRateCards, rates, search])

  let sections = getSections(data);
  const goTo = (letter: string) => {
    if (sections[letter]) {
      sections[letter].ref.current?.scrollIntoView();
    }
  };

  const changeRate = (id: string) => {
    // console.log("Change rate: " + id);
  };
    
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <div className="container">
        <div className="cursor-pointer m-1" onClick={onClose}>
          <i className="bi bi-x-lg fs-5"></i>
        </div>
        <div className="row py-4">
          <div className="col-12">
            <h2 className="text-secondary typo-display fw-bold text-center">
              {title}
            </h2>
          </div>
          <div className="col-12 mt-2 p-0 d-flex justify-content-center">
            <button className={`btn ${typeRateCards === 'system' ? 'btn-primary' : 'btn-default'} mx-1`}  onClick={() => {setTypeRateCards("system");}}>SYSTEM RATE CARDS</button>
            <button className={`btn ${typeRateCards === 'clients' ? 'btn-primary' : 'btn-default'} mx-1`} onClick={() => {setTypeRateCards("clients");}}>CLIENT RATE CARDS</button>
          </div>
          <div className="col-12 input-icon mt-2 d-flex align-items-center">
            <i className="bi bi-search text-primary mx-2"></i>
            <input
              className="form-control typo-body text-primary"
              type="search"
              id="search-input"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="row position-relative mx-0">
          <div className="col p-0 h-100 custom-scrollbar contact-list">
            {standartRateCard && 
              <RateItem
              contact={standartRateCard}
              onSelect={() => onSelectRate(standartRateCard)}
              block
              />}
              <Fragment>
                {data.length > 0 ? (
                  <FlatList
                    list={Object.keys(sections)}
                    renderItem={(item: string, index: number) => {
                      return (
                        <RateSection
                          ref={sections[item].ref}
                          key={index}
                          letter={item}
                          items={sections[item].items}
                          onRateSelected={onSelectRate}
                        />
                      );
                    }}
                    renderWhenEmpty={() => <RateListEmpty />}
                  />
                ) : (
                  <RateListEmpty />
                )}
              </Fragment>
          </div>

          <div className="list-linked p-2 overflow-y hide-scrollbar h-100">
            {Object.keys(sections).map((item, index) => {
              return (
                <p
                  // onClick={goTo.bind(null, item)}
                  key={index}
                  className="m-0 p-0 typo-body text-primary"
                >
                  {item}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SelectRateCard;
