import InputFloatingLabel from '../../../../UI/forms/InputFloatingLabel';
import RatesSchema from './validations';
import { useFormik, Form, FormikProvider } from 'formik';
import { RatesModel } from '../../../../../models/ItemModel';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { setRates, changeRatesErrors } from '../../../../../store/stockpile/createFormSlice';
import { useEffect } from 'react';
import CurrencyInput from 'react-currency-input-field';


const Rates: React.FC = () => {

  const dispatch = useDispatch()
  const data = useSelector((state: RootState) => state.createFormSlice.RatesData);


  const formik = useFormik({
    validationSchema: RatesSchema,
    initialValues: {
      ...data
    },
    onSubmit: () => {

    }
  })

  const { errors, touched, handleSubmit, values, setFieldValue } = formik

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      dispatch(changeRatesErrors(true))
    } else {
      dispatch(changeRatesErrors(false))
    }
  }, [errors])

  return (
    <div className="container-fluid px-3 mt-3 vh-100 ">
      <p className="m-0 text-primary typo-body">STANDARD RATE CARD</p>
      <FormikProvider value={formik} >
        <Form autoComplete="off" noValidate onSubmit={(e) => { }}  >
          <div className="row mt-2">
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
              id={'r1'}
                placeHolder="Hour rate"
                value={values.hour}
                onChange={(value) => {
                  setFieldValue('hour', value)
                  dispatch(setRates({ hour: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.hour}
              />

            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
            <InputFloatingLabel
            id={'r2'}
                placeHolder="Day rate"
                value={values.daily}
                onChange={(value) => {
                  setFieldValue('daily', value)
                  dispatch(setRates({ daily: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.daily}
              />

            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
            <InputFloatingLabel
            id={'r3'}
                placeHolder="Week rate"
                value={values.week}
                onChange={(value) => {
                  setFieldValue('week', value)
                  dispatch(setRates({ week: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.week}
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
            <InputFloatingLabel
            id={'r4'}
                placeHolder="Sale rate"
                value={values.sale}
                onChange={(value) => {
                  setFieldValue('sale', value)
                  dispatch(setRates({ sale: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.sale}
              />
           
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <CurrencyInput
                suffix={' %'}
                name="currencyInput"
                id="currencyInput"
                data-number-to-fixed="2"
                data-number-stepfactor="100"
                value={values.overtime_hour}
                placeHolder="Overtime hour rate"
                onChange={
                  (e: any) => {
                    e.preventDefault();
                    const { value = "" } = e.target;
                    const parsedValue = value.replace(/[^\d.]/gi, "");
                    setFieldValue('overtime_hour', parsedValue)
                    dispatch(setRates({ overtime_hour: parsedValue } as RatesModel))
                  }
                }
                onBlur={() => { }}
                allowDecimals
                decimalsLimit="2"
                disableAbbreviations
                onError={
                  errors.sale
                }
                className="form-control text-secondary typo-body-important colorCurrency"
                style={{ height: 42, color: 'red' }}
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
            <InputFloatingLabel
              id={'r6'}
                placeHolder="Holiday hour rate"
                value={values.holiday_hour}
                onChange={(value) => {
                  setFieldValue('holiday_hour', value)
                  dispatch(setRates({ holiday_hour: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.holiday_hour}
    
              />
             
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
            <InputFloatingLabel
            id={'r7'}
                placeHolder="Holiday day rate"
                value={values.holiday_day}
                onChange={(value) => {
                  setFieldValue('holiday_day', value)
                  dispatch(setRates({ holiday_day: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.holiday_day}
              />
     
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
            <InputFloatingLabel
            id={'r8'}
                placeHolder="Holiday overtime hour rate"
                value={values.holiday_overtime_hour}
                onChange={(value) => {
                  setFieldValue('holiday_overtime_hour', value)
                  dispatch(setRates({ holiday_overtime_hour: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.holiday_overtime_hour}
              />
       
            </div>
            
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default Rates;
