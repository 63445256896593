import React from "react";
import CounterElement from './CounterElement'

interface Props {
    count: any
}

const CounterBar: React.FC<Props> = (props) => {
    let count = props.count


    const formatNumber = (number: number) => {

        let n: string = number?.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            minimumFractionDigits: 3,
            useGrouping: false
        })

        let n2: any = n

        let n3: any = n2?.toString().replace('.', ',')

        return n3
    }

    return (
        <div className="row px-2 mt-0 pt-0 mb-5 " >

            <div className="col-lg-11 d-flex ">
                <div className="col-lg-9 row  mt-0 p-0 gy-4" >
                    {
                        // <CounterElement title="approved" count={formatNumber(count?.count2)} color="lightGreen" />
                    }
                    <div style={{marginTop: '9%'}}>
                        <CounterElement title="proposal" count={count?.count1} color='purple' border="border-end" />
                    </div>
                    <CounterElement title="approved" count={count?.count2} color="lightGreen" />
                    <CounterElement title="active" count={count?.count3} color="greenActive" />
                    <CounterElement title="on hold" count={count?.count4} color="lightBlue" />
                    <CounterElement title="complete" count={count?.count5} color="primary" border={'false'} />
                    <CounterElement title="lost" count={count?.count6} color="danger" />
                </div>
            </div>

        </div>
    )

}


export default CounterBar