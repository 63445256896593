import { AxiosResponse } from "axios";
import { FormGroup } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createRef,
  ForwardedRef,
  ForwardRefExoticComponent,
  FunctionComponent,
  RefAttributes,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ApiError } from "src/models";
import useQuery from "src/hooks/useQuery";
import { RootState } from "src/store/store";
import { useToast } from "src/hooks/useToast";
import Checkbox from "../../UI/forms/Checkbox";
import { ICategory } from "src/models/Category";
import useContacts from "src/hooks/useContacts";
import { useRequest } from "src/hooks/useRequest";
import ConfirmModal from "../../Modals/ConfirmModal";
import { ContactType } from "src/models/ContactModel";
import ContactsModal from "../../Modals/ContactsModal";
import ProgressbarTitle from "../../UI/ProgressbarTitle";
import { BlackbookService } from "src/services/blackbook";
import StepButton from "src/components/UI/buttons/stepButton";
import { createContactForm } from "src/store/blackbook/createContactSlice";
import SuccesCreate from "../../Modals/Contact/SuccesCreate";
import Notes from "./tabs/Freelance/Notes";
import EmergencyContacts from "./tabs/Freelance/EmergencyContacts";
import FreelanceDetails from "./tabs/Freelance/FreelanceDetails/FreelanceDetails";
import FreelancePersonalInformation from "./tabs/Freelance/PersonalInformation/PersonalInformation";

import ClientOrganization from "./tabs/Client/Organization";
import ClientPersonalInformation from "./tabs/Client/PersonalInformation/PersonalInformation";

import TMPersonalInformation from "./tabs/TeamMember/PersonalInformation";

import OrganizationNote from "./tabs/Organization/Note";
import OrganizationDocuments from "./tabs/Organization/Documents";
import OrganizationGeneralInformation from "./tabs/Organization/General/General";

import ProspectPersonalInformation from "./tabs/Prospect/PersonalInformation";
import ProspectNotes from "./tabs/Prospect/Notes";
import SelectInput from "src/components/UI/forms/Select";
import { SkillsetsService } from "src/services/skillsets";
import { BCreateSkillset } from "src/models/Bodies";
import { UploadError } from "src/util";
import { layoutConfig } from "src/store/layout";
import SelectTypeContact from "../../Modals/SelectTypeContact";

interface Props {
  onFinish: (category?: string) => void;
}

interface TabRef {
  handleValidation: () => Promise<boolean>;
}

export interface TabsContent {
  title: string;
  ref: ForwardedRef<TabRef>;
  type?: ContactType | "all";
  props?: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Component:
  | ForwardRefExoticComponent<any & RefAttributes<TabRef>>
  | FunctionComponent<{ ref: TabsContent["ref"] }>;
}

const AddContact: React.FC<Props> = (props) => {
  const { search } = useLocation();
  const query = useQuery();
  const history = useHistory();

  const toast = useToast();

  const { mutate } = useContacts();
  const { data: categories } = useRequest<ICategory[]>({
    url: "categories/contacts/all",
  });


  const dispatch = useDispatch();
  const data = useSelector(
    (state: RootState) => state.createContactFormSlice.data
  );

  const [contID, setContID] = useState('');

  const [modalend, setModalEnd] = useState(false)

  const [client, setClient] = useState<boolean>(false)
  const [supplier, setSupplier] = useState<boolean>(false)
  const [venue, setVenue] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState(false);
  const [isCreateOther , setisCreateOther] = useState(false)
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [isLinkingContact, setIsLinkingContact] = useState<boolean>(false);
  const [shouldSelectCategory, setShouldSelectCategory] =
    useState<boolean>(true);
  const [selectedCategory, setSelectedCategory] = useState<ICategory>();
  const totalCategories = useMemo(
    () =>
      categories
        ? categories.reduce((acc, category) => {
          return [
            ...acc,
            category,
            ...category.sub_categories.map((cat) => ({
              ...cat,
              name: `${category.name} > ${cat.name}`,
            })),
          ];
        }, [] as ICategory[])
        : [],
    [categories]
  );




  useEffect(() => {
    dispatch(layoutConfig.setModuleTitle("BLACKBOOK - CREATE CONTACT"));
  }, []);

  useEffect(() => {
    const category = query.get("create");
    if (!categories) return;
    const exitsQuery = category !== undefined && category !== null;
    if (!exitsQuery) return;

    if (totalCategories.some((c) => c._id === category)) {
      setShouldSelectCategory(false);
    }

    if (category === "") {
      setShouldSelectCategory(true);
      return;
    }

    let selectedCat = categories.find((c) => c._id === category);

    if (!selectedCat) {
      const t = categories.find((c) =>
        c?.sub_categories?.some((sc) => sc._id === category)
      );
      selectedCat = t?.sub_categories.find((sc) => sc._id === category);
    }

    selectedCat && setSelectedCategory(selectedCat);
  }, [search, categories, query, totalCategories]);

  const tabsContent = useMemo<TabsContent[]>(
    () => [
      {
        Component: FreelancePersonalInformation,
        ref: createRef(),
        title: "Personal information",
        type: ContactType.FREELANCE,
      },
      {
        Component: FreelanceDetails,
        props: { for: "freelance" },
        ref: createRef(),
        title: "Freelance details",
        type: ContactType.FREELANCE,
      },
      {
        Component: EmergencyContacts,
        ref: createRef(),
        title: "Emergency contacts",
        type: ContactType.FREELANCE,
      },
      {
        Component: Notes,
        ref: createRef(),
        title: "Notes",
        type: ContactType.FREELANCE,
      },

      {
        Component: ClientPersonalInformation,
        ref: createRef(),
        title: "Personal information",
        type: ContactType.CLIENT,
      },
      {
        Component: ClientOrganization,
        ref: createRef(),
        title: "Organization",
        type: ContactType.CLIENT,
      },
      {
        Component: Notes,
        ref: createRef(),
        title: "Notes",
        type: ContactType.CLIENT,
      },

      {
        Component: OrganizationGeneralInformation,
        ref: createRef(),
        title: "General information",
        type: ContactType.ORGANIZATION,
        props: {
          setClient: (value: boolean) => setClient(value),
          setSupplier: setSupplier,
          setVenue: setVenue,
          client: client,
          supplier: supplier,
          venue: venue
        }
      },
      {
        Component: OrganizationDocuments,
        ref: createRef(),
        title: "Corporate Documents",
        type: ContactType.ORGANIZATION,
      },
      {
        Component: OrganizationNote,
        ref: createRef(),
        title: "Notes",
        type: ContactType.ORGANIZATION,
      },

      {
        Component: ProspectPersonalInformation,
        ref: createRef(),
        title: "Personal information",
        type: ContactType.PROSPECT,
      },
      {
        Component: ProspectNotes,
        ref: createRef(),
        title: "Notes",
        type: ContactType.PROSPECT,
      },

      {
        Component: TMPersonalInformation,
        ref: createRef(),
        title: "Personal information",
        type: ContactType.TEAM_MEMBER,
      },
      {
        Component: FreelanceDetails,
        props: { for: "teammember" },
        ref: createRef(),
        title: "Details",
        type: ContactType.TEAM_MEMBER,
      },
      {
        Component: EmergencyContacts,
        ref: createRef(),
        title: "Emergency contacts",
        type: ContactType.TEAM_MEMBER,
      },
      {
        Component: Notes,
        ref: createRef(),
        title: "Notes",
        type: ContactType.TEAM_MEMBER,
      },
    ],
    []
  );

  const renderedTabs = useMemo(
    () =>
      tabsContent.filter(
        (tab) =>
          (tab.type && tab.type === selectedCategory?.type) ||
          tab.type === "all"
      ),
    [tabsContent, selectedCategory]
  );

  const handleSave = async () => {
    const tab = renderedTabs[currentTab];

    if (tab.ref) {
      /* @ts-ignore */
      const validateFn: TabRef["handleValidation"] =
        tab.ref?.current?.handleValidation;

      validateFn &&
        validateFn().then((isValid) => {
          if (!isValid) {
            toast.error({
              message: "Please fill all required fields.",
              uniqueId: "add-contact-step-invalid",
            });
            return;
          }
        });
    }

    setIsSaving(true);

    const dataCopy = JSON.parse(JSON.stringify(data.data));

    if ("bookable_skills" in dataCopy) {
      let createdSkillsets: string[] = [];

      try {
        const createRes = await SkillsetsService.CreateManySkillsets(
          {
            skillsets: dataCopy.bookable_skills as BCreateSkillset[],
          },
          "blackbook"
        );

        createdSkillsets = createRes?.skillsets?.map(
          (skillset) => skillset._id
        );
      } catch (error: any) {
        if (error.data) {
          const _err: AxiosResponse<ApiError<UploadError[]>> = error;
          toast.error({
            message: [
              `Error while creating skillsets:`,
              ..._err.data.message.map((err) =>
                typeof err === "string" ? err : `${err.field} - ${err.error}`
              ),
            ],
          });
          return;
        }

        toast.error({
          message: ["Error while creating skillsets.", error.message],
        });
        return;
      }

      dataCopy.bookable_skills = createdSkillsets;
    }

    BlackbookService.CreateContact(
      {
        data: dataCopy,
        category: query.get("create")!,
        contact_since: data.contact_since,
        ...(data.note ? { note: data.note } : {}),
      },
      data.files
    )
      .then((contact) => {
        setIsSaving(false);
        mutate(undefined, true);
        dispatch(createContactForm.clearData({}));
        toast.success("Contact created successfully.");
        setContID(contact)
        //Trigger Modal
        return setModalEnd(true)
        // return history.push(`/blackbook/${contact._id}`);
      })
      .catch((err: AxiosResponse<ApiError>) => {
        setIsSaving(false);
        return toast.error(err.data.message);
      });
  };

  const onCancelModalClose = (confirm?: boolean) => {
    setIsCancelling(false);
    if (confirm !== undefined && !confirm) {
      dispatch(createContactForm.clearData({}));
      props.onFinish();
    }
  };

  const onLinkModalClose = (value?: boolean) => {
    setIsLinkingContact(false);

    if (value === undefined) {
      return;
    }

    if (!value) {
    }
  };

  const prevStep = () => {
    setCurrentTab((prev) => {
      if (prev > 0) {
        return prev - 1;
      }
      return prev;
    });
  };

  const nextStep = () => {
    const tab = renderedTabs[currentTab];

    if (tab.ref) {
      /* @ts-ignore */
      const validateFn: TabRef["handleValidation"] =
        tab.ref?.current?.handleValidation;

      validateFn &&
        validateFn().then((isValid) => {
          if (!isValid) {
            toast.error({
              message: "Please fill all required fields for the current step.",
              uniqueId: "add-contact-step-invalid",
            });
            return;
          }

          if (currentTab < renderedTabs.length - 1) {
            return setCurrentTab((prev) => prev + 1);
          } else {
            return handleSave();
          }
        });
    }
  };

  const handleTabClick = (index: number) => {
    if (index === currentTab) return;

    if (index > currentTab) {
      for (let i = currentTab; i < index; i++) {
        nextStep();
      }
    } else {
      for (let i = currentTab; i > index; i--) {
        prevStep();
      }
    }
  };

  const handleSelectCategory = (category: string) => {
    if (!category) return;

    query.set("create", category);
    history.push({ search: query.toString() });
  };

  const messageComming = (message: any) => {
    toast.info(message + ' is coming soon!')
  }

  return (
    <>
      {isCancelling && (
        <ConfirmModal
          title="Are you sure?"
          description="This new contact will be discarted"
          confirmText="KEEP EDITING"
          cancelText="DISCARD CONTACT"
          onClose={onCancelModalClose}
        />
      )}

      {isLinkingContact && (
        <ContactsModal
          title="Link an existing contact"
          description={[
            "Select and existing contact from your Black book to import their personal information into you new contact.",
            "The information you edit in the new contact won't affect the original contact.",
          ]}
          onClose={onLinkModalClose}
        />
      )}

      {isSaving && (
        <ConfirmModal
          title="Saving..."
          onClose={() => { }}
          buttons={
            <div className="d-flex justify-content-center align-items-center h-100">
              <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block"></i>
            </div>
          }
          description="Wait while we save your changes..."
        />
      )}

      {
        isCreateOther && (
          <SelectTypeContact
            title="Select a contact type"
            placeHolder=""
            toggle={onCancelModalClose}
            sendData={(value:any) => props.onFinish(value)}
            setData={() => { }}
            data={[]}
            view={isCreateOther}
            setView={(value:any) => { setisCreateOther(value) }}
          />
        )
      }

      <div className="container-fluid h-100-vh"  >
        <div className="row h-100">
          <div className="col card h-100 custom-scrollbar overflow-y">
            {shouldSelectCategory && (
              <main className="d-flex justify-content-center align-items-center h-100">
                <FormGroup>
                  <SelectInput
                    value={null}
                    placeholder="Select a category for the new contact *"
                    onChange={(val: string) => handleSelectCategory(val)}
                    options={totalCategories?.map((category) => ({
                      label: category.name,
                      value: category._id,
                    }))}
                  />
                </FormGroup>
              </main>
            )}

            {!shouldSelectCategory && (
              <>
                {/* Top actions */}
                <div className="sticky-top bg-white row align-items-center p-2" style={{ zIndex: 0 }}>
                  <button
                    className="btn btn-danger text-white typo-body col-auto border-0"
                    onClick={setIsCancelling.bind(null, true)}
                  >
                    Cancel
                  </button>

                  <div className="AddContactDivider"></div>

                  {renderedTabs[currentTab - 1] !== undefined && (
                    <StepButton previous onClick={prevStep} />
                  )}

                  {currentTab < renderedTabs.length - 1 && (
                    <>
                      <StepButton id="next-step-create-org" onClick={nextStep} next />
                    </>
                  )}

                  {currentTab === renderedTabs.length - 1 && (
                    <StepButton id='save-contanct-button' onClick={nextStep} save customText="Save" />
                  )}
                </div>

                {/* Tab View */}
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <ul className="nav nav-tabs mt-3 justify-content-between">
                        <ul className="row">
                          {renderedTabs.map((item, index) => {
                            if (!selectedCategory || !item.type) return null;

                            return (
                              <li
                                key={index}
                                onClick={() => handleTabClick(index)}
                                className={
                                  `nav-item col-auto p-0 cursor-pointer` +
                                  (index === currentTab
                                    ? " cursor-default"
                                    : "")
                                }
                              >
                                <p
                                  className={`nav-link typo-body user-select-none ${currentTab === index ? "active" : ""
                                    }`}
                                  aria-current="page"
                                >
                                  {item.title}
                                </p>
                              </li>
                            );
                          })}
                        </ul>

                        <button
                          type="button"
                          className="btn btn-link text-primary typo-body col-auto p-0"
                          onClick={
                            () => {
                              messageComming('LINK EXISTING CONTACT')
                              //setIsLinkingContact.bind(null, true)
                            }
                          }
                        >
                          LINK EXISTING CONTACT
                        </button>
                      </ul>

                      <div className="row justify-content-end mt-2">
                        <div className="col-auto">
                          <ProgressbarTitle value={0.4} />

                          <Checkbox
                            title="Mark as completed"
                            value={false}
                            onChange={(value) => { }}
                          />
                        </div>
                      </div>

                      {renderedTabs.map((Tab, index) => {
                        const Component = Tab.Component;
                        if (!selectedCategory || !Tab.type) return null;
                        if (!tabsContent[index]) return null;
                        if (index !== currentTab) return null;

                        return (
                          <Component {...Tab.props} ref={Tab.ref} key={index} />
                        );
                      })}

                      <div className="mb-4"></div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <SuccesCreate
            view={modalend}
            setView={setModalEnd}
            contact={contID}
            handleTabClick={handleTabClick}
            setDataIs={(value:any) => setisCreateOther(value)}
          />
        </div>
      </div>
    </>
  );
};

export default AddContact;
