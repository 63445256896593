import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
// import Create from "src/components/Rates/Create/Create";
import Create from "../components/Rates/Create/Create";
import CreateVersion from "../components/Rates/Create/Version";
import useQuery from "../hooks/useQuery";
import RateModel from "../models/RateModel";
import SelectRateCard from "../components/Modals/SelectRateCard";
import GlobalChange from "../components/Rates/GlobalChange/GlobalChange";
import RateComparison from "../components/Rates/RateComparison/RateComparison";
import RateList from "../components/Rates/RateList/RateList";
import RateView from "../components/Rates/RateView/RateView";

import "../styles/rates/rates.scss";
import { layoutConfig } from "../store/layout";
import { useDispatch } from "react-redux";

type RateParams = {
  rateType: string;
  rateCardVersion: string;
};

const Rates: React.FC = () => {
  const query = useQuery();
  const history = useHistory();
  const params = useParams<RateParams>();

  const [isContactViewOpen, setIsContactViewOpen] = useState<boolean>(false);

  const [isComparing, setIsComparing] = useState<boolean>(false);
  const [globalChange, setGlobalChange] = useState<boolean>(false);
  const [showRateSelect, setShowRateSelect] = useState<boolean>(false);

  const [typeRate, setTypeRate] = useState<string>("master");

  const [firstRateId, setFirstRateId] = useState<string>("");
  const [secRateId, setSecRateId] = useState<string>("");

  const [isAddingRateCard, setIsAddingRateCard] = useState<boolean>(false);
  const [isAddingRateCardVersion, setisAddingRateCardVersion] =
    useState<boolean>(false);

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(layoutConfig.setModuleTitle('RATES'));
  }, []);

  const onSelectRateToCompare = (rate: RateModel) => {
    const versionId = rate.rate_card_version[0]._id.toString();
    setFirstRateId(versionId);
  //  setIsComparing(true);
    setShowRateSelect(false);
  };

  const onCompare = (id: string) => {
    setSecRateId(id);
    setShowRateSelect(true);
  };




  const toggleAddingRateCard = (type?: string) => {
    if (isAddingRateCard) {
      query.delete("create");
      history.replace({ search: query.toString() });
    } else {
      query.set("create", type || "");
      history.push({ search: query.toString(), pathname: "/rates" });
    }
    setIsAddingRateCard((prev) => !prev);
  };

  useEffect(() => {
    const { rateType } = params;

    const shouldOpenCreateView = query.get("create");

    if (
      shouldOpenCreateView !== undefined &&
      shouldOpenCreateView !== null &&
      !isAddingRateCard
    ) {
      setIsAddingRateCard(true);
    }

    if (shouldOpenCreateView === undefined || shouldOpenCreateView === null) {
      setIsAddingRateCard(false);
      if (!rateType) {
        history.push("/rates/system");
      }
    }

    if (rateType !== typeRate) {
      setTypeRate(rateType);
    }
  }, [params, isAddingRateCard]);

  return (
    <>
      {isAddingRateCard && <Create />}

      {(isAddingRateCardVersion || params.rateCardVersion) && (
        <CreateVersion
          toggleOnAddRateCardVersion={() =>
            setisAddingRateCardVersion((prev) => !prev)
          }
        />
      )}

      {globalChange && (
        <GlobalChange onGlobalChange={() => setGlobalChange((prev) => !prev)} />
      )}

      {isComparing && (
        <RateComparison
          onGlobalChange={() => setIsComparing((prev) => !prev)}
          firstRateId={firstRateId}
          secRateId={secRateId}
        />
      )}

      {
        <SelectRateCard
          isOpen={showRateSelect}
          onClose={() => setShowRateSelect((prev) => !prev)}
          onSelectRate={onSelectRateToCompare}
          title="Select rate card to compare"
        />
      }

      {!globalChange &&
        !isComparing &&
        !isAddingRateCard &&
        !isAddingRateCardVersion &&
        !params.rateCardVersion && (
          <div className={`container-fluid h-100 `}>
            <div className="row" style={{ height: "98%" }}>
              {!params.rateCardVersion && (
                <div
                  className={`col-4 card px-0 h-100  ${isContactViewOpen ? "d-none" : "d-block"
                    }`}
                >
                  <RateList
                    onAddRateCard={toggleAddingRateCard}
                    typeRate={typeRate}
                    onChangeTypeRate={setTypeRate}
                  />
                </div>
              )}

              <div
                className="col card h-100 overflow-y overflow-x-none custom-scrollbar trans-all px-0"
                style={{ flexGrow: isContactViewOpen ? 1 : 2 }}
              >
                <RateView
                  isOpen={isContactViewOpen}
                  typeRate={typeRate}
                  toggleIsOpen={() => setIsContactViewOpen((prev) => !prev)}
                  onCreateNewVersion={() =>
                    setisAddingRateCardVersion((prev) => !prev)
                  }
                  onGlobalChange={() => setGlobalChange((prev) => !prev)}
                  onCompare={onCompare}
                />
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default Rates;
