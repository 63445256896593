import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { setShowAssetLis } from 'src/store/stockpile/RecursosVisualeSlice';
import { setDataAsset } from 'src/store/stockpile/selectAssetsSlice';
import { RootState } from 'src/store/store';
import { setCategoryFilter, setSubcategoryFilter } from '../../../store/stockpile/categorieSlice';
import DoubleClick from 'src/components/Behaviors/DoubleClicks';
import ConfirmDelete from 'src/components/Modals/Categories/ConfirmDelete'
import { BlackbookService } from 'src/services/blackbook';


interface optionModel {
  name: string
  action: any
}

interface Props {
  title: string;
  count?: number;
  options?: Array<optionModel>;
  category: any;
  selected?: any
  setSelect?: any,
  index?: any,
}

const CategoryItem: React.FC<Props> = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const selected: any = useSelector((state: RootState) => state.categorieSlice.categorieActive);

  const [dropdown, setDropdown] = useState<boolean>(false)

  const [confirmD, setConfirmD] = useState<boolean>(false)

  const [subSelect, setSubSelect] = useState('')

  useEffect(() => {
    if (selected == props.index) {
      setDropdown(true)
    } else {
      setDropdown(false)
    }
  }, [selected])


  const Delete = () => {

    BlackbookService.DeleteCategory(props.category._id)
    setConfirmD(false)

  }

  const onCancelModalClose = () => {
    setConfirmD(false)
  }


  return (
    <DoubleClick onDoubleClick={() => {
      setConfirmD(true)
    }} >
      <div onClick={() => {
        props.setSelect(props.index)

        dispatch(setCategoryFilter(props.category._id))


        dispatch(setShowAssetLis(false))
        dispatch(setDataAsset([]))
        history.push(`/stockpile`)
      }}>
        <li onClick={() => {
          if (props.options) {
            dropdown ? setDropdown(false) : setDropdown(true)
          }
          dispatch(setSubcategoryFilter('any'))
        }} className="text-primary typo-body mb-1 row  justify-content-between align-items-center contact-list-item" style={{ flexDirection: 'row' }}>
          <p style={props.selected === props.index ? { fontWeight: 'bold' } : { color: '#344469' }} className={`col-auto m-0 ${props.count && 'dropdown-toggle'} `}>{props.title}</p>
          {props.category.element_count != "0" && props.category.element_count && props.selected == props.index && (
            <p className="m-0 col-auto contact-trailing typo-body bg-primary me-3 ">
              {props.category.element_count}
            </p>
          )}
        </li>
        {

          dropdown && props.options?.map((option, index: any) => {
            return (
              <li
                style={index === subSelect ? { fontWeight: 'bold', marginLeft: '1%', fontSize: '12.6px' } : { color: '#344469', marginLeft: '1%' }}
                onClick={() => {
                  option.action()
                  setSubSelect(index)
                }} className="text-primary typo-body mb-1 row p-0 justify-content-between align-items-center contact-list-item">
                <p className="col-auto m-0">{option.name}</p>
              </li>
            )
          })
        }

      </div>

      {confirmD && (<ConfirmDelete

        title="Are you sure?"
        description="This categorie will be deleted"
        confirmText="Delete"
        cancelText="cancel"
        onClose={onCancelModalClose}
        onConfirm={Delete}
      />)}
    </DoubleClick>
  );
};

export default CategoryItem;
