import FlatList from "flatlist-react";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Table } from "reactstrap";
import AdvanceSearch from "../../Contacts/ContactList/AdvanceSearch";
import ContactSection from "../../Contacts/ContactList/ContactSection";
import IconButton from "../../UI/buttons/IconButton";
import { RootState } from '../../../store/store'
import { GetAllRateForItem, getAssetForItem, GetItemForID } from "../../../store/stockpile/stockpileSlice";
import AssetData from "../AddItem/tabs/AssetData/AssetData";
import Scanner from "../../Scanner/scanner";
import { getCommentsForAsset, getAssetForID, cleanAsset, setDataAsset } from "../../../store/stockpile/selectAssetsSlice";
import ButtonAssetFilters from "../../UI/buttons/ButtoAssetFilter";
import moment from "moment";
import BarcodeIcon from '../../../assets/icons/barcode.svg'
import SearchIcon from '../../../assets/icons/search.svg'
import { useParams } from "react-router-dom";
import { setselectedState } from "src/store/stockpile/RecursosVisualeSlice";
import { EmptyForm } from "src/store/stockpile/EditFormSlice";


interface props {
    data: string
}

const AssetList: React.FC<any> = (props) => {
    const [search, setSearch] = useState<string>("")
    const AssetsData: any = useSelector((state: RootState) => state.stockpileSlice.AssetData);
    const ASSET_SELECTED: any = useSelector((state: RootState) => state.selectAssetsSlice.data);
    const ItemGetted = useSelector((state: RootState) => state.stockpileSlice.ItemGetted) || [];
    const FilterData: Array<any> = useSelector((state: RootState) => state.stockpileSlice.AssetData);
    const FullScreen = useSelector((state: RootState) => state.RecursosVisualeSlice.FullWidth);
    const RatesForItem = useSelector((state: RootState) => state.stockpileSlice.RatesForItem);
    const selectedState = useSelector((state: RootState) => state.RecursosVisualeSlice.selectedState);
    const LoadingAssets = useSelector((state: RootState) => state.stockpileSlice.LoadingAssets);

    const [assets, setAssets] = useState<any>(AssetsData ? AssetsData : [])
    const [assetsFilter, setAssetsFilter] = useState<any>([])
    const [assetSelected, setAssetSelected] = useState<any>({ serial: 'test' })
    const [statusSelected, selectStatus] = useState<any>('')
    const [scannerModal, setScannerModal] = useState<boolean>(false)
    const [scannerCode, setScannerCode] = useState<any>('')
    const [closeHead, setcloseHead] = useState<any>(true)

    const dispatch = useDispatch()
    const params = useParams<{ itemId: string; }>();

    const sections = props.data

    const { innerHeight } = window

    useEffect(() => {
        setTimeout(() => {
            if (params.itemId) {
                // dispatch(getAssetForItem(params.itemId))
                dispatch(GetAllRateForItem(params.itemId))
                dispatch(GetAllRateForItem(params.itemId))
            }

        }, 500)
    }, [])


    const CheckState = async () => {
        if (FilterData.length < 1) {
            dispatch(setDataAsset({ item: ASSET_SELECTED?.item }))
            //  dispatch(setAssetSelected(''))
        }
    }


    useEffect(() => {
        CheckState()

    }, [AssetsData])


    const bySearch = (asset: any, search: any) => {
        if (search) {
            return asset.serial.toLowerCase().includes(search.toLowerCase());
        } else return asset;
    };

    const byStatus = (asset: any, status: any) => {
        if (status) {
            return asset.status === status;
        } else return asset;
    };

    const isObject = (val: any) => {
        return val instanceof Array;
    }

    const filteredList = (assets: any, search: any, status: any, code: any) => {

        return assets.filter((asset: any) => byStatus(asset, status))
            .filter((asset: any) => bySearch(asset, search))
            .filter((asset: any) => bySearch(asset, code))

    };


    const handleSearch = (e: any) => {
        setSearch(e.target.value)
    }

    const ActionSelectAsset = async (ItemId: any, index: any) => {
        const response: any = dispatch(getAssetForID(ItemId))
        if (response?.payload) {
            dispatch(getCommentsForAsset(ItemId))
            dispatch(setAssetSelected(ItemId))
        }
        dispatch(setselectedState(index))
        dispatch(EmptyForm([]))

    }

    const Filetered: Array<object> = filteredList(FilterData, search, statusSelected, scannerCode)

    useEffect(() => {
        if (filteredList.length < 1) {
            [1,2,3,4].map(() => dispatch(setDataAsset([])) )
            
        }
    }, [Filetered])


    return (
        <div className="p-0">
            <div className="d-flex flex-column h-100">
                <div className="contact-header d-flex flex-column mt-0  h-25">

                    <div className="d-flex row col-12 pt-0 mt-0 overflow-hidden ps-2 pb-0" >


                        <div className="col-lg-1 col-md-12 col-sm-12 col-12 pe-5">
                            <Button onClick={() => { selectStatus(null) }} size="small" className="btn-tertiary active-point me-5 mt-1"
                                style={{ width: "50px", height: '23px' }}
                            >
                                <p style={{
                                    fontSize: '10px',
                                    color: 'white',
                                    fontFamily: 'poppins'
                                }}>ALL</p>
                            </Button>
                        </div>
                        <div className="col-lg-auto col-md-12 col-sm-12 col-12  ms-0 p-0 ">
                            <ButtonAssetFilters
                                title="available"
                                status={'success'}
                                onClick={() => selectStatus('0')}
                            />
                        </div>
                        <div className="col-auto ms-0 p-0 ">
                            <ButtonAssetFilters
                                title="in use"
                                status={'primary'}
                                onClick={() => selectStatus(1)}
                            />
                        </div>
                        <div className="col-lg-3 col-sm-12 ms-0 p-0 ">
                            <ButtonAssetFilters
                                title="quarantine"
                                status={'warning'}
                                onClick={() => selectStatus(2)}
                            />
                        </div>

                    </div>
                </div>
                <div className="row mt-2" >
                    <div className="input-icon col-9 ms-2" style={{ justifyContent: 'center' }} >
                        <img src={SearchIcon} alt="" style={{ width: '16px', height: '16px', marginTop: '2%', marginLeft: '5%' }}
                        />
                        <Input
                            className="form-control py-3 searchAssetsItems"
                            type="search"
                            id="example-search-input"
                            placeholder="Search serial number"
                            value={search}
                            onChange={handleSearch}
                            style={{ minWidth: '14%', height: '37px', fontSize: '12px' }}
                        />
                    </div>
                    <div className="p-0 col-2 mt-0 ms-1" >
                        <div>
                            <Button onClick={() => setScannerModal(true)} className="btn btn-text" color="Link"
                                style={{
                                    width: '45px', height: '37px', border: '1px solid #DEE2E6',
                                }}>
                                <img src={BarcodeIcon} alt="" style={{ width: '21px', height: '15px' }} />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <Scanner
                isOpen={scannerModal}
                setCode={setScannerCode}
                setStateModal={setScannerModal}
            />

            <div className="p-0 ">
                <div className="p-0 mt-1 overflow-auto">

                    <Table className='table-striped table-borderless table-hover '>
                        <thead>
                            <tr>
                                <th className="sortable HeadTableFonts text-responsiveSmall" >
                                    Asset number
                                </th>
                                <th className="HeadTableFonts text-responsiveSmall" >
                                    Serial Number
                                </th>
                                <th className="HeadTableFonts text-responsiveSmall" >
                                    Usage
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {LoadingAssets ? (
                                <div className="d-flex justify-content-center align-items-center h-100">
                                    <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block"></i>
                                </div>
                            ) : (
                                <FlatList
                                    list={
                                        filteredList(FilterData, search, statusSelected, scannerCode)
                                    }
                                    renderItem={(item: any, index: any) => {

                                        setcloseHead(true)
                                        let color = ''

                                        switch (item.status) {
                                            case 0:
                                                color = '#C6D763'
                                                break;

                                            case 1:
                                                color = '#6B8AB8'
                                                break;

                                            case 2:
                                                color = '#F6CE49'
                                                break;

                                            default:
                                                break;
                                        }


                                        return (
                                            <tr
                                                key={index}
                                                onClick={() => {
                                                    ActionSelectAsset(item._id, index)
                                                }}

                                                className={
                                                    index == selectedState ? 'selected-row' : 'normal-row inter'
                                                }
                                                style={index == selectedState ? {
                                                    background: '#A6B9D4', color: 'white', fontWeight: 'bold',
                                                    maxWidth: '100px'
                                                } : { maxWidth: '100px' }}

                                            >

                                                <img onLoad={() => {
                                                    if (index === 0) {
                                                        if (index == selectedState) {


                                                            dispatch(getCommentsForAsset(item._id))
                                                            dispatch(setselectedState(index))
                                                        }
                                                    }
                                                }} src="https://www.google.co.ve/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png" alt="" style={{ display: 'none' }} />
                                                <td >
                                                    <div style={{ display: 'flex' }} className="text-responsiveSmall">
                                                        <div style={{
                                                            width: '10px',
                                                            height: '10px',
                                                            backgroundColor: color,
                                                            borderRadius: '100%',
                                                            marginTop: '13px',
                                                            marginRight: '9px'
                                                        }} >
                                                        </div>
                                                        {item._id} <br />{item.is_external ? 'External' : 'Direct'}
                                                    </div>
                                                </td>
                                                <td style={{ maxWidth: '10px' }}>
                                                    <p
                                                        className="text-responsiveSmall"
                                                        style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                                                    >{item.serial}</p>
                                                </td>
                                                <td className="text-responsiveSmall" >
                                                    190 hours
                                                </td>
                                            </tr>
                                        );
                                    }}
                                    renderWhenEmpty={() => (<p></p>)}
                                />)}
                        </tbody>
                    </Table>
                    {Filetered.length < 1 && FilterData.length > 0 && !LoadingAssets && (
                        <div className="d-flex flex-column justify-content-center align-items-center h-100 text-muted mx-5 text-center">
                            <i className="fs-3 bi bi-back d-block"></i>
                            <p className="h6">No items were found for the current filters.</p>
                        </div>
                    )}
                    {
                        FilterData.length < 1 && !LoadingAssets && <div className="d-flex flex-column justify-content-center align-items-center h-100 text-muted mx-5 text-center">
                            <i className="fs-3 bi bi-back d-block"></i>
                            <p className="h6">No assets were found for the current item.</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default AssetList

