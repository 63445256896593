import React from "react";
import { formatNumber } from "../../../../../../util";

interface Props {
    isSubRow?: boolean;
    isExpand?: boolean;
    isVisible: boolean;
    name: string;
    subTotal: number; 
    onExpand: () => void;
    onChange: (name: string, value) => void;
    onDelete: () => void;
    onAddSubHeader?: () => void;
    onAddItems: () => void;
    onDrag: (e) => void;
    onDrop: (e) => void
}

const MainRow: React.FC<Props>  = (props) => {
    const {name, subTotal, isSubRow, isExpand, isVisible, onExpand, onChange, onDelete, onAddSubHeader, onAddItems, onDrop, onDrag} = props;

    return(
        <tr className={`quote-row ${isVisible? '': 'd-none'} ${isSubRow? 'sub-header': 'header'} text-secondary align-middle`} draggable onDrop={(e) => onDrop(e)} onDragStart={(e) => onDrag(e)} onDragOver={(e) => e.preventDefault()}>
            <td colSpan={8}>
                <div className="d-flex align-items-center position-relative">
                    <i className="bi bi-list fs-3 cursor-pointer" style={{marginLeft: isSubRow? '1rem' : '0rem'}}></i>
                    <input type="text" name="name" className="form-control h-25 w-25 ms-3" value={name} onChange={(e) => onChange(e.target.name, e.target.value)}/>
                    <i className={`bi ${isExpand? 'bi-caret-up-fill' : 'bi-caret-down-fill'} ms-2 cursor-pointer`} onClick={onExpand}></i>
                    <div className="cursor-pointer ms-3 d-flex align-items-center" onClick={onAddItems}> 
                        <i className="bi bi-plus-circle-fill text-primary fs-6 me-2" />
                        <span className=" text-primary text-decoration-underline">ADD ITEMS </span>
                    </div>
                    {isSubRow || 
                        <div className="cursor-pointer ms-3 d-flex align-items-center position-absolute end-0" onClick={onAddSubHeader}>
                            <i className="bi bi-plus-circle-fill text-primary fs-6 me-2" />
                            <span className=" text-primary text-decoration-underline">ADD SUB-HEADER</span>
                        </div>
                    }
                </div>
            </td>
            <td>
                <span className="fs-6">{formatNumber(subTotal || 0)}</span>
            </td>
            <td>
                <i className="bi bi-dash-circle text-danger cursor-pointer fs-5" onClick={onDelete}></i>
            </td>
        </tr>
    )
}
export default MainRow;