import AdvanceSearch from "../../Stock/StockList/AdvanceSearch";

interface Props {
    setAdvanceCategory:any
    setWords:any
    setType:any
}

const AdvanceSearchList: React.FC<Props> = (props) => {

    const { setWords ,setType ,setAdvanceCategory } = props

    return(
        <div className={`col-12  mt-2 mb-1 ${false && "d-none"}`}>
        <button
          className="btn btn-link typo-body p-0 float-end me-2"
          data-bs-toggle="dropdown"
          style={{ boxShadow: "none" }}
          onClick={() => {
            // dispatch(setCategoryFilter('any'))
            // dispatch(setCategorieActive(888876373900000))
          }}
        >
          ADVANCED SEARCH
        </button>
        {
          <AdvanceSearch
            setAdvanceCategory={setAdvanceCategory}
            setWords={setWords}
            setType={setType}
          />
        }
      </div>
    )
}

export default AdvanceSearchList