import { useState, useRef, useEffect } from 'react';
import ConfirmModal from '../../Modals/ConfirmModal';
import Checkbox from '../../UI/forms/Checkbox';
import ProgressbarTitle from '../../UI/ProgressBarForm';
//import Description from './tabs/Description';
import Rates from './tabs/Rates/Rates';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/store'
import checkFields from '../../../util/CheckFields'

import Description from './tabs/Description'
import Items from './tabs/Items/Items';
import { AddAssetEmpty, AddSpecificAssetsPackage, CreatePackage, EmptyPackageForm, setCheckItemFormAlert } from '../../../store/stockpile/packageItems';
import { PackageModel } from '../../../models/ItemModel';
import { CreateRates, RefreshForNewItem } from 'src/store/stockpile/stockpileSlice';
import StepButton from 'src/components/UI/buttons/stepButton';
import { useToast } from 'src/hooks/useToast';
import { setCategoryFilter } from 'src/store/stockpile/categorieSlice';
import { useHistory } from 'react-router-dom';


interface Props {
  onFinish: () => void;
}

const tabs: string[] = [
  'Description',
  'Items',
  'Rates'
];


const AddStockPackage: React.FC<Props> = (props) => {
  const history = useHistory()
  const toast = useToast()
  const dispatch = useDispatch()
  const [checkItemForm, setCheckItemForm] = useState<any>(false);


  const [currentTab, setCurrentTab] = useState<number>(0);
  const [isCancelling, setIsCancelling] = useState<boolean>(false);
  const [images, setImagesForm] = useState<Array<any>>([]);

  const [errorQty, setErrorQty] = useState<any>(false);


  const [dateCount, setDateCount] = useState<
    { type: string; date: string }[]
  >([{ type: 'date of purchase', date: '' }]);

  /////CREATE ITEM CHECK
  const ItemID = useSelector((state: RootState) => state.stockpileSlice.ItemResponse);
  const checkItemFormAlert = useSelector((state: RootState) => state.packageItems.checkItemFormAlert);

  /////Errors 
  const Rtes = useSelector((state: RootState) => state.packageItems.RatesErrors);
  const Desc = useSelector((state: RootState) => state.packageItems.DescriptionErrors);
  const Itms = useSelector((state: RootState) => state.packageItems.ItemsErrors);


  /////FORMS
  const DescriptionData = useSelector((state: RootState) => state.packageItems.Description);
  const rates = useSelector((state: RootState) => state.packageItems.RatesData);
  const ItemsPackage = useSelector((state: RootState) => state.packageItems.Items);
  const Assets = useSelector((state: RootState) => state.packageItems.Assets);
  const AssetsPackage: Array<any> = useSelector((state: RootState) => state.packageItems.PackageAssets);


  const [maxValue, setMaxValue] = useState<any>(ItemsPackage.map((as: any) => {
    return 1
  }))
  //const accesories = useSelector((state: RootState) => state.createFormSlice.AccesoriesData);

  const progress = {
    ...DescriptionData, ...rates
  }
  useEffect(() => {
    [DescriptionData].map((item) => {
      if (item.name) {
        setCheckItemForm(true)
      } else { setCheckItemForm(false) }
    })

  }, [checkItemForm, DescriptionData])

  // const percenCompletion = (1 - checkFields(progress).percent)
  const percenCompletion = 0.05

  const tabsContent: JSX.Element[] = [
    <Description
      alertComplete={checkItemFormAlert}
    />,
    <Items toggleAddingPackage={props.onFinish} maxValue={maxValue} setMaxValue={setMaxValue} />,
    <Rates />,
  ];

  const onCancelModalClose = (value?: boolean) => {
    setIsCancelling(false);

    if (value === undefined) {
      return;
    }

    if (!value) {
      props.onFinish();
      dispatch(EmptyPackageForm())
    }
  };

  const prevStep = () => {
    setCurrentTab((prev) => {
      if (prev > 0) {
        return prev - 1;
      }
      return prev;
    });
  };

  const nextStep = () => {
    setCurrentTab((prev) => {
      if (prev < tabs.length - 1) {
        return prev + 1;
      }
      return prev;
    });
  };

  const setItem = async () => {
    if (!Rtes) {
      const res: any = await dispatch(CreatePackage({
        ...DescriptionData,
        items: ItemsPackage,
        assets: Assets
      } as PackageModel))
    

      if (res.payload) {
        dispatch(RefreshForNewItem(res.payload))

        history.push("/stockpile/" + res?.payload?._id)

        dispatch(setCategoryFilter('any'))


        ////Create Rates
        const AllDataRates = {
          data: rates,
          item: res.payload._id.toString()
        }
        const resRates = await dispatch(CreateRates(AllDataRates))

        dispatch(EmptyPackageForm())
        toast.success('Save package succesfull')
        props.onFinish()
      }



    } else {
      alert('hay errores en el formulario')
    }
  }

  useEffect(() => {
    dispatch(AddSpecificAssetsPackage(ItemsPackage.length))
  }, [ItemsPackage])


  useEffect(() => {
    ItemsPackage.map((item: any, index: any) => {
      if (AssetsPackage[index] == null || AssetsPackage[index] == undefined) {
        dispatch(AddAssetEmpty(index))
      }
    })
  }, [AssetsPackage])

  return (
    <>
      {isCancelling && (
        <ConfirmModal
          title="Are you sure?"
          description="This new item will be discarted"
          confirmText="KEEP EDITING"
          cancelText="DISCARD ITEM"
          onClose={onCancelModalClose}
        />
      )}

      <div className="container-fluid h-100">
        <div className="row" style={{ height: '98vh', maxWidth: '1500px' }}>
          <div className="col card h-100 pt-2">
            {/* Top actions */}
            <div className="row justify-content-between align-items-center p-2
            col-lg-12 col-md-auto col-sm-12 col-12 align-self-center
            "
              style={{ position: 'absolute', background: 'white', zIndex: 1 }}>

              <div className='p-0 m-0 border-0 col-auto row'>

                <button
                  onClick={setIsCancelling.bind(null, true)}
                  className="btn btn-danger text-white typo-body col-auto border-0">
                  Cancel
                </button>
                <div className='p-0 m-0 border-0 col-auto ms-1'>
                  {currentTab > 0 ? (
                    <StepButton onClick={prevStep} previous />
                  ) : (<div style={{ width: '110px' }}> </div>)}
                </div>
              </div>

              <h4
                className='text-primary col-auto' >Create Package</h4>

              <div className='p-0 m-0 border-0 col-auto row justify-content-between'>
                {currentTab < tabs.length - 1 && (
                  <div className='p-0 m-0 border-0 col-auto me-1'>
                    <StepButton onClick={
                      !Desc ? !checkItemForm ? () => { dispatch(setCheckItemFormAlert(true)) } : nextStep : () => { }
                    } next />
                  </div>
                )}

                <button onClick={setItem} className="btn btn-success text-white typo-body col-auto border-0">
                  Save Package
                </button>

              </div>

            </div>
            <div className="custom-scrollbar overflow-y h-100 mt-4">
            {/* Tab View */}
            <div className={`container mt-5`}  >
              <div className="row mt-4">
                <div className="col">
                  <ul className="nav nav-tabs mt-3 justify-content-between">
                    <div className="row">
                      {tabs.map((item, index) => {
                        return (
                          <li
                            key={index}
                            className="nav-item col-auto p-0"
                            onClick={!Desc ? !checkItemForm ? () => { dispatch(setCheckItemFormAlert(true)) } : setCurrentTab.bind(null, index) : () => { }}
                          >
                            <p
                              className={`nav-link typo-body user-select-none ${currentTab === index ? 'active' : !Desc ? !checkItemForm ? 'disabled' : '' : 'disabled'

                                }`}
                              aria-current="page"
                            >
                              {item}
                            </p>
                          </li>
                        );
                      })}
                    </div>
                  </ul>

                  <div className="row justify-content-end mt-2">
                    <div className="col-auto">
                      <ProgressbarTitle value={
                        [
                          DescriptionData?.name,
                          DescriptionData?.picture,
                          DescriptionData?.description,
                          ItemsPackage,
                          rates?.daily,
                          rates?.holiday_day,
                          rates?.holiday_hour,
                          rates?.holiday_overtime_hour,
                          rates?.hour,
                          rates?.overtime_hour,
                          rates?.sale,
                          rates?.week ,
                        ]
                        } />

                      <Checkbox
                        title="Mark as completed"
                        value={false}
                        onChange={(value) => { }}
                      />
                    </div>
                  </div>

                  {tabsContent[currentTab]}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default AddStockPackage;
