import React, { useState } from "react";
import { Modal } from "reactstrap";
import Checkbox from "../../../../../UI/forms/Checkbox";
import InputFloatingLabel from "../../../../../UI/forms/InputFloatingLabel";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onApply: (value: number) => void;
}
const GlobalDiscount: React.FC<Props> = (props) => {
  const [value, setValue] = useState(0);

  const handleApplyDiscount = () => {
    props.onApply(value);
    props.onClose();
  }

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.onClose}
      centered
      // style={{ width: "600px", maxWidth: "750px" }}
    >
      <div
        className={
          "text-center d-flex flex-column custom-scrollbar overflow-hidden"
        }
        style={{ height: "320px" }}
      >
        <div
          onClick={props.onClose}
          className="cursor-pointer p-2"
          style={{ width: "30px" }}
        >
          <i
            className="bi bi-x-lg  "
            style={{
              fontSize: "18px",
              marginRight: "300px",
              height: "150px",
              padding: "10px",
            }}
          ></i>
        </div>
        <div className="row ">
          <div
            className="p-3 d-flex flex-column"
            style={{ marginBottom: "-15px" }}
          >
            <h1 className="text-secondary fw-bold">Apply global discount</h1>

            <div className="m-auto mt-2" style={{ width: "200px" }}>
              <InputFloatingLabel
                placeHolder="Global discount"
                onChange={(value) => setValue(parseFloat(value))}
                value={`${value}`}
                inputType="number"
                errorMessage={value > 100? 'Must be less than or equal to 100': undefined}
              />
              <p
                className="text-gray"
                style={{ fontSize: "12px", marginBottom: "-2px" }}
              >
                APPLY TO:
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-center m-auto mb-4 ms-3">
            <div className="me-5">
              <Checkbox
                title="External"
                value={false}
                onChange={(value) => {}}
              />
            </div>
            <div className="me-5">
              <Checkbox title="Items" value={false} onChange={(value) => {}} />
            </div>
            <div className="me-5">
              {" "}
              <Checkbox title="Sales" value={false} onChange={(value) => {}} />
            </div>
            <div className="me-5">
              <Checkbox
                title="Services"
                value={false}
                onChange={(value) => {}}
              />
            </div>
          </div>

          <div className="d-flex align-items-end pt-2">
            <button
              className="btn btn-success text-white m-auto "
              style={{ width: "120px", height: "40px" }}
              onClick={() => handleApplyDiscount()}
            >
              Apply discount
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GlobalDiscount;
