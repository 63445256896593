import InputFloatingLabel from '../../../../UI/forms/InputFloatingLabel';
import RatesSchema from './validations';
import { useFormik, Form, FormikProvider } from 'formik';
import { RatesModel } from '../../../../../models/ItemModel';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { setRates, changeRatesErrors } from '../../../../../store/stockpile/packageItems';
import { useEffect } from 'react';
import { Table } from 'reactstrap';
import FlatList from 'flatlist-react';


const Rates: React.FC = () => {


  const dispatch = useDispatch()
  const data = useSelector((state: RootState) => state.packageItems.RatesData)
  const Rates = useSelector((state: RootState) => state.packageItems.RatesDataInfo)


  const formik = useFormik({
    validationSchema: RatesSchema,
    initialValues: {
      ...data
    },
    onSubmit: () => {

    }
  })

  const { errors, touched, handleSubmit, values, setFieldValue } = formik

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      dispatch(changeRatesErrors(true))
    } else {
      dispatch(changeRatesErrors(false))
    }
  }, [errors])

  useEffect(() => {
  }, [])

  return (
    <div className="container-fluid px-3 mt-3">
      <p className="m-0 text-primary typo-body">STANDARD RATE CARD</p>
      <FormikProvider value={formik} >
        <Form autoComplete="off" noValidate onSubmit={(e) => { }}  >
          <div className="row mt-2">
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Hour rate"
                value={values.hour.toString()}
                onChange={(value) => {
                  setFieldValue('hour', value)
                  dispatch(setRates({ hour: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.hour}
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Day rate"
                value={values.daily.toString()}
                onChange={(value) => {
                  setFieldValue('daily', value)
                  dispatch(setRates({ daily: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.daily}
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Week rate"
                value={values.week.toString()}
                onChange={(value) => {
                  setFieldValue('week', value)
                  dispatch(setRates({ week: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.week}
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Sell rate"
                value={values.sale.toString()}
                onChange={(value) => {
                  setFieldValue('sale', value)
                  dispatch(setRates({ sale: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.sale}
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Overtime hour rate"
                value={values.overtime_hour.toString()}
                onChange={(value) => {
                  setFieldValue('overtime_hour', value)
                  dispatch(setRates({ overtime_hour: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.overtime_hour}
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Holiday hour rate"
                value={values.holiday_hour.toString()}
                onChange={(value) => {
                  setFieldValue('holiday_hour', value)
                  dispatch(setRates({ holiday_hour: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.holiday_hour}
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Holiday day rate"
                value={values.holiday_day.toString()}
                onChange={(value) => {
                  setFieldValue('holiday_day', value)
                  dispatch(setRates({ holiday_day: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.holiday_day}
              />
            </div>

            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <InputFloatingLabel
                placeHolder="Holiday overtime rate"
                value={values.holiday_overtime_hour.toString()}
                onChange={(value) => {
                  setFieldValue('holiday_overtime_hour', value)
                  dispatch(setRates({ holiday_overtime_hour: parseInt(value) } as RatesModel))
                }}
                errorMessage={errors?.holiday_overtime_hour}
              />
            </div>
            <div className='col-12'>
              <hr />
              <p className='contact-title' style={{ color: '#8898AA', fontSize: '10px' }}>
                INDIVIDUAL COST
                <hr />
              </p>
              <Table className='table table-striped table-borderless table-hover w-100'>
                <thead>
                  <tr>
                    <th scope="col" className="sortable HeadTableFonts" >
                      item name
                    </th>
                    <th scope="col" className="sortable HeadTableFonts" >
                      type
                    </th>
                    <th className="HeadTableFonts" scope="col">
                      hour
                    </th>
                    <th className="HeadTableFonts" scope="col">
                      day
                    </th>
                    <th className="HeadTableFonts" scope="col">
                      week
                    </th>
                    <th className="HeadTableFonts" scope="col">
                      sale
                    </th>
                  </tr>
                </thead>
                <tbody>


                  <FlatList
                    list={Rates}
                    renderItem={(rate: any, index: any) => {
                      return (
                        <>
                          <tr style={{ height: '48px' }}>
                            <td>
                              <p className='PoppinsBold'>
                                TOTAL INDIVIDUAL COST  X {rate.Qty}
                              </p>
                            </td>
                            <td>
                              <ul className='tableList' style={{ marginLeft: '-10%' }}>
                                <li><p className='PoppinsBold'>Regular</p></li>
                                <li><p className='PoppinsBold'>Overtime</p></li>
                                <li><p className='PoppinsBold'>Holiday</p></li>
                                <li><p className='PoppinsBold'>Holiday overtime</p></li>
                              </ul>
                            </td>
                            <td>
                              <ul className='tableList' style={{ marginLeft: '-18%' }}>
                                <li><p className='PoppinsBold'>{rate.hour?'$' + rate.hour:'-'}</p></li>
                                <li><p className='PoppinsBold'>{rate.overtime_hour?'$' + rate.overtime_hour: '-' }</p></li>
                                <li><p className='PoppinsBold'>{rate.holiday_hour?'$'+ rate.holiday_hour: '-' }</p></li>
                                <li><p className='PoppinsBold'>{rate.holiday_overtime_hour?'$' + rate.holiday_overtime_hour : '-'}</p></li>
                              </ul>
                            </td>
                            <td>
                              <ul className='tableList' style={{ marginLeft: '-18%' }}>
                                <li><p className='PoppinsBold'>{rate.daily?'$'+rate.daily:'-'}</p></li>
                                <li><p className='PoppinsBold'>{rate.holiday_day?'$'+rate.daily:'-'}</p></li>
                              </ul>
                            </td>
                            <td>
                              <p className='PoppinsBold'>{rate.week?'$'+rate.daily:'-'}</p>
                            </td>
                            <td>
                              <p className='PoppinsBold'>{rate.sale?'$'+rate.daily:'-'}</p>
                            </td>
                          </tr>
                        </>
                      );
                    }}
                    renderWhenEmpty={() => {
                      return (
                        <>
                          <p style={{ color: 'lightgray', marginLeft: 8 }} >No results</p>
                        </>
                      )
                    }}
                  />
                </tbody>
              </Table>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default Rates;
