import { useEffect } from "react";
import { useToast } from "src/hooks/useToast";

interface states {
    cargado: boolean,
    setCargado: Function
  }
  
const useValidateRates = (RatesItemSelected:any, states: states) => {
    const toast = useToast()
    const { cargado, setCargado } = states
  
    useEffect(() => {
      setCargado(true);
  
      if (RatesItemSelected.length < 1 && cargado === true) {
        toast.error('The item selected has no rates');
      }
    }, [RatesItemSelected, cargado]);
  
    return cargado;
  };

  export default useValidateRates
  