import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DUMMY_CONTACTS from '../../../dummy_data/contacts.json';
import ContactItem from '../../Contacts/ContactList/ContactItem';
import PackageModal from '../../Modals/PackageModal/PackageModal';
import { RootState } from '../../../store/store';
import { GetCategories, setCategoryFilter, GetTotalInvetory, setSubcategoryFilter, setCategorieActive } from '../../../store/stockpile/categorieSlice';
import SimpleButton from '../../UI/buttons/SimpleButton';
import { useToast } from 'src/hooks/useToast';
import TotalValueStockpile from '../../Molecules/TotalValueStockpile'
import CategorietListComponent from '../../Molecules/CategorieListComponent'

interface Props {
  toggleAddingPackage: any
}

const StockCategories: React.FC<Props> = (props) => {
  const toast = useToast()
  const dispatch = useDispatch()
  const TotalPrice = useSelector((state: RootState) => state.categorieSlice.TotalPrice);
  const [itemsPreselect, setPreselectItems] = useState<Array<any>>([])
  const [CreatePackageModal, setViewPackageModal] = useState<boolean>(false)

  useEffect(() => {
    dispatch(GetCategories())
    dispatch(GetTotalInvetory())
  }, [])



  const messageComming = (message: any) => {
    toast.info(message + ' is coming soon!')
  }

  return (
    <div className="d-flex flex-column h-100">
      <div className="h-100 custom-scrollbar overflow-x-none overflow-y ">
        <CategorietListComponent
        
          setViewPackageModal={setViewPackageModal}
          messageComming={messageComming}
        />
      </div>
      <PackageModal
        View={CreatePackageModal}
        setView={setViewPackageModal}
        toggleAddingPackage={props.toggleAddingPackage}
        itemsPreselect={itemsPreselect}
        setPreselectItems={setPreselectItems}
        Qtys={[]}
      />
      <TotalValueStockpile TotalPrice={TotalPrice} />
    </div>
  );
};

export default StockCategories;
