import React from "react";
import ReactQuill from "react-quill";

interface Props {
  value: string;
  placeHolder?: string;
  onChange: (value: string) => void;
  className?: string;
}
const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      [{ "list": "ordered" }, { "list": "bullet" },
      { "indent": "-1" }, { "indent": "+1" }],
      [{ size: [] }],
      [{ color: [] }, { background: [] }],
      ["clean"]
    ],
    clipboard: {
      matchVisual: false,
    }
  }
  
const TextEditQuill: React.FC<Props> = (props) => {
  return (
    <div>
        <ReactQuill
          onChange={props.onChange}
          value={props.value}
          modules={modules}
          placeholder={props.placeHolder || 'Write your message'}
          style = {{color: "black"}}
          className={`m-2 ${props.className}`}
        />
    </div>
  );
};

export default TextEditQuill;
