import { useEffect, useState } from "react";

interface Props {
    value: any;
    className?: string,
    file?: boolean,
    width?: any
    markedComplete?:any
}

const ProgressBarForm: React.FC<Props> = (props) => {

    const [valuePercent, setvaluePercent] = useState(null)
    ///TABS APROBADAS SON LAS TAPS QUE YA FUERON COMPLETADAS
   /// const [TabsAprobadas, setTabsAprobadas] = useState<any>(0)


    let TABS = props.value
    let TotalTabs = props.value.length

    let CamposCompletados = 0
    let camposTotales = TABS.length

    let camposLlenos = TABS.filter((campo:any) => (campo != '' && campo != undefined)).length


    CamposCompletados = camposLlenos





    let percent = props.markedComplete? 100 : CamposCompletados * 100 / camposTotales







    // const percent:number = props.value * 100
    return (
        <div className={`d-flex flex-column align-items-center ${props.className}`}>
            <p className={`text-primary typo-body text-muted ${props.file && 'd-none'}`} style={{ marginLeft: '-45%', marginBottom: '0px' }}>
                {parseFloat((percent).toFixed(1))}% COMPLETED
            </p>
            {/* <progress value={props.value}></progress> */}
            <div className="progress" style={{ width: props.width ? props.width : '168px', height: '8px' }}>
                <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={props.value}
                    aria-valuemin={0}
                    aria-valuemax={1}
                    style={{ width: `${percent}%` }}
                ></div>
            </div>
        </div>
    );
};

export default ProgressBarForm;
