import moment from "moment";
import { useEffect, useMemo, useState } from "react";

import { SocialAndComm } from "../SocialAndComm";
import InfoDisplay from "src/components/UI/InfoDisplay";
import { ContactType, IContact } from "src/models/ContactModel";
import { IContactContactTypeEx } from "src/util/ContactsFunctions";
import { useHistory } from "react-router-dom";

const ClientContactData: React.FC<{ contact: IContact, extractor: IContactContactTypeEx }> = ({ contact, extractor }) => {
  const contactSinceMoment = useMemo(() => moment(contact.contact_since), [contact.contact_since]);
  const history = useHistory();

  const [modalEdit, setmodalEdit] = useState(false)
  /* This is just for typescript to know that the "entity" field is for ClientData */
  if (contact.type !== ContactType.CLIENT) return null;
  return (
    <div className="container-fluid px-3">
      <div className="row border-bottom p-0 py-3 ">
        <InfoDisplay
          title="general note"
          texts={[
            {
              text: contact.note || 'No note.',
            },
          ]}
          setEditModal={() => setmodalEdit(!modalEdit)}
          editView={modalEdit}
        />
      </div>

      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <InfoDisplay title="nickname" texts={[{ text: contact.entity.nickname || 'No nickname.' }]} />
        </div>
        <div className="col-6">
          <InfoDisplay
            title="email"
            texts={[{ text: contact.entity.email }]}
          />
        </div>
        <div className="col-6">
          <InfoDisplay title="skype" texts={[{ text: contact.entity?.communication?.skype || 'No skype.' }]} />
        </div>
        <div className="col-6">
          <InfoDisplay
            title="personal email"
            texts={[{ text: contact.entity.personal_email || 'No personal email.' }]}
          />
        </div>
        <div className="col-6">
          <InfoDisplay
            title="mobile number"
            texts={[{ text: contact.entity.phone || 'No phone number.' }]}
          />
        </div>
      </div>

      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <InfoDisplay
            title="contact since"
            texts={[
              { text: contactSinceMoment.format('LL') },
              { text: contactSinceMoment.fromNow() }
            ]}
          />
        </div>
      </div>

      <div className="row border-bottom p-0 py-3">
        <div className="col-6">
          <InfoDisplay
            title="organization"

            texts={[{
              text: contact?.entity?.organization?.entity?.name || 'No organization.',
              action: () => {
                history.push(`/blackbook/${contact?.entity?.organization?._id}`)
              }
            }]}
          />
        </div>
      </div>

      <SocialAndComm
        social={contact.entity.social}
        communication={contact.entity.communication}
      />
    </div>
  );
};

export default ClientContactData;