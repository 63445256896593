import { useDispatch, useSelector } from "react-redux";
import { setFullWidth } from '../../../store/stockpile/RecursosVisualeSlice';
import { RootState } from '../../../store/store'

import IconButton from '../../UI/buttons/IconButton';

import TrashIcon from '../../../assets/icons/trash.svg'
import EditIcon from '../../../assets/icons/edit.svg'

interface Props {
    ASSET_DATA: any
    toggleAddingAsset:any
    toggleEditItem:any
    setIsCancelling:any
    toggleEditingAsset:any
    setIsCancellingAsset:any
}

const HeaderStockList: React.FC<Props> = (props) => {

    const { ASSET_DATA , toggleAddingAsset , toggleEditItem , toggleEditingAsset , setIsCancelling , setIsCancellingAsset } = props

    const dispatch = useDispatch()

    const FullWidth: any = useSelector((state: RootState) => state.RecursosVisualeSlice.FullWidth);



    return (
        <header className="w-100 mt-3 d-flex justify-content-between align-items-center ps-1">
            <i
                onClick={() => FullWidth ? dispatch(setFullWidth(false)) : dispatch(setFullWidth(true))}
                className={`${FullWidth
                    ? 'bi bi-arrows-angle-contract'
                    : 'bi bi-arrows-angle-expand'
                    } col-auto  h5 m-0 ExpandIcon`}
            ></i>
            <div className='d-flex ContainerRow' >

                <div className='d-flex me-4 mt-2 ContainerChild' >
                    <p className='text-primary'>ITEM</p>
                    {ASSET_DATA?.serial && <p className='text-primary'>ASSET ({ASSET_DATA?.serial})</p>}
                </div>

                <div className='d-flex ContainerButtons' >

                    <div className="d-flex align-items-center">
                        <IconButton
                            color="primary"
                            icon="bi bi-plus-lg"
                            // tooltip="Add"
                            onClick={() => toggleAddingAsset()}
                            outline
                        />

                        <IconButton
                            color="primary"
                            icon="bi bi-share-fill"
                            // tooltip="Share contact"
                            outline
                        />

                        <IconButton
                        id={'Edit-item-stockview'}
                            color="primary"
                            custom={EditIcon}
                            customStyles={{ width: '12px', height: '12px' }}
                            onClick={() => toggleEditItem()}
                            outline
                        />

                        <IconButton
                            color="danger"
                            custom={TrashIcon}
                            customStyles={{ width: '12px', height: '12px' }}
                            onClick={() => {
                                setIsCancelling(true)
                            }}
                            outline
                        />
                    </div>
                    {ASSET_DATA?.serial && <div className='d-flex align-items-end mt-1 SerialAsset' >
                        <IconButton
                            color="primary"
                            custom={EditIcon}
                            customStyles={{ width: '12px', height: '12px' }}
                            onClick={() => toggleEditingAsset(ASSET_DATA?._id)}
                            outline
                        />

                        <IconButton
                            color="danger"
                            custom={TrashIcon}
                            customStyles={{ width: '12px', height: '12px' }}
                            onClick={() => {
                                setIsCancellingAsset(true)
                            }}
                            outline
                        />
                    </div>}

                </div>

            </div>

        </header>
    )

}

export default HeaderStockList