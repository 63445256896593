import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import EmptyCircle from "./EmptyCircle";
import { setEquipmentItem } from "src/store/stockpile/RecursosVisualeSlice";
import { RootState } from "src/store/store";
import {
  cleanAsset,
  setIdItem,
} from "src/store/stockpile/selectAssetsSlice";
import { getJobsId } from "../../../../../store/factory/factoryJobSlice";
import { setDetailView } from "../../../../../store/factory/factorySlice";

interface Props {
  index?: any;
  onSelect?: (id: string) => void;
  item: any;
  setID: any;
  showAssets: any;
}

const ContactItem: React.FC<Props> = (props) => {

  const DetailView = useSelector(
    (state: RootState) => state.factorySlice.DetailView
  );



  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ id: any }>();



  const handleAsset = () => {
    dispatch(cleanAsset());
    props.showAssets(true);
    dispatch(setEquipmentItem(true));
    history.push(`/stockpile/${props.item._id}`);
    dispatch(setIdItem(props.item._id));
  };


  const getJobsDataId = (id) => {
    dispatch(getJobsId(id));
  };

  useEffect(() => {
    if (params.id) {
      getJobsDataId(params.id)
    }
  }, [])

  useEffect(() => {

    if (params.id == props.item._id) {
      dispatch(setDetailView(props.item._id))
    }
    

  }, [])
  


  const getTypeClass = (type: any) => {
    switch (type) {
      case 'Active':
        return 'active';

        break;
      case 'Approved':
        return 'approved';

        break;

      case 'On Hold':
        return 'onHold'
        break;

      case 'Proposal':
        return 'proposal'
        break;

      case 'Complete':
        return 'complete'
        break;
      default:
        break;
    }
  }

  return (
    // <DoubleClick onDoubleClick={} >
    <div
      className={`border-top p-3 py-2 contact-item  ${props.item._id == params.itemId && "contact-item-selected"
        }`}
        style={DetailView == props.item._id ? { background: 'rgba(107, 138, 184, 0.2)' , color: 'white'} : {}}
    >
      <div className="row ps-1">

        <div className="align-item-center pe-5" style={{ padding: '0.5%', width: '10%' }}>
          <div className="px-1 pe-5">
            {props.item.file_cover && (
              <img
                loading="lazy"
                src={process.env.REACT_APP_IMAGES_AWS + props.item.file_cover }
                alt=""
                className="avatar2"
              />
            )}
            {!props.item.file_cover && (
              <EmptyCircle title={props.item.name} small />
            )}
          </div>

        </div>

        <div
          onClick={() => {
            if (props.item.category == "67807598690173953") {
              handleAsset();
            } else {
              getJobsDataId(props.item._id)
              // dispatch(setEquipmentItem(false));
              // dispatch(setIdItem(props.item._id));
              // dispatch(cleanAsset());
              history.push(`/factory/project/detail/${props.item._id}`);
              dispatch(setDetailView(props.item._id))
            }
          }}
          className={`col d-flex p-0 mt-1 text-truncate  ${props.onSelect ? "ms-3" : "ms-2"
            }`}
        >

          <div className="ms-0">

            <div className="row">
              <p className="m-0 text-black typo-body items-Lists-title">
                {props.item.name}
              </p>
              <div className="row col-5 p-0 m-0">
                <div className="col-6">
                  <div className="col-12 my-0 py-0">
                    <p  className="items-Lists-subtitle">PROJECT NUMBER</p>
                  </div>
                  <div className="col-12 mt-n3">
                    <p className="items-Lists-subtitle" >JOBS</p>
                  </div>
                  <div className="col-12 mt-n3">
                    <p className="items-Lists-subtitle">ORG</p>
                  </div>
                  <div className="col-12 mt-n3 pe-1">
                    <span className={getTypeClass(props.item.status) + ` baseBadge-mini`}>
                      {props.item.status}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row col-5 ps-1 p-0 m-0">
                <div className="col-12">
                  <p className="items-Lists-subtitle-black">{props.item._id}</p>
                </div>
                <div className="col-12 items-Lists-subtitle-container" >
                  <p className="items-Lists-subtitle-black">2</p>
                </div>
                <div className="col-12 mt-n0 " style={{marginTop:'-25%'}}>
                  <p className="items-Lists-subtitle-black">{props.item?.organization?.entity?.name}</p>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
    //  </DoubleClick>
  );
};

export default ContactItem;
