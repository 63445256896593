import SearchIcon from "../../../assets/icons/search.svg";
import { Input } from "reactstrap";


interface Props {
    setItemSearch?: any
    itemSearch?:any
}

const SearchList: React.FC<Props> = (props) => {
    const { setItemSearch , itemSearch } = props


  const handleSearch = (e: any) => {
    setItemSearch(e.target.value);
  };
    
    return (

        <div className="input-icon ps-2 pe-2" style={{ marginTop: "3.5%" }}>
          <img
            src={SearchIcon}
            alt=""
            style={{
              width: "16px",
              height: "16px",
              marginTop: "1.4%",
              marginLeft: "4.4%",
            }}
          />
          <Input
            className="form-control py-2 typo-body text-primary"
            type="search"
            id="example-search-input"
            placeholder="Search"
            value={itemSearch}
            onChange={handleSearch}
            style={{ fontSize: "12px", height: "37px" }}
          />
        </div>
    )

}

export default SearchList;

