import { useSelector } from "react-redux";
import { Input } from "reactstrap";
import React, { useState, useMemo, useCallback, useRef, RefObject } from "react";
import IconButton from "../../../../UI/buttons/IconButton";
import { RootState } from "../../../../../store/store";
import { ABC, getSections } from "../../../../../util/index";
import Scanner from "../../../../Scanner/scanner";
import ItemModel from "src/models/ItemModel";
import SearchIcon from "../../../../../assets/icons/search.svg";
import FilterIcon from '../../../../../assets/icons/filter.svg'
import { useToast } from "src/hooks/useToast";
import ProjectListComponent from "src/components/Molecules/ProjectListComponent";
import FactoryModal from "../../../../Modals/FactoryModal";
import FactoryModalFilter from "../../../../Modals/factoryModalFilter";

interface props {
    data: string;
    onAddContact: any;
    onAddService: any;
    toggleAddingkit: any;
    view?: boolean;
    setView?: any;
    toggle?: any;
    activeControlView?: string;
    setActiveControlView?: any;
    selectJob: string | null;
    setSelectJob?: any;
    activeTask?: string | null;
    setActiveTask?: any;
    selectProject?: any;
    setSelectProject?: any;
    setViewOpenModalFactory?: any;
    closeModalJob: boolean;
    setCloseModalJob: any;
    viewOptions?: boolean;
    setViewOptions?: any;
}

const ProjectList: React.FC<any> = (props) => {
    const toast = useToast();
    const [advancedFilters, setAdvancedFilters] = useState<any>({});
    const [search, setSearch] = useState<string>('');
    const [sortby, setSortBy] = useState<string>("name");
    const [code, setCoder] = useState<any>("");
    const [scanModal, setScanModal] = useState<boolean>(false);
    const [test, settest] = useState<boolean>(false);
    const [letterSelected, setStatusSelected] = useState<any>(false);
    const [viewOptions, setViewOptions] = useState(false);
    const [filterModal, setFilterModal] = useState<boolean>(false);
    const { innerHeight } = window;
    const containerRef: RefObject<HTMLDivElement> = useRef(null);
    const ProjectData = useSelector((state: RootState) => state.factorySlice);
    const [openModal, setOpenModal] = useState<boolean>(false);

    const { loadingProjects, projectData, ProjectResponse } = ProjectData;

    const [alphSelected, setAlphSelected] = useState<any>();

    const abc: any = [
        "A",
        "B",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "Ñ",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
    ];

    const handleChangeFilters = (name, value) => {
        setAdvancedFilters((prev) => ({...prev, [name]: value}))
    };

    const byDates = (item: any, fromDate: any, toDate: any) => {
        let result = true
        if (fromDate){
            result = result && new Date(item.start_date) >= fromDate
        }
        if (toDate){
            result = result && new Date(item.end_date) <= toDate
        }
        return result;
    };

    const byOrganization = (item: any, organizationName: any) => {
        if (organizationName) {
            const result = item.organization.entity.name.includes(organizationName)
            return result;
        } 
        else return true;
    };

    const byClient = (item: any, clientName: any) => {
        if (clientName) {
            const result = `${item.client.entity.first_name} ${item?.client?.entity.middle_name} ${item?.client?.entity.last_name}`.toLowerCase().includes(clientName.toLowerCase())
            return result;
        } else 
        return true;
    };

    const byName = (item: any, name: any) => {
        if (name) {
            console.log(name)
            return item.name.toLowerCase().includes(name.toLowerCase());
        } else 
        return true;
    };

    const filteredList = useMemo(() => {
        const result = projectData
        .filter((item: any) => byName(item, search))
        .filter((item: any) => byDates(item, advancedFilters.fromDate, advancedFilters.toDate))
        .filter((item: any) => byOrganization(item, advancedFilters.organizationName)) 
        .filter((item: any) => byClient(item, advancedFilters.clientName))
        return result;
    }, [projectData, search, advancedFilters]);

    const sections = useMemo(() => getSections<ItemModel, HTMLDivElement>(
        [
            ...(filteredList || projectData),
        ] || [],
        (item) => sortby
    ), [filteredList, projectData, sortby]);

    const goTo = useCallback((letter: ABC) => {
        if (sections[letter]) {
            containerRef.current?.scrollTo({
                top: sections[letter].ref.current?.offsetTop,
                behavior: 'smooth'
            });
        }
    }, [sections, containerRef]);

    const openModalCreate = () => {
        setOpenModal(true);
        setViewOptions(true);
    };


    return (
        <div className="d-flex flex-column  justify-content-center ps-0 my-sm-5 my-lg-0 h-100"
            style={{ minWidth: '100%', maxWidth: '100%' }} >
            <div className="d-flex flex-column p-1" >
                <div className={`d-flex justify-content-end ${letterSelected ? 'mt-3 pt-0' : 'mt-3'} mx-1`}
                    style={letterSelected ? { marginTop: '200px' } : {}}
                >
                    <div className="me-1">
                        <IconButton
                            color="primary"
                            custom={FilterIcon}
                            customStyles={{ width: "9.5px", height: "11px" }}
                            onClick={() => {setFilterModal((prev) => !prev)}}
                            tooltip="Duplicate"
                            outline
                        />
                    </div>
                    <div className="p-0">
                        <button id="create-factory" className="btn btn-primary" onClick={openModalCreate} style={{ width: '89px', height: '29px' }}>
                            Create
                        </button>
                    </div>
                </div>

                <Scanner
                    isOpen={scanModal}
                    setCode={setCoder}
                    setStateModal={setScanModal}
                />

                <div className="input-icon ps-2 pe-2" style={{ marginTop: "3.5%" }}>
                    <img
                        src={SearchIcon}
                        alt=""
                        style={{
                            width: "16px",
                            height: "16px",
                            marginTop: "1.4%",
                            marginLeft: "4.4%",
                        }}
                    />
                    <Input
                        className="form-control py-2 typo-body text-primary"
                        type="search"
                        id="example-search-input"
                        placeholder="Search"
                        value={search}
                        onChange={(e: any) => setSearch(e.target.value)}
                        style={{ fontSize: "12px", height: "37px" }}
                    />
                </div>

                <div className={`col-12  mt-2 mb-1 ${test && "d-none"}`}>


                </div>
            </div>
            <div className="mb-0 mt-2 ps-3" style={{
                background: '#F3F3F3', height: '30px', width: '100%', color: '#8898AA',
                fontSize: '19px', overflow: 'auto'
            }}>
                PROJECTS
            </div>
            <div className={`row p-0 contact-container w-100 `} style={{ overflow: 'hidden' }}>

                <div className="col  h-100 custom-scrollbar contact-list p-0 ps-2" ref={containerRef}>

                    <ProjectListComponent
                        Loading={loadingProjects}
                        data={projectData}
                        sections={sections}
                        results={filteredList?? []}
                        setID={props.setID}
                        showAssets={props.showAssets}
                    />
                </div>

                <div className="list-linked overflow-y hide-scrollbar p-0 h-100" >
                    {[...Object.keys(sections), ...abc]
                        .filter((item, index) => {
                            return [...Object.keys(sections), ...abc].indexOf(item) === index;
                        })
                        .map((item: any, index: any) => {
                            return (
                                <p
                                    onClick={goTo.bind(null, item)}
                                    key={index}
                                    className="m-0 p-0 typo-body text-primary"
                                    style={{
                                        fontSize: "12px",
                                        textAlign: "center",
                                        fontWeight: alphSelected == index ? "bold" : "",
                                    }}
                                >
                                    <span
                                        onClick={() => {
                                            setAlphSelected(index);
                                            setStatusSelected(true)
                                        }}
                                    >
                                        {item}
                                    </span>
                                </p>
                            );
                        })}
                </div>
            </div>
            <FactoryModal
                toggle={openModal}
                view={openModal}
                setView={(val:any) => setOpenModal(val)}
                activeControlView={props.activeControlView}
                setActiveControlView={props.setActiveControlView}
                selectJob={props.selectJob}
                setSelectJob={props.setSelectJob}
                activeTask={props.activeTask}
                setActiveTask={props.setActiveTask}
                selectProject={props.selectProject}
                setSelectProject={props.setSelectProject}
                closeModalJob={props.closeModalJob}
                setCloseModalJob={props.setCloseModalJob}
                viewOptions={viewOptions}
                setViewOptions={setViewOptions}
            />
            <FactoryModalFilter 
                isOpen = {filterModal}
                toggle = {() => setFilterModal((prev) => !prev)}
                filters = {advancedFilters}
                handleSearch = {(filters) => setAdvancedFilters(filters)}
            />
        </div>
    );
};

export default ProjectList;
