import { Form, FormikProvider, useFormik } from "formik";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ButtonFiles from "src/components/UI/buttons/ButtonFile";
import DropZone from "src/components/UI/Dropzone";
import { useRequest } from "src/hooks/useRequest";
import { useToast } from "src/hooks/useToast";
import { changeDescriptionErrors, setDescriptionData, setNamePicture } from "src/store/stockpile/packageItems";
import { setCheckItemFormAlert } from "src/store/stockpile/RecursosVisualeSlice";
import { RootState } from "src/store/store";
import { toBase64 } from "src/util/converToBase64";
import InputFloatingLabel from '../../../UI/forms/InputFloatingLabel';
import TextArea from '../../../UI/forms/TextArea';
import GeneralRatesSchema from './validations'


interface Props {
  alertComplete: any
}

const Description = forwardRef((props: Props, ref) => {

  const dispatch = useDispatch()

  const params = useParams<{ itemId: string; }>();
  const toast = useToast()

  const { data: ITEM_DATA } = useRequest<any>({ url: 'stockpile/items/' + params.itemId });

  const Data: any = useSelector((state: RootState) => state.packageItems.Description)

  const NameFile: any = useSelector((state: RootState) => state.createServiceForm.namePicture)
  const [errorpictureSelected, setErrorPictureSelected] = useState<any>('')
  const [fileReceipt, setFileReceipt] = useState<any>([])


  const handlePicture = async (e: any) => {
    setFileReceipt(e)
    const file = e[0]
    const value = await toBase64(file)
    
    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      setErrorPictureSelected('Please select valid image. (jpg , jpeg, png')
    } else {
      setErrorPictureSelected(false);
      dispatch(setDescriptionData({ ...Data, picture: value }))
      dispatch(setNamePicture(file?.name))
    }
  }


  const formik = useFormik({
    initialValues: {
      ...Data
    },
    onSubmit: () => {

    },
    validationSchema: GeneralRatesSchema
  })

  const { errors, values, handleSubmit, setFieldValue, setValues, touched } = formik

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      dispatch(setCheckItemFormAlert(false))
    }
  }, [errors])

  useEffect(() => {
    if (props.alertComplete) {
      setFieldValue('name', '')
      toast.error('Complete the required fields')
    }
  }, [props.alertComplete])


  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      dispatch(changeDescriptionErrors(true))
    } else {
      dispatch(changeDescriptionErrors(false))
    }
  }, [errors])

  const Charge = (ITEM: any) => {    
    dispatch(setDescriptionData({ ...ITEM }))
    setValues(ITEM)
  }

  useImperativeHandle(ref, () => ({

    FullDataForm(ITEM: any) {
      Charge(ITEM)
    }

  }));

  return (
    <div className="container-fluid px-3 mt-3">
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate >
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-6">
              <InputFloatingLabel
                placeHolder="Service name"
                value={Data.name}
                onChange={(value) => {
                  dispatch(setDescriptionData({ ...Data, name: value }))
                  setFieldValue('name', value)
                }}
                isRequired
                errorMessage={errors.name}
              />
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 col-6 mb-0">
              <p className=' text-primary typo-body p-0'>SELECT PICTURE</p>
              <DropZone
                setFile={handlePicture}
                files={fileReceipt}
                maxFiles={1}
                errors={errorpictureSelected}
              />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-6">
              <InputFloatingLabel
                placeHolder="Skillsets"
                onChange={(value) => {
                  dispatch(setDescriptionData({ ...Data, skill_sets: value }))
                  setFieldValue('skill_sets', value)
                }}
                value={Data.skill_sets}
                isRequired
                errorMessage={errors.skill_sets}
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-12">
              <TextArea
                title="Description"
                placeHolder="Type here..."
                value={Data.description || ITEM_DATA?.description}
                onChange={(value) => {
                  dispatch(setDescriptionData({ ...Data, description: value }))
                }}
              />
            </div>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
})

export default Description;
