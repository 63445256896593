interface Props {
    TotalPrice?: any
}

const TotalValueStockpile: React.FC<Props> = (props) => {
    const { TotalPrice } = props
    
    return (

        <div className="h-25 d-flex flex-column justify-content-end p-0">
            <div className='p-0 m-4 mb-1'>
                <p className="m-0 typo-body" style={{ color: '#8898AA', fontSize: '10px' }} >STOCKPILE TOTAL VALUE:</p>
                <p className="typo-body" style={{ color: '#8898AA', fontSize: '10px' }} >${TotalPrice}</p>
            </div>
        </div>
    )

}

export default TotalValueStockpile;

