import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ItemModel from "src/models/ItemModel";
import { getAllItems } from "src/store/stockpile/stockpileSlice";
import { RootState } from "src/store/store";
import { getSections } from "src/util";

// Definimos el tipo para los filtros
interface Filters {
    search: string;
    supFilter: string;
  }
  
  // Custom Hook
const useFilteredItems = (filters: Filters) => {
    const dispatch = useDispatch();
    const { ItemData, LoadingItems } = useSelector((state: RootState) => state.stockpileSlice);
  
    useEffect(() => {
      dispatch(getAllItems());
    }, [dispatch]);
  
    const byName = (item: ItemModel, name: string) => {
      return name ? item.name.toLowerCase().includes(name.toLowerCase()) : true;
    };
  
    const bySupplier = (item: ItemModel, idSupplier: string) => {
      return idSupplier ? item?.supplier === idSupplier : true;
    };
  
    const filteredItems = useMemo(() => {
      return ItemData.filter((item: ItemModel) => byName(item, filters.search))
        .filter((item: ItemModel) => item?.supplier)
        .filter((item: ItemModel) => bySupplier(item, filters.supFilter));
    }, [ItemData, filters.search, filters.supFilter]);
  
    const sections = useMemo(() => {
      return getSections(filteredItems, 'name');
    }, [filteredItems]);
  
    return { filteredItems, sections };
  };

  export default useFilteredItems
  
  