import { returnDate } from "@mobiscroll/react/dist/src/core/util/datetime";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import update from "immutability-helper";

const initialState = {
  activeControlView: null as string | null,
  clientTempForm: null as any,
  organizationTempForm: null as any,
  IDroute: ''
};

const ViewResources = createSlice({
  name: "ViewResource",
  initialState,
  reducers: {
    setActiveControlViewAll: (state, action) => {
      state.activeControlView = action.payload
    },
    setClient: (state, action) => {
      state.clientTempForm = action.payload
    },
    setOrganization: (state, action) => {
      state.organizationTempForm = action.payload
    },
    setIDroute: (state, action) => {
      state.IDroute = action.payload
    }
  },
});

export const { setActiveControlViewAll, setClient, setOrganization ,setIDroute } = ViewResources.actions;
export default ViewResources.reducer;
