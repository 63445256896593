import React, { RefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import FlatList from "flatlist-react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { RootState } from "src/store/store";
import AdvanceSearch from "./AdvanceSearch";
import { useToast } from "src/hooks/useToast";
import useContacts from "src/hooks/useContacts";
import { ICategory } from "src/models/Category";
import useDebounce from "src/hooks/useDebounce";
import CopyIcon from "src/assets/icons/copy.svg";
import { useRequest } from "src/hooks/useRequest";
import { IContact } from "src/models/ContactModel";
import IconButton from "../../UI/buttons/IconButton";
import { ABC, getSections } from "../../../util/index";
import { ContactTypeEx } from "src/util/ContactsFunctions";
import { blackbookFilters } from "src/store/blackbook/filtersSlice";
import ListItem from "src/components/DashboardLayout/MidCard/ListItem";
import LetterSection from "src/components/DashboardLayout/MidCard/LetterSection";
import InputSearch from "../../UI/forms/InputSearch";
import { BlackbookService } from "src/services/blackbook";
import ConfirmDuplicate from "../../Modals/Contact/ConfirmDuplicate";
import SelectTypeContact from "../../Modals/SelectTypeContact";


interface Props {
  onUploadCSV: () => void;
  onAddContact: (category: string) => void;
}

const ContactList: React.FC<Props> = ({ onAddContact, onUploadCSV }) => {
  const toast = useToast();
  const history = useHistory();
  const params = useParams<{ contactId: string }>();

  const dispatch = useDispatch();
  const filters = useSelector(
    (state: RootState) => state.blackbookFiltersSlice
  );

  const [organization, setorganization] = useState('')
  const [confirmD, setConfirmD] = useState<boolean>(false)

  const [searchTerm, setSearchTerm] = useState("");
  const searchValue = useDebounce<string>(searchTerm, 500);

  const { data: contacts, error } = useContacts();
  const { data: categories } = useRequest<ICategory[]>({
    url: "categories/contacts/all",
  });
  const containerRef: RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    dispatch(blackbookFilters.setSearch(searchValue));
  }, [searchValue]);

  useEffect(() => {
    console.log(contacts);
  }, [contacts])


  const byOrganization = (contact: any, organizationId: any) => {

    if (contact.entity?.organization) {
      const result = contact.entity?.organization == organizationId
      return result;

    }
    else return false;
  };

  const filteredList = useMemo(() => {
    const result = contacts
      ?.filter((contact: any) => byOrganization(contact, organization)) || []
    return result;

  }, [contacts, organization]);


  useEffect(() => {

    // let data:any = contacts?.filter((contact: any) => contact?.entity?.organization == organization)

    //console.log(data);
    //console.log(organization);

    console.log(filteredList);


  }, [organization])




  const sections = getSections<IContact, HTMLDivElement>(
    filteredList?.length == 0 ? contacts || [] : filteredList,
    (contact) => ContactTypeEx[contact.type].getSortKey(contact)
  );
  
  const goTo = useCallback((letter: ABC) => {
    if (sections[letter]) {
      containerRef.current?.scrollTo({
        top: sections[letter].ref.current?.offsetTop,
        behavior: 'smooth'
    });
    }
  }, [sections, containerRef]);

  const handleUnimplementedBtn = useCallback(() => {
    toast.info({
      message: "This feature is not implemented yet.",
      timeout: 3000,
    });
  }, [toast]);


  const Duplicate = () => {

    params.contactId && history.push(`/blackbook/duplicate/${params.contactId}`)
    !params.contactId && toast.error('please select an contact')

  }

  const onCancelModalClose = () => {
    setConfirmD(false)
  }


  return (
    <div className="d-flex flex-column h-100">
      {confirmD && (<ConfirmDuplicate
        title="Are you sure?"
        description="This contact will be duplicated"
        confirmText="Duplicate"
        cancelText="cancel"
        onClose={onCancelModalClose}
        onConfirm={Duplicate}
      />)}

      <div className="contact-header d-flex flex-column">
        <div className="row justify-content-end align-items-center mt-3 mx-1">
          <div className="dropdown col-auto p-0 me-1">
            <button
              className="btn btn-outline-primary dropdown-toggle typo-body"
              data-bs-toggle="dropdown"
            >
              Sort by
            </button>
            <ul className="dropdown-menu">
              <li className="dropdown-item typo-body user-select-none">
                First name
              </li>
              <li className="dropdown-item typo-body user-select-none">
                Last name
              </li>
              <li className="dropdown-item typo-body user-select-none">
                Company name
              </li>
            </ul>
          </div>

          <IconButton
            icon="bi bi-archive"
            color="primary col-auto"
            outline={!filters.archivedOnly}
            tooltip={
              filters.archivedOnly ? "All contacts" : "Archived contacts"
            }
            onClick={() =>
              dispatch(blackbookFilters.setArchivedOnly(!filters.archivedOnly))
            }
          />

          <IconButton
            outline
            color="primary"
            custom={CopyIcon}
            tooltip="Duplicate"
            onClick={() => setConfirmD(true)}
            customStyles={{ width: "9.5px", height: "11px" }}
          />

          <div className="dropdown col-auto p-0 ms-1">
            <IconButton
              color="primary"
              icon="bi bi-person-plus-fill"
              tooltip="Add new contact"
              buttonProps={{
                "data-bs-toggle": "dropdown",
                "data-toggle": "tooltip",
              }}
            />

            <ul className="dropdown-menu DropdownZindexCorrupt" >
              {categories &&
                categories.map((category: ICategory) => (
                  <>
                    <li
                      onClick={() => onAddContact(category._id)}
                      className="dropdown-item typo-body user-select-none"
                    >
                      {category.name}
                    </li>

                    {category.sub_categories &&
                      category.sub_categories.map((sub_category: ICategory) => (
                        <li
                          onClick={() => onAddContact(sub_category._id)}
                          className="dropdown-item typo-body user-select-none itemSubCategoriaContacts"
                        >
                          - {sub_category.name}
                        </li>
                      ))}
                  </>
                ))}

              <li>
                <hr className="dropdown-divider" />
              </li>
              <li
                className="dropdown-item typo-body user-select-none"
                onClick={onUploadCSV}
              >
                Upload CSV
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-3">
          <InputSearch
            value={searchTerm}
            placeholder="Search"
            onChange={(e) => setSearchTerm(e)}
          />
        </div>

        <div className="row justify-content-end mt-1 user-select-none">
          <div className="col-auto">
            <div className="dropdown col-auto p-0 me-1">
              <button
                className="btn btn-link typo-body p-0 ButtonBoxAdvanceSearch"
                data-bs-toggle="dropdown"
              >
                ADVANCED SEARCH
              </button>
              <AdvanceSearch
                setOrganization={setorganization}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-2 contact-container">
        <div className="col p-0 h-100 custom-scrollbar contact-list" ref={containerRef}>
          {!contacts && !error && (
            <div className="d-flex justify-content-center align-items-center h-100">
              <i className="text-primary fs-1 bi bi-arrow-repeat animate-spin d-block"></i>
            </div>
          )}

          {contacts && contacts.length > 0 && (
            <FlatList
              list={Object.keys(sections)}
              renderItem={(item: any, index: any) => {
                if (!sections[item].items.length) return null;

                return (
                  <LetterSection
                    key={index}
                    letter={item}
                    ref={sections[item].ref}
                    renderItems={() => (
                      <>
                        {sections[item].items.map((contact: IContact) => (
                          <ListItem
                            key={index}
                            itemId={contact._id}
                            title={ContactTypeEx[contact.type].getTitle(
                              contact
                            )}
                            image={ContactTypeEx[contact.type].getLogo?.(
                              contact
                            )}
                            subtitle={ContactTypeEx[contact.type].getSubtitle?.(
                              contact
                            )}
                            active={
                              !!params.contactId &&
                              params.contactId === contact._id
                            }
                            onClick={(id: string) =>
                              history.push(`/blackbook/${contact._id}`)
                            }
                          />
                        ))}
                      </>
                    )}
                  />
                );
              }}
              renderWhenEmpty={() => (
                <div className="d-flex flex-column justify-content-center align-items-center h-100 text-muted mx-5 text-center">
                  <i className="fs-3 bi bi-back d-block"></i>
                  <p className="h6">
                    No contacts were found for the current filters.
                  </p>
                </div>
              )}
            />
          )}

          {contacts && contacts.length === 0 && (
            <div className="d-flex flex-column justify-content-center align-items-center h-100 text-muted mx-5 text-center">
              <i className="fs-3 bi bi-back d-block"></i>
              <p className="h6">
                No contacts were found for the current filters.
              </p>
            </div>
          )}

          {!contacts && error && (
            <div className="d-flex flex-column justify-content-center align-items-center h-100 text-muted p-4 text-center">
              <i className="fs-3 bi bi-exclamation-triangle d-block"></i>
              <p className="h6">Error while loading the blackbook contacts.</p>
              <small>
                {error.data ? error.data.message : error.statusText}
              </small>
            </div>
          )}
        </div>

        <div className="list-linked overflow-y hide-scrollbar p-2 h-100">
          {Object.keys(sections).map((item, index) => {
            return (
              <p
                key={index}
                onClick={() => goTo(item as ABC)}
                className="m-0 p-0 typo-body text-primary"
              >
                {item}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ContactList;
