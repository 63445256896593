import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { RootState } from "src/store/store";
import { TabsContent } from "../../AddContact";
import OrganizationValidation from "./validation";
import { toBase64 } from "src/util/converToBase64";
import { OrganizationData } from "src/models/ContactModel";
import ButtonFiles from "src/components/UI/buttons/ButtonFile";
import { RODocumentMimeTypesArray, ROImageMimeTypesArray } from "src/util";
import { createContactForm } from "src/store/blackbook/createContactSlice";
import DropZone from "../../../../UI/Dropzone";

const OrganizationDocuments = forwardRef((_props, ref: TabsContent['ref']) => {
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.createContactFormSlice.data);
  const Names = useSelector((state: RootState) => state.createContactFormSlice.data.files);


  const [cropped1, setcropped1] = useState(false)
  const [cropped2, setcropped2] = useState(false)
  const [cropped3, setcropped3] = useState(false)
  const [cropped4, setcropped4] = useState(false)
  const [cropped5, setcropped5] = useState(false)

  const [deletedLogo, setdeletedLogo] = useState(false)
  const [deletedLicense, setdeletedLicense] = useState(false)
  const [deletedContract, setdeletedContract] = useState(false)
  const [deletedInsurance, setdeletedInsurance] = useState(false)
  const [deletedStyleGuide, setdeletedStyleGuide] = useState(false)

  const [filesLogo, setFilesLogo] = useState<any>([])
  const [filesLicense, setFilesLicense] = useState<any>([])
  const [filesContract, setFilesContract] = useState<any>([])
  const [filesInsurace, setFilesInsurance] = useState<any>([])
  const [filesStyleGuide, setFilesStyleGuide] = useState<any>([])


  useEffect(() => {
    if (Names?.documents) {
      Names?.documents?.docs_logo && setFilesLogo([ { name: Names?.documents?.docs_logo} ])
      Names?.documents?.contract &&  setFilesContract([ {name: Names?.documents?.contract } ])
      Names?.documents?.insurance && setFilesInsurance([{ name: Names?.documents?.insurance}])
      Names?.documents?.trade_license && setFilesLicense([{ name: Names?.documents?.trade_license }])
      Names?.documents?.style_guide && setFilesStyleGuide([ { name: Names?.documents?.style_guide } ])
    }
  }, [])


  const formik = useFormik<OrganizationData>({
    initialValues: {
      ...(data.data || {}),
    },
    validationSchema: OrganizationValidation.pick(['documents']),
    onSubmit: () => { },
  });

  useImperativeHandle(ref, () => ({
    async handleValidation() {
      try {
        const errors = await formik.validateForm();
        if (errors && Object.keys(errors).length > 0) {
          return false;
        }

        return true;
      } catch {
        return false;
      }
    }
  }));

  useEffect(() => {
    if (data.initialValues !== undefined && data.appliedInitialValues === false) {
      formik.setValues(data.initialValues || {});
      /* @ts-ignore */
      dispatch(createContactForm.setField('appliedInitialValues', true, ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }



  const handleChange = async (fieldName: string, value: any, addData = true, dispatchValue = true) => {
    formik.setFieldValue(fieldName, value);

    const file = value[0]
    const Document = await toBase64(file)

    console.log(Document);


    dispatch(createContactForm.setField(fieldName, Document as string, 'data'));
    dispatch(createContactForm.setField(fieldName, file.path, 'files'));

    /* Files will be saved as strings instead of File, and then they will be re-coded to Files in the request. */
    //   if (value instanceof File) {
    //     toBase64(value as File).then(base64 => {
    /* @ts-ignore */
    //       dispatch(createContactForm.setField(fieldName, base64 as string, 'data'));
    /* @ts-ignore */
    //       dispatch(createContactForm.setField(fieldName, value.name, 'files'));
    //      }).catch(err => {
    //      console.log(err);
    //  })

    return;
    //   }

    if (dispatchValue) {
      /* @ts-ignore */
      dispatch(createContactForm.setField(fieldName, value, addData ? 'data' : ''));
    }
  }

  const handleChangeFile = async (fieldName: string, value: any, addData = true, dispatchValue = true) => {

    const file = value[0]
    const Document = await toBase64(file)

    console.log(Document);


    dispatch(createContactForm.setField(fieldName, Document as string, 'data'));
    dispatch(createContactForm.setField(fieldName, file.path, 'files'));

    /* Files will be saved as strings instead of File, and then they will be re-coded to Files in the request. */
    //   if (value instanceof File) {
    //     toBase64(value as File).then(base64 => {
    /* @ts-ignore */
    //       dispatch(createContactForm.setField(fieldName, base64 as string, 'data'));
    /* @ts-ignore */
    //       dispatch(createContactForm.setField(fieldName, value.name, 'files'));
    //      }).catch(err => {
    //      console.log(err);
    //  })

    return;
    //   }

    if (dispatchValue) {
      /* @ts-ignore */
      dispatch(createContactForm.setField(fieldName, value, addData ? 'data' : ''));
    }
  }

  const handleChangePic = async (fieldName: string, value: any, addData = true, dispatchValue = true) => {
 //   formik.setFieldValue(fieldName, value);


    /* Files will be saved as strings instead of File, and then they will be re-coded to Files in the request. */
    if (value instanceof File) {
      toBase64(value as File).then(base64 => {
        /* @ts-ignore */
        dispatch(createContactForm.setField(fieldName, base64 as string, 'data'));
        /* @ts-ignore */
        dispatch(createContactForm.setField(fieldName, value.name, 'files'));
      }).catch(err => {
        console.log(err);
      })

      return;
    }

    if (dispatchValue) {
      /* @ts-ignore */
      dispatch(createContactForm.setField(fieldName, value, addData ? 'data' : ''));
    }
  }

  const handleSetFile = async (field: string, dataFile: any) => {
    console.log(dataFile[0]);

    if (field == 'documents.docs_logo') {
      handleChangePic(field, dataFile[0])

      return
    }

    handleChange(field, dataFile[0])




  }


  return (
    <div className="container-fluid px-3 mt-3">

      <div className="row mt-4">
        <div className="col-6">
          <p style={{ color: '#344469' }}>Docs Logo</p>

          <DropZone
            setFile={async (data: any) => {
              handleSetFile('documents.docs_logo', data)
              setFilesLogo(data)
              setcropped1(true)
              setdeletedLogo(true)
            }}
            files={filesLogo}
            maxFiles={1}
            errors={formik.errors.logo}
            setvieCropper={setcropped1}
            vieCropper={cropped1}
            setImage={(b: any) => {
              dispatch(createContactForm.setField('logo', b as string, 'data'));
            }}
            nameFile={filesLogo[0]?.name}
            Delete={deletedLogo}
            setDelete={() => {
              setdeletedLogo(false)
              setFilesLogo([])
            }}
          />
        </div>
        <div className="col-6">
          <p style={{ color: '#344469' }}>Contract</p>

          <DropZone
            setFile={async (data: any) => {
              handleChangeFile('documents.contract', data)
              setFilesContract(data)
              setdeletedContract(true)
            }}
            files={filesContract}
            maxFiles={1}
            errors={formik.errors.contract}
            file={true}
            Delete={deletedContract}
            setDelete={() => {
              setdeletedContract(false)
              setFilesContract([]) 
            }}
          />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-6">
          <p style={{ color: '#344469' }}>Trade License</p>

          <DropZone
            setFile={async (data: any) => {
              handleChangeFile('documents.trade_license', data)
              setFilesLicense(data)
              setcropped3(true)
              setdeletedLicense(true)
            }}
            files={filesLicense}
            maxFiles={1}
            errors={formik.errors.logo}
            file={true}
            Delete={deletedLicense}
            setDelete={setdeletedLicense}
          />
        </div>
        <div className="col-6">
          <p style={{ color: '#344469' }}>Insurance</p>
          <DropZone
            setFile={async (data: any) => {
              handleChangeFile('documents.insurance', data)
              setFilesInsurance(data)
              setcropped4(true)
              setdeletedInsurance(true)
            }}
            files={filesInsurace}
            maxFiles={1}
            errors={formik.errors.logo}
            file={true}
            Delete={deletedInsurance}
            setDelete={setdeletedInsurance}
          />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-6">
          <p style={{ color: '#344469' }}>Style guide</p>
          <DropZone

            setFile={async (data: any) => {
              handleChangeFile('documents.style_guide', data)
              setFilesStyleGuide(data)
              setcropped5(true)
              setdeletedStyleGuide(true)
            }}
            files={filesStyleGuide}
            maxFiles={1}
            errors={formik.errors.logo}
            file={true}
            Delete={deletedStyleGuide}
            setDelete={setdeletedStyleGuide}
          />
        </div>
        <div className="col-6"></div>
      </div>
    </div>
  );
});

export default OrganizationDocuments;
