import EmptyCircle from '../../UI/EmptyCircle';



interface Props {
    ASSET_DATA:any
    AllAssetForItem:any
}

const ProfileItem: React.FC<Props> = (props) => {
    const { ASSET_DATA , AllAssetForItem } = props



    
    return (
        <div className="d-flex align-items-center">
          {ASSET_DATA?.item?.picture_url && <img
            className="avatar-profile"
            src={process.env.REACT_APP_IMAGES_AWS + ASSET_DATA?.item?.picture_url}
            alt=""
          />
          }

          {
            !ASSET_DATA?.item?.picture_url && ASSET_DATA?.item?.name && <EmptyCircle title={ASSET_DATA?.item?.name} />
          }

          <div className="ms-3">
            <p className="m-0 text-black typo-body contact-title">
              {AllAssetForItem && ASSET_DATA?.item?.name}
            </p>
            <p className="m-0 text-black typo-body contact-subtitle">
              {
                //ITEM_DATA?.description
              }
            </p>
          </div>
        </div>
    )

}

export default ProfileItem;

