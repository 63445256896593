import React, { useEffect, useState } from "react";
import InputFloatingLabel from '../../../UI/forms/InputFloatingLabel';
import TextArea from '../../../UI/forms/TextArea';
import ButtonFiles from "../../../UI/buttons/ButtonFile";
import { RootState } from "../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { changeDescriptionErrors, setCheckItemFormAlert, setDescriptionData, setNamePicture } from "../../../../store/stockpile/packageItems";
import { toBase64 } from "../../../../util/converToBase64";
import { useFormik } from "formik";
import DescriptionScheme from "./validations";
import { useToast } from "src/hooks/useToast";
import DropZone from "src/components/UI/Dropzone";


interface Props {
  alertComplete: any
}

const Description: React.FC<Props> = (props) => {
  const toast = useToast()

  const dispatch = useDispatch()
  const Data = useSelector((state: RootState) => state.packageItems.Description)
  const NamePicture = useSelector((state: RootState) => state.packageItems.namePicture)
  const [fileReceipt, setFileReceipt] = useState<any>([])
  const [vieCropper, setviewCropper] = useState<any>(false)

  const formik = useFormik({
    initialValues: {
      name: ''
    },
    validationSchema: DescriptionScheme,
    onSubmit: () => { }
  })

  const { errors, setErrors, values, setFieldValue } = formik
  const [errorpictureSelected, setErrorPictureSelected] = useState<any>('')

  const handlePicture = async (e: any) => {
    setFileReceipt(e)
    const file = e[0]
    const value = await toBase64(file)


    setErrorPictureSelected(false)
    dispatch(setNamePicture(file?.name))

    e[0] && setviewCropper(true)

  }


  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      dispatch(changeDescriptionErrors(errors))
    } else {
      dispatch(changeDescriptionErrors(false))
    }
  }, [errors])

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      dispatch(setCheckItemFormAlert(false))
    }
  }, [errors])


  useEffect(() => {
    if (props.alertComplete) {
      setFieldValue('name', '')
      toast.error('Complete the required fields')
    }

  }, [props.alertComplete])

  return (
    <div className="container-fluid px-3 mt-3">
      <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <InputFloatingLabel
            placeHolder="Package name"
            value={Data.name}
            onChange={(value) => {
              dispatch(setDescriptionData({ ...Data, name: value }))
              setFieldValue('name', value)
            }}
            isRequired
            errorMessage={errors.name}
          />
        </div>

        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <p className=' text-primary typo-body p-0'>SELECT PICTURE</p>
          <DropZone
            setFile={handlePicture}
            files={fileReceipt}
            maxFiles={1}
            errors={errorpictureSelected}
            setvieCropper={setviewCropper}
            vieCropper={vieCropper}
            setImage={
              (base64: any) => dispatch(setDescriptionData({ ...Data, picture: base64 }))
            }
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col">

          <TextArea
            title="Description"
            placeHolder="Type here..."
            value={Data.description}
            onChange={(value) => {
              dispatch(setDescriptionData({ ...Data, description: value }))
              setFieldValue('description', value)
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Description;
