import { returnDate } from "@mobiscroll/react/dist/src/core/util/datetime";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import update from "immutability-helper";

const initialState = {
    clientType: false as boolean,
    supplierType: false as boolean,
    venueType: false as boolean ,
    ArrayTypes: [] as Array<string>
};

const ViewBlackbookResources = createSlice({
  name: "ViewBlackbookResource",
  initialState,
  reducers: {
    setClientType:(state, action) => {
        state.clientType = action.payload
    },
    setSupplierType:(state, action) => {
        state.supplierType = action.payload
    },
    setVenueType:(state, action) => {
        state.venueType = action.payload
    },
    setTypeinToArray: (state, action) => {
        state.ArrayTypes = [...state.ArrayTypes, action.payload]
    },
    setArray: (state, action) => {
        state.ArrayTypes = action.payload
    },
  },
});

export const { setClientType , setSupplierType , setVenueType , setTypeinToArray , setArray} = ViewBlackbookResources.actions;
export default ViewBlackbookResources.reducer;
