import InfoDisplay from '../../../../../UI/InfoDisplay';
import PhotoDisplay from '../../../../../UI/PhotoDisplay';
import QuickNotes from '../../../../../UI/QuickNotes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { useEffect, useState } from 'react';
import AddComentsModal from 'src/components/Modals/AddComentsModal';
import { AllWareHouses, CreateCommentForAsset, DeleteCommentForAsset, UpdateCommentForAsset } from 'src/store/stockpile/stockpileSlice';
import { AddComment, deleteComment, GetAceesoriesbyID, updateComment } from 'src/store/stockpile/selectAssetsSlice';
import SimpleButton from 'src/components/UI/buttons/SimpleButton';
import { ConvertSize } from 'src/util/sizesConvert';
import moment from 'moment';
import { getCategoryName } from 'src/util/NameTypes';
import { CheckValue } from 'src/util/CheckValue';
import ConfirmModal from 'src/components/Modals/ConfirmModal';
import { useHistory, useParams } from 'react-router-dom';
import { useRequest } from 'src/hooks/useRequest';
import { useToast } from 'src/hooks/useToast';

import TrashIcon from '../../../../../../assets/icons/trash.svg'
import EditIcon from '../../../../../../assets/icons/edit.svg'
import { getProjectId, updateProject } from '../../../../../../store/factory/factorySlice';
import ModalStatus from '../../../tabs/modalStatus/ModalStatus';
import ModalBrief from '../../../tabs/modalBrief/ModalBrief';
import QuoteStatusModal from '../../../../../Modals/Factory/Quotes/QuoteStatusModal';
import { QuoteStatusType } from '../../../../../../models/QuotesModel';
import { UpdateStatusQuote } from '../../../../../../store/factory/factoryJobSlice';

interface Props {
    quotesData?: any
    setDeleteJobModal: any;
    setjobIdDelete: any;
}

const ProjectDetail: React.FC<Props> = (props) => {
    const params = useParams<{ id: any; }>();
    const ASSET_DATA: any = useSelector((state: RootState) => state.selectAssetsSlice.data) || [];
    const COMMENTS_ASSETS: Array<object> = useSelector((state: RootState) => state.selectAssetsSlice.comments);
    const categories = useSelector((state: RootState) => state.categorieSlice.categories);
    const WareHouses: Array<any> = useSelector((state: RootState) => state.stockpileSlice.AllWareHouses);
    const Selected: any = useSelector((state: RootState) => state.RecursosVisualeSlice.selectedState);
    const [isOpenChangeStatusModal, setIsOpenChangeStatusModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [idForEdit, setIdForEdit] = useState('')
    const [view, setView] = useState<boolean>(false);
    const [client, setClient] = useState<any>([]);
    const [organization, setOrganization] = useState<any>([]);
    const [subCat, setSubCat] = useState('')
    const toast = useToast()
    const [jodIdDelete, setjobIdDelete] = useState<string | null>(null);
    const [deleteJobModal, setDeleteJobModal] = useState<boolean>(false);
    const [deleteQuoteModal, setDeleteQuoteModal] = useState<boolean>(false);
    const history = useHistory()
    const { projectDataId } = useSelector((state: RootState) => state.factorySlice);
    const [brief, setBrief] = useState<string>("");
    const [viewBrief, setViewBrief] = useState<boolean>(false);


    const dispatch = useDispatch()

    const [activeControl, setActiveControl] = useState<string>("");

    const { QuotesId , updateQuoteStatus } = useSelector(
        (state: RootState) => state.getJobsTypeSlice
    );

    const { updateProjectStatus } = useSelector(
        (state: RootState) => state.factorySlice
    );


    const [sendProject, setSendProject] = useState({
        name: projectDataId?.name,
        start_date: moment(projectDataId?.start_date).format("MM/DD/YYYY"),
        end_date: moment(projectDataId?.end_date).format("MM/DD/YYYY"),
        status: projectDataId?.status,
        id: params.id,
    });



    useEffect(() => {
        //))
    }, [])

    const selectData = () => {
        [projectDataId && projectDataId].map((e: any) => {
            if (e?.client) {
                setClient(e.client.entity);
            }
            if (e?.organization) {
                setOrganization(e?.organization.entity);
            }
        });
    };
    useEffect(() => {
        setSendProject({ ...sendProject, name: projectDataId?.name });
    }, [sendProject.status]);

    useEffect(() => {
        setSendProject((prev) => ({
            ...prev,
            status: projectDataId?.status,
        }));
    }, [projectDataId]);

    const getNewStatus = () => {
        dispatch(updateProject(sendProject));
    };

    const selectedColor = (status: any) => {
        let select;
        //  if (item?.status) {
        if (status === "Approved") {
            select = "approved";
        } else if (status === "Proporsal") {
            select = "proporsal";
        } else if (status === "Active") {
            select = "active";
        } else if (status === "On Hold") {
            select = "onHold";
        } else if (status === "Lost") {
            select = "lost";
        } else if (status === "Complete") {
            select = "complete";
        }
        // }


        return select;
    };

    useEffect(() => {
        selectedColor(projectDataId?.status);
        selectData();
    }, [projectDataId, updateProjectStatus]);


    // 67807598690173953
    const getCategory = (id: any) => {
        setSubCat(getCategoryName(id, categories))
    }



    const getDataId = () => {
        dispatch(getProjectId(params.id));
    };

    useEffect(() => {
        getDataId();
    }, [params]);


    const tab = "\u00A0";


    const handleDeletedJob = (e: any, item: any) => {
        props.setDeleteJobModal(true);
        props.setjobIdDelete(item._id);
    };


    const editProject = (e, item) => {
        history.push(
            `/factory/edit/project/${params.id}/job/${item._id}`
        );
    };

    const viewBriefCLient = (brief: any) => {
        setBrief(brief.client_brief);
        setViewBrief(true);
        console.log("brief", brief.client_brief);
    };

    const handleChangeStatus = (status: QuoteStatusType) => {
        const data = {id: QuotesId._id, status};
        dispatch(UpdateStatusQuote(data));
        setIsOpenChangeStatusModal(true)
      }

    return (
        <div className="container-fluid p-0 m-0">
            <QuoteStatusModal
                isOpen={isOpenChangeStatusModal}
                handleConfirm={handleChangeStatus}
                onClose={() => setIsOpenChangeStatusModal((prev) => !prev)}
                currentStatus={updateQuoteStatus || QuotesId?.status}
            />
            <ModalBrief
                view={viewBrief}
                setView={setViewBrief}
                toggle={viewBrief}
                brief={brief}
            />
            <div className="row border-bottom p-0 py-3 gy-n5 px-3">
                <div className="col-10">
                    <InfoDisplay title="QUOTE DESCRIPTION" texts={[{ text: CheckValue(QuotesId?.description) }]} />
                </div>
            </div>
            <div className="row border-bottom p-0 py-3 gy-n5 px-3">


                <div className="col-4">
                    <InfoDisplay title="PROJECT" texts={[{ text: CheckValue(QuotesId?.project?.name) }]} />
                </div>

                <div className="col-4">
                    <InfoDisplay title="JOB" texts={[{
                        text: CheckValue(QuotesId?.job?.name)
                    }]} />
                </div>

                <div className="col-4">
                    <InfoDisplay title="ORGANIZATION" texts={[{ text: CheckValue(QuotesId?.organization?.entity?.name) }]} />
                </div>


                <div className="col-4">
                    <InfoDisplay
                        title="QUOTE STATUS"
                        texts={[{ text: [] }]}
                    />
                    <span
                        style={{
                            fontSize: "12.5px",
                        }}
                        className={`${selectedColor(
                            !updateQuoteStatus?.status
                                ? QuotesId?.status
                                : updateQuoteStatus?.status
                        )} baseBadge me-1`}                    >
                        {!updateQuoteStatus?.status
                            ? QuotesId?.status
                            : updateQuoteStatus?.status}
                    </span>
                    {QuotesId?.status && <button
                        type="button"
                        className="btn btn-light-gray text-black btn-sm "

                        onClick={() => setIsOpenChangeStatusModal(true)}
                    >
                        CHANGE
                    </button>}
                </div>
            </div>

            <div className="row border-bottom p-0 py-3 gy-n5 px-3">
                <div className="col-4">
                    <InfoDisplay title="QUOTE NUMBER" texts={[{ text: CheckValue(QuotesId?._id) }]} />
                </div>
                <div className="col-4">
                    <InfoDisplay title="QUOTE EXPIRATION DATE" texts={[{ text: CheckValue(projectDataId?._id) }]} />
                </div>
                <div className="col-4">
                    <InfoDisplay title="QUOTE DUE DATE" texts={[{ text: CheckValue(moment(QuotesId?.due_date).calendar()) }]} />
                </div>
            </div>


            <div className="row border-bottom p-0 py-3 gy-n5 px-3">
                <div className="col-4">
                    <InfoDisplay title="TARGET BUDGET" texts={[{ text: CheckValue(QuotesId?.target_budget) }]} />
                </div>
                <div className="col-4">
                    <InfoDisplay title="QUOTE TOTAL" texts={[{ text: CheckValue(QuotesId?.total) }]} />
                </div>
                <div className="col-4">
                    <InfoDisplay title="COST" texts={[{ text: CheckValue(QuotesId?.cost) }]} />
                </div>
                <div className="col-4">
                    <InfoDisplay title="DIFFERENCE TARGET VS TOTAL" texts={[{
                        text: CheckValue(
                            QuotesId?.total - QuotesId?.target_budget
                        )
                    }]} />
                </div>
            </div>
            <div className="row border-bottom p-0 py-3 gy-n5 px-3">
                <div className="col-4">
                    <InfoDisplay title="QUOTE LINK" texts={[{ text: 'www.google.com' /*CheckValue(projectDataId?._id)*/ }]} />
                </div>
            </div>

            <div className='p-0 m-0 w-100'>

            </div>
            <ModalStatus
                view={view}
                setView={setView}
                toggle={view}
                activeControl={activeControl}
                setActiveControl={setActiveControl}
                getNewStatus={getNewStatus}
                sendProject={sendProject}
                setSendProject={setSendProject}
            />
        </div>
    );
};

export default ProjectDetail;
