import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "reactstrap";
import dataPrueba from "../../../../../components/dataPrueba";
import Checkbox from "../../../../../components/UI/forms/Checkbox";
import InputSearch from "../../../../../components/UI/forms/InputSearch";
import { getAssetForItem } from "../../../../../store/stockpile/stockpileSlice";
import { RootState } from "../../../../../store/store";
import QuoteBuilder from "../../../../Modals/QuoteBuilder";


interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (assets) => void;
  item: string;
}

const AssingAsset: React.FC<Props> = ({ isOpen, item, onClose, onSelect }) => {
  const dispatch = useDispatch();
  const [selectedAssets, setSelectedAssets] = useState<any>([]);
  const AssetsData: any = useSelector((state: RootState) => state.stockpileSlice.AssetData) || [];
  const [isOpenQuoteBuilderModal, setIsOpenQuoteBuilderModal] = useState<boolean>(false);

  useEffect(() => {
    if (item) {
      dispatch(getAssetForItem(item));
    }
  }, [dispatch, item]);

  const handleChangeSelect = (checked, asset) => {
    const newSelectedAssets = checked ? [...selectedAssets, asset] : selectedAssets.filter((e) => e._id !== asset._id);
    setSelectedAssets(newSelectedAssets);
  }

  const handleSelect = () => {
    onSelect(selectedAssets);
    setSelectedAssets([]);
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered size="lg">
      <div className="container">
        <div className="cursor-pointer m-1" onClick={onClose}>
          <i className="bi bi-x-lg fs-5"></i>
        </div>
        <div>
          <h1 className="text-secondary fw-bold fs-2 text-center">Assign asset</h1>
        </div>
        <div className="ps-3 pe-3 my-2">
          <InputSearch placeholder="Search asset number" />
        </div>
        <div>
          <table className="table table-borderless">
            <thead className="">
              <tr className="text-primary typo-body">
                <th scope="col">
                  <span className="ms-4">ASSET NUMBER</span>
                </th>

                <th scope="col" >
                  <div >USAGE</div>
                </th>
                <th scope="col">
                  AGE
                </th>
                <th scope="col">
                  SERIAL NUMBER
                </th>

                <th scope="col" >
                  <div>CONDITION</div>
                </th>
                <th scope="col">
                  OWNER
                </th>
              </tr>
            </thead>

            <tbody>
              {AssetsData?.map((asset: any, index: any) => {
                return (
                  <tr
                    className={`${(index / 2) % 1 > 0 ? "table-primary" : ""
                      } text-primary typo-body`}
                    key={index}
                  // onClick={() => handleChange(item)}
                  >
                    <td
                      className="d-flex align-items-center ps-3"
                      style={{ height: "58px" }}
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={(e) => handleChangeSelect(e.target.checked, asset)}
                      />
                      <p className="m-0 ms-2"> {asset._id}</p>
                    </td>

                    <td
                      className="align-middle "
                      style={{ width: "50px" }}
                    >
                      <div className="d-flex ">{'-'}</div>
                    </td>
                    <td className="align-middle">
                      <div className="d-flex ">
                        <div className="d-flex ">{'-'}</div>
                      </div>
                    </td>

                    <td className="" style={{ padding: "20px" }}>
                      <div className="d-flex ">{asset.serial}</div>
                    </td>
                    <td
                      className=""
                      style={{ padding: "20px", paddingRight: "20px" }}
                    >
                      <div className="d-flex ">{asset.condition}</div>
                    </td>
                    <td>
                      <div className="d-flex ">
                        <div className="d-flex ">{'-'}</div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="d-flex cursor-pointer mb-2  ms-2">
          <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-5 me-1"></i>
          <small className=" text-primary mt-1">Quick add new asset</small>
        </div>
        <div className="d-flex cursor-pointer mb-2  ms-2" onClick={() => setIsOpenQuoteBuilderModal(true)}>
          <i className="bi bi-plus-circle-fill text-primary cursor-pointer fs-5 me-1"></i>
          <small className=" text-primary mt-1"> Add an external asset</small>
        </div>
        <div className="align-middle text-center mb-4">
          <button
            className="btn btn-success btn-lg mt-2 text-white fw-bold"
            onClick={() => handleSelect()}
          >
            Assign especific asset
          </button>
        </div>
      </div>
      <QuoteBuilder
        isOpen={isOpenQuoteBuilderModal}
        onClose={() => setIsOpenQuoteBuilderModal((prev) => !prev)}
        onSelectItems={() => { }}
      />
    </Modal>
  );
};

export default AssingAsset;
