import { useDispatch, useSelector } from 'react-redux';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { RootState } from 'src/store/store';
import { TabsContent } from '../../AddContact';
import InputFloatingLabel from '../../../../UI/forms/InputFloatingLabel';
import { createContactForm } from 'src/store/blackbook/createContactSlice';
import useContacts from '../../../../../hooks/useContacts';
import SelectInput from '../../../../UI/forms/Select';
import ClientPersonalValidation, { ClientData } from './PersonalInformation/validation';
import { useFormik } from 'formik';
import SimpleButton from '../../../../UI/buttons/SimpleButton'
import CreateQuickOrganization from '../../../../Modals/Contact/CreateQuickOrganization';

const ClientOrganization = forwardRef((_props, ref: TabsContent['ref']) => {
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.createContactFormSlice.data);
  const [organizations, setOrganizations] = useState<Array<any>>([])
  const [quick, setQuick] = useState(false)

  const formik = useFormik<ClientData>({
    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      contact_since: data.contact_since || '',
      ...(data.data || {}),
    },
    validationSchema: ClientPersonalValidation,
    onSubmit: () => { },
  });


  useImperativeHandle(ref, () => ({
    async handleValidation() {
      return true;
    }
  }));

  const { data: contacts, error } = useContacts();


  useEffect(() => {

    let data: any = contacts?.filter((contact: any) => contact?.type == "ORGANIZATION")

    setOrganizations(data)

    console.log('orgs');

    console.log(data);

  }, [contacts])

  return (
    <div className="container-fluid px-3 mt-3">
      <div className="row">
        <div className="col-12 col-md-6 ">
          <SelectInput
            onChange={(e: string) => dispatch(createContactForm.setField('organization', e))}
            options={
              organizations?.map((i: any) => {
                return { label: i?.entity?.name, value: i?.entity?._id }
              })
            }
            value={formik.values.organization}
            placeholder="Organization"
          />
          <div style={{ marginTop: '4%' }}>
            <SimpleButton onClick={() => {
              setQuick(true)
            }}

            >
              CREATE ORGANIZATION
            </SimpleButton>
          </div>
          {quick && <CreateQuickOrganization
            onClose={() => setQuick(false)}
            title='Quick create organization'
            confirmText='Create'
            organizations={organizations}
            setorganizations={setOrganizations}
          />}
        </div>
      </div>
    </div>
  );
});

export default ClientOrganization;
