import { Modal } from "reactstrap";

interface Props {
    view: boolean;
    setView: any;
}

const ModalItemCreate: React.FC<Props> = (props) => {
    return (
        <Modal isOpen={props.view} toggle={() => { }} centered>
            <div className="container text-center">
                <div className="row p-4">
                    <div className="col-12">
                        <h3 className="font-weight-bold text-secondary typo-display">
                            Info
                        </h3>
                    </div>
                    <div className="col-12 mt-3">
                        <p>
                            Your Item has been successfully quick added. Your task list will be updated
                            with the incomplete entry with access to the item within the Stockpile. [Task
                            list is unavailable in this version]
                        </p>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="row justify-content-evenly">
                            <button
                                className="btn btn-success text-white col-auto"
                                onClick={() => props.setView(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ModalItemCreate