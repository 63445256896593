import {useToast} from '../../../hooks/useToast'

const TopButtons: React.FC<any> = (props) => {

    const toast = useToast()


  const messageComming = (message: any) => {
    toast.info(message + ' is coming soon!')
  }

    return(
        <div className="row ms-4">
        <div className="col offset-1 d-flex align-items-cente">
          <button className="btn btn-default mx-1 typo-body DisableStockButton" onClick={() => messageComming('Assign item')}  >
            Assign item
          </button>

          <button className="btn btn-default mx-1 typo-body DisableStockButton" onClick={() => messageComming('Quarantine')}  >
            Quarantine
          </button>

          <button className="btn btn-default mx-1 typo-body DisableStockButton" onClick={() => messageComming('Retire item')}  >
            Retire item
          </button>

          <button className="btn btn-default mx-1 typo-body DisableStockButton" onClick={() => messageComming('Generate report')}  >
            Generate report
          </button>

          <button className="btn mx-1 typo-body largeButton ButtonFlag" onClick={() => messageComming('Flag item')} >
            Flag item
          </button>
        </div>
      </div>
    )
}

export default TopButtons