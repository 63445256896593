import { Modal } from 'reactstrap';

import { useState } from 'react';



type Props = {
    files: any;
    onClose: (value?: boolean) => void;
    // onConfirm: (value?: boolean) => void;
    title: string;
} & ({
    confirmText: string;
    cancelText: string;
    buttons?: undefined;
} | {
    buttons: React.ReactNode;
    confirmText?: undefined;
    cancelText?: undefined;
})

const ViewerFileModal: React.FC<Props> = ({
    onClose,
    title,
    buttons,
    cancelText,
    files
}) => {

    const [blobURL, setBlobURL] = useState<any>(null);



    const docs = files?.map((file) => {

        return { uri: file }
    });

    console.log(docs)
    return (
        <Modal isOpen={true} toggle={onClose.bind(null, undefined)} centered>
            <div className="container text-center">
                <div className="row p-4">
                    <div className="col-12">
                        <h2 className="font-weight-bold text-secondary typo-display">
                            {title}
                        </h2>
                    </div>
                    <div className="col-12 mt-3">
                        <iframe
                            src={docs[0]?.uri}
                            height={"500px"}
                            style={{border: '1px solid black', width: '100%'}}
                        />
                    </div>

                    <div className="col-12 mt-3">
                        <div className="row justify-content-evenly">
                            {buttons !== undefined ? buttons : (
                                <>
                                    <button
                                        className="btn btn-danger text-white col-auto"
                                        onClick={onClose.bind(null, false)}
                                    >
                                        {cancelText}
                                    </button>

                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ViewerFileModal;
