import { Modal } from "reactstrap";
import InputFloatingLabel from "../../UI/forms/InputFloatingLabel";
import { BlackbookService } from "src/services/blackbook";
import { useState } from "react";
import { useToast } from "../../../hooks/useToast";
import SelectInput from "src/components/UI/forms/Select";
import { ICategory } from 'src/models/Category';
import { useRequest } from 'src/hooks/useRequest';
import { useHistory } from "react-router-dom";
import { setActiveControlViewAll, setClient } from "../../../store/factory/ViewResources";
import { useDispatch } from "react-redux";

interface Props {
    view: boolean;
    setView: any;
    contact: any
    handleTabClick: any
    setDataIs?: any
}

const SuccesCreate: React.FC<Props> = (props) => {

    const { contact } = props

    const toast = useToast();

    const history = useHistory();

    const [activeControl, setActiveControl] = useState<any>('')

    const [type, settype] = useState(true)

    const dispatch = useDispatch()



    const handleType = (e: any) => {
        settype(e)
    }


    return (
        <Modal isOpen={props.view} toggle={() => { }} centered>
            <div className="container text-center">
                <div className="row p-4">
                    <div className="col-12">
                        <p className="ModalTitle"
                            style={{
                                fontSize: '24px', fontWeight: 'bold',
                                justifyContent: 'center', alignSelf: 'center',
                                display: 'flex', marginLeft: '1%', color: '#344469'
                            }}
                        >
                            Success
                        </p>
                    </div>

                    <div className="col-12 mt-3">
                        <div className="container-Selector">
                            {["create another contact", "go back to contacts",
                                "create project for this contact"].map((e, index) => (
                                    <div
                                        key={index}
                                        className="container-selector-map"
                                        style={index == 2 && contact.type != "CLIENT" ? { display: 'none' } : {}}
                                        onClick={() => {
                                            if (e == 'task') {
                                                //toast.info('task option' + " is coming soon!");
                                            } else {
                                                setActiveControl(e)
                                            }
                                        }}
                                        id={index + "-go-backtoContacts"}
                                    >

                                        <h6
                                            className={
                                                "factory-link"
                                            }
                                            onClick={
                                                () => {
                                                    if (index == 0) {
                                                        /*  contact.type == 'ORGANIZATION' && history.push(`/blackbook/432332347111638077?create=104861306456114188`)
                                                           contact.type == 'CLIENTS' && history.push(`/blackbook/432332347111638077?create=104861306456114166`)
                                                           contact.type == 'FREELANCE' && history.push(`/blackbook/432332347111638077?create=104861306456114177`)
                                                           contact.type == 'TEAM_MEMBERS' && history.push(`/blackbook/432332347111638077?create=104861306456114345`)
                                                           contact.type == 'PROSPECTS' && history.push(`/blackbook/432332347111638077?create=104861306456114135`)
                                                           */
                                                        props.setDataIs(true)
                                                        props.setView(false)
                                                        props.handleTabClick(0)
                                                    }

                                                    index == 1 && (
                                                        history.push(`/blackbook/${contact?._id}`)
                                                        // blackbook/432332347111638077?create=104861306456114188
                                                    )

                                                    if (index == 2) {
                                                        let obj = {
                                                            label: contact.entity.first_name + ' ' + props.contact.entity.last_name && props.contact.entity.last_name,
                                                            value: props.contact._id,
                                                        };

                                                        dispatch(setClient(obj.value))
                                                        history.push('/factory')
                                                        dispatch(setActiveControlViewAll('project'))
                                                    }

                                                }
                                            }
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {e}
                                        </h6>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="row justify-content-evenly">

                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default SuccesCreate